import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createNewAdminAPI, deleteAdminUserAPI, disableAdminUserAPI, getAllAdminUserAPI, updateAdminUserAPI } from '../../api/auth';


const initialState = {
    getAllUserFetching: false,
    createUserFetching: false,
    updateFetching: false,
    allAdmins: [],
    adminDetail: {}
}

export const createNewAdmin = createAsyncThunk('/adminuser/create-new', async (adminCreationPayload, { rejectWithValue }) => {
    const response = await createNewAdminAPI(adminCreationPayload)
    if (response.success) return response.data;
    return rejectWithValue(response.message)
})

export const getAllAdmins = createAsyncThunk('/adminuser/get-all', async ({ page }, { rejectWithValue }) => {
    const response = await getAllAdminUserAPI({ page })
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const updateAdmin = createAsyncThunk('/adminuser/update', async (updationPayload, { rejectWithValue }) => {
    const response = await updateAdminUserAPI(updationPayload)
    if (response.success) return response.data
    return rejectWithValue(response.message)
})


export const disableAdmin = createAsyncThunk('/adminuser/disable', async (deletionPayload, { rejectWithValue }) => {
    const response = await disableAdminUserAPI(deletionPayload)
    if (response.success) return response.data
    return rejectWithValue(response.message)
})


export const deleteAdmin = createAsyncThunk('/adminuser/delete', async (deletionPayload, { rejectWithValue }) => {
    const response = await deleteAdminUserAPI(deletionPayload)
    if (response.success) return response.data
    return rejectWithValue(response.message)
})


export const adminUserSlice = createSlice({
    name: 'adminUser',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(createNewAdmin.pending, (state) => {
                state.createUserFetching = true
            })
            .addCase(createNewAdmin.fulfilled, (state, action) => {
                state.createUserFetching = false
                state.error = false
                state.allAdmins.unshift(action.payload)
            })
            .addCase(createNewAdmin.rejected, (state, action) => {
                state.createUserFetching = false
                state.error = true
            })


            .addCase(getAllAdmins.pending, (state, action) => {
                state.getAllUserFetching = true
            })
            .addCase(getAllAdmins.fulfilled, (state, action) => {
                const { totalPages, results } = action.payload
                state.getAllUserFetching = false
                state.allAdmins = results
                state.totalPages = totalPages
            })
            .addCase(getAllAdmins.rejected, (state, action) => {
                state.getAllUserFetching = false
            })


            .addCase(updateAdmin.pending, (state, action) => {
                state.updateFetching = true
            })
            .addCase(updateAdmin.fulfilled, (state, action) => {
                state.updateFetching = false
                let tempAdmins = state.allAdmins
                for (let index = 0; index < tempAdmins.length; index++) {
                    const element = tempAdmins[index];
                    if (element.uid === action.payload.uid) {
                        tempAdmins[index] = action.payload
                        break;
                    }
                }
                state.allAdmins = tempAdmins
            })
            .addCase(updateAdmin.rejected, (state, action) => {
                state.updateFetching = false
            })


            .addCase(deleteAdmin.pending, (state, action) => {
                state.updateFetching = true
            })
            .addCase(deleteAdmin.fulfilled, (state, action) => {
                state.updateFetching = false
                let tempAdmins = state.allAdmins
                let indexToDelete = undefined;
                for (let index = 0; index < tempAdmins.length; index++) {
                    const element = tempAdmins[index];
                    if (element.uid === action.payload.uid) {
                        indexToDelete = index
                        break;
                    }
                }
                if (indexToDelete) {
                    tempAdmins.splice(indexToDelete, 1);
                }
                state.allAdmins = tempAdmins
            })
            .addCase(deleteAdmin.rejected, (state, action) => {
                state.updateFetching = false
            })

    }
})
