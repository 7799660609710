import { unwrapResult } from '@reduxjs/toolkit'
import React from 'react'
import { useEffect, useState } from 'react'
import { Accordion, Card, Form, Modal, Row, Spinner, Table, Col, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { printLabelApi } from '../../../app/api/general'
import { fetchSingleBusinessOrderDetail, updateBusinessOrderStatus } from '../../../app/features/shipmantrabusiness/order/businessOrderSlice'
import { printIcon } from '../../../FontAwesome'


function SkuDetails({ skuDetails }) {
    return (
        <>
            {
                skuDetails?.map((sku, index) => {
                    return (
                        <Row key={index}>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="orderID">
                                    <Form.Label>SKU id</Form.Label>
                                    <Form.Control value={sku.sku_id} type="text" placeholder="Order id" readOnly />
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="orderID">
                                    <Form.Label>SKU Name</Form.Label>
                                    <Form.Control value={sku.sku_name} type="text" placeholder="Order id" readOnly />
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="orderID">
                                    <Form.Label>SKU Qty</Form.Label>
                                    <Form.Control value={sku.qty} type="text" placeholder="Order id" readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                    )
                })
            }
        </>
    )
}



function Details({ order }) {
    return (
        <>
            <Row>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="orderID">
                        <Form.Label>Order id</Form.Label>
                        <Form.Control value={order.sm_orderid ? order.sm_orderid : order._id} type="text" placeholder="Order id" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="cost">
                        <Form.Label>Cost</Form.Label>
                        <Form.Control value={order.cost} type="text" placeholder="Cost" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="channel">
                        <Form.Label>Channel</Form.Label>
                        <Form.Control value={order.channel} type="text" placeholder="Order channel" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>Shipper</Form.Label>
                        <Form.Control value={order.shipperID} type="text" placeholder="Shipper" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="startAddr">
                        <Form.Label>Start address</Form.Label>
                        <Form.Control value={order.start?.address?.houseNo + order.start?.address?.buildingName + order.start?.address?.mapData?.address} type="text" placeholder="Address" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="endAddr">
                        <Form.Label>End address</Form.Label>
                        <Form.Control value={order.end?.address?.houseNo + order.end?.address?.buildingName + order.end?.address?.mapData?.address} type="text" placeholder="Address" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="bookedAt">
                        <Form.Label>Booked at</Form.Label>
                        <Form.Control value={order.bookedAt} type="text" placeholder="Booked at" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="orderID">
                        <Form.Label>Coupon</Form.Label>
                        <Form.Control value={order.coupon} type="text" placeholder="Coupon" readOnly />
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}


function PackageDetail({ order }) {
    const dispatch = useDispatch();

    const [packages, setPackages] = useState(order ? order.package : [])
    const [orderType, setOrderType] = useState('');

    useEffect(() => {
        if (order) {
            console.log(order)
            setPackages(order.package)
            setOrderType(order?.sm_orderid?.split("-")[0].toLowerCase());
        }
    }, [order])


    const updateOrderStatus = (event, awb) => {
        console.log(event.target.value)
        const body = {
            awb: awb,
            status: event.target.value,
            event: {
                eventType: "Order updated from admin",
                timestamp: new Date().toISOString(),
                status: event.target.value
            }
        }
        if (orderType === 'pan') {
            dispatch(updateBusinessOrderStatus(body))
                .then(unwrapResult)
                .then(() => {
                    alert('Order status updated successfully')
                })
        }
    }

    return (
        <>
            <Table striped bordered hover responsive>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th>Length</th>
                        <th>Breadth</th>
                        <th>Height</th>
                        <th>Weight</th>
                        <th>AWB</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        packages?.map((pkg, index) => {
                            return (
                                <tr key={index}>
                                    <td>{pkg.l}</td>
                                    <td>{pkg.b}</td>
                                    <td>{pkg.h}</td>
                                    <td>{pkg.weight}</td>
                                    <td>{pkg.awb}</td>
                                    {/* <td>
                                        <Form.Group className="mb-3" controlId="orderID">
                                            <Form.Control value={pkg.awb} type="text" placeholder="AWB" />
                                        </Form.Group>
                                    </td> */}
                                    <td>
                                        {orderType !== 'pan' ? order.order_status : pkg.status}
                                    </td>
                                    <td>
                                        <Form.Select defaultValue={pkg.status ? pkg.status : "Initiated"} name='order-status' onChange={(e) => updateOrderStatus(e, pkg.awb)} aria-label="Default select example">
                                            <option value='Initiated'>Initiated</option>
                                            <option value='To be placed'>To be placed</option>
                                            <option value='Placed'>Order placed</option>
                                            <option value='Manifested'>Order manifested</option>
                                            <option value='Out for pickup'>Out for pickup</option>
                                            <option value='At source hub'>At source hub</option>
                                            <option value='In transit'>In transit</option>
                                            <option value='At destination hub'>At destination hub</option>
                                            <option value='Out for delivery'>Out for delivery</option>
                                            <option value='Delivered'>Delivered</option>
                                            <option value='Cancelled'>Order cancelled</option>
                                            <option value='Under Hold'>Shipment under hold</option>
                                            <option value='Delivery failed'>Delivery failed</option>
                                        </Form.Select>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </>
    )
}

export default function OrderDetail({ show, handleClose, order }) {
    const dispatch = useDispatch();

    const { fetching, orderDetail } = useSelector(state => state.businessOrder);

    const [skuDetails, setSKUDetails] = useState([])
    const [labelLoader, setLableLoader] = useState(false);

    useEffect(() => {
        dispatch(fetchSingleBusinessOrderDetail(order.sm_orderid))
        let tempSkuArr = []
        if (order.sku) {
            tempSkuArr.push(...order.sku)
        } else {
            order?.package?.map(pkg => {
                tempSkuArr.push(...pkg.sku)
            })
        }
        setSKUDetails(tempSkuArr)
    }, [order])

    const generateLabel = (orderId) => {
        setLableLoader(true)
        printLabelApi(orderId)
            .then(res => {
                console.log(res);
                if (res.success) {
                    window.open(res.labelUrl)
                } else {
                    alert("There was some error while generating label")
                }
            })
            .catch(err => {
                console.error(err)
                alert("There was some error while generating label")
            })
            .finally(() => {
                setLableLoader(false)
            })
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg' centered style={{ backdropFilter: 'blur(20px)' }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span>Order details</span>
                        <Button className='ms-1' onClick={() => generateLabel(order.sm_orderid)} disabled={labelLoader}>
                            {printIcon}&nbsp;Print Manifest&nbsp;
                            {
                                labelLoader && <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            }
                        </Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        fetching ?
                            <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
                                <Spinner animation="border" />
                            </div>
                            :
                            <Accordion defaultActiveKey="package_details">
                                <Card className='mb-3'>
                                    <Card.Body className='p-0'>
                                        <Accordion.Item eventKey="package_details">
                                            <Accordion.Header style={{ fontWeight: 'bolder' }}>Package details</Accordion.Header>
                                            <Accordion.Body>
                                                <PackageDetail order={orderDetail} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Card.Body>
                                </Card>
                                <Card className='mb-3'>
                                    <Card.Body className='p-0'>
                                        <Accordion.Item eventKey="order_details">
                                            <Accordion.Header>Order Details</Accordion.Header>
                                            <Accordion.Body>
                                                <Details order={order} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Card.Body>
                                </Card>
                                <Card className='mb-3'>
                                    <Card.Body className='p-0'>
                                        <Accordion.Item eventKey="sku_details">
                                            <Accordion.Header>SKU Details</Accordion.Header>
                                            <Accordion.Body>
                                                <SkuDetails skuDetails={skuDetails} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Card.Body>
                                </Card>
                            </Accordion>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
