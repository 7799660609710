import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createBusinessWalletAPI, getBusinessUserWalletTransactionsAPI, rechargeBusinessWalletAPI } from '../../../api/shipmantrabusiness/wallet';


const initialState = {
    fetching: false,
    transactionFetching: false,
    walletTransactions: [],
    typeWalletTransactions: {},
    totalPages: 1
}


export const createBusinessWallet = createAsyncThunk('/business/wallet/create', async (accessToken, { rejectWithValue }) => {
    const response = await createBusinessWalletAPI(accessToken);
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const rechargeBusinessWallet = createAsyncThunk('/business/wallet/recharge', async (reqBody, { rejectWithValue }) => {
    const response = await rechargeBusinessWalletAPI(reqBody);
    if (response.success) return response.data
    return rejectWithValue("Some error occured while adding funds to the wallet.")
})

export const getWalletTransactions = createAsyncThunk('/get/business-wallet-transactions', async (data, { rejectWithValue }) => {
    const { uid, page = 1, transactionType = undefined } = data
    const response = await getBusinessUserWalletTransactionsAPI({ uid, page, transactionType });
    if (response.success) return response.data
    return rejectWithValue("Some error occured while fetching transactions of user.")
})


export const businessWalletSlice = createSlice({
    name: 'businessWallet',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(createBusinessWallet.pending, (state) => {
                state.fetching = true
            })
            .addCase(createBusinessWallet.fulfilled, (state, action) => {
                state.fetching = false
            })
            .addCase(createBusinessWallet.rejected, (state) => {
                state.fetching = false
            })

            .addCase(rechargeBusinessWallet.pending, (state) => {
                state.fetching = true
            })
            .addCase(rechargeBusinessWallet.fulfilled, (state, action) => {
                state.fetching = false
            })
            .addCase(rechargeBusinessWallet.rejected, (state) => {
                state.fetching = false
            })

            .addCase(getWalletTransactions.pending, (state) => {
                state.transactionFetching = true
            })
            .addCase(getWalletTransactions.fulfilled, (state, action) => {
                state.transactionFetching = false
                state.walletTransactions = action.payload.transactions
                state.totalPages = action.payload.totalPages
            })
            .addCase(getWalletTransactions.rejected, (state) => {
                state.transactionFetching = false
            })
    }
})
