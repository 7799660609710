import { checkLogin } from "../../utils/firebase";

const localhostUrl = 'http://localhost:8000'
const baseUrl = process.env.REACT_APP_BASE_URL

export const getCMSAnnouncementFeedAPI = async (reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = fetch(`${baseUrl}/cms/headers/announcement-bar`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}

export const getCMSSingleAnnouncementFeedAPI = async (id) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = fetch(`${baseUrl}/cms/headers/announcement-bar/getSingleFeed/${id}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}

export const addCMSAnnouncementFeedAPI = async (reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = fetch(`${baseUrl}/cms/headers/announcement-bar`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}

export const editCMSAnnouncementFeedAPI = async (reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = fetch(`${baseUrl}/cms/headers/announcement-bar/update`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}

export const deleteCMSAnnouncementFeedAPI = async (reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = fetch(`${baseUrl}/cms/headers/announcement-bar/delete`, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}