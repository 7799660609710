import React, { useState } from 'react'
import AWS from 'aws-sdk'
import { Form, Button, Modal, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { fetchBanners } from '../../app/features/banner/bannerSlice';
import { checkLogin } from '../../utils/firebase';

const S3_BUCKET = 'shipmantra-assets';
const REGION = 'ap-south-1';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})


export default function BannerUpload({ show, handleClose }) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState("")

    const { adminUser } = useSelector(state => state.adminUser)

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const uploadFiletoServer = async (params) => {
        const lastUpdatedBy = adminUser.email ? adminUser.email : adminUser.displayName
        const key = params.Key.replaceAll(" ", "+")
        const imgURL = `https://shipmantra-assets.s3.ap-south-1.amazonaws.com/${key}`

        let data = {}

        switch (redirectUrl) {
            case "/hyperlocal/rental":
                data = {
                    redirectUrl,
                    serviceType: '1.1'
                }
                break;
            case "/hyperlocal/two-wheeler":
                data = {
                    redirectUrl,
                    serviceType: '1'
                }
                break;
            case "/hyperlocal/on-demand":
                data = {
                    redirectUrl,
                    serviceType: '1'
                }
                break;
            case "/international":
                data = {
                    redirectUrl,
                    serviceType: '3'
                }
                break;
            default:
                data = {
                    redirectUrl,
                    serviceType: '99'
                }
                break;
        }

        const reqBody = {
            lastUpdatedBy,
            imgURL,
            data
        }

        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/banners/create`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        })
            .then(res => res.json())
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
        if (res.success) {
            alert("Banner uploaded");
            document.getElementById("bannerInputFile").value = "";
            setLoading(false)
            dispatch(fetchBanners())
        } else {
            setLoading(false)
            alert(res.message)
        }
    }

    const uploadFile = (file) => {
        console.log(file);
        const now = new Date().getTime();
        const fileName = file.name.split(".")[0] + now + "." + file.name.split(".")[1]
        // console.log(fileName);
        try {
            setLoading(true)
            const contentType = file.name.split(".")[1] + (file.name.split(".")[1] === 'svg' ? '+xml' : '')
            const params = {
                ACL: 'public-read',
                Body: file,
                Bucket: S3_BUCKET,
                Key: `banners/${fileName}`,
                ContentType: `image/${contentType}`
            };

            myBucket.putObject(params, async (err, data) => {
                if (err) {
                    setLoading(false)
                    alert("Error while uploading. " + err);
                } else {
                    console.log(params.Key + " uploaded");
                    await uploadFiletoServer(params)
                }
            })
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        //upload file to aws
        await uploadFile(selectedFile)
    }


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="bannerInputFile" className="mb-3">
                            <Form.Label>File</Form.Label>
                            <Form.Control type="file" onChange={handleFileInput} required />
                        </Form.Group>

                        <Form.Group controlId="bannerInputFile" className="mb-3">
                            <Form.Label>Link to</Form.Label>
                            <Form.Select aria-label="Default select example" value={redirectUrl} onChange={e => setRedirectUrl(e.currentTarget.value)}>
                                <option value="">Open this select menu</option>
                                <option value="/hyperlocal/rental">Hyperlocal-Rent a vehicle</option>
                                <option value="/hyperlocal/two-wheeler">Hyperlocal-Two Wheeler</option>
                                <option value="/hyperlocal/on-demand">Hyperlocal-Three Wheeler</option>
                                <option value="/hyperlocal/on-demand">Hyperlocal-Four Wheeler</option>
                                <option value="/international">International-Export</option>
                                <option value="/international/USA">International-Export USA</option>
                                <option value="/international/UK">International-Export UK</option>
                                <option value="/apsrtc/personal">APSRTC</option>
                            </Form.Select>
                        </Form.Group>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            {
                                loading ?
                                    <Spinner animation="border" variant="primary" />
                                    :
                                    <Button variant="primary" type="submit">
                                        Upload
                                    </Button>
                            }

                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal>
        </>
    )
}
