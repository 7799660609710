import { configureStore } from '@reduxjs/toolkit'
import { userSlice } from './features/user/userSlice'
import { bannerSlice } from './features/banner/bannerSlice'
import { enquirySlice } from './features/enquiries/enquirySlice'
import { citySlice } from './features/cities/citySlice'
import { orderSlice } from './features/order/orderSlice'
import { appUserSlice } from './features/customers/customerSlice'
import { couponSlice } from './features/coupons/couponSlice'
import { reviewSlice } from './features/reviews/reviewSlice'
import { countrySlice } from './features/countries/countrySlice'
import { shipperSlice } from './features/shipper/shipperSlice'
import { contentManagementSlice } from './features/cms/contentmanagementSlice'
import { shipmantraChargeSlice } from './features/shipmantracharges/shipmantraChargeSlice'
import { dashboardSlice } from './features/dashboard/dashboardSlice'
import { businessUserSlice } from './features/shipmantrabusiness/user/businessUserSlice'
import { businessWalletSlice } from './features/shipmantrabusiness/wallet/businessWalletSlice'
import { apsrtcBookingSlice } from './features/apsrtc_booking/apsrtcBookingSlice'
import { businessOrderSlice } from './features/shipmantrabusiness/order/businessOrderSlice'
import { generalSlice } from './features/general/generalSlice'
import { apsrtcCitySlice } from './features/apsrtc_booking/apsrtcCitySlice'
import { saasAgentSlice } from './features/saas/agent/agentSlice'
import { adminUserSlice } from './features/user/adminUserSlice'


export const store = configureStore({
    reducer: {
        adminUser: userSlice.reducer,
        newAdminUser: adminUserSlice.reducer,
        banner: bannerSlice.reducer,
        enquiry: enquirySlice.reducer,
        city: citySlice.reducer,
        order: orderSlice.reducer,
        customer: appUserSlice.reducer,
        coupon: couponSlice.reducer,
        review: reviewSlice.reducer,
        country: countrySlice.reducer,
        shipper: shipperSlice.reducer,
        cms: contentManagementSlice.reducer,
        shipmantra_charge: shipmantraChargeSlice.reducer,
        dashboard: dashboardSlice.reducer,
        businessUser: businessUserSlice.reducer,
        businessWallet: businessWalletSlice.reducer,
        businessOrder: businessOrderSlice.reducer,
        apsrtcBooking: apsrtcBookingSlice.reducer,
        general: generalSlice.reducer,
        apsrtcCity: apsrtcCitySlice.reducer,
        saasAgent: saasAgentSlice.reducer
    },
})