import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchBusinessEnquiriesAPI, fetchGeneralEnquiriesAPI, fetchNoOptionEnquiriesAPI, fetchShipperEnquiriesAPI, updateBusinessEnquiryAPI, updateGeneralEnquiryAPI, updateNoOptionEnquiryAPI, updateShipperEnquiryAPI } from '../../api/enquiry'

const initialState = {
    fetching: false,
    toggling: false,
    enquiries: [],
    businessEnquiries: [],
    shipperEnquiries: []
}

export const fetchNoOptionEnquiries = createAsyncThunk('/no-option-enquiries', async (obj, { rejectWithValue }) => {
    const response = await fetchNoOptionEnquiriesAPI()
    if (response) return response;
    return rejectWithValue('Oops there seems to be an error')
})

export const fetchBusinessEnquiries = createAsyncThunk('/business-enquiries', async ({ page }, { rejectWithValue }) => {
    const response = await fetchBusinessEnquiriesAPI(page)
    if (response.success) return {
        page: page,
        data: response.data
    };
    return rejectWithValue('Oops there seems to be an error')
})

export const updateBusinessEnquiry = createAsyncThunk('/update/business-enquiry', async (data, { rejectWithValue }) => {
    const response = await updateBusinessEnquiryAPI(data)
    if (response.success) return response.data
    return rejectWithValue('Oops there seems to be an error')
})


export const fetchShipperEnquiries = createAsyncThunk('/shipper-enquiries', async ({ page }, { rejectWithValue }) => {
    const response = await fetchShipperEnquiriesAPI(page)
    if (response.success) return {
        page: page,
        data: response.data
    };
    return rejectWithValue('Oops there seems to be an error')
})

export const updateShipperEnquiry = createAsyncThunk('/update/shipper-enquiry', async (data, { rejectWithValue }) => {
    const response = await updateShipperEnquiryAPI(data)
    if (response.success) return response.data
    return rejectWithValue('Oops there seems to be an error')
})

export const fetchGeneralEnquiries = createAsyncThunk('/general-enquiries', async ({ page }, { rejectWithValue }) => {
    const response = await fetchGeneralEnquiriesAPI(page)
    if (response.success) return {
        page: page,
        data: response.data
    };
    return rejectWithValue('Oops there seems to be an error')
})

export const updateGeneralEnquiry = createAsyncThunk('/update/general-enquiry', async (data, { rejectWithValue }) => {
    const response = await updateGeneralEnquiryAPI(data)
    if (response.success) return response.data
    return rejectWithValue('Oops there seems to be an error')
})

export const updateNoOptionEnquiry = createAsyncThunk('/update/enquiry', async ({ id, data }, { rejectWithValue }) => {
    const response = await updateNoOptionEnquiryAPI(id, data)
    console.log(response);
    if (response.success) return response.data
    return rejectWithValue('Oops there seems to be an error')
})


export const enquirySlice = createSlice({
    name: 'enquiry',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchNoOptionEnquiries.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchNoOptionEnquiries.fulfilled, (state, action) => {
                state.fetching = false
                state.enquiries = action.payload.data
            })
            .addCase(fetchNoOptionEnquiries.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })

            .addCase(updateNoOptionEnquiry.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateNoOptionEnquiry.fulfilled, (state, action) => {
                state.fetching = false
            })
            .addCase(updateNoOptionEnquiry.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })

            .addCase(fetchBusinessEnquiries.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchBusinessEnquiries.fulfilled, (state, action) => {
                state.fetching = false
                // state.enquiries = action.payload.enquiries
                const { page, data } = action.payload
                const { enquiries } = data
                if (page <= 1) {
                    state.businessEnquiries = enquiries
                } else {
                    enquiries?.map(enquiry => {
                        state.businessEnquiries.push(enquiry)
                    })
                }
            })
            .addCase(fetchBusinessEnquiries.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })

            .addCase(updateBusinessEnquiry.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateBusinessEnquiry.fulfilled, (state, action) => {
                state.fetching = false
                // state.enquiries = action.payload.enquiries
                let tempEnquiries = [...state.businessEnquiries];
                for (let index = 0; index < tempEnquiries.length; index++) {
                    const element = tempEnquiries[index];
                    if (element.enquiry_id === action.payload.enquiry_id) {
                        tempEnquiries[index] = action.payload
                        break;
                    }
                }
                state.businessEnquiries = tempEnquiries
            })
            .addCase(updateBusinessEnquiry.rejected, (state, action) => {
                state.fetching = false
            })

            .addCase(fetchShipperEnquiries.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchShipperEnquiries.fulfilled, (state, action) => {
                state.fetching = false
                // state.enquiries = action.payload.enquiries
                const { page, data } = action.payload
                const { enquiries } = data
                if (page <= 1) {
                    state.shipperEnquiries = enquiries
                } else {
                    enquiries?.map(enquiry => {
                        state.shipperEnquiries.push(enquiry)
                    })
                }
            })
            .addCase(fetchShipperEnquiries.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })

            .addCase(updateShipperEnquiry.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateShipperEnquiry.fulfilled, (state, action) => {
                state.fetching = false
                // state.enquiries = action.payload.enquiries
                let tempEnquiries = [...state.shipperEnquiries];
                for (let index = 0; index < tempEnquiries.length; index++) {
                    const element = tempEnquiries[index];
                    if (element.enquiry_id === action.payload.enquiry_id) {
                        tempEnquiries[index] = action.payload
                        break;
                    }
                }
                state.shipperEnquiries = tempEnquiries
            })
            .addCase(updateShipperEnquiry.rejected, (state, action) => {
                state.fetching = false
            })

            .addCase(fetchGeneralEnquiries.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchGeneralEnquiries.fulfilled, (state, action) => {
                state.fetching = false
                // state.enquiries = action.payload.enquiries
                const { page, data } = action.payload
                const { enquiries } = data
                if (page <= 1) {
                    state.enquiries = enquiries
                } else {
                    enquiries?.map(enquiry => {
                        state.enquiries.push(enquiry)
                    })
                }
            })
            .addCase(fetchGeneralEnquiries.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })

            .addCase(updateGeneralEnquiry.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateGeneralEnquiry.fulfilled, (state, action) => {
                state.fetching = false
                // state.enquiries = action.payload.enquiries
                let tempEnquiries = [...state.enquiries];
                for (let index = 0; index < tempEnquiries.length; index++) {
                    const element = tempEnquiries[index];
                    if (element.enquiry_id === action.payload.enquiry_id) {
                        tempEnquiries[index] = action.payload
                        break;
                    }
                }
                state.enquiries = tempEnquiries
            })
            .addCase(updateGeneralEnquiry.rejected, (state, action) => {
                state.fetching = false
            })
    }

})