import React, { useState } from 'react'
import { useEffect } from 'react';
import { Container, Form, Row, Col, Button, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { editCity, fetchOneCity } from '../../app/features/cities/citySlice';
import Loader from '../Loader';
import { backButtonIcon } from '../../FontAwesome';

export default function EditCity() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { id } = useParams()

    const { city, fetching } = useSelector(state => state.city)

    const [hyperlocal, setHyperlocal] = useState((city.type?.hyperlocal && city.type?.hyperlocal.length > 0) ? city.type?.hyperlocal : [""]);
    const [panIndia, setPanIndia] = useState((city.type?.panIndia && city.type?.panIndia.length > 0) ? city.type?.panIndia : [""]);
    const [international, setInternational] = useState((city.type?.international && city.type?.international.length > 0) ? city.type?.international : [""]);
    const [hyperlocal4Wheelers, setHyperlocal4Wheelers] = useState((city.type?.hyperlocal4Wheelers && city.type?.hyperlocal4Wheelers.length > 0) ? city.type?.hyperlocal4Wheelers : [""]);
    const [hyperlocalRental, setHyperlocalRental] = useState((city.type?.hyperlocalRental && city.type?.hyperlocalRental.length > 0) ? city.type?.hyperlocalRental : [""]);
    const [cityAliases, setCityAliases] = useState((city.aliases && city.aliases > 0) ? city.aliases : [""]);
    const [vehicles, setVehicles] = useState((city.type?.vehicles && city.type?.vehicles.length > 0) ? city.type?.vehicles : [{ value: "", text: "" }]);
    const [state, setState] = useState(city.state ? city.state : "");
    const [cityText, setCityText] = useState(city.cityText ? city.cityText : "")
    const [cityString, setCityString] = useState(city.cityString ? city.cityString : "")
    const [swLatLng, setswLatLng] = useState({ lat: city.sw?.lat ? city.sw?.lat : undefined, lng: city.sw?.lng ? city.sw?.lng : undefined })
    const [neLatLng, setneLatLng] = useState({ lat: city.ne?.lat ? city.ne?.lat : undefined, lng: city.ne?.lng ? city.ne?.lng : undefined })

    useEffect(() => {
        dispatch(fetchOneCity(id))
    }, [])

    useEffect(() => {
        const functionToRun = (e) => {
            e.preventDefault()
            console.log(e);
            // navigate('/city')
            e.returnValue = 'Are you sure?'
        }

        window.addEventListener("beforeunload", functionToRun);

        return () => {
            window.removeEventListener("beforeunload", functionToRun);
        }

    }, [])

    useEffect(() => {
        if (city) {
            console.log(city)
            setHyperlocal((city.type?.hyperlocal && city.type?.hyperlocal.length > 0) ? city.type?.hyperlocal : [""]);
            setPanIndia((city.type?.panIndia && city.type?.panIndia.length > 0) ? city.type?.panIndia : [""]);
            setInternational((city.type?.international && city.type?.international.length > 0) ? city.type?.international : [""]);
            setHyperlocal4Wheelers((city.type?.hyperlocal4Wheelers && city.type?.hyperlocal4Wheelers.length > 0) ? city.type?.hyperlocal4Wheelers : [""]);
            setHyperlocalRental((city.type?.hyperlocalRental && city.type?.hyperlocalRental.length > 0) ? city.type?.hyperlocalRental : [""]);
            setCityAliases((city.aliases && city.aliases.length > 0) ? city.aliases : [""]);
            setVehicles((city.type?.vehicles && city.type?.vehicles.length > 0) ? city.type?.vehicles : [{ value: "", text: "" }]);
            setState(city.state ? city.state : "");
            setCityText(city.cityText ? city.cityText : "");
            setCityString(city.cityString ? city.cityString : "");
            setswLatLng({ lat: city.sw?.lat ? city.sw?.lat : undefined, lng: city.sw?.lng ? city.sw?.lng : undefined });
            setneLatLng({ lat: city.ne?.lat ? city.ne?.lat : undefined, lng: city.ne?.lng ? city.ne?.lng : undefined });
        }
    }, [city])




    const handleInputChange = (e, index, prevList, setData) => {
        const list = [...prevList];
        // list[index].value = e.target.value;
        list[index] = e.target.value
        setData(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index, prevList, setData) => {
        const list = [...prevList];
        list.splice(index, 1);
        setData(list);
    };

    // handle click event of the Add button
    const handleAddClick = (prevList, setData) => {
        const values = [...prevList];
        values.push("")
        console.log(values);
        // values.push({ value: null });
        setData(values);
    };


    const handleVehicleInputChange = (e, index) => {
        const select = e.target
        // console.log(e.target.options[e.target.selectedIndex].text);
        const selectedOptionText = select.options[select.selectedIndex].text
        const list = [...vehicles];
        list[index].value = e.target.value;
        list[index].text = selectedOptionText;
        setVehicles(list);
    };

    // handle click event of the Remove button
    const handleVehicleRemoveClick = index => {
        const list = [...vehicles];
        list.splice(index, 1);
        setVehicles(list);
    };

    // handle click event of the Add button
    const handleVehicleAddClick = () => {
        const values = [...vehicles];
        values.push({ value: "", text: "" });
        setVehicles(values);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            id: city._id,
            state,
            cityText,
            cityString,
            aliases: cityAliases,
            ne: neLatLng,
            sw: swLatLng,
            type: {
                hyperlocal: (hyperlocal.length === 1 ? (hyperlocal[0] === "" ? [] : hyperlocal) : hyperlocal),
                panIndia: (panIndia.length === 1 ? (panIndia[0] === "" ? [] : panIndia) : panIndia),
                international: (international.length === 1 ? (international[0] === "" ? [] : international) : international),
                hyperlocal4Wheelers: (hyperlocal4Wheelers.length === 1 ? (hyperlocal4Wheelers[0] === "" ? [] : hyperlocal4Wheelers) : hyperlocal4Wheelers),
                hyperlocalRental: (hyperlocalRental.length === 1 ? (hyperlocalRental[0] === "" ? [] : hyperlocalRental) : hyperlocalRental),
                vehicles: (vehicles.length === 1 ? ((vehicles[0].value === "" || vehicles[0].text === "") ? [] : vehicles) : vehicles)
            },
        }
        console.log("Submitted data:", data);

        //dispatch code.....
        dispatch(editCity(data))
            .then(res => dispatch(fetchOneCity(id)))
    }

    if (fetching) return (
        <Loader />
    )

    return (
        <Container className='p-3'>
            <Button variant='primary' onClick={e => { navigate(-1) }}>{backButtonIcon}&nbsp;Back</Button>
            <hr />

            <Form onSubmit={handleSubmit}>
                <div className='text-end'><Button type='submit'>Submit</Button></div>
                {/* <hr /> */}
                <Row className="mb-3">
                    <Form.Group as={Col} xs='12' md='4' controlId="state">
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" value={state} placeholder="State" onChange={e => setState(e.target.value)} required />
                    </Form.Group>

                    <Form.Group as={Col} xs='12' md='4' controlId="cityText">
                        <Form.Label>CityText</Form.Label>
                        <Form.Control type="text" value={cityText} placeholder="CityText" onChange={e => setCityText(e.target.value)} required />
                    </Form.Group>

                    <Form.Group as={Col} xs='12' md='4' controlId="cityString">
                        <Form.Label>City String</Form.Label>
                        <Form.Control type="text" value={cityString} placeholder="City string" onChange={e => setCityString(e.target.value)} required />
                    </Form.Group>
                </Row>
                <hr />
                <fieldset>
                    <legend>City aliases</legend>
                    <Row>
                        {
                            cityAliases.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>Alias</Form.Label>
                                                <Form.Control autoFocus type="text" placeholder="Enter alias" value={x || ""} onChange={e => handleInputChange(e, i, cityAliases, setCityAliases)} />
                                            </Form.Group>
                                            <div>
                                                {cityAliases.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleRemoveClick(i, cityAliases, setCityAliases)}>Remove</Button>}
                                                {cityAliases.length - 1 === i && <Button variant='outline-success' onClick={e => handleAddClick(cityAliases, setCityAliases)}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>South-west(sw)</legend>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="sw-lat">
                                <Form.Label visuallyHidden>Lat</Form.Label>
                                <Form.Control type="text" value={swLatLng.lat} placeholder="lat" onChange={e => setswLatLng(prev => ({ ...prev, lat: e.target.value }))} required />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group className="mb-3" controlId="sw-lng">
                                <Form.Label visuallyHidden>Lng</Form.Label>
                                <Form.Control type="text" value={swLatLng.lng} placeholder="lng" onChange={e => setswLatLng(prev => ({ ...prev, lng: e.target.value }))} required />
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>North-east(ne)</legend>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="ne-lat">
                                <Form.Label visuallyHidden>Lat</Form.Label>
                                <Form.Control type="text" value={neLatLng.lat} placeholder="lat" onChange={e => setneLatLng(prev => ({ ...prev, lat: e.target.value }))} required />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group className="mb-3" controlId="ne-lng">
                                <Form.Label visuallyHidden>Lng</Form.Label>
                                <Form.Control type="text" value={neLatLng.lng} placeholder="lng" onChange={e => setneLatLng(prev => ({ ...prev, lng: e.target.value }))} required />
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>Hyperlocal</legend>
                    <Row>
                        {
                            hyperlocal?.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>shipper</Form.Label>
                                                <Form.Control type="text" placeholder="Enter shipper" value={x || ""} onChange={e => handleInputChange(e, i, hyperlocal, setHyperlocal)} />
                                            </Form.Group>
                                            <div>
                                                {hyperlocal.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleRemoveClick(i, hyperlocal, setHyperlocal)}>Remove</Button>}
                                                {hyperlocal.length - 1 === i && <Button variant='outline-success' onClick={e => handleAddClick(hyperlocal, setHyperlocal)}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>Pan India</legend>
                    <Row>
                        {
                            panIndia?.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>shipper</Form.Label>
                                                <Form.Control type="text" placeholder="Enter shipper" value={x || ""} onChange={e => handleInputChange(e, i, panIndia, setPanIndia)} />
                                            </Form.Group>
                                            <div>
                                                {panIndia.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleRemoveClick(i, panIndia, setPanIndia)}>Remove</Button>}
                                                {panIndia.length - 1 === i && <Button variant='outline-success' onClick={e => handleAddClick(panIndia, setPanIndia)}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>International</legend>
                    <Row>
                        {
                            international?.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>shipper</Form.Label>
                                                <Form.Control type="text" placeholder="Enter shipper" value={x || ""} onChange={e => handleInputChange(e, i, international, setInternational)} />
                                            </Form.Group>
                                            <div>
                                                {international.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleRemoveClick(i, international, setInternational)}>Remove</Button>}
                                                {international.length - 1 === i && <Button variant='outline-success' onClick={e => handleAddClick(international, setInternational)}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>Hyperlocal 4 wheeler</legend>
                    <Row>
                        {
                            hyperlocal4Wheelers?.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>shipper</Form.Label>
                                                <Form.Control type="text" placeholder="Enter shipper" value={x || ""} onChange={e => handleInputChange(e, i, hyperlocal4Wheelers, setHyperlocal4Wheelers)} />
                                            </Form.Group>
                                            <div>
                                                {hyperlocal4Wheelers.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleRemoveClick(i, hyperlocal4Wheelers, setHyperlocal4Wheelers)}>Remove</Button>}
                                                {hyperlocal4Wheelers.length - 1 === i && <Button variant='outline-success' onClick={() => handleAddClick(hyperlocal4Wheelers, setHyperlocal4Wheelers)}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>Hyperlocal Rental</legend>
                    <Row>
                        {
                            hyperlocalRental?.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>shipper</Form.Label>
                                                <Form.Control type="text" placeholder="Enter shipper" value={x || ""} onChange={e => handleInputChange(e, i, hyperlocalRental, setHyperlocalRental)} />
                                            </Form.Group>
                                            <div>
                                                {hyperlocalRental.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleRemoveClick(i, hyperlocalRental, setHyperlocalRental)}>Remove</Button>}
                                                {hyperlocalRental.length - 1 === i && <Button variant='outline-success' onClick={() => handleAddClick(hyperlocalRental, setHyperlocalRental)}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>Vehicles</legend>
                    <Row>
                        {
                            vehicles?.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>shipper</Form.Label>
                                                <Form.Select aria-label="Default select example" value={x.value || ""} onChange={e => handleVehicleInputChange(e, i)} >
                                                    <option disabled value="">Open this select menu</option>
                                                    <option value="tataAce">Tata Ace</option>
                                                    <option value="bolero">Bolero</option>
                                                    <option value="tata407">Tata 407</option>
                                                </Form.Select>
                                                {/* <Form.Control type="text" placeholder="Enter vehicle" value={x.value || ""} onChange={e => handleVehicleInputChange(e, i)} /> */}
                                            </Form.Group>
                                            <div>
                                                {vehicles.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleVehicleRemoveClick(i)}>Remove</Button>}
                                                {vehicles.length - 1 === i && <Button variant='outline-success' onClick={handleVehicleAddClick}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <div className='text-center'><Button type='submit'>Submit</Button></div>
            </Form>

            {/* <CityDetails hideAllCity={hideAllCity} /> */}
        </Container>
    )
}
