import { Badge, Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchAllPanindiaShipper, fetchSinglePanindiaShipper, updatePanIndiaShipper } from '../../app/features/shipper/shipperSlice'
import { sortAscIcon, sortDescIcon, sortIcon, editIcon, refreshIcon } from '../../FontAwesome'
import Loader from '../Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamationTriangle, faEye, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import MonitoringErrorDetail from './MonitoringErrorDetail'

function ShipperMonitoringCard({shipperData}) {

    const [showErrorDetail, setShowErrorDetail] = useState(false);
    const [busy, setBusy] = useState(false)
    const { company, integrationStatus, showWarningInSearch, lastError, isBookingApiAvailable, isActive } = shipperData;

    const dispatch = useDispatch()

    const editShipper = async (payload) => {
        // console.log(data)
        setBusy(true);
        await dispatch(updatePanIndiaShipper({ id: shipperData._id, reqBody: payload }))
        setBusy(false);
            // .then(() => dispatch(fetchSinglePanindiaShipper(shipperData.id)))
    }


    let APIStatusPill = <Badge pill bg="secondary">No Data</Badge>;
    switch (integrationStatus) {
        case 'ok':
            APIStatusPill = <Badge pill bg="success">OK</Badge>;
            break;
        case 'error':
            APIStatusPill = <Badge pill bg="danger">Error</Badge>;
            break;
        case 'maintenance':
            APIStatusPill = <Badge pill bg="warning">Under Maintenance</Badge>;
            break;
    
        default:
            break;
    }

    let ErrorStatusPill = <Badge pill bg="danger">Unresolved</Badge>;
    switch (lastError?.status) {
        case 'new_error':
            ErrorStatusPill = <Badge pill bg="danger">New Error</Badge>;
            break;
        case 'investigating':
            ErrorStatusPill = <Badge pill bg="warning">Investigating</Badge>;
            break;
        case 'fixing':
            ErrorStatusPill = <Badge pill bg="blue">Fixing</Badge>;
            break;
        case 'resolved':
            ErrorStatusPill = <Badge pill bg="success">Resolved</Badge>;
            break;
    
        default:
            break;
    }
    return (

        <Col
        md={6} lg={4}
      >   
      <Card
          style={{marginBottom:'10px', padding:'20px'}}
      >
          <div style={{fontSize:'20px', fontWeight: 800}}>
              {company}
          </div>
          <div style={{fontSize:'12px', fontWeight: 500}}>
              API Status:&nbsp;
              {isBookingApiAvailable?APIStatusPill:<Badge pill bg="secondary">No Integration</Badge>}
          </div>
          <hr/>
          <div>
              <div style={{marginBottom:'10px', display:'flex', alignItems:'center'}}>
                  <FontAwesomeIcon icon={faEye}/>&nbsp;Visible in search API:&nbsp;
                  <Form.Check
                    onChange={(e)=>editShipper({isActive: e.target.checked})}
                    style={{marginLeft:'10px'}}
                    type="switch"
                    id="visible-switch"
                    readOnly={!isBookingApiAvailable}
                    disabled={!isBookingApiAvailable || busy}
                    checked={isActive}
                  />
              </div>
              <div style={{display:'flex', alignItems:'center'}}>
               <FontAwesomeIcon icon={faExclamationTriangle}/>&nbsp;Show warning in search:&nbsp;
                  <Form.Check
                    onChange={(e)=>editShipper({showWarningInSearch: e.target.checked})}
                      style={{marginLeft:'10px'}}
                      type="switch"
                      id="warning-switch"
                      readOnly={!isBookingApiAvailable}
                      disabled={!isBookingApiAvailable || busy}
                      checked={showWarningInSearch}
                  />
              </div>
          </div>
          <hr/>
          <div style={{fontSize:'12px', fontWeight: 500, color:'#555', marginBottom:'10px'}}>
              <div>
                  Last error Timestamp: {lastError?new Date(lastError.timeStamp+"").toLocaleDateString():"N/A"}
              </div>
              <div>
                  Last error status:&nbsp;
                  {lastError?
                    ErrorStatusPill
                    :
                    <Badge pill bg="success">No Error</Badge>
                    }
              </div>
          </div>
          <Button disabled={!lastError} variant={lastError?undefined:"success"}
            onClick={()=>setShowErrorDetail(true)}
          >
              {lastError?
                <><FontAwesomeIcon icon={faMagnifyingGlass}/> View Last Error</>
                :
                <><FontAwesomeIcon icon={faCheck}/> No Error</>
              }
          </Button>
      </Card>
      {lastError && showErrorDetail && <MonitoringErrorDetail editShipper={editShipper} busy={busy} show={showErrorDetail} handleClose={()=>setShowErrorDetail(false)} errorData={lastError}/>}
      </Col>
    )
}

export default function PanIndiaShipperMonitoring() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { shippers, fetching } = useSelector(state => state.shipper)

    const [sortType, setSortType] = useState("updatedAt-desc")
    const [sortedResults, setSortedResults] = useState(shippers ? shippers : [])

    const handleSort = (type) => {
        const order = sortType.split("-")[1]
        if (order === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (order === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        dispatch(fetchAllPanindiaShipper())
    }, [])

    useEffect(() => {
        if (!fetching) {
            const [attr, type] = sortType.split("-")
            let temp = [...shippers]
            if (attr === "updatedAt" || attr === "createdAt") {
                if (type === "asc") {
                    temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) =>
                        new Date(b[attr]) - new Date(a[attr])
                    )
                    setSortedResults(temp)
                }
            } else {
                if (type === "asc") {
                    temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                    setSortedResults(temp)
                }
            }
        }
    }, [sortType, fetching, shippers])

    // if (fetching) return (
    //     <Loader />
    // )

    return (
        <div style={{ padding: '30px' }}>
            <h3 style={{ marginBottom: '30px' }}>Pan india Shippers - API Status</h3>
            <div className="d-flex flex-column flex-md-row justify-content-between">
                {/* <Button onClick={() => navigate('/shipper/add/panIndiaShipper')}>Add new Shipper</Button> */}
                <Button variant='secondary' onClick={() => dispatch(fetchAllPanindiaShipper())}>{refreshIcon}&nbsp;Refresh</Button>
            </div>
            <hr />
            <div style={{padding:'15px', borderRadius:'10px', backgroundColor:'#f8f8f8'}}>
                <Row>
                {
                        sortedResults.map((shipper, index) => {
                            return(
                                <ShipperMonitoringCard shipperData={shipper} key={'shipper_monitoring_card_'+index}/>
                            )
                        })
                }
                </Row>
            </div>
            
        </div>
    )
}
