import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react'
import { Form, Button, Spinner, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import App from '../../App';
import { signIn } from '../../app/features/user/userSlice';
import { adminLoginWithEmailPassword } from '../../utils/firebase';
import './Auth.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { adminUser, fetching, error, isAuth } = useSelector(state => state.adminUser)
    const [showPassword, setShowPassword] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [searchParams] = useSearchParams()


    useEffect(() => {
        if (isAuth) {
            const redirectURL = searchParams.get('redirect')
            // console.log(searchParams.get('redirect'))
            // dispatch(clearState());
            navigate(redirectURL ? redirectURL : '/')
        }
        if (error) {
            // toast.error(errorMessage);
            // dispatch(clearState());
            console.log("Some error occurred");
        }
    }, [isAuth, error]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const email = e.target.email.value
        const password = e.target.password.value
        // console.log(email);
        // console.log(password)
        // const reqBody = {
        //     email,
        //     password
        // }

        setIsBusy(true)
        adminLoginWithEmailPassword(email, password)
            .then((res) => {
                console.log(res);
                dispatch(signIn())
                    .then(unwrapResult)
                    .then(() => {
                        // const redirectURL = searchParams.get('redirect');
                        // if (redirectURL !== null) {
                        //     navigate(redirectURL);
                        // }
                    }).catch(() => {
                        alert("User doesn't exist")
                    });
            })
            .catch((err) => {
                alert('Email or password is incorrect')
            })
            .finally(() => {
                setIsBusy(false)
            })
    }

    return (
        <>
            <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '100vh' }}>
                <Form className='p-5 login-form' onSubmit={handleSubmit}>
                    <h2 className='text-center'>Login</h2>
                    <hr />
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email address" required />
                        <Form.Text className="text-muted">
                            We'll never share your credentials with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control type={showPassword ? "text" : "password"} placeholder="Password" required />
                            <InputGroup.Text id="btnGroupAddon" style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}>
                                {!showPassword && <FontAwesomeIcon icon={faEye} />}
                                {showPassword && <FontAwesomeIcon icon={faEyeSlash} />}
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={fetching || isBusy}>
                        Submit&nbsp;
                        {
                            (fetching || isBusy) && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </Button>
                </Form>
            </div>
        </>
    )
}
