import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { addNewShipmantraChargeAPI, getShipmantraChargesForServiceAPI } from '../../api/shipmantracharges';
import { addNewPanIndiaShipperAPI } from '../../api/shippers/panindiashippers';

const initialState = {
    fetching: false,
    allCharges: {}
}

export const getShipmantraChargeForServiceType = createAsyncThunk('/charges/addNew', async (serviceType, { rejectWithValue }) => {
    const response = await getShipmantraChargesForServiceAPI(serviceType);
    if (response.success) return response.data
    return rejectWithValue(response.message)
})


export const addNewShipmantraCharge = createAsyncThunk('/getchargesforservicetype/', async (data, { rejectWithValue }) => {
    const response = await addNewShipmantraChargeAPI(data);
    if (response.success) return response.data
    return rejectWithValue('Oops there seems to be an error while adding charges')
})

export const shipmantraChargeSlice = createSlice({
    name: 'shipmantra-charge-slice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addNewShipmantraCharge.pending, (state) => {
                state.fetching = true
            })
            .addCase(addNewShipmantraCharge.fulfilled, (state, action) => {
                state.fetching = false
                // console.log("PAYLOAD");
                // console.log(action.payload);
                alert("Shipmantra charge added successfully")
            })
            .addCase(addNewShipmantraCharge.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
                state.allCharges = {}
            })
            .addCase(getShipmantraChargeForServiceType.pending, (state) => {
                state.fetching = true
            })
            .addCase(getShipmantraChargeForServiceType.fulfilled, (state, action) => {
                state.fetching = false
                state.allCharges = action.payload
            })
            .addCase(getShipmantraChargeForServiceType.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
                state.allCharges = {}
            })
    }
})