const baseUrl = process.env.REACT_APP_BASE_URL
const localhostUrl = 'http://localhost:8000'
const { checkLogin } = require('../../../utils/firebase')

export const fetchAllHyShippersAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = fetch(`${baseUrl}/shippers`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}

export const fetchSingleHyShipperAPI = async (id) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/hyperlocal-shippers/getSingleShipper/${id}`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error)
    }
}

export const addHyShipperAPI = async (reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/shippers`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error)
    }
}

export const updateHyShippperAPI = async (id, reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = fetch(`${baseUrl}/hyperlocal-shippers/update/${id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error)
    }
}