import React from 'react'
import { Form } from 'react-bootstrap'
import { Loader } from "@googlemaps/js-api-loader"
import { useEffect } from 'react';

let APIKEY = '';
APIKEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : ''
const loader = new Loader({
    apiKey: APIKEY,
    version: "weekly",
    libraries: ["places"]
});


export default function MapInput({ setMapData, value, id, restrictedCountryCode }) {
    useEffect(() => {
        let autocomplete;
        const input = document.getElementById(id);

        if (value) {
            input.value = value;
        } else {
            input.value = '';
        }

        loader
            .load()
            .then((google) => {

                const options = {
                    // bounds: city,
                    // location: {latitude:19.0453567,longitude:72.8379152},
                    // radius: 50000,
                    fields: ["address_components", "geometry", "icon", "name"],
                    // strictBounds: true
                };

                if (restrictedCountryCode) {
                    options.componentRestrictions = { country: restrictedCountryCode }
                }

                autocomplete = new google.maps.places.Autocomplete(input, options);

                autocomplete.addListener('place_changed', makeHandler(autocomplete));
            })
            .catch(e => {
                // do something
                console.log('Map error' + e)
            });

    }, []);


    const makeHandler = (autocomplete) => {
        return async function () {
            const place = autocomplete.getPlace();
            // console.log("PLACE", place);
            var pincode = '';
            var state = ''
            var city = ''
            var country = ''
            const input = document.getElementById(id);

            const addressComponents = place.address_components;
            if (addressComponents) {
                for (let i = 0; i < addressComponents.length; i++) {
                    const typesArray = addressComponents[i].types
                    for (let j = 0; j < typesArray.length; j++) {
                        if (typesArray[j] === "postal_code")
                            pincode = addressComponents[i].long_name
                        if (typesArray[j] === "administrative_area_level_1")
                            state = addressComponents[i].long_name
                        if (typesArray[j] === "locality")
                            city = addressComponents[i].long_name
                        if (typesArray[j] === "country")
                            country = addressComponents[i].long_name
                    }
                }
            }

            if (place !== undefined && place.geometry !== undefined && place.geometry.location !== undefined) {
                var placeData = {
                    address: input.value,
                    pincode: pincode,
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                    state: state,
                    city: city,
                    country: country
                };

                if (setMapData !== undefined)
                    await setMapData(placeData);

                // if (onSelect !== undefined) {
                //     onSelect()
                // }

                // if (getGoogleData != undefined) {
                //     getGoogleData(placeData);
                // }
            }
        }
    }

    return (
        <Form.Control id={id} type='text' placeholder='Search by address, landmark or locality' onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
    )
}
