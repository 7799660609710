import { Button, Table } from 'react-bootstrap'
import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchAllPanindiaShipper } from '../../app/features/shipper/shipperSlice'
import { sortAscIcon, sortDescIcon, sortIcon, editIcon, refreshIcon } from '../../FontAwesome'
import Loader from '../Loader'

export default function PanIndiaShippers() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { shippers, fetching } = useSelector(state => state.shipper)

    const [sortType, setSortType] = useState("updatedAt-desc")
    const [sortedResults, setSortedResults] = useState(shippers ? shippers : [])

    const handleSort = (type) => {
        const order = sortType.split("-")[1]
        if (order === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (order === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        dispatch(fetchAllPanindiaShipper())
    }, [])

    useEffect(() => {
        if (!fetching) {
            const [attr, type] = sortType.split("-")
            let temp = [...shippers]
            if (attr === "updatedAt" || attr === "createdAt") {
                if (type === "asc") {
                    temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) =>
                        new Date(b[attr]) - new Date(a[attr])
                    )
                    setSortedResults(temp)
                }
            } else {
                if (type === "asc") {
                    temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                    setSortedResults(temp)
                }
            }
        }
    }, [sortType, fetching, shippers])

    if (fetching) return (
        <Loader />
    )

    return (
        <div style={{ padding: '30px' }}>
            <h3 style={{ marginBottom: '30px' }}>Pan india Shippers</h3>
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <Button onClick={() => navigate('/shipper/add/panIndiaShipper')}>Add new Shipper</Button>
                <Button variant='secondary' onClick={() => dispatch(fetchAllPanindiaShipper())}>{refreshIcon}&nbsp;Refresh</Button>
            </div>
            <hr />
            <Table striped bordered hover responsive>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th>Shipper UID</th>
                        <th>
                            Company&nbsp;
                            {sortType.split("-")[0] !== "company" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("company")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "company" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("company")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "company" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("company")}>{sortDescIcon}</span>
                            }
                        </th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Services</th>
                        <th>
                            Created at&nbsp;
                            {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                            }
                        </th>
                        <th>
                            Updated at&nbsp;
                            {sortType.split("-")[0] !== "updatedAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortDescIcon}</span>
                            }
                        </th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sortedResults.map((shipper, index) => {
                            const updatedAtDateString = new Date(shipper.updatedAt);
                            const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();

                            const createdAtDateString = new Date(shipper.createdAt);
                            const createdAtDate = createdAtDateString.toDateString() + ", " + createdAtDateString.toLocaleTimeString();
                            return (
                                <tr key={index}>
                                    <td>{shipper.shipperUID}</td>
                                    <td>{shipper.company}</td>
                                    <td>{shipper.phone}</td>
                                    <td>{shipper.email}</td>
                                    <td>
                                        {
                                            shipper.services?.map((service, index) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td key={index}>{service.label}</td>
                                                        </tr>
                                                        {index !== (shipper.services.length - 1) && < hr className='my-2' />}
                                                    </>
                                                )
                                            })
                                        }
                                    </td>
                                    <td>{createdAtDate}</td>
                                    <td>{updatedAtDate}</td>
                                    <td>
                                        <Button variant='primary' onClick={() => navigate(`/shipper/panIndiaShipper/${shipper._id}`)}>{editIcon}</Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}
