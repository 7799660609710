import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react'
import { Button, Table, Modal, Row, Col, Spinner, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { allotExpressShipper, allotHypShipper, allotManualExpressShipper, getAllPendingOrders, searchHypShippers, searchPanShippers } from '../../../app/features/shipmantrabusiness/order/businessOrderSlice';
import { backButtonIcon, refreshIcon, phoneIcon } from '../../../FontAwesome';
import Loader from '../../Loader'
import VoiceCall from '../../Misc/VoiceCall';


function ShipperRow({ shipper, orderType, placeOrder, setSelectedShipperID, setIsPlacingManualOrder }) {
    const { bookShipperFetching } = useSelector(state => state.businessOrder);


    return (
        <>
            <Row>
                <Col xs={4}>
                    <img src={shipper.shipper.logoURL} height={30} alt={shipper.shipper.fullName} />
                    {!shipper.shipper.isBookingApiAvailable && <p className="text-muted mb-0">**Place manual order**</p>}
                    {/* <br /> */}
                </Col>
                <Col xs={2}>
                    {/* {shipper.shipper.fullName} */}
                    {orderType === 'hyperlocal' ? shipper.estimate.time + " min" : "2-3 days"}
                </Col>
                <Col xs={2}>
                    {shipper.estimate.actualCost && <p style={{ textDecoration: 'line-through', marginBottom: '5px' }}>₹ {shipper.estimate.actualCost}</p>}
                    <span>₹ {shipper.estimate.cost}</span>
                </Col>
                <Col xs={4}>
                    <Row>
                        <Col sm={3}>
                            {
                                shipper.shipper.isBookingApiAvailable &&
                                <Button disabled={bookShipperFetching} onClick={() => placeOrder(shipper.shipper.name)}>
                                    Book&nbsp;
                                    {
                                        bookShipperFetching && <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    }
                                </Button>
                            }
                        </Col>
                        <Col sm={9}>
                            <Button variant="info" className='ms-1' onClick={() => { setIsPlacingManualOrder(true); setSelectedShipperID(shipper.shipper.name) }}>Place manual order</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
        </>
    )
}

function ManualOrderContainer({ order, shipper, orderType }) {
    const dispatch = useDispatch();
    const [packageDetails, setPackageDetails] = useState(order.package ? [...order.package] : [])
    const { bookShipperFetching } = useSelector(state => state.businessOrder)

    const handleAwbChange = (event, index) => {
        let tempPackageDetails = JSON.parse(JSON.stringify(packageDetails));
        tempPackageDetails[index].awb = event.target.value
        setPackageDetails(tempPackageDetails)
    }

    const handlePackageStatusChange = (event, index) => {
        let tempPackageDetails = JSON.parse(JSON.stringify(packageDetails));
        tempPackageDetails[index].status = event.target.value
        setPackageDetails(tempPackageDetails)
    }

    const placeManualOrder = (e) => {
        console.log(e.preventDefault())
        const reqBody = {
            ...order,
            package: packageDetails,
            shipperID: shipper
        }
        console.log(reqBody)

        if (orderType === 'pi') {
            dispatch(allotManualExpressShipper(reqBody))
                .then(unwrapResult)
                .then(() => {
                    alert(`Order placed with ${shipper}`)
                    dispatch(getAllPendingOrders())
                })
                .catch(err => {
                    console.log('There was some err while placing an order ')
                    console.log(err)
                    if (err.message) {
                        alert(err.message)
                    } else {
                        alert('There was some err while placing an order ')
                    }
                })
        } else if (orderType === 'hyperlocal') {
            dispatch(allotHypShipper(reqBody))
                .then(unwrapResult)
                .then(() => {
                    alert(`Order placed with ${shipper}`)
                    dispatch(getAllPendingOrders())
                })
                .catch(err => {
                    console.log('There was some err while placing an order ')
                    console.log(err)
                    if (err.message) {
                        alert(err.message)
                    } else {
                        alert('There was some err while placing an order ')
                    }
                })
        }
    }

    return (
        <>
            <Form onSubmit={placeManualOrder}>
                {
                    order?.package?.map((pkg, index) => {
                        return (
                            <Row key={index}>
                                <Col>
                                    <Form.Group className="mb-3" controlId="length">
                                        <Form.Label>Length</Form.Label>
                                        <Form.Control value={pkg.l} type="text" placeholder="Length of the package" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="breadth">
                                        <Form.Label>Breadth</Form.Label>
                                        <Form.Control value={pkg.b} type="text" placeholder="Breadth of the package" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="height">
                                        <Form.Label>Height</Form.Label>
                                        <Form.Control value={pkg.h} type="text" placeholder="Height of the package" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="weight">
                                        <Form.Label>Weight</Form.Label>
                                        <Form.Control value={pkg.weight} type="text" placeholder="Weight of the package" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="awb">
                                        <Form.Label>AWB</Form.Label>
                                        <Form.Control value={packageDetails[index].awb} type="text" placeholder="AWB of the package" onChange={(e) => handleAwbChange(e, index)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="status">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select defaultValue={pkg.status ? pkg.status : "Initiated"} value={packageDetails[index].status} onChange={(e) => handlePackageStatusChange(e, index)} placeholder="Status of the package" name="package_status">
                                            <option value='Initiated'>Initiated</option>
                                            <option value='To be placed'>To be placed</option>
                                            <option value='Placed'>Order placed</option>
                                            <option value='Manifested'>Order manifested</option>
                                            <option value='Out for pickup'>Out for pickup</option>
                                            <option value='At source hub'>At source hub</option>
                                            <option value='In transit'>In transit</option>
                                            <option value='At destination hub'>At destination hub</option>
                                            <option value='Out for delivery'>Out for delivery</option>
                                            <option value='Delivered'>Delivered</option>
                                            <option value='Cancelled'>Order cancelled</option>
                                            <option value='Under Hold'>Shipment under hold</option>
                                            <option value='Delivery failed'>Delivery failed</option>
                                            <option value='Shipper not allotted'>Shipper not allotted</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        )
                    })
                }
                <Button type='submit' disabled={bookShipperFetching}>
                    Update&nbsp;
                    {
                        bookShipperFetching && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                </Button>
            </Form>
        </>
    )
}


function ShipperResults({ order, show, handleClose }) {
    const dispatch = useDispatch();
    const { searchShipperFetching } = useSelector(state => state.businessOrder)

    const [searchResults, setSearchResults] = useState([])
    const [isPlacingManualOrder, setIsPlacingManualOrder] = useState(false);
    const [selectedShipperID, setSelectedShipperID] = useState('')

    const orderType = order?.order_type?.split("_")[0]

    let packageWeight = 0;
    order?.package?.map(pkg => {
        packageWeight += pkg.weight
    })

    useEffect(() => {
        if (orderType === 'hyperlocal') {
            const reqBody = {
                weight: packageWeight,
                start: order?.start?.address?.mapData,
                end: order?.end?.address?.mapData,
                sku: order?.sku,
                vehicle: order?.vehicle,
                user: order?.user
            }
            dispatch(searchHypShippers(reqBody))
                .then(unwrapResult)
                .then(res => {
                    setSearchResults(res.data)
                })
        } else if (orderType === 'pi') {
            const reqBody = {
                weight: packageWeight,
                start: order?.start?.address?.mapData,
                end: order?.end?.address?.mapData,
                service: "express-delivery",
                user: order?.user
            }
            dispatch(searchPanShippers(reqBody))
                .then(unwrapResult)
                .then(res => {
                    setSearchResults(res.data)
                })
        }
    }, [order])


    const placeOrder = (shipperID) => {
        const reqBody = {
            ...order,
            shipperID: shipperID
        }
        console.log(reqBody)

        if (orderType === 'pi') {
            dispatch(allotExpressShipper(reqBody))
                .then(unwrapResult)
                .then(() => {
                    handleClose()
                    alert(`Order placed with ${shipperID}`)
                    dispatch(getAllPendingOrders())
                })
                .catch(err => {
                    console.log('There was some err while placing an order ')
                    console.log(err)
                    if (err.message) {
                        alert(err.message)
                    } else {
                        alert('There was some err while placing an order ')
                    }
                })
        } else if (orderType === 'hyperlocal') {
            dispatch(allotHypShipper(reqBody))
                .then(unwrapResult)
                .then(() => {
                    handleClose()
                    alert(`Order placed with ${shipperID}`)
                    dispatch(getAllPendingOrders())
                })
                .catch(err => {
                    console.log('There was some err while placing an order ')
                    console.log(err)
                    if (err.message) {
                        alert(err.message)
                    } else {
                        alert('There was some err while placing an order ')
                    }
                })
        }
    }


    return (
        <Modal show={show} size='xl' onHide={handleClose}>
            <Modal.Body>
                {isPlacingManualOrder && <Button variant='secondary' onClick={() => setIsPlacingManualOrder(false)}>{backButtonIcon}&nbsp;Back to shippers list</Button>}
                <hr />
                {
                    isPlacingManualOrder ? <ManualOrderContainer orderType={orderType} order={order} shipper={selectedShipperID} /> :
                        searchShipperFetching ? <Loader />
                            :
                            <>
                                <Row style={{ fontWeight: 'bolder' }}>
                                    <Col xs={4}>
                                        Shipper Name
                                    </Col>
                                    <Col xs={2}>
                                        Time
                                    </Col>
                                    <Col xs={2}>
                                        Cost
                                    </Col>
                                    <Col xs={4}>
                                    </Col>
                                </Row>
                                <hr />
                                {
                                    searchResults.map((shipper, index) => {
                                        return <ShipperRow shipper={shipper} orderType={orderType} key={index} placeOrder={placeOrder} setSelectedShipperID={setSelectedShipperID} setIsPlacingManualOrder={setIsPlacingManualOrder} />
                                    })
                                }
                            </>
                }
            </Modal.Body>
        </Modal>
    )
}


function OrderRow({ order }) {
    const dispatch = useDispatch();
    const [showShippers, setShowShippers] = useState(false)

    const [numberToCall, setNumberToCall] = useState('')
    const [showVoiceCallModal, setShowVoiceCallModal] = useState(false)

    const orderType = order.order_type?.split("_")[0]

    // const updatedAtDateString = new Date(order.updatedAt);
    // const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();
    return (
        <>
            <tr>
                <td>{order.sm_orderid}</td>
                <td>
                    Name: {order.start?.contact?.name}
                    <br />
                    Phone: {order.start?.contact?.phone}&nbsp;<span style={{ cursor: 'pointer' }} onClick={() => { setNumberToCall(order.start?.contact?.phone); setShowVoiceCallModal(true) }}>{phoneIcon}</span>
                    <br />
                    City: {order.start?.address?.mapData?.city}
                    <br />
                    Address: {order.start?.address?.mapData?.address}
                </td>
                <td>
                    Name: {order.end?.contact?.name}
                    <br />
                    Phone: {order.end?.contact?.phone}&nbsp;<span style={{ cursor: 'pointer' }} onClick={() => { setNumberToCall(order.end?.contact?.phone); setShowVoiceCallModal(true) }}>{phoneIcon}</span>
                    <br />
                    City: {order.end?.address?.mapData?.city}
                    <br />
                    Address: {order.end?.address?.mapData?.address}
                </td>
                <td>
                    {
                        orderType === 'hyperlocal' ? "Hyperlocal" :
                            (orderType === 'pi' ? "Pan india" : "International")
                    }
                </td>
                <td>
                    {
                        orderType === 'hyperlocal' ?
                            order.sku?.map((item, index) => {
                                return <span key={index}>{item.sku_name} x{item.qty} <br /></span>
                            }) :
                            order.package?.map(pkg => {
                                return (
                                    pkg?.sku?.map((item, index) => {
                                        return <span key={index}>{item.sku_name} x{item.qty} <br /></span>
                                    })
                                )
                            })
                    }
                </td>
                {/* <td>{updatedAtDate}</td> */}
                <td>
                    <Button onClick={() => setShowShippers(true)}>Allocate Shipper</Button>
                </td>
            </tr>
            <VoiceCall show={showVoiceCallModal} handleClose={() => setShowVoiceCallModal(false)} numberToCall={numberToCall} orderType='apsrtc' orderId={order?.sm_orderid} />
            {showShippers && <ShipperResults show={showShippers} handleClose={() => setShowShippers(false)} order={order} />}
        </>
    )
}


export default function PendingOrders() {
    const dispatch = useDispatch();
    const { orders, fetchingPendingOrders } = useSelector(state => state.businessOrder);


    useEffect(() => {
        dispatch(getAllPendingOrders())
    }, [])


    if (fetchingPendingOrders) {
        return (<Loader />)
    }


    return (
        <div style={{ padding: '30px' }}>
            <div className="d-flex justify-content-between">
                <h3>Pending Orders</h3>
                <Button onClick={() => dispatch(getAllPendingOrders())} variant='secondary'>{refreshIcon}&nbsp;Refresh</Button>
            </div>
            <hr />
            <Table striped bordered hover responsive>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th className='col-2'>Shipper ID</th>
                        <th className='col-3'>Start</th>
                        <th className='col-3'>End</th>
                        <th className='col-1'>Order Type</th>
                        <th className='col-1'>Items</th>
                        {/* <th>
                            Updated at&nbsp;
                            {sortType.split("-")[0] !== "updatedAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortDescIcon}</span>
                            }
                        </th> */}
                        <th className='col-2'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        orders?.map((order, index) => {
                            return (
                                <OrderRow order={order} key={index} />
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}
