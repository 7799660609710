import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, ToastContainer, Toast, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { updateAdminUser } from '../../app/features/user/userSlice'
import { closeIcon, copyIcon } from '../../FontAwesome'

export default function Profile({ show, handleClose }) {
    const { adminUser, updating } = useSelector(state => state.adminUser)
    const dispatch = useDispatch()

    const [showTextCopiedToast, setShowTextCopiedToast] = useState(false);
    const [displayName, setDisplayName] = useState(adminUser.displayName ? adminUser.displayName : "")

    useEffect(() => {
        if (adminUser) {
            setDisplayName(adminUser.displayName ? adminUser.displayName : "");
        }
    }, [adminUser])

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            ...adminUser
        }
        data.displayName = displayName ? displayName : ""
        dispatch(updateAdminUser(data))
    }

    const copyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        setShowTextCopiedToast(true)
        setTimeout(() => {
            setShowTextCopiedToast(false)
        }, 3000);
    }


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        showTextCopiedToast && <ToastContainer position='top-center'>
                            <Toast bg='info' delay={3000} autohide style={{ margin: '20px', fontSize: '20px', textAlign: 'center' }}>
                                <Toast.Body>
                                    <span className='px-2'>Text Copied</span>
                                    <span style={{ float: 'right', cursor: 'pointer' }} onClick={() => setShowTextCopiedToast(false)}>{closeIcon}</span>
                                </Toast.Body>
                            </Toast>
                        </ToastContainer>
                    }
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="displayName">
                            <Form.Label>Display Name</Form.Label>
                            <Form.Control type="text" placeholder="Display name" value={displayName} onChange={e => setDisplayName(e.currentTarget.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder="Username" value={adminUser?.username} disabled />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="name@example.com" value={adminUser?.email} disabled />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>
                                UID
                                <span className='ms-1' style={{ cursor: 'pointer' }} onClick={() => copyText(adminUser?.uid)}>{copyIcon}</span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="uid" value={adminUser?.uid} disabled />
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Update Password</Form.Label>
                            <Form.Control type="password" placeholder="**********" value={password} onChange={e => setPassword(e.currentTarget.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="confirmpwd">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" placeholder="**********" value={confirmpwd} onChange={e => setConfirmPwd(e.currentTarget.value)} />
                        </Form.Group> */}
                        {/* <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>
                        </Modal.Footer> */}
                        <hr />
                        <div className='d-flex justify-content-end align-items-center p-1'>
                            <Button variant="secondary" onClick={handleClose} className='me-2'>
                                Close
                            </Button>
                            <Button variant="primary" type="submit" disabled={updating}>
                                Save Changes&nbsp;
                                {
                                    updating && <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                }
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
