import { checkLogin } from "../../../utils/firebase";

const localhostUrl = 'http://localhost:8000'
const baseUrl = process.env.REACT_APP_BASE_URL

export const getAllImportOrdersAPI = async function () {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/getImporOrders/admin`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return res
    } catch (err) {
        console.log(err)
        return err
    }
}

export const getSingleImportOrderAPI = async function (id) {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/getSingleImportOrder/${id}`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return res
    } catch (err) {
        console.log(err)
        return err
    }
}

export const updateImportOrderAPI = async function (id, data) {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/updateImportOrder/${id}`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        }).then(res => res.json());
        return res
    } catch (err) {
        console.log(err)
        return err
    }
}