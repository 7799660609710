import React from 'react'
import { useEffect, useState } from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import { addIcon, phoneIcon } from '../../FontAwesome';
import { callToConnectTwoNumbers, getSupportPhoneNumbers } from '../../app/features/general/generalSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import AddSupportNumber from './AddSupportNumber';

export default function VoiceCall({ show, handleClose, numberToCall, orderType, orderId }) {
    const dispatch = useDispatch();

    const [openNewSupportMemberModal, setOpenNewSupportMemberModal] = useState(false)

    const { fetching, supportPhoneNumbers } = useSelector(state => state.general);

    const { handleSubmit, control, setValue, formState: { errors } } = useForm({
        defaultValues: {
            fromNumber: '',
            toNumber: numberToCall ? numberToCall : ''
        }
    });

    useEffect(() => {
        setValue('toNumber', numberToCall)
    }, [numberToCall])

    const voiceCallTheNumber = (data) => {
        const reqBody = {
            ...data,
            sm_orderid: orderId,
            type: orderType
        }
        dispatch(callToConnectTwoNumbers(reqBody))
            .then(unwrapResult)
            .then(res => {
                alert('You will receive a call shortly')
                handleClose()
            })
            .catch(err => {
                alert(err ? err : 'Internal server error')
            })
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(voiceCallTheNumber)}>
                        <Controller
                            control={control}
                            name="fromNumber"
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="fromNumber">
                                    <Form.Label>
                                        From <Button variant='link' size='sm' onClick={() => setOpenNewSupportMemberModal(true)}>{addIcon}&nbsp;Add new number</Button>
                                    </Form.Label>
                                    <Form.Select onChange={onChange} value={value}>
                                        <option value="" disabled>From</option>
                                        {
                                            supportPhoneNumbers?.map((data, index) => {
                                                return (
                                                    <option key={index} value={data.phone}>{data.name} ({data.phone})</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                    {errors.fromNumber && <div className='error-text'>{errors.fromNumber.message}</div>}
                                </Form.Group>
                            )}
                        />
                        <Controller
                            control={control}
                            name="toNumber"
                            rules={{ required: { value: true, message: 'This field is required' }, validate: value => (value.length >= 10 && value.length <= 15) || 'Please enter a valid phone number' }}
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="toNumber">
                                    <Form.Label>To</Form.Label>
                                    <Form.Control type="text" onChange={onChange} value={value} placeholder="To" />
                                    {errors.toNumber && <div className='error-text'>{errors.toNumber.message}</div>}
                                </Form.Group>
                            )}
                        />
                        <Button type='submit' disabled={fetching}>
                            Call&nbsp;{phoneIcon}&nbsp;
                            {fetching && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <AddSupportNumber show={openNewSupportMemberModal} handleClose={() => setOpenNewSupportMemberModal(false)} />
        </>
    )
}
