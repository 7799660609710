import axios, { AxiosResponse } from "axios";

const localhostUrl = 'http://localhost:8000'
const baseUrl = process.env.REACT_APP_BASE_URL

export const getDashboardOrderDetailsAPI = async (startDate, endDate, graphDataStartDate, graphDataEndDate) => {
    try {
        const res = await fetch(`${baseUrl}/admin/dashboard?startDate=${startDate}&endDate=${endDate}&graphDataStartDate=${graphDataStartDate}&graphDataEndDate=${graphDataEndDate}`).then(res => res.json());
        return res
    } catch (error) {
        console.log(error);
        return error
    }
}