import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllHyperlocal2Wheeler } from '../../app/features/order/orderSlice'
import { Spinner, Table, Button, Card, Dropdown, Form, Toast, ToastContainer } from 'react-bootstrap'
import { copyIcon, editIcon, sortAscIcon, sortDescIcon, sortIcon, phoneIcon } from '../../FontAwesome'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faCheck, faXmark, faFlagCheckered, faHourglassStart, faPause, faFilter } from '@fortawesome/free-solid-svg-icons'
import VoiceCall from '../Misc/VoiceCall'


function OrderRow({ order, copyTextFunction }) {
    const navigate = useNavigate();

    const [numberToCall, setNumberToCall] = useState('')
    const [showVoiceCallModal, setShowVoiceCallModal] = useState(false)

    const createdAtDateString = new Date(order.createdAt);
    const createdAtDate = createdAtDateString.toDateString() + ", " + createdAtDateString.toLocaleTimeString();

    return (
        <>
            <tr>
                <td>
                    {order.sm_orderid ? order.sm_orderid : order._id}
                    <span style={{ cursor: 'pointer' }} onClick={() => copyTextFunction(order.sm_orderid)}>&nbsp;{copyIcon}</span>
                </td>
                <td>{order.shipperID}</td>
                <td>
                    {order.payment_status}
                    <hr />
                    <span>Cost: {order.cost}</span>
                </td>
                {/* <td>{order.user}</td> */}
                <td>
                    Phone: {order.start?.contact?.phone}&nbsp;<span style={{ cursor: 'pointer' }} onClick={() => { setNumberToCall(order.start?.contact?.phone); setShowVoiceCallModal(true) }}>{phoneIcon}</span> <br />
                    Name: {order.start?.contact?.name} <br />
                    Address: {order.start?.address?.fromGoogle?.address}
                </td>
                <td>
                    Phone: {order.end?.contact?.phone}&nbsp;<span style={{ cursor: 'pointer' }} onClick={() => { setNumberToCall(order.end?.contact?.phone); setShowVoiceCallModal(true) }}>{phoneIcon}</span> <br />
                    Name: {order.end?.contact?.name} <br />
                    Address: {order.end?.address?.fromGoogle?.address}
                </td>
                <td>{order.state}</td>
                <td>{createdAtDate}</td>
                <td>
                    <Button variant='primary' onClick={e => { navigate(`/orders/hyperlocalOrders/${order._id}`) }}>{editIcon}</Button>
                </td>
            </tr>
            <VoiceCall show={showVoiceCallModal} handleClose={() => setShowVoiceCallModal(false)} numberToCall={numberToCall} orderType='apsrtc' orderId={order?.sm_orderid} />
        </>
    )
}

export default function Hyperlocal2WheelerOrders({ searchString }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(fetchAllHyperlocal2Wheeler())
    }, [])

    const { fetching, orders } = useSelector(state => state.order)

    const [sortType, setSortType] = useState("createdAt-desc")
    const [sortedResults, setSortedResults] = useState([])

    const [paymentStatusFilter, setPaymentStatusFilter] = useState('Successful')

    const [activeOrdersCount, setActiveOrderCount] = useState(0)
    const [placedOrdersCount, setPlacedOrderCount] = useState(0)
    const [initiatedOrdersCount, setInitiatedOrderCount] = useState(0)
    const [deliveredOrdersCount, setDeliveredOrderCount] = useState(0)
    const [underHoldOrdersCount, setUnderHoldOrderCount] = useState(0)
    const [cancelledOrdersCount, setCancelledOrderCount] = useState(0)

    const [isTextCopied, setIsTextCopied] = useState(false);


    const [activeSelectedState, setActiveSelectedState] = useState('')
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            // href=""
            size='sm'
            style={{ marginLeft: '10px' }}
            ref={ref}
            variant='secondary'
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <FontAwesomeIcon icon={faFilter} />
        </Button>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                        )}
                    </ul>
                </div>
            );
        },
    );

    const copyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        setIsTextCopied(true)
        setTimeout(() => {
            setIsTextCopied(false)
        }, 3000);
    }

    // const filterByOrderState = (stateToFilter) => {
    //     console.log(stateToFilter)
    //     setActiveSelectedState(stateToFilter)
    //     setPaymentStatusFilter('')
    //     if (stateToFilter === 'Remove filter') {
    //         setSortedResults(orders)
    //     } else {
    //         let temp = []
    //         orders.map(order => {
    //             if (order.state === stateToFilter && order.vehicle === 2) {
    //                 temp.push(order)
    //             }
    //         })
    //         setSortedResults(temp)
    //     }
    //     setSortType('updatedAt-desc')
    // }

    // const filterByPaymentStatus = (paymentStatus) => {
    //     console.log(paymentStatus)
    //     setActiveSelectedState('')
    //     setPaymentStatusFilter(paymentStatus)
    //     if (paymentStatus === 'Remove filter') {
    //         setSortedResults(orders)
    //     } else {
    //         let temp = []
    //         orders.map(order => {
    //             if (order.payment_status === paymentStatus && order.vehicle === 2) {
    //                 temp.push(order)
    //             }
    //         })
    //         setSortedResults(temp)
    //     }
    //     setSortType('updatedAt-desc')
    // }


    const handleSort = (type) => {
        const order = sortType.split("-")[1]
        if (order === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (order === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        setSortedResults(orders)
        // filterByOrderState('Placed')
        if (!paymentStatusFilter && !activeSelectedState) {
            setSortedResults(orders)
        } else {
            let temp = []
            orders?.map(order => {
                if (paymentStatusFilter && activeSelectedState) {
                    if (order.payment_status === paymentStatusFilter && order.vehicle === 2 && order.state === activeSelectedState) {
                        temp.push(order)
                    }
                } else {
                    if (order.payment_status === paymentStatusFilter && order.vehicle === 2) {
                        temp.push(order)
                    } else if (order.vehicle === 2 && order.state === activeSelectedState) {
                        temp.push(order)
                    }
                }
            })
            setSortedResults(temp)
        }
        setSortType('createdAt-desc')
    }, [orders, paymentStatusFilter, activeSelectedState])

    useEffect(() => {
        let activeCount = 0, initCount = 0, delCount = 0, underholdCount = 0, placedCount = 0, cancelCount = 0;
        if (orders) {
            orders?.map(order => {
                if (order.state === "In Progress") {
                    activeCount += 1
                } else if (order.state === "Placed") {
                    placedCount += 1
                } else if (order.state === "Initiated") {
                    initCount += 1
                } else if (order.state === "Delivered") {
                    delCount += 1
                } else if (order.state === "Under Hold") {
                    underholdCount += 1
                } else if (order.state === "Cancelled") {
                    cancelCount += 1
                }
            });
            setActiveOrderCount(activeCount);
            setPlacedOrderCount(placedCount);
            setInitiatedOrderCount(initCount);
            setDeliveredOrderCount(delCount);
            setUnderHoldOrderCount(underholdCount);
            setCancelledOrderCount(cancelCount)
        }
    }, [orders])


    useEffect(() => {
        if (!fetching) {
            const [attr, type] = sortType.split("-")
            let temp = [...sortedResults]
            if (attr === "updatedAt" || attr === "createdAt") {
                if (type === "asc") {
                    temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) =>
                        new Date(b[attr]) - new Date(a[attr])
                    )
                    setSortedResults(temp)
                }
            } else {
                if (type === "asc") {
                    temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                    setSortedResults(temp)
                }
            }
        }
    }, [sortType])

    useEffect(() => {
        if (searchString) {
            let temp = []
            orders.map(order => {
                if (order.sm_orderid?.includes(searchString) || order._id?.includes(searchString)) {
                    temp.push(order)
                } else if (order.user?.includes(searchString)) {
                    temp.push(order)
                }
            })
            setSortedResults(temp)
            setSortType('createdAt-desc')
        }
    }, [searchString])

    if (fetching) return (
        <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
            <Spinner animation="border" />
        </div>
    )

    return (

        <>

            {
                isTextCopied && <ToastContainer position='top-center' style={{ zIndex: 1000000 }}>
                    <Toast bg='info' delay={3000} autohide style={{ margin: '20px', fontSize: '20px', textAlign: 'center' }}>
                        <Toast.Body>Item Copied</Toast.Body>
                    </Toast>
                </ToastContainer>
            }


            <div className='d-flex mb-3' style={{ flexWrap: 'wrap' }}>
                <Card className='me-3 dashboard-order-card'>
                    <Card.Body>
                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <FontAwesomeIcon icon={faHourglassStart} className={activeOrdersCount ? 'dashboard-card-svg' : 'dashboard-card-svg-zero-count'} />
                            <div className="d-flex flex-column">
                                <p className='dashboard-card-title'>In progress orders</p>
                                <p className='dashboard-card-text'>{activeOrdersCount}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className='me-3 dashboard-order-card'>
                    <Card.Body>
                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <FontAwesomeIcon icon={faChartLine} className={placedOrdersCount ? 'dashboard-card-svg' : 'dashboard-card-svg-zero-count'} />
                            <div className="d-flex flex-column">
                                <p className='dashboard-card-title'>Placed orders</p>
                                <p className='dashboard-card-text'>{placedOrdersCount}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className='me-3 dashboard-order-card'>
                    <Card.Body>
                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <FontAwesomeIcon icon={faFlagCheckered} className={initiatedOrdersCount ? 'dashboard-card-svg' : 'dashboard-card-svg-zero-count'} />
                            <div className="d-flex flex-column">
                                <p className='dashboard-card-title'>Initiated orders</p>
                                <p className='dashboard-card-text'>{initiatedOrdersCount}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className='me-3 dashboard-order-card'>
                    <Card.Body>
                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <FontAwesomeIcon icon={faCheck} className={deliveredOrdersCount ? 'dashboard-card-svg' : 'dashboard-card-svg-zero-count'} />
                            <div className="d-flex flex-column">
                                <p className='dashboard-card-title'>Delivered orders</p>
                                <p className='dashboard-card-text'>{deliveredOrdersCount}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className='me-3 dashboard-order-card'>
                    <Card.Body>
                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <FontAwesomeIcon icon={faPause} className={underHoldOrdersCount ? 'dashboard-card-svg' : 'dashboard-card-svg-zero-count'} />
                            <div className="d-flex flex-column">
                                <p className='dashboard-card-title'>Under hold orders</p>
                                <p className='dashboard-card-text'>{underHoldOrdersCount}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className='dashboard-order-card'>
                    <Card.Body>
                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <FontAwesomeIcon icon={faXmark} className={cancelledOrdersCount ? 'dashboard-card-svg' : 'dashboard-card-svg-zero-count'} />
                            <div className="d-flex flex-column">
                                <p className='dashboard-card-title'>Cancelled orders</p>
                                <p className='dashboard-card-text'>{cancelledOrdersCount}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>


            <Table striped bordered hover responsive style={{ minHeight: '300px' }}>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th className='col-1'>Order id</th>
                        <th className='col-1'>
                            Shipper ID&nbsp;
                            {sortType.split("-")[0] !== "shipperID" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("shipperID")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "shipperID" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("shipperID")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "shipperID" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("shipperID")}>{sortDescIcon}</span>
                            }
                        </th>
                        <th className='col-2'>
                            Payment Status
                            <Dropdown style={{ display: 'inline-block' }} onSelect={(paymentStatus) => setPaymentStatusFilter(paymentStatus)}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components-payment">
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu}>
                                    <Dropdown.Item eventKey="">Remove filter</Dropdown.Item>
                                    <Dropdown.Item eventKey="Initiated" active={paymentStatusFilter === "Initiated"}>Initiated</Dropdown.Item>
                                    <Dropdown.Item eventKey="Successful" active={paymentStatusFilter === "Successful"}>Successful</Dropdown.Item>
                                    <Dropdown.Item eventKey="Pending" active={paymentStatusFilter === "Pending"}>Pending</Dropdown.Item>
                                    <Dropdown.Item eventKey="Failed" active={paymentStatusFilter === "Failed"}>Failed</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <p className="text-muted mb-0" style={{ fontSize: '12px' }}>Selected: {paymentStatusFilter}</p>
                        </th>
                        {/* <th className='col-1'>
                            User&nbsp;
                            {sortType.split("-")[0] !== "user" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("user")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "user" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("user")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "user" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("user")}>{sortDescIcon}</span>
                            }
                        </th> */}
                        <th className='col-2'>
                            From
                        </th>
                        <th className='col-2'>To</th>
                        <th className='col-2'>
                            State
                            <Dropdown style={{ display: 'inline-block' }} onSelect={(orderStatus) => setActiveSelectedState(orderStatus)}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu}>
                                    <Dropdown.Item eventKey="">Remove filter</Dropdown.Item>
                                    <Dropdown.Item eventKey="Initiated" active={activeSelectedState === "Initiated"}>Initiated</Dropdown.Item>
                                    <Dropdown.Item eventKey="Placed" active={activeSelectedState === "Placed"}>Placed</Dropdown.Item>
                                    <Dropdown.Item eventKey="In Progress" active={activeSelectedState === "In Progress"}>In Progress</Dropdown.Item>
                                    <Dropdown.Item eventKey="Delivered" active={activeSelectedState === "Delivered"}>Delivered</Dropdown.Item>
                                    <Dropdown.Item eventKey="Cancelled" active={activeSelectedState === "Cancelled"}>Cancelled</Dropdown.Item>
                                    <Dropdown.Item eventKey="Under Hold" active={activeSelectedState === "Under Hold"}>Under Hold</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <p className='text-muted mb-0' style={{ fontSize: '10px' }}>Selected: {activeSelectedState}</p>
                        </th>
                        <th className='col-1'>
                            Booked at&nbsp;
                            {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                            }
                        </th>
                        <th className='col-1'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sortedResults.map((order, index) => {
                            return (
                                <OrderRow key={index} order={order} copyTextFunction={copyText} />
                            )
                        })
                    }
                </tbody>
            </Table>

        </>
    )
}
