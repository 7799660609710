import { pdf, PDFDownloadLink } from '@react-pdf/renderer'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Col, Form, Row, Spinner, InputGroup } from 'react-bootstrap'
import SHIPMANTRA_LOGO from '../../assets/images/shipmantra_logo_with_name.svg'
import { addIcon, deleteIcon } from '../../FontAwesome'
import { convertDecimalNumbersToWords, getShipmantraDetails, sendInvoiceMail } from '../../utils/helper'
import InvoicePDFTemplate from './InvoicePDFTemplate'
import { saveAs } from 'file-saver'
import Indian_states_cities_list from "indian-states-cities-list";
import InvoiceEmail from './InvoiceEmail'
import gst_state_code_list from '../../data_files/gst_state_code_list.json'
import InvoiceTemplate from './InvoiceTemplate'
import MapInput from '../MapInput'
import WalletRechargeInvoiceTemplate from './WalletRechargeInvoice'

export default function WalletRechargeInvoiceInput() {
    const [companyName, setCompanyName] = useState('')
    const [companyGST, setCompanyGST] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [companyCIN, setCompanyCIN] = useState('')
    const [placeOfSupply, setPlaceOfSupply] = useState('')

    const [docType, setDocType] = useState('invoice') //invoice or quotation
    const [invoiceNo, setInvoiceNo] = useState('')
    const [invoiceDate, setInvoiceDate] = useState(new Date().toISOString().split("T")[0])
    const [dueDate, setDueDate] = useState('')
    const [expiryDate, setExpiryDate] = useState('')

    const [senderType, setSenderType] = useState('sender')
    const [senderName, setSenderName] = useState('')
    const [senderContact, setSenderContact] = useState('')
    const [senderHouseNo, setSenderHouseNo] = useState('')
    const [senderMapData, setSenderMapData] = useState(undefined);

    const [receiverType, setReceiverType] = useState('receiver')
    const [receiverName, setReceiverName] = useState('')
    const [receiverContact, setReceiverContact] = useState('')
    const [receiverHouseNo, setReceiverHouseNo] = useState('')
    const [receiverMapData, setReceiverMapData] = useState(undefined)
    const [buyerCompanyName, setBuyerCompanyName] = useState('')
    const [buyerCompanyGST, setBuyerCompanyGST] = useState('')


    const [invoiceDetails, setInvoiceDetails] = useState([{ desc: '', qty: 0, rate: 0, sacCode: '', totalAmount: 0 }])

    const [gst, setGst] = useState(0)
    const [platformFee, setPlatformFee] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalAmountInWords, setTotalAmountInWords] = useState('');

    const [terms, setTerms] = useState('')
    const [additionalNotes, setAdditionalNotes] = useState('')

    const [showEmailModal, setShowEmailModal] = useState(false)
    const [loader, setLoader] = useState(false)


    const getMinDate = () => {
        return new Date().toISOString().split("T")[0]
    }

    useEffect(() => {
        const { name, companyAddress, companyGST, companyCIN } = getShipmantraDetails();
        setCompanyName(name);
        setCompanyAddress(companyAddress);
        setCompanyGST(companyGST)
        setCompanyCIN(companyCIN)
    }, [])

    useEffect(() => {
        let amount = 0;
        invoiceDetails.map(detail => {
            amount += detail.totalAmount
        })
        setTotalAmount(amount);
        if (amount) {
            setTotalAmountInWords(convertDecimalNumbersToWords(amount))
        } else {
            setTotalAmountInWords('')
        }
    }, [invoiceDetails])

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    const pdfCreation = () => {
        const pdfData = {
            sm_orderid: invoiceNo,
            start: {
                contact: {
                    name: senderName,
                    phone: senderContact
                },
                address: {
                    houseNo: senderHouseNo,
                    mapData: senderMapData
                }
            },
            end: {
                contact: {
                    name: receiverName,
                    phone: receiverContact
                },
                address: {
                    houseNo: receiverHouseNo,
                    mapData: receiverMapData
                }
            },
            cost: {
                gst: gst,
                amountCollectedFromCustomer: parseFloat(totalAmount)
            },
            createdAt: new Date(invoiceDate)
        }
        console.log(pdfData)
        const doc = <WalletRechargeInvoiceTemplate dataArr={[pdfData]} />
        return doc
    }

    const handleClickPDF = () => {
        setLoader(true)
        const doc = pdfCreation()
        pdf(doc)
            .toBlob()
            .then(async (b) => {
                saveAs(b, "invoice.pdf")
                setLoader(false)
            })
            .catch((e) => {
                setLoader(false)
                alert("There was an error while creating a pdf")
            })
            .finally(()=>{
                setLoader(false)
            })
    }

    const handleEmail = (email) => {
        setLoader(true)
        if (email) {
            const doc = pdfCreation()
            pdf(doc)
                .toBlob()
                .then(async (b) => {
                    let base64 = await blobToBase64(b);
                    base64 = base64.replace('data:application/pdf;base64,', '');
                    var base64Data = base64;
                    const msgRes = await sendInvoiceMail(base64Data, email)
                    setLoader(false)
                })
                .catch((e) => {
                    setLoader(false)
                    alert("There was an error while sending an email")
                })
        } else {
            setLoader(false)
            alert("Please enter a valid email")
        }
    }

    // useEffect(() => {
    //     const gst = parseFloat((totalAmount - (totalAmount / 1.18)).toFixed(2))
    //     setGst(gst);
    //     if (totalAmount) {
    //         setTotalAmountInWords(convertDecimalNumbersToWords(totalAmount))
    //     } else {
    //         setTotalAmountInWords('')
    //     }
    // }, [senderMapData, totalAmount])

    const styles = {
        page: {
            fontFamily: 'Inter',
            padding: '30px',
        },
        companyAddrDetails: {
            fontSize: '10px'
        },
        container_padding_5: {
            padding: '5px'
        },
        font_10: {
            fontSize: '10px'
        },
        fw_bold: {
            fontWeight: 'bold'
        },
        tableHead: {
            borderRight: "1px solid black",
            padding: "5px 2px",
            backgroundColor: "rgb(216, 216, 216)",
            // color: "white",
            verticalAlign: "middle",
            fontFamily: 'Inter',
            fontWeight: "bold",
            // flex: 1,
            // fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        table: {
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
            borderCollapse: "none",
            display: "flex",
            flexDirection: "column"
        },
        tableCell: {
            padding: "5px 2px",
            // borderLeft: "1px solid black",
            borderRight: "1px solid black",
            // textAlign: "center",
            display: "table-cell",
            verticalAlign: "middle"
            // flex: 1,
        },
        tableRow: {
            // borderTop: "1px solid black",
            borderBottom: "1px solid black",
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch"
        },
        border: {
            border: '1px solid black'
        }
    }

    return (
        <div style={{ margin: '30px', padding: '20px', borderRadius: '10px' }}>
            {/* Header and shipmantra company details */}
            <div style={{ ...styles.container_padding_5, ...styles.border, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                    {/* Replace the following with your actual logo image source */}
                    <img src={SHIPMANTRA_LOGO} className='mb-2' style={{ width: '300px' }} alt="" />
                    <div style={{ fontWeight: 'bold', marginTop: '10px' }}>
                        <div>GoLintu Services Pvt. Ltd</div>
                        <div>NASSCOM CoE - IoT & AI</div>
                        <div>AU North Campus, Andhra University</div>
                        <div>Visakhapatnam Andhra Pradesh 530003 India</div>
                        <div>GSTIN 37AAHCG9147L1ZZ</div>
                    </div>
                </div>
                <div style={{ width: '50%', textAlign: 'right', fontSize: '20px', fontWeight: 'bolder' }}>
                    <div>TAX INVOICE</div>
                </div>
            </div>

            {/* Invoice details */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'stretch', borderLeft: '1px solid black', borderRight: '1px solid black' }}>
                <div style={{ ...styles.container_padding_5, borderRight: '1px solid black', width: '50%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
                        <div style={{ width: '30%' }}>#Invoice No</div>
                        <Form.Control style={{ width: '70%' }} type="text" value={invoiceNo} onChange={e => setInvoiceNo(e.target.value)} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '30%' }}>Invoice date</div>
                        {/* <Form.Control type="text" value={invoiceNo} onChange={e => setInvoiceNo(e.target.value)} /> */}
                        <Form.Control style={{ width: '70%' }} type="date" min={getMinDate()} value={invoiceDate} onChange={e => setInvoiceDate(e.target.value)} />
                    </div>
                </div>
                <div style={{ ...styles.container_padding_5, display: 'flex', flexDirection: 'row', flex: '1', width: '50%' }}>
                    <div style={{ flex: '1' }}>
                        <div>Place of supply</div>
                    </div>
                    <div style={{ flex: '1', fontWeight: 'bold' }}>
                        <Form.Select className='mb-2' value={placeOfSupply} onChange={e => setPlaceOfSupply(e.target.value)}>
                            {
                                Indian_states_cities_list.STATES_OBJECT.map((state, index) => {
                                    return (
                                        <option key={index} value={state.value}>{state.label} ({gst_state_code_list[state.value]})</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </div>
                </div>
            </div>

            {/* Shipping details */}
            <div style={{ ...styles.border, borderBottom: '1px solid black', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'stretch' }}>
                <div style={{ borderRight: '1px solid black', display: 'flex', flexDirection: 'column', flex: '1' }}>
                    <div style={{ ...styles.container_padding_5, fontWeight: 'bold', backgroundColor: 'rgb(216,216,216)', borderBottom: '1px solid black' }}>
                        Bill To
                    </div>
                    <div style={{ ...styles.container_padding_5 }}>
                        <Form.Control type="text" className='mb-2' placeholder="Sender name" value={senderName} onChange={e => setSenderName(e.target.value)} />
                        <Form.Control type="text" className='mb-2' placeholder="Sender houseno/building name" value={senderHouseNo} onChange={e => setSenderHouseNo(e.target.value)} />
                        <MapInput setMapData={setSenderMapData} id={"sender-map-address"} />
                        <Form.Control type="text" className='my-2' placeholder="Sender City" value={senderMapData?.city} disabled />
                        <Form.Control type="text" className='mb-2' placeholder="Sender Pincode" value={senderMapData?.pincode} disabled />
                        <Form.Control type="text" className='mb-2' placeholder="Sender State" value={senderMapData?.state} disabled />
                        <Form.Control type="text" className='mb-2' placeholder="Sender Country" value={senderMapData?.country} disabled />
                        {/* <Form.Control type="text" className='mb-2' placeholder="Sender address" value={senderAddress} onChange={e => setSenderAddress(e.target.value)} />
                        <Form.Select className='mb-2' value={senderState} onChange={e => setSenderState(e.target.value)}>
                            {
                                Indian_states_cities_list.STATES_OBJECT.map((state, index) => {
                                    return (
                                        <option key={index} value={state.name}>{state.label}</option>
                                    )
                                })
                            }
                        </Form.Select>
                        {
                            senderState &&
                            <Form.Select className='mb-2' value={senderCity} onChange={e => setSenderCity(e.target.value)}>
                                {
                                    Indian_states_cities_list.STATE_WISE_CITIES?.[senderState]?.map((state, index) => {
                                        return (
                                            <option key={index} value={state.value}>{state.label}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        }
                        <Form.Control type="text" className='mb-2' placeholder="Sender Pincode" value={senderPincode} onChange={e => setSenderPincode(e.target.value)} /> */}
                    </div>
                </div>
            </div>

            {/* Table description */}
            <div style={{ ...styles.table }}>
                <div style={{ ...styles.tableRow }}>
                    <div style={{ ...styles.tableHead, width: '10%', justifyContent: 'left' }}>#</div>
                    <div style={{ ...styles.tableHead, width: '40%', justifyContent: 'left' }}>Item</div>
                    <div style={{ ...styles.tableHead, width: '10%' }}>Qty</div>
                    <div style={{ ...styles.tableHead, width: '10%' }}>Rate</div>
                    <div style={{ ...styles.tableHead, width: '10%' }}>Discount</div>
                    <div style={{ ...styles.tableHead, width: '20%' }}>Amount</div>
                </div>
                {/* table body */}
                {/* {
                    invoiceDetails?.map((details, index) => {
                        return ( */}
                <div style={{ ...styles.tableRow }}>
                    <div style={{ ...styles.tableCell, width: '10%' }}>{1}</div>
                    <div style={{ ...styles.tableCell, width: '40%' }}>
                        <div>Wallet Topup</div>
                        <div style={{ fontSize: '12px', color: '#333' }}>HSN: 9965</div>
                    </div>
                    <div style={{ ...styles.tableCell, width: '10%', textAlign: 'center' }}>1.00</div>
                    <div style={{ ...styles.tableCell, width: '10%', textAlign: 'center' }}>
                        <InputGroup>
                            <InputGroup.Text>₹</InputGroup.Text>
                            <Form.Control type='text' placeholder="Rate" value={totalAmount} onChange={(e)=>setTotalAmount(e.currentTarget.value)} required />
                        </InputGroup>
                    </div>
                    <div style={{ ...styles.tableCell, width: '10%', textAlign: 'center' }}>0.00</div>
                    <div style={{ ...styles.tableCell, width: '20%', textAlign: 'center' }}>{totalAmount}</div>
                </div>
                {/* )
                    })
                } */}
            </div>

            {/* PDF Footer */}
            <div style={{ ...styles.border, display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
                <div style={{ ...styles.container_padding_5, width: '50%', borderRight: '1px solid black' }}>
                    <div style={{ margin: '10px 0 25px' }}>
                        <div>Total in Words</div>
                        <div style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                            {totalAmountInWords}
                        </div>
                    </div>
                    <div style={{ margin: '10px 0 25px' }}>Thank you for the payment. You just made our day</div>
                    <div style={{ margin: '10px 0' }}>
                        <div>Account Name: Golintu Services Pvt Ltd</div>
                        <div>Account Number: 249405500165</div>
                        <div>Bank Name: ICICI</div>
                        <div>Account Type: Current</div>
                        <div>IFSC Code: ICIC0002494</div>
                    </div>
                </div>
                <div style={{ ...styles.container_padding_5, width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {/* Cost Break down labels */}
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <div style={{ margin: '5px 0' }}>Sub Total</div>
                        {/* {
                            senderMapData?.state === 'Andhra Pradesh' ?
                                <>
                                    <div style={{ margin: '5px 0' }}>CGST (9.00%)</div>
                                    <div style={{ margin: '5px 0' }}>SGST (9.00%)</div>
                                </> :
                                <div style={{ margin: '5px 0' }}>IGST (18.00%)</div>
                        } */}
                        <div style={{ margin: '5px 0' }}>Discount</div>
                        <div style={{ fontWeight: 'bold', margin: '5px 0' }}>Total</div>
                        <div style={{ margin: '5px 0' }}>Payment Made</div>
                        <div style={{ fontWeight: 'bold', margin: '5px 0' }}>Balance Due</div>
                    </div>

                    {/* Values */}
                    <div style={{ flex: '1', textAlign: 'left', paddingLeft: '60px', }}>
                        {/* Sub Total w/o GST */}
                        <div style={{ margin: '5px 0' }}>{(totalAmount - gst).toFixed(2)}</div>

                        {/* {
                            senderMapData?.state === 'Andhra Pradesh' ?
                                <>
                                    <div style={{ margin: '5px 0' }}>{(gst / 2).toFixed(2)}</div>
                                    <div style={{ margin: '5px 0' }}>{(gst / 2).toFixed(2)}</div>
                                </> :
                                <div style={{ margin: '5px 0' }}>{gst}</div>
                        } */}

                        {/* Discount */}
                        <div style={{ margin: '5px 0' }}>0.00</div>

                        {/* Total */}
                        <div style={{ fontWeight: 'bold', margin: '5px 0' }}>{totalAmount}</div>

                        {/* Payment Made */}
                        <div style={{ color: 'red', margin: '5px 0' }}>(-) {totalAmount}</div>

                        {/* Balance due */}
                        <div style={{ fontWeight: 'bold', margin: '5px 0' }}>₹ 0.00</div>
                    </div>
                </div>
            </div>


            {/* Save As PDF Button */}
            <div className="d-flex justify-content-center mt-5">
                <Button variant='info' className='me-2' onClick={handleClickPDF}>
                    {
                        loader ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Loading...
                            </> :
                            "Save as PDF"
                    }
                </Button>
                <Button variant='warning' onClick={() => setShowEmailModal(true)}>Email to</Button>
            </div>

            <InvoiceEmail show={showEmailModal} handleClose={() => setShowEmailModal(false)} sendEmail={handleEmail} loading={loader} />
        </div >

    )
}
