import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Container, Button, Table, Spinner } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { refreshIcon, sortIcon, editIcon, sortAscIcon, sortDescIcon, deleteIcon } from '../../FontAwesome'
import { deleteCoupon, fetchAllCoupons } from '../../app/features/coupons/couponSlice'
import { useState } from 'react'

export default function CouponDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { fetching, allCoupons } = useSelector(state => state.coupon)

    const [sortType, setSortType] = useState("updatedAt-desc")
    const [sortedResults, setSortedResults] = useState(allCoupons ? allCoupons : [])

    const handleSort = (type) => {
        const order = sortType.split("-")[1]
        if (order === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (order === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    const handleDelete = (couponName) => {
        dispatch(deleteCoupon(couponName))
            .then(() => {
                dispatch(fetchAllCoupons())
                alert('Coupon deleted successfully')
                navigate('/coupons')
            })
    }

    useEffect(() => {
        const [attr, type] = sortType.split("-")
        let temp = [...allCoupons]
        if (attr === "updatedAt" || attr === "createdAt") {
            if (type === "asc") {
                temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) =>
                    new Date(b[attr]) - new Date(a[attr])
                )
                setSortedResults(temp)
            }
        } else {
            if (type === "asc") {
                temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                setSortedResults(temp)
            }
        }
    }, [sortType, fetching, allCoupons])

    useEffect(() => {
        dispatch(fetchAllCoupons())
    }, [])

    if (fetching) return (
        <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
            <Spinner animation="border" />
        </div>
    )

    return (
        <Container className='mt-3'>
            <hr />
            <div className='ms-auto text-end mb-3'>
                <Button variant="secondary" onClick={e => { dispatch(fetchAllCoupons()) }}>
                    <span>{refreshIcon}</span>&nbsp;
                    Refresh
                </Button>
            </div>
            <LoadingOverlay
                active={false}
                spinner
                text='Disabling.....'
            >
                <div style={{ height: '100%', overflowY: 'auto' }}>
                    <Table striped bordered hover responsive className='mt-3'>
                        <thead style={{ verticalAlign: 'middle' }}>
                            <tr>
                                <th>Active</th>
                                <th>Coupon</th>
                                <th>Description</th>
                                <th>AvailedFor</th>
                                <th>
                                    Expiring On&nbsp;
                                    {sortType.split("-")[0] !== "expiry" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("expiry")}>{sortIcon}</span>}
                                    {
                                        (sortType.split("-")[0] === "expiry" && sortType.split("-")[1] === "asc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("expiry")}>{sortAscIcon}</span>
                                    }
                                    {
                                        (sortType.split("-")[0] === "expiry" && sortType.split("-")[1] === "desc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("expiry")}>{sortDescIcon}</span>
                                    }
                                </th>
                                {/* <th>
                                    Created At&nbsp;
                                    {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                                    {
                                        (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                                    }
                                    {
                                        (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                                    }
                                </th> */}
                                <th>
                                    Updated At&nbsp;
                                    {sortType.split("-")[0] !== "updatedAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortIcon}</span>}
                                    {
                                        (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "asc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortAscIcon}</span>
                                    }
                                    {
                                        (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "desc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortDescIcon}</span>
                                    }
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortedResults.map((coupon, index) => {
                                    const createdAtDateString = new Date(coupon.createdAt);
                                    const createdAtDate = createdAtDateString.toDateString() + ", " + createdAtDateString.toLocaleTimeString();
                                    const updatedAtDateString = new Date(coupon.updatedAt);
                                    const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();
                                    const expiryDataString = new Date(coupon.expiry);
                                    const expiryDate = expiryDataString.toDateString() + ", " + expiryDataString.toLocaleTimeString();
                                    return (
                                        <tr key={index}>
                                            <td className='col-1'>
                                                {
                                                    coupon.isActive ?
                                                        <FontAwesomeIcon style={{ color: 'green' }} icon={faCircleCheck} /> :
                                                        <FontAwesomeIcon style={{ color: 'red' }} icon={faCircleExclamation} />
                                                }
                                            </td>
                                            <td className='col-1'>{coupon.coupon}</td>
                                            <td className='col-2'>{coupon.desc}</td>
                                            <td className='col-1'>{coupon.serviceType}</td>
                                            <td className='col-2'>{expiryDate}</td>
                                            {/* <td>{createdAtDate}</td> */}
                                            <td className='col-2'>{updatedAtDate}</td>
                                            <td className='col-2'>
                                                <Button variant="primary" onClick={() => navigate(`/coupons/${coupon.coupon}`)}>{editIcon}</Button>
                                                <Button variant="danger" className='ms-1' onClick={() => handleDelete(coupon.coupon)}>{deleteIcon}</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </LoadingOverlay>
        </Container>
    )
}
