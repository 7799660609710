import React, { useEffect, useState } from 'react'
import { Button, Table, Card, Dropdown, Form, Container, ToastContainer, Toast } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchExpressDeliveryOrders } from '../../app/features/order/orderSlice'
import { addIcon, copyIcon, editIcon, phoneIcon, refreshIcon, sortAscIcon, sortDescIcon, sortIcon } from '../../FontAwesome'
import Loader from '../Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faCheck, faXmark, faFlagCheckered, faHourglassStart, faPause, faFilter } from '@fortawesome/free-solid-svg-icons'
import VoiceCall from '../Misc/VoiceCall'
import PDFPreview from '../PDFPreview'
import InvoiceTemplate from '../Invoice/InvoiceTemplate'

function OrderRow({ order, copyTextFunction }) {
    const navigate = useNavigate();

    const dateString = new Date(order.createdAt);
    const date = dateString.toDateString() + ", " + dateString.toLocaleTimeString();

    const [numberToCall, setNumberToCall] = useState('')
    const [showVoiceCallModal, setShowVoiceCallModal] = useState(false)
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false)

    const formattedOrder = {
        ...order,
        start: {
            contact: order.start?.contact,
            address: {
                ...order?.start?.startLocation,
                houseNo: order?.start?.startLocation?.address,
                buildingName: order?.start?.startLocation?.address?.building,
                mapData: {
                    ...order?.start?.startLocation?.fromGoogle,
                    state: order?.start?.startLocation?.state,
                    city: order?.start?.startLocation?.city,
                }
            }
        },
        end: {
            contact: order.end?.contact,
            address: {
                ...order?.end?.endLocation,
                houseNo: order?.end?.endLocation?.address,
                buildingName: order?.end?.endLocation?.address?.building,
                mapData: {
                    ...order?.end?.endLocation?.fromGoogle,
                    state: order?.end?.endLocation?.state,
                    city: order?.end?.endLocation?.city,
                }
            }
        },
        cost: {
            amountCollectedFromCustomer: order?.cost,
            gst: order?.cost - (order?.cost / 1.18)
        }
    }


    return (
        <>
            <tr>
                <td>
                    {order.sm_orderid ? order.sm_orderid : order._id}
                    <span style={{ cursor: 'pointer' }} onClick={() => copyTextFunction(order.sm_orderid)}>&nbsp;{copyIcon}</span>
                </td>
                <td>{order.shipperID}</td>
                <td>
                    {order.payment_status} <hr />
                    <span style={{ fontWeight: 'bold' }}>Amount</span>: {order.cost?.toFixed(2)}
                </td>
                {/* <td>{order.start?.contact?.name}</td> */}
                <td>
                    {order.start?.startLocation?.city} <hr />
                    <span style={{ fontWeight: 'bold' }}>Phone</span>: {order.start?.contact?.phone}&nbsp;<span style={{ cursor: 'pointer' }} onClick={() => { setNumberToCall(order.start?.contact?.phone); setShowVoiceCallModal(true) }}>{phoneIcon}</span>
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Name</span>: {order.start?.contact?.name}
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Pincode</span>: {order.start?.startLocation?.fromGoogle?.pincode}
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Address</span>: {order.start?.startLocation?.fromGoogle?.address}
                </td>
                <td>
                    {order.end?.endLocation?.city}<hr />
                    <span style={{ fontWeight: 'bold' }}>Phone</span>: {order.end?.contact?.phone}&nbsp;<span style={{ cursor: 'pointer' }} onClick={() => { setNumberToCall(order.end?.contact?.phone); setShowVoiceCallModal(true) }}>{phoneIcon}</span>
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Name</span>: {order.end?.contact?.name}
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Pincode</span>: {order.end?.endLocation?.fromGoogle?.pincode}
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Address</span>: {order.end?.endLocation?.fromGoogle?.address}
                </td>
                {/* <td>
                                        {order.cost?.toFixed(2)}
                                    </td> */}
                <td>{order.weight}</td>
                <td>{order.state}</td>
                <td>{date}</td>
                {/* <td>{updatedAtDate}</td> */}
                <td>
                    <Button variant="primary" onClick={() => navigate(`/orders/panIndiaOrders/express-orders/${order._id}`)}>Edit</Button><br />
                    <Button variant="info" className='mt-2' onClick={() => { setOpenInvoiceModal(true) }}>Invoice</Button><br />
                </td>
            </tr>
            <VoiceCall show={showVoiceCallModal} handleClose={() => setShowVoiceCallModal(false)} numberToCall={numberToCall} orderType='pan' orderId={order?.sm_orderid} />
            {openInvoiceModal && <PDFPreview fileName={order?.sm_orderid + "_Invoice"} show={openInvoiceModal} handleClose={() => setOpenInvoiceModal(false)} pdfTemplate={<InvoiceTemplate dataArr={[formattedOrder]} />} />}
        </>
    )
}


export default function ExpressDeliveryOrders() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [searchString, setSearchString] = useState('')


    const { orders, fetching } = useSelector(state => state.order)

    useEffect(() => {
        dispatch(fetchExpressDeliveryOrders())
    }, [])

    const [isTextCopied, setIsTextCopied] = useState(false);

    const [sortType, setSortType] = useState("createdAt-desc")
    const [sortedResults, setSortedResults] = useState(orders ? orders : [])

    const [paymentStatusFilter, setPaymentStatusFilter] = useState('Successful')

    const [activeOrdersCount, setActiveOrderCount] = useState(0)
    const [placedOrdersCount, setPlacedOrderCount] = useState(0)
    const [initiatedOrdersCount, setInitiatedOrderCount] = useState(0)
    const [deliveredOrdersCount, setDeliveredOrderCount] = useState(0)
    const [underHoldOrdersCount, setUnderHoldOrderCount] = useState(0)
    const [cancelledOrdersCount, setCancelledOrderCount] = useState(0)

    const [activeSelectedState, setActiveSelectedState] = useState('')
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            // href=""
            size='sm'
            variant='secondary'
            style={{ marginLeft: '10px' }}
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <FontAwesomeIcon icon={faFilter} />
        </Button>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                        )}
                    </ul>
                </div>
            );
        },
    );

    // const filterByOrderState = (stateToFilter) => {
    //     console.log(stateToFilter)
    //     setActiveSelectedState(stateToFilter)
    //     setPaymentStatusFilter('')
    //     if (stateToFilter === 'Remove filter') {
    //         setSortedResults(orders)
    //     } else {
    //         let temp = []
    //         orders.map(order => {
    //             console.log(order)
    //             if (order.state === stateToFilter) {
    //                 temp.push(order)
    //             }
    //         })
    //         setSortedResults(temp)
    //     }
    //     setSortType('updatedAt-desc')
    // }

    // const filterByPaymentStatus = (paymentStatus) => {
    //     console.log(paymentStatus)
    //     setPaymentStatusFilter(paymentStatus)
    //     setActiveSelectedState('')
    //     if (paymentStatus === 'Remove filter') {
    //         setSortedResults(orders)
    //     } else {
    //         let temp = []
    //         orders.map(order => {
    //             if (order.payment_status === paymentStatus) {
    //                 temp.push(order)
    //             }
    //         })
    //         setSortedResults(temp)
    //     }
    //     setSortType('updatedAt-desc')
    // }

    const copyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        setIsTextCopied(true)
        setTimeout(() => {
            setIsTextCopied(false)
        }, 3000);
    }


    const handleSort = (type) => {
        const order = sortType.split("-")[1]
        if (order === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (order === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        setSortedResults(orders)
        // filterByOrderState('Placed')
        if (!paymentStatusFilter && !activeSelectedState) {
            setSortedResults(orders)
        } else {
            let temp = []
            orders.map(order => {
                if (paymentStatusFilter && activeSelectedState) {
                    if (order.payment_status === paymentStatusFilter && order.state === activeSelectedState) {
                        temp.push(order)
                    }
                } else {
                    if (order.payment_status === paymentStatusFilter) {
                        temp.push(order)
                    } else if (order.state === activeSelectedState) {
                        temp.push(order)
                    }
                }
            })
            setSortedResults(temp)
        }
        setSortType('createdAt-desc')
    }, [orders, paymentStatusFilter, activeSelectedState])

    useEffect(() => {
        let activeCount = 0, initCount = 0, delCount = 0, underholdCount = 0, placedCount = 0, cancelCount = 0;
        if (orders) {
            orders?.map(order => {
                if (order.state === "Manifested" || order.state === "Out for pickup" || order.state === "At source hub" || order.state === "In transit" || order.state === "At destination hub" || order.state === "Out for delivery") {
                    activeCount += 1
                } else if (order.state === "Placed") {
                    placedCount += 1
                } else if (order.state === "Initiated") {
                    initCount += 1
                } else if (order.state === "Delivered") {
                    delCount += 1
                } else if (order.state === "Under Hold") {
                    underholdCount += 1
                } else if (order.state === "Cancelled") {
                    cancelCount += 1
                }
            });
            setActiveOrderCount(activeCount);
            setPlacedOrderCount(placedCount);
            setInitiatedOrderCount(initCount);
            setDeliveredOrderCount(delCount);
            setUnderHoldOrderCount(underholdCount);
            setCancelledOrderCount(cancelCount)
        }
    }, [orders])

    useEffect(() => {
        if (searchString) {
            let temp = []
            orders.map(order => {
                if (order.sm_orderid?.includes(searchString) || order._id?.includes(searchString)) {
                    temp.push(order)
                } else if (order.user?.includes(searchString)) {
                    temp.push(order)
                }
            })
            setSortedResults(temp)
            setSortType('createdAt-desc')
        }
    }, [searchString])

    useEffect(() => {
        if (!fetching) {
            const [attr, type] = sortType.split("-")
            let temp = [...sortedResults]
            if (attr === "updatedAt" || attr === "createdAt") {
                if (type === "asc") {
                    temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) =>
                        new Date(b[attr]) - new Date(a[attr])
                    )
                    setSortedResults(temp)
                }
            } else {
                if (type === "asc") {
                    temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                    setSortedResults(temp)
                }
            }
        }
    }, [sortType])


    if (fetching) return (
        <Loader />
    )

    return (
        <Container className='my-4'>
            {
                isTextCopied && <ToastContainer position='top-center' style={{ zIndex: 1000000 }}>
                    <Toast bg='info' delay={3000} autohide style={{ margin: '20px', fontSize: '20px', textAlign: 'center' }}>
                        <Toast.Body>Item Copied</Toast.Body>
                    </Toast>
                </ToastContainer>
            }

            <div className='d-flex flex-column flex-md-row justify-content-between mb-3'>
                <h4>Express delivery orders</h4>
                <div>
                    <Button variant='secondary' className='me-1' onClick={() => dispatch(fetchExpressDeliveryOrders())}>{refreshIcon}&nbsp;Refresh</Button>
                    <Button onClick={() => navigate('/orders/panIndiaOrders/express-orders/add')}>{addIcon}&nbsp;Add new order</Button>
                </div>
            </div>

            <Form.Control type='text' className='mb-3' value={searchString} onChange={e => setSearchString(e.target.value)} placeholder='Search order...' />

            <div className='d-flex mb-3' style={{ flexWrap: 'wrap' }}>
                <Card className='me-3 dashboard-order-card'>
                    <Card.Body>
                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <FontAwesomeIcon icon={faHourglassStart} className={activeOrdersCount ? 'dashboard-card-svg' : 'dashboard-card-svg-zero-count'} />
                            <div className="d-flex flex-column">
                                <p className='dashboard-card-title'>In progress orders</p>
                                <p className='dashboard-card-text'>{activeOrdersCount}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className='me-3 dashboard-order-card'>
                    <Card.Body>
                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <FontAwesomeIcon icon={faChartLine} className={placedOrdersCount ? 'dashboard-card-svg' : 'dashboard-card-svg-zero-count'} />
                            <div className="d-flex flex-column">
                                <p className='dashboard-card-title'>Placed orders</p>
                                <p className='dashboard-card-text'>{placedOrdersCount}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className='me-3 dashboard-order-card'>
                    <Card.Body>
                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <FontAwesomeIcon icon={faFlagCheckered} className={initiatedOrdersCount ? 'dashboard-card-svg' : 'dashboard-card-svg-zero-count'} />
                            <div className="d-flex flex-column">
                                <p className='dashboard-card-title'>Initiated orders</p>
                                <p className='dashboard-card-text'>{initiatedOrdersCount}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className='me-3 dashboard-order-card'>
                    <Card.Body>
                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <FontAwesomeIcon icon={faCheck} className={deliveredOrdersCount ? 'dashboard-card-svg' : 'dashboard-card-svg-zero-count'} />
                            <div className="d-flex flex-column">
                                <p className='dashboard-card-title'>Delivered orders</p>
                                <p className='dashboard-card-text'>{deliveredOrdersCount}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className='me-3 dashboard-order-card'>
                    <Card.Body>
                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <FontAwesomeIcon icon={faPause} className={underHoldOrdersCount ? 'dashboard-card-svg' : 'dashboard-card-svg-zero-count'} />
                            <div className="d-flex flex-column">
                                <p className='dashboard-card-title'>Under hold orders</p>
                                <p className='dashboard-card-text'>{underHoldOrdersCount}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className='dashboard-order-card'>
                    <Card.Body>
                        <div className="d-flex align-items-center" style={{ height: '100%' }}>
                            <FontAwesomeIcon icon={faXmark} className={cancelledOrdersCount ? 'dashboard-card-svg' : 'dashboard-card-svg-zero-count'} />
                            <div className="d-flex flex-column">
                                <p className='dashboard-card-title'>Cancelled orders</p>
                                <p className='dashboard-card-text'>{cancelledOrdersCount}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>

            <Table striped bordered hover responsive style={{ minHeight: '300px' }}>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th className='col-1'>Order Id</th>
                        <th className='col-1'>Shipper Id</th>
                        <th className='col-2'>
                            Payment Status
                            <Dropdown style={{ display: 'inline-block' }} onSelect={(paymentStatus) => setPaymentStatusFilter(paymentStatus)}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components-payment">
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu}>
                                    <Dropdown.Item eventKey="">Remove filter</Dropdown.Item>
                                    <Dropdown.Item eventKey="Initiated" active={paymentStatusFilter === "Initiated"}>Initiated</Dropdown.Item>
                                    <Dropdown.Item eventKey="Successful" active={paymentStatusFilter === "Successful"}>Successful</Dropdown.Item>
                                    <Dropdown.Item eventKey="Pending" active={paymentStatusFilter === "Pending"}>Pending</Dropdown.Item>
                                    <Dropdown.Item eventKey="Failed" active={paymentStatusFilter === "Failed"}>Failed</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <p className="text-muted mb-0" style={{ fontSize: '10px' }}>Selected: {paymentStatusFilter}</p>
                        </th>
                        {/* <th className='col-1'>User</th> */}
                        <th className='col-2'>From</th>
                        <th className='col-2'>Destination</th>
                        {/* <th className='col-1'>Cost</th> */}
                        <th className='col-1'>Weight</th>
                        <th className='col-1'>
                            Status
                            <Dropdown style={{ display: 'inline-block' }} onSelect={(orderStatus) => setActiveSelectedState(orderStatus)}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu}>
                                    <Dropdown.Item eventKey="">Remove filter</Dropdown.Item>
                                    <Dropdown.Item eventKey="Initiated" active={activeSelectedState === "Initiated"}>Initiated</Dropdown.Item>
                                    <Dropdown.Item eventKey="Placed" active={activeSelectedState === "Placed"}>Placed</Dropdown.Item>
                                    <Dropdown.Item eventKey="To be placed" active={activeSelectedState === "To be placed"}>To be placed</Dropdown.Item>
                                    <Dropdown.Item eventKey="Manifested" active={activeSelectedState === "Manifested"}>Manifested</Dropdown.Item>
                                    <Dropdown.Item eventKey="Out for pickup" active={activeSelectedState === "Out for pickup"}>Out for pickup</Dropdown.Item>
                                    <Dropdown.Item eventKey="At source hub" active={activeSelectedState === "At source hub"}>At source hub</Dropdown.Item>
                                    <Dropdown.Item eventKey="In transit" active={activeSelectedState === "In transit"}>In transit</Dropdown.Item>
                                    <Dropdown.Item eventKey="At destination hub" active={activeSelectedState === "At destination hub"}>At destination hub</Dropdown.Item>
                                    <Dropdown.Item eventKey="Out for delivery" active={activeSelectedState === "Out for delivery"}>Out for delivery</Dropdown.Item>
                                    <Dropdown.Item eventKey="Delivered" active={activeSelectedState === "Delivered"}>Delivered</Dropdown.Item>
                                    <Dropdown.Item eventKey="Cancelled" active={activeSelectedState === "Cancelled"}>Cancelled</Dropdown.Item>
                                    <Dropdown.Item eventKey="Under Hold" active={activeSelectedState === "Under Hold"}>Under Hold</Dropdown.Item>
                                    <Dropdown.Item eventKey="Delivery failed" active={activeSelectedState === "Delivery failed"}>Delivery failed</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <p className="text-muted mb-0" style={{ fontSize: '10px' }}>Selected: {activeSelectedState}</p>
                        </th>
                        <th className='col-1'>
                            Booked At&nbsp;
                            {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                            }
                        </th>
                        {/* <th className='col-1'>
                            Updated at&nbsp;
                            {sortType.split("-")[0] !== "updatedAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortDescIcon}</span>
                            }
                        </th> */}
                        <th className='col-1'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sortedResults.map((order, index) => {
                            const dateString = new Date(order.createdAt);
                            const date = dateString.toDateString() + ", " + dateString.toLocaleTimeString();
                            const updatedAtDateString = new Date(order.updatedAt);
                            const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();
                            return (
                                <OrderRow order={order} key={index} copyTextFunction={copyText} />
                            )
                        })
                    }
                </tbody>
            </Table>
        </Container>
    )
}
