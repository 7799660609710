import React, { useEffect } from 'react'
import { Button, Spinner, Row, Col, Form } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchSingleHyShipper, updateHyShipper } from '../../app/features/shipper/shipperSlice'
import { backButtonIcon } from '../../FontAwesome'
import Loader from '../Loader'

export default function EditHyShipper() {
    const navigate = useNavigate()
    const dispatch = useDispatch(0)

    const { shipperID } = useParams()

    const { shipperDetail, fetching } = useSelector(state => state.shipper)

    const { register, handleSubmit, watch, reset, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            name: shipperDetail.name ? shipperDetail.name : "",
            company: shipperDetail.company ? shipperDetail.company : "",
            address: shipperDetail.address ? shipperDetail.address : "",
            phone: shipperDetail.phone ? shipperDetail.phone : "",
            email: shipperDetail.email ? shipperDetail.email : "",
            desc: shipperDetail.desc ? shipperDetail.desc : "",
            logoURL: shipperDetail.logoURL ? shipperDetail.logoURL : "",
        }
    });
    const editHyShipper = (data) => {
        console.log(data)
        dispatch(updateHyShipper(data))
            .then(() => dispatch(fetchSingleHyShipper({ shipperID })))
    }

    useEffect(() => {
        dispatch(fetchSingleHyShipper({ shipperID }))
    }, [])

    useEffect(() => {
        reset(shipperDetail)
    }, [shipperDetail])

    if (fetching) {
        return <Loader />
    }

    return (
        <div style={{ padding: '30px' }}>
            <h4 className='mb-4'>Update Shipper : {shipperDetail.company}</h4>
            <Button variant='primary' onClick={() => navigate(-1)}>{backButtonIcon}&nbsp;Back</Button>
            <hr />
            <Form onSubmit={handleSubmit(editHyShipper)}>
                <Row>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter name" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="company"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Company name</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter company" required />
                                </Form.Group>
                            )}
                        />

                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="address"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter address" />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="phone"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Contact number</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter contact" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" value={value} onChange={onChange} placeholder="Enter email" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="desc"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="desc">
                                    <Form.Label>Desc</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter desc" />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="logoURL"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="logoURL">
                                    <Form.Label>LogoURL</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter logo url" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                </Row>
                <hr />
                <Button type='submit' disabled={fetching}>
                    Update shipper&nbsp;
                    {fetching && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />}
                </Button>
            </Form>
        </div>
    )
}
