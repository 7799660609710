import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Table, Spinner, Pagination } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getWalletTransactions } from '../../../app/features/shipmantrabusiness/wallet/businessWalletSlice'
import { sortAscIcon, sortDescIcon, sortIcon } from '../../../FontAwesome'

export default function WalletTransactionsList({ show, handleClose, businessUserUID }) {
    const dispatch = useDispatch();

    const { transactionFetching, walletTransactions, totalPages } = useSelector(state => state.businessWallet)

    const [sortType, setSortType] = useState("createdAt-desc")
    const [sortedResults, setSortedResults] = useState(walletTransactions ? walletTransactions : [])
    const [page, setPage] = useState(1)

    const handleSort = (type) => {
        const sortOrder = sortType.split("-")[1]
        if (sortOrder === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (sortOrder === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        dispatch(getWalletTransactions({ uid: businessUserUID, page }))
    }, [page])

    useEffect(() => {
        setSortedResults(walletTransactions)
    }, [walletTransactions])

    useEffect(() => {
        if (!transactionFetching) {
            const [attr, type] = sortType.split("-")
            let temp = [...sortedResults]
            if (attr === "updatedAt" || attr === "createdAt") {
                if (type === "asc") {
                    temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) =>
                        new Date(b[attr]) - new Date(a[attr])
                    )
                    setSortedResults(temp)
                }
            } else {
                if (type === "asc") {
                    temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                    setSortedResults(temp)
                }
            }
        }
    }, [sortType])

    return (
        <>
            <Modal size='xl' show={show} onHide={handleClose}>
                <Modal.Body>
                    {
                        transactionFetching ? <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
                            <Spinner animation="border" />
                        </div> :
                            <Table striped bordered hover responsive>
                                <thead style={{ verticalAlign: 'middle' }}>
                                    <tr>
                                        <th>
                                            Date&nbsp;
                                            {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                                            {
                                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                                            }
                                            {
                                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                                            }
                                        </th>
                                        <th>Description</th>
                                        <th>
                                            Type
                                        </th>
                                        <th>Amount(₹)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sortedResults.map((transaction, index) => {
                                            const dateString = new Date(transaction.createdAt);
                                            const date = dateString.toDateString() + ", " + dateString.toLocaleTimeString();
                                            const updatedAtDateString = new Date(transaction.updatedAt);
                                            const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {date}
                                                    </td>
                                                    <td>{transaction.desc}</td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                        {transaction.type}
                                                    </td>
                                                    <td style={{ color: (transaction.type === 'recharge' ? 'green' : transaction.type === 'debit' ? 'red' : '') }}>{Math.abs(transaction.amount / 100).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                    }

                    <Pagination style={{ marginRight: '20px' }}>
                        <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || transactionFetching} />
                        <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || transactionFetching} />
                        {page - 2 > 0 && <Pagination.Item disabled={transactionFetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                        {page - 1 > 0 && <Pagination.Item disabled={transactionFetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                        <Pagination.Item active>{page}</Pagination.Item>
                        {totalPages >= page + 1 && <Pagination.Item disabled={transactionFetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                        {totalPages >= page + 2 && <Pagination.Item disabled={transactionFetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                        <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || transactionFetching} />
                        <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || transactionFetching} />
                    </Pagination>
                </Modal.Body>
            </Modal>
        </>
    )
}
