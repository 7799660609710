import React, { useEffect, useState } from 'react'
import { Table, Dropdown, Button, Form, Toast, ToastContainer } from 'react-bootstrap'
import { copyIcon, filterIcon, refreshIcon } from '../../FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import EnquiryDetail from './EnquiryDetail';
import { updateBusinessEnquiry, updateGeneralEnquiry, updateShipperEnquiry } from '../../app/features/enquiries/enquirySlice';


function EnquiryTableRow({ enquiry, setIsTextCopied, enquiryType }) {
    const dispatch = useDispatch();

    const [busy, setBusy] = useState(false);
    const [showEnquiryDetail, setShowEnquiryDetail] = useState(false)


    const copyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        setIsTextCopied(true)
        setTimeout(() => {
            setIsTextCopied(false)
        }, 3000);
    }

    const updateEnquiry = async (event) => {
        setBusy(true)
        if (enquiryType === 'business-enquiry') {
            const updatePayload = {
                enquiry_id: enquiry?.enquiry_id,
                payload: {
                    ...enquiry,
                    enquiryStatus: event?.currentTarget?.value
                }
            }
            dispatch(updateBusinessEnquiry(updatePayload))
                .then(res => {
                    if (res.error) {
                        alert('There was some error while updating the enquiry')
                    }
                })
                .catch(err => {
                    console.error(err)
                    alert('There was some error while updating the enquiry')
                })
                .finally(() => {
                    setBusy(false)
                })
        } else if (enquiryType === 'shipper-enquiry') {
            const updatePayload = {
                enquiry_id: enquiry?.enquiry_id,
                payload: {
                    ...enquiry,
                    enquiryStatus: event?.currentTarget?.value
                }
            }
            dispatch(updateShipperEnquiry(updatePayload))
                .then(res => {
                    if (res.error) {
                        alert('There was some error while updating the enquiry')
                    }
                })
                .catch(err => {
                    console.error(err)
                    alert('There was some error while updating the enquiry')
                })
                .finally(() => {
                    setBusy(false)
                })
        } else if (enquiryType === 'general-enquiry') {
            const updatePayload = {
                enquiry_id: enquiry?.enquiry_id,
                payload: {
                    ...enquiry,
                    enquiryStatus: event?.currentTarget?.value
                }
            }
            dispatch(updateGeneralEnquiry(updatePayload))
                .then(res => {
                    if (res.error) {
                        alert('There was some error while updating the enquiry')
                    }
                })
                .catch(err => {
                    console.error(err)
                    alert('There was some error while updating the enquiry')
                })
                .finally(() => {
                    setBusy(false)
                })
        }
    }

    const createdAtDateObj = new Date(enquiry.createdAt)

    return (
        <>
            <tr>
                <td>
                    {enquiry.enquiry_id}&nbsp;
                    <span style={{ cursor: 'pointer' }} onClick={() => copyText(enquiry.enquiry_id)}>{copyIcon}</span>
                </td>
                <td>
                    {enquiry.contactName ? enquiry.contactName : enquiry.name} <br />
                    {enquiry.phone} <br />
                    {enquiry.email}
                </td>
                <td>
                    {enquiry.companyName ? enquiry.companyName : enquiry.brandName} <br />
                    <a href={enquiry.websiteUrl} target='_blank' rel='noreferrer'>{enquiry.websiteUrl}</a>
                    {enquiry.address ? 'City: ' + enquiry.address?.city : null}
                </td>
                <td>
                    <Form.Select disabled={busy} aria-label="Default select example" value={enquiry.enquiryStatus} onChange={e => updateEnquiry(e)}
                    >
                        <option>Open this select menu</option>
                        <option value="pending">Pending</option>
                        <option value="initiated">Initiated</option>
                        <option value="in_progress">In Progress</option>
                        <option value="resolved">Resolved</option>
                    </Form.Select>
                </td>
                <td>{createdAtDateObj.toDateString()}<br />{createdAtDateObj.getHours() + ":" + createdAtDateObj.getMinutes()}</td>
                <td>
                    <Button
                        onClick={() => { setShowEnquiryDetail(true) }}
                        variant='success'>View Details</Button>
                </td>
            </tr>
            {showEnquiryDetail && <EnquiryDetail enquiry={enquiry} show={showEnquiryDetail} handleClose={() => setShowEnquiryDetail(false)} />}
        </>
    )
}


export default function EnquiryTable({ enquiryType, enquiries }) {
    const [sortedResults, setSortedResults] = useState([])
    const [sortType, setSortType] = useState('createdAt-desc')
    const [activeEnquiryStatus, setActiveEnquiryStatus] = useState('')
    const [isTextCopied, setIsTextCopied] = useState(false);

    useEffect(() => {
        setSortedResults(enquiries)
    }, [enquiries])

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            // href=""
            // style={{ cursor: 'pointer' }}
            size='sm'
            style={{ marginLeft: '10px' }}
            ref={ref}
            variant='secondary'
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            {filterIcon}
        </Button>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                        )}
                    </ul>
                </div>
            );
        },
    );


    useEffect(() => {
        // setSortedResults(enquiries)
        // filterByenquiriestate('Placed')
        // if (!activeEnquiryStatus) {
        //     setSortedResults(enquiries)
        // } else {
        //     let temp = []
        //     enquiries?.map(enquiry => {
        //         if (enquiry.enquiryStatus === activeEnquiryStatus) {
        //             temp.push(enquiry)
        //         }
        //     })
        //     setSortedResults(temp)
        // }

        if (!activeEnquiryStatus) {
            setSortedResults(enquiries)
            return;
        }
        let temp = []
        enquiries?.map(enquiry => {
            if (enquiry.enquiryStatus === activeEnquiryStatus) {
                temp.push(enquiry)
            }
        })
        setSortedResults(temp)
        setSortType('updatedAt-desc')
    }, [activeEnquiryStatus])

    return (
        <>
            {
                isTextCopied && <ToastContainer position='top-center'>
                    <Toast bg='info' delay={3000} autohide style={{ margin: '20px', fontSize: '20px', textAlign: 'center' }}>
                        <Toast.Body>Item Copied</Toast.Body>
                    </Toast>
                </ToastContainer>
            }

            <Table striped bordered hover responsive className='mt-3' style={{ minHeight: '60vh' }}>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th className='col-2'>Enquiry ID</th>
                        <th className='col-2'>Contact details</th>
                        <th className='col-2'>Company name</th>
                        <th className='col-2'>
                            Enquiry Status
                            <Dropdown style={{ display: 'inline-block', zIndex: '1000' }} onSelect={(enquiryStatus) => setActiveEnquiryStatus(enquiryStatus)}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu}>
                                    <Dropdown.Item eventKey="">Remove filter</Dropdown.Item>
                                    <Dropdown.Item eventKey="pending" active={activeEnquiryStatus === "pending"}>Pending</Dropdown.Item>
                                    <Dropdown.Item eventKey="initiated" active={activeEnquiryStatus === "initiated"}>Initiated</Dropdown.Item>
                                    <Dropdown.Item eventKey="in_progress" active={activeEnquiryStatus === "in_progress"}>In progress</Dropdown.Item>
                                    <Dropdown.Item eventKey="resolved" active={activeEnquiryStatus === "resolved"}>Resolved</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </th>
                        <th className='col-2'>Created At</th>
                        <th className='col-2'></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sortedResults?.map((enquiry, index) => {
                            return (
                                <EnquiryTableRow
                                    key={"enquiry_" + index} setIsTextCopied={setIsTextCopied} enquiry={enquiry} enquiryType={enquiryType}
                                />
                            )
                        })
                    }
                </tbody>
            </Table>
        </>
    )
}
