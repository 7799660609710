import React, { useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal, Spinner, Tab, Tabs, Table, Button, Pagination, Collapse, Card, Badge } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'
import { editIcon, sortAscIcon, sortDescIcon, sortIcon, phoneIcon } from '../../../FontAwesome';
import OrderDetail from './OrderDetails';
import VoiceCall from '../../Misc/VoiceCall';
import { fetchAllBusinessOrders } from '../../../app/features/shipmantrabusiness/order/businessOrderSlice';
import { fetchBusinessUserDetail } from '../../../app/features/shipmantrabusiness/user/businessUserSlice';
import { fetchBusinessUserDetailsAPI } from '../../../app/api/shipmantrabusiness/users';
import PDFPreview from '../../PDFPreview';
import InvoiceTemplate from '../../Invoice/InvoiceTemplate';

function TableRow({ order, orderType }) {
    const dispatch = useDispatch();

    // const { userDetail, fetching: userDetailsFetching } = useSelector(state => state.businessUser)

    const [showOrderDetail, setShowOrderDetail] = useState(false);
    const [userDetail, setUserDetails] = useState({});
    const [userDetailsFetching, setUserDetailsFetching] = useState(false);

    const [open, setOpen] = useState(false);

    const [numberToCall, setNumberToCall] = useState('')
    const [showVoiceCallModal, setShowVoiceCallModal] = useState(false)
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false)

    const updatedAtDateString = new Date(order.updatedAt);
    const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();

    const bookedAtDateString = new Date(order.createdAt);
    const bookedAtDate = bookedAtDateString.toDateString() + ", " + bookedAtDateString.toLocaleTimeString();

    const fetchCollapsableUserDetails = (uid) => {
        setOpen(!open);
        if (!open) {
            setUserDetailsFetching(true);
            fetchBusinessUserDetailsAPI(uid)
                .then(res => {
                    setUserDetails(res.data)
                })
                .finally(() => {
                    setUserDetailsFetching(false)
                })
        }
    }

    const formattedOrder = {
        ...order,
        cost: {
            amountCollectedFromCustomer: order?.cost,
            gst: order?.costBreakDown?.gst
        }
    }


    return (
        <>
            <tr>
                <td className='py-4'>
                    <span className='fw-bold'>Order Id: {order.sm_orderid ? order.sm_orderid : order._id}</span>
                    {
                        order?.order_status === 'Cancelled' ? <Badge pill bg='danger' className='ms-1'>{order?.order_status}</Badge>
                            : order?.order_status === 'shipper_issue' ? <Badge pill bg='warning' className='ms-1'>{order?.order_status}</Badge>
                                : order?.order_status === 'Delivered' ? <Badge pill bg='success' className='ms-1'>{order?.order_status}</Badge>
                                    : <Badge pill bg='info' className='ms-1'>{order?.order_status}</Badge>
                    }
                    <hr />
                    <span className='fw-bold'>User</span>: {order.user}
                    <Button
                        disabled={userDetailsFetching}
                        className='ms-2'
                        size='sm'
                        variant='secondary'
                        onClick={() => { fetchCollapsableUserDetails(order.user) }}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                    >
                        Details&nbsp;
                        {userDetailsFetching && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text">
                            <Card body style={{ width: '400px' }}>
                                <p>Name: {userDetail?.fullName}</p>
                                <p>Email: {userDetail?.email}</p>
                                <p className='mb-0'>Contact: {userDetail?.phone}</p>
                            </Card>
                        </div>
                    </Collapse>
                    <br />
                    <span className='fw-bold'>Shipper</span>: {order.shipperID}
                    <br />
                    <span className='fw-bold'>Cost</span>: {order.cost}
                    <br />
                    <span className='fw-bold'>Updated At</span>: {updatedAtDate}
                    <br />
                    <span className='fw-bold'>Booked At</span>: {bookedAtDate}
                </td>
                <td className='py-4'>
                    <span className='fw-bold'>Name</span>: {order.start?.contact?.name}
                    <br />
                    <span className='fw-bold'>Phone</span>: {order.start?.contact?.phone}&nbsp;<span style={{ cursor: 'pointer' }} onClick={() => { setNumberToCall(order.start?.contact?.phone); setShowVoiceCallModal(true) }}>{phoneIcon}</span>
                    <br />
                    {/* <span className='fw-bold'>City</span>: {order.start?.address?.mapData?.city}
                    <br /> */}
                    <span className='fw-bold'>Address</span>: {order.start?.address?.mapData?.address}
                </td>
                <td className='py-4'>
                    <span className='fw-bold'>Name</span>: {order.end?.contact?.name}
                    <br />
                    <span className='fw-bold'>Phone</span>: {order.end?.contact?.phone}&nbsp;<span style={{ cursor: 'pointer' }} onClick={() => { setNumberToCall(order.end?.contact?.phone); setShowVoiceCallModal(true) }}>{phoneIcon}</span>
                    <br />
                    {/* <span className='fw-bold'>City</span>: {order.end?.address?.mapData?.city}
                    <br /> */}
                    <span className='fw-bold'>Address</span>: {order.end?.address?.mapData?.address}
                </td>
                <td className='py-4'>
                    <Button onClick={() => dispatch(setShowOrderDetail(true))}>Edit</Button><br />
                    <Button variant="info" className='mt-2' onClick={() => { setOpenInvoiceModal(true) }}>Invoice</Button><br />
                </td>
            </tr>
            <VoiceCall show={showVoiceCallModal} handleClose={() => setShowVoiceCallModal(false)} numberToCall={numberToCall} orderType='apsrtc' orderId={order?.sm_orderid} />
            {showOrderDetail && <OrderDetail show={showOrderDetail} handleClose={() => setShowOrderDetail(false)} order={order} />}
            {openInvoiceModal && <PDFPreview fileName={order?.sm_orderid + "_Invoice"} show={openInvoiceModal} handleClose={() => setOpenInvoiceModal(false)} pdfTemplate={<InvoiceTemplate dataArr={[formattedOrder]} />} />}
        </>
    )
}

function OrdersTable({ orderType, orders }) {

    const [sortedResults, setSortedResults] = useState(orders ? orders : []);
    const [sortType, setSortType] = useState("updatedAt-desc");

    const handleSort = (type) => {
        const order = sortType.split("-")[1]
        if (order === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (order === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        // if (!fetching) {
        const [attr, type] = sortType.split("-")
        let temp = []
        if (orderType === 'hyp') {
            temp = [...orders.filter(order => order.sm_orderid?.split("-")[0] === 'HYP')]
        } else if (orderType === 'int') {
            temp = [...orders.filter(order => order.sm_orderid?.split("-")[0] === 'INT')]
        } else if (orderType === 'pan') {
            temp = [...orders.filter(order => order.sm_orderid?.split("-")[0] === 'PAN')]
        }
        if (attr === "updatedAt" || attr === "createdAt") {
            if (type === "asc") {
                temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) =>
                    new Date(b[attr]) - new Date(a[attr])
                )
                setSortedResults(temp)
            }
        } else {
            if (type === "asc") {
                temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                setSortedResults(temp)
            }
        }
        // }
    }, [sortType, orderType, orders])

    return (
        <>
            <Table striped bordered hover responsive>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th className='col-5'>Order Details</th>
                        <th className='col-3'>Start</th>
                        <th className='col-3'>End</th>
                        {/* <th className='col-1'>Shipper</th> */}
                        {/* <th className='col-3'>Order Details</th> */}
                        {/* <th className='col-1'>Payment Status</th> */}
                        {/* <th className='col-1'>Cost</th> */}
                        {/* <th className='col-2'>
                            Updated at&nbsp;
                            {sortType.split("-")[0] !== "updatedAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortDescIcon}</span>
                            }
                        </th> */}
                        <th className='col-1'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sortedResults.map((order, index) => {
                            return (
                                <TableRow order={order} orderType={orderType} key={index} />
                            )
                        })
                    }
                </tbody>
            </Table>
            {/* {showOrderDetail && <OrderDetail show={showOrderDetail} handleClose={() => setShowOrderDetail(false)} />} */}
        </>
    )
}

export default function Orders({ show, handleClose }) {
    const { orders } = useSelector(state => state.businessOrder)
    // const [showOrderDetail, setShowOrderDetail] = useState(false)

    const [orderTab, setOrderTab] = useState('hyp');

    return (
        <Modal show={show} fullscreen={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Orders</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={orderTab}
                    onSelect={(k) => setOrderTab(k)}
                    className="mb-3"
                >
                    <Tab eventKey="hyp" title="Hyperlocal">
                        <OrdersTable orderType='hyp' orders={orders} />
                    </Tab>
                    <Tab eventKey="int" title="International">
                        <OrdersTable orderType='int' orders={orders} />
                    </Tab>
                    <Tab eventKey="pan" title="Pan India">
                        <OrdersTable orderType='pan' orders={orders} />
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
    )
}

export function AllBusinessOrders() {
    const dispatch = useDispatch();

    const { orders, totalPages, fetchingAllOrders: fetching } = useSelector(state => state.businessOrder);

    const isSearchInputFirstLoad = useRef(true);


    const [page, setPage] = useState(1)
    const [searchString, setSearchString] = useState('')


    useEffect(() => {
        dispatch(fetchAllBusinessOrders({ page, searchString }))
    }, [page])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (!isSearchInputFirstLoad.current) {
                dispatch(fetchAllBusinessOrders({ page, searchString }))
            } else {
                isSearchInputFirstLoad.current = false
            }
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchString])

    return (
        <div style={{ padding: '30px' }}>
            <div className='d-flex justify-content-md-between'>
                <h3>Business Orders</h3>
            </div>
            <hr />

            <input type="text" className='form-control mb-2' placeholder='Search order ID or phone number' value={searchString} onChange={e => setSearchString(e.currentTarget.value)} />

            <Pagination style={{ marginRight: '20px' }}>
                <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || fetching} />
                <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || fetching} />
                {page - 2 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                {page - 1 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                <Pagination.Item active>{page}</Pagination.Item>
                {totalPages >= page + 1 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                {totalPages >= page + 2 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || fetching} />
                <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || fetching} />
            </Pagination>

            {
                fetching ? "Loading ...." :
                    <OrdersTable orders={orders} orderType='pan' />
            }

            <Pagination style={{ marginRight: '20px' }}>
                <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || fetching} />
                <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || fetching} />
                {page - 2 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                {page - 1 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                <Pagination.Item active>{page}</Pagination.Item>
                {totalPages >= page + 1 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                {totalPages >= page + 2 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || fetching} />
                <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || fetching} />
            </Pagination>
        </div>
    )
}
