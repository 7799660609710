import React, { useState, useEffect } from 'react'
import { Container, Tabs, Tab, Form } from 'react-bootstrap'
import { act } from 'react-dom/test-utils'
import { useDispatch } from 'react-redux'
import { fetchAllHyperlocal2Wheeler } from '../../app/features/order/orderSlice'
import Hyperlocal2WheelerOrders from './Hyperlocal2WheelerOrders'
import Hyperlocal3WheelerOrders from './Hyperlocal3WheelerOrders'
import Hyperlocal4WheelerOrders from './Hyperlocal4WheelerOrders'
import HyperlocalOrders from './HyperlocalOrders'

export default function Hyperlocal() {
    const dispatch = useDispatch()
    const [active, setActive] = useState("0")
    // useEffect(() => {
    //     dispatch(fetchAllHyperlocalOrders())
    // }, [])

    const [searchString, setSearchString] = useState('')

    const handleTabChange = e => {
        setActive(e)
    }

    return (
        <Container className='mt-4'>
            <Form.Control type='text' className='mb-3' value={searchString} onChange={e => setSearchString(e.target.value)} placeholder='Search order...' />

            <Tabs defaultActiveKey={active} id="uncontrolled-tab-example" onSelect={handleTabChange} className="mb-3">
                <Tab eventKey="0" title="All Orders">
                    {active === "0" && <HyperlocalOrders searchString={searchString} />}
                </Tab>
                <Tab eventKey="1" title="2 Wheeler">
                    {active === "1" && <Hyperlocal2WheelerOrders searchString={searchString} />}
                </Tab>
                <Tab eventKey="2" title="3 Wheeler">
                    {active === "2" && <Hyperlocal3WheelerOrders searchString={searchString} />}
                </Tab>
                <Tab eventKey="3" title="4 Wheeler">
                    {active === "3" && <Hyperlocal4WheelerOrders searchString={searchString} />}
                </Tab>
            </Tabs>
        </Container>
    )
}
