import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { Col, Form, Row, Button } from 'react-bootstrap'
import { addCMSAnnouncementFeed, addContentManagement, editCMSAnnouncementFeed, getCMSSingleAnnouncementFeed } from '../../../app/features/cms/contentmanagementSlice'
import { DefaultEditor } from 'react-simple-wysiwyg';
import { useEffect } from 'react'
import Loader from '../../Loader'
import { useSelector } from 'react-redux'
import { backButtonIcon } from '../../../FontAwesome'

export default function EditAnnouncementFeed() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { fetching, detail } = useSelector(state => state.cms)

    const id = params.id

    const { handleSubmit, control, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {
            feed: detail.feed ? detail.feed : ''
        }
    })

    const resetData = () => {
        setValue('feed', '')
    }


    const submitDetails = (data) => {
        console.log(data)
        const body = {
            id: id,
            feed: data.feed
        }
        dispatch(editCMSAnnouncementFeed(body))
            .then(() => dispatch(getCMSSingleAnnouncementFeed(id)))
    }

    useEffect(() => {
        dispatch(getCMSSingleAnnouncementFeed(id))
    }, [])

    useEffect(() => {
        setValue('feed', detail.feed)
    }, [detail])

    if (fetching) {
        <Loader />
    }

    return (
        <div style={{ padding: '30px' }}>
            <Button onClick={() => navigate(-1)}>{backButtonIcon}&nbsp;Back</Button>
            <hr />
            <Form onSubmit={handleSubmit(submitDetails)}>
                <Row>
                    <Col xs={12}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="feed"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="feed">
                                    <Form.Label>Announcement bar</Form.Label>
                                    <DefaultEditor value={value} onChange={onChange} />
                                </Form.Group>
                            )}
                        />
                        {errors.feed && <div className='error-text'>This field is required</div>}
                    </Col>
                </Row>
                <hr />
                <Button type='submit'>Submit</Button>
            </Form>
        </div>
    )
}
