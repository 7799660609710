import React, { useState, useEffect } from 'react'
import { Badge, Pagination, Button, Col, Row, Form, Modal, OverlayTrigger, Card, InputGroup, Table, Toast, ToastContainer, Tooltip, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { copyIcon, phoneIcon, refreshIcon, sortAscIcon, sortDescIcon, sortIcon } from '../../FontAwesome';
import OrderDetail from './OrderDetails';
import { unwrapResult } from '@reduxjs/toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import VoiceCall from '../Misc/VoiceCall';
import { useRef } from 'react';
import { fetchAllApsrtcRequestOrders, updateApsrtcOrder, updateRequestApsrtcOrder } from '../../app/features/apsrtc_booking/apsrtcBookingSlice';
import { useForm } from 'react-hook-form';


function ConvertOrderModal({ show, handleClose, order }) {
    const dispatch = useDispatch();

    const { fetchingSingle } = useSelector(state => state.apsrtcBooking);

    const [selectedFmAgentId, setSelectedFmAgentId] = useState('');
    const [selectedLmAgentId, setSelectedLmAgentId] = useState('');

    const [fmAgentData, setFmAgentData] = useState(undefined)
    const [lmAgentData, setLmAgentData] = useState(undefined)

    useEffect(() => {
        if (order) {
            setSelectedFmAgentId(order?.stakeholders?.firstMileAgents?.length === 1 ? order?.stakeholders?.firstMileAgents[0]?.agentId?._id : '')
            setFmAgentData(order?.stakeholders?.firstMileAgents?.length === 1 ? order?.stakeholders?.firstMileAgents[0] : '')

            setSelectedLmAgentId(order?.stakeholders?.lastMileAgents?.length === 1 ? order?.stakeholders?.lastMileAgents[0]?.agentId?._id : '')
            setLmAgentData(order?.stakeholders?.lastMileAgents?.length === 1 ? order?.stakeholders?.lastMileAgents[0] : '')
        }
    }, [order])


    const handleAgentSelectChange = (agent_type, agent) => {
        if (agent_type === 'fm_agent') {
            setSelectedFmAgentId(agent.agentId?._id)
            setFmAgentData(agent)
        }
        else if (agent_type === 'lm_agent') {
            setSelectedLmAgentId(agent.agentId?._id)
            setLmAgentData(agent)
        }
    }

    const convertRequestOrder = () => {
        if (!order?.metadata?.requestOrderData?.pricing) {
            alert('Estimate not yet added');
            return;
        }

        const updateBody = {
            sm_orderid: order.sm_orderid,
            action: 'send-payment-link',
            payload: {
                "firstMile.shipperUid": fmAgentData.companyUid,
                "firstMile.end.contact": fmAgentData.hubDetails.contact,
                "firstMile.end.address": fmAgentData.hubDetails.address,
                "stakeholders.firstMileAgentOrgId": selectedFmAgentId,
                "midMile.start.contact": fmAgentData.hubDetails.contact,
                "midMile.start.address": fmAgentData.hubDetails.address,
                "midMile.end.contact": lmAgentData.hubDetails.contact,
                "midMile.end.address": lmAgentData.hubDetails.address,
                "stakeholders.lastMileAgentOrgId": selectedLmAgentId,
                "lastMile.shipperUid": lmAgentData.companyUid,
                "lastMile.start.contact": lmAgentData.hubDetails.contact,
                "lastMile.start.address": lmAgentData.hubDetails.address,
            }
        }

        dispatch(updateRequestApsrtcOrder(updateBody))
            .then(res => {
                console.log(res)
                if (!res?.error) {
                    alert('Payment Link sent successfully');
                    handleClose();
                }
            })
    }


    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Convert Request Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Label className='fw-bold text-decoration-underline'>First Mile Agents</Form.Label>
                            {
                                order?.stakeholders?.firstMileAgents?.length ? order?.stakeholders?.firstMileAgents?.map((agent, index) => {
                                    return (
                                        <Card body key={index} className='mb-1'>
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Form.Check
                                                        type="radio"
                                                        id={"fm_agent_" + agent?.agentId?._id}
                                                        onChange={() => handleAgentSelectChange('fm_agent', agent)}
                                                        checked={selectedFmAgentId === agent?.agentId?._id}
                                                    />
                                                </div>
                                                <div className='ms-3'>
                                                    <b>Agent:</b>&nbsp;{agent.agentId?.companyDisplayName}<br />
                                                    <b>Estimated Distance:</b>&nbsp;{agent.distance} KM<br />
                                                </div>
                                            </div>
                                        </Card>
                                    )
                                })
                                    : 'No Agents Available'
                            }
                        </Col>

                        <Col sm={12} md={6}>
                            <Form.Label className='fw-bold text-decoration-underline'>Last Mile Agents</Form.Label>
                            {
                                order?.stakeholders?.lastMileAgents?.length ? order?.stakeholders?.lastMileAgents?.map((agent, index) => {
                                    return (
                                        <Card body key={index} className='mb-1'>
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Form.Check
                                                        type="radio"
                                                        id={"lm_agent_" + agent?.agentId?._id}
                                                        onChange={() => handleAgentSelectChange('lm_agent', agent)}
                                                        checked={selectedLmAgentId === agent?.agentId?._id}
                                                    />
                                                </div>
                                                <div className='ms-3'>
                                                    <b>Agent:</b>&nbsp;{agent.agentId?.companyDisplayName}<br />
                                                    <b>Estimated Distance:</b>&nbsp;{agent.distance} KM<br />
                                                </div>
                                            </div>
                                        </Card>
                                    )
                                })
                                    : 'No Agents Available'
                            }
                        </Col>

                    </Row>
                    <hr />
                    <Button style={{ float: 'right' }} onClick={convertRequestOrder} disabled={fetchingSingle}>
                        Convert&nbsp;
                        {
                            fetchingSingle && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

function CalculateEstimateModal({ show, handleClose, order }) {
    const dispatch = useDispatch();

    const { fetchingSingle } = useSelector(state => state.apsrtcBooking);

    const [totalCost, setTotalCost] = useState(0);

    const [fmPrice, setFmPrice] = useState(0)
    const [lmPrice, setLmPrice] = useState(0)
    const [mmPrice, setMMPrice] = useState(0)
    const [loadingCharges, setloadingCharges] = useState(0)
    const [packagingCharges, setPackagingCharges] = useState(0)

    const [baseTotal, setBaseTotal] = useState(0)
    const [deviationPercent, setDeviationPercent] = useState(0)
    const [deviationCharges, setDeviationCharges] = useState(0)
    const [commission, setCommission] = useState(0)
    const [gst, setGst] = useState(0);


    useEffect(() => {
        let tempTotalCost = 0
        const baseCost = fmPrice + mmPrice + lmPrice + loadingCharges + packagingCharges
        setBaseTotal(baseCost);
        tempTotalCost += baseCost

        const deviation = tempTotalCost * 0.01 * deviationPercent
        setDeviationCharges(deviation)
        tempTotalCost += deviation

        const tempCommissionCharges = tempTotalCost * 0.01 * 15
        setCommission(tempCommissionCharges)
        tempTotalCost += tempCommissionCharges

        const tempGst = tempTotalCost * 0.01 * 18
        setGst(tempGst)
        tempTotalCost += tempGst

        setTotalCost(tempTotalCost)

    }, [fmPrice, mmPrice, lmPrice, loadingCharges, packagingCharges, deviationPercent])


    useEffect(() => {
        if (order) {
            setFmPrice(order.metadata?.requestOrderData?.pricing?.firstMilePrice || 0)
            setLmPrice(order.metadata?.requestOrderData?.pricing?.lastMilePrice || 0)
            setMMPrice(order.metadata?.requestOrderData?.pricing?.midMilePrice || 0)
            setloadingCharges(order.metadata?.requestOrderData?.pricing?.loadingCharges || 0)
            setPackagingCharges(order.metadata?.requestOrderData?.pricing?.packagingCharges || 0)
            setDeviationPercent(order.metadata?.requestOrderData?.pricing?.deviation?.percent || 0)
        }
    }, [order])


    const saveRequestOrderPricingData = () => {
        const updateBody = {
            sm_orderid: order.sm_orderid,
            payload: {
                "metadata.requestOrderData.pricing": {
                    firstMilePrice: fmPrice,
                    lastMilePrice: lmPrice,
                    midMilePrice: mmPrice,
                    loadingCharges: loadingCharges,
                    packagingCharges: packagingCharges,
                    deviation: {
                        percent: deviationPercent,
                        charges: deviationCharges
                    },
                    commission: {
                        percent: 15,
                        charges: commission
                    },
                    gst: {
                        percent: 18,
                        charges: gst
                    },
                    totalCost: totalCost
                }
            }
        }

        dispatch(updateRequestApsrtcOrder(updateBody))
            .then(res => {
                console.log(res)
                if (!res?.error) {
                    alert('Estimates saved successfully')
                    handleClose()
                }
            })
    }

    // const saveRequestOrderPricingData = () => {
    //     const updateBody = {
    //         sm_orderid: order.sm_orderid,
    //         action: 'send-payment-link',
    //         payload: {
    //             "metadata.requestOrderData.pricing": {
    //                 firstMilePrice: fmPrice,
    //                 lastMilePrice: lmPrice,
    //                 midMilePrice: mmPrice,
    //                 loadingCharges: loadingCharges,
    //                 packagingCharges: packagingCharges,
    //                 deviation: {
    //                     percent: deviationPercent,
    //                     charges: deviationCharges
    //                 },
    //                 commission: {
    //                     percent: 15,
    //                     charges: commission
    //                 },
    //                 gst: {
    //                     percent: 18,
    //                     charges: gst
    //                 },
    //                 totalCost: totalCost
    //             }
    //         }
    //     }

    //     dispatch(updateRequestApsrtcOrder(updateBody))
    //         .then(res => {
    //             console.log(res)
    //             if (!res?.error) {
    //                 alert('Payment Link sent successfully')
    //             }
    //         })
    // }


    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Estimate Calculation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            {/* Inputs */}
                            <Col sm={12} md={6}>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Label htmlFor='fm_price'>First Mile Price (A)</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="fm_price">₹</InputGroup.Text>
                                            {/* <Form.Group controlId="fm_price" className="mb-3"> */}
                                            {/* <Form.Label>First Mile Price (A)</Form.Label> */}
                                            <Form.Control id='fm_price' type='number' min={0} value={fmPrice} onChange={e => setFmPrice(parseFloat(e.currentTarget.value))} onWheel={(e) => e.target.blur()} />
                                            {/* </Form.Group> */}
                                        </InputGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Label htmlFor='mm_price'>Mid Mile Price (B)</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="mm_price">₹</InputGroup.Text>
                                            {/* <Form.Group controlId="mm_price" className="mb-3"> */}
                                            <Form.Control type='number' id='mm_price' min={0} value={mmPrice} onChange={e => setMMPrice(parseFloat(e.currentTarget.value))} onWheel={(e) => e.target.blur()} />
                                            {/* </Form.Group> */}
                                        </InputGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Label htmlFor='lm_price'>Last Mile Price (C)</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="lm_price">₹</InputGroup.Text>
                                            {/* <Form.Group controlId="lm_price" className="mb-3"> */}
                                            <Form.Control type='number' id='lm_price' min={0} value={lmPrice} onChange={e => setLmPrice(parseFloat(e.currentTarget.value))} onWheel={(e) => e.target.blur()} />
                                            {/* </Form.Group> */}
                                        </InputGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Label htmlFor='loading_charge'>Loading Charges (D)</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="loading_charge">₹</InputGroup.Text>
                                            {/* <Form.Group controlId="loading_charge" className="mb-3"> */}
                                            <Form.Control type='number' id='loading_charge' min={0} value={loadingCharges} onChange={e => setloadingCharges(parseFloat(e.currentTarget.value))} onWheel={(e) => e.target.blur()} />
                                            {/* </Form.Group> */}
                                        </InputGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Label htmlFor='packaging_charge'>Packaging Charges (E)</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="fm_price">₹</InputGroup.Text>
                                            {/* <Form.Group controlId="packaging_charge" className="mb-3"> */}
                                            <Form.Control type='number' id='packaging_charge' min={0} value={packagingCharges} onChange={e => setPackagingCharges(parseFloat(e.currentTarget.value))} onWheel={(e) => e.target.blur()} />
                                            {/* </Form.Group> */}
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Outputs - Auto Populated based on inputs*/}
                            <Col sm={12} md={6}>
                                <Row>
                                    {/* Base Cost */}
                                    <Col sm={12}>
                                        {/* <Form.Group controlId="base_total" className="mb-3"> */}
                                        <Form.Label htmlFor='base_total'>A + B + C + D + E</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="base_total">₹</InputGroup.Text>
                                            <Form.Control type='number' id='base_total' min={0} value={baseTotal} disabled />
                                        </InputGroup>
                                        {/* </Form.Group> */}
                                    </Col>

                                    {/* Deviation 8% */}
                                    <Col sm={12}>
                                        <Row>
                                            <Form.Label htmlFor='deviation_percent'>Deviation</Form.Label>
                                            <Col>
                                                <InputGroup className="mb-3">
                                                    <Form.Control type='number' id='deviation_percent' min={0} value={deviationPercent} onChange={e => setDeviationPercent(parseFloat(e.currentTarget.value))} onWheel={(e) => e.target.blur()} />
                                                    <InputGroup.Text id="deviation_percent">%</InputGroup.Text>
                                                </InputGroup>
                                            </Col>

                                            <Col>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>₹</InputGroup.Text>
                                                    <Form.Control type='number' min={0} value={deviationCharges.toFixed(2)} disabled />
                                                </InputGroup>
                                            </Col>
                                        </Row>

                                    </Col>

                                    {/* Shipmantra Commission 15% */}
                                    <Col sm={12}>
                                        <Row>
                                            <Form.Label htmlFor='commission_percent'>Shipmantra Commission</Form.Label>
                                            <Col>
                                                <InputGroup className="mb-3">
                                                    <Form.Control type='number' id='commission_percent' min={0} value={15} disabled />
                                                    <InputGroup.Text id="commission_percent">%</InputGroup.Text>
                                                </InputGroup>
                                            </Col>

                                            <Col>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>₹</InputGroup.Text>
                                                    <Form.Control type='number' min={0} value={commission.toFixed(2)} disabled />
                                                </InputGroup>
                                            </Col>
                                        </Row>

                                    </Col>

                                    {/* GST 18% */}
                                    <Col sm={12}>
                                        <Row>
                                            <Form.Label htmlFor='gst_percent'>GST</Form.Label>
                                            <Col>
                                                <InputGroup className="mb-3">
                                                    <Form.Control type='number' id='gst_percent' min={0} value={18} disabled />
                                                    <InputGroup.Text id="gst_percent">%</InputGroup.Text>
                                                </InputGroup>
                                            </Col>

                                            <Col>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>₹</InputGroup.Text>
                                                    <Form.Control type='number' min={0} value={gst.toFixed(2)} disabled />
                                                </InputGroup>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                        <hr />

                        <div className='d-flex justify-content-between'>
                            <p className='fs-3'>
                                <b>Total : </b>{totalCost.toFixed(2)}
                            </p>

                            <div>
                                <Button onClick={saveRequestOrderPricingData} disabled={fetchingSingle}>
                                    Save&nbsp;
                                    {
                                        fetchingSingle && <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    }
                                </Button>
                            </div>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

function OrderRow({ superuser, order, setIsTextCopied, index, setOrderData, setShowOrderDetail, setNumberToCall, setShowFileUploadModal, setShowVoiceCallModal, setShowPackageImgModal, setShowInvoicePreviewModal }) {
    const dispatch = useDispatch();

    const [busy, setBusy] = useState(false);

    const [showCalculateEstimateModal, setShowCalculateEstimateModal] = useState(false);
    const [showConvertOrderModal, setShowConvertOrderModal] = useState(false);

    const copyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        setIsTextCopied(true)
        setTimeout(() => {
            setIsTextCopied(false)
        }, 3000);
    }


    const createdAtDateObj = new Date(order.createdAt)
    const fromCounter = order.firstMile.fromCounter

    let totalPackageWeight = order?.package?.reduce((prevValue, pkg) => prevValue + (pkg.weight || 0), 0)
    let totalVolWeight = order?.package?.reduce((prevValue, pkg) => prevValue + (pkg.volumetricWeight || 0), 0)


    const orderid = order.sm_orderid.split("-")
    return (
        <>
            <tr>
                <td>
                    <div>
                        {createdAtDateObj.toDateString() + ", " + createdAtDateObj.toLocaleTimeString()}
                    </div>
                    <hr />
                    {orderid[0] + '-'}<OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="stn-tooltip" style={{ width: 'fit-content' }}>{order.firstMile.city}&nbsp;to&nbsp;{order.lastMile.city}</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <span ref={ref} {...triggerHandler} style={{ cursor: 'pointer', fontWeight: 600 }}>{orderid[1] + '-' + orderid[2]}</span>
                        )}
                    </OverlayTrigger>{'-' + orderid[3]}
                    <span style={{ cursor: 'pointer' }} onClick={() => copyText(order.sm_orderid)}>&nbsp;{copyIcon}</span>
                    <hr />
                    {/* <div className='fw-bold'>
                        Payment: <span style={{ textTransform: 'capitalize', color: order?.paymentStatus?.toLowerCase() === 'pending' || order?.paymentStatus?.toLowerCase() === 'failed' ? 'red' : 'green' }}>{order?.paymentStatus}</span>
                    </div> */}
                </td>
                <td>
                    <Badge pill bg={!order.metadata?.requestOrderData?.isOutOfPickupArea ? 'success' : order?.firstMile.shipperUid ? 'success' : 'danger'} className='mb-1'>{!order.metadata?.requestOrderData?.isOutOfPickupArea ? 'FM Serviceable' : 'ODA'}</Badge>
                    <div>
                        <b>From:</b>&nbsp;{order.start.contact.name}<br />
                        <b>Phone: </b>&nbsp;{order.start.contact.phone}<span style={{ cursor: 'pointer', float: 'right' }} onClick={() => { setNumberToCall(order.start?.contact?.phone); setShowVoiceCallModal(true) }}>&nbsp;{phoneIcon}</span><br />
                        <b>Address:</b>&nbsp;{order?.start?.address?.mapData?.address}
                        {
                            fromCounter &&
                            <Badge bg="primary">
                                Booked From Counter
                            </Badge>
                        }
                    </div>
                    <hr />
                    <div>
                        <p className='fw-bold mb-1'>Available Agents:</p>
                        {
                            order?.stakeholders?.firstMileAgents?.length ? order?.stakeholders?.firstMileAgents?.map((agent, index) => {
                                return (
                                    <Card body key={index}>
                                        <b>Agent:</b>&nbsp;{agent.agentId?.companyDisplayName}<br />
                                        <b>Estimated Distance:</b>&nbsp;{agent.distance} KM<br />
                                        <b>Estimated Cost:</b>&nbsp;{agent.cost} ₹<br />
                                    </Card>
                                )
                            })
                                : 'No Agents Available'
                        }
                    </div>
                </td>
                <td>
                    <Badge pill bg={!order.metadata?.requestOrderData?.isOutOfDeliveryArea ? 'success' : order?.lastMile.shipperUid ? 'success' :  'danger'} className='mb-1'>{!order.metadata?.requestOrderData?.isOutOfDeliveryArea ? ' LM Serviceable' : 'ODA'}</Badge>
                    <div>
                        <b>To:</b>&nbsp;{order.end.contact.name}<br />
                        <b>Phone: </b>&nbsp;{order.end.contact.phone}<span style={{ cursor: 'pointer', float: 'right' }} onClick={() => { setNumberToCall(order.end?.contact?.phone); setShowVoiceCallModal(true) }}>&nbsp;{phoneIcon}</span><br />
                        <b>Address:</b>&nbsp;{order?.end?.address?.mapData?.address}
                    </div>
                    <hr />
                    <p className='fw-bold mb-1'>Available Agents:</p>
                    {
                        order?.stakeholders?.lastMileAgents?.length ? order?.stakeholders?.lastMileAgents?.map((agent, index) => {
                            return (
                                <Card body key={index}>
                                    <b>Agent:</b>&nbsp;{agent.agentId?.companyDisplayName}<br />
                                    <b>Estimated Distance:</b>&nbsp;{agent.distance} KM<br />
                                    <b>Estimated Cost:</b>&nbsp;{agent.cost} ₹<br />
                                </Card>
                            )
                        })
                            : 'No Agents Available'
                    }
                </td>
                <td>
                    <Badge pill bg={(totalPackageWeight > 100 || totalVolWeight > 100) ? 'danger' : 'success'} className='mb-1'>{(totalPackageWeight > 100 || totalVolWeight > 100) ? 'Weight Greater than 100 Kg' : 'Within Limit'}</Badge>
                    <div>
                        <b>Weight:</b>&nbsp;{totalPackageWeight} KG<br />
                        <b>Vol. Weight:</b>&nbsp;{totalVolWeight} KG<br />
                    </div>

                    <hr />

                    <div>
                        <b>Estimated Midmile Cost:</b>&nbsp;{order?.midMile?.cost} ₹<br />
                    </div>
                </td>
                <td>
                    <Button
                        onClick={() => { setOrderData(order); setShowOrderDetail(true) }}
                        style={{ width: '100%', marginBottom: '5px' }}
                        variant='success'>Details
                    </Button> <br />
                    <Button
                        onClick={() => { setShowCalculateEstimateModal(true) }}
                        style={{ width: '100%', marginBottom: '5px' }}
                        variant='dark'>Calculate
                    </Button> <br />
                    <Button
                        onClick={() => { setShowConvertOrderModal(true) }}
                        style={{ width: '100%', marginBottom: '5px' }}
                        variant='primary'>Convert
                    </Button> <br />
                </td>
            </tr >
            {showCalculateEstimateModal && <CalculateEstimateModal show={showCalculateEstimateModal} handleClose={() => setShowCalculateEstimateModal(false)} order={order} />}
            {showConvertOrderModal && <ConvertOrderModal show={showConvertOrderModal} handleClose={() => setShowConvertOrderModal(false)} order={order} />}
        </>
    )
}

function OrderTable({ superuser, onScroll, setSortedResults, sortType, sortedResults, setSortType, firstMileStatus, lastMileStatus, midMileStatus }) {
    const dispatch = useDispatch();
    const { orders, fetching } = useSelector(state => state.apsrtcBooking)

    const [isTextCopied, setIsTextCopied] = useState(false);
    const [showOrderDetail, setShowOrderDetail] = useState(false)
    const [orderData, setOrderData] = useState(undefined)

    const [filterOrderStatus, setFilterOrderStatus] = useState('');
    const [startCityFilter, setStartCityFilter] = useState('');
    const [endCityFilter, setEndCityFilter] = useState('');
    const [priorityPickupFilter, setPriorityPickupFilter] = useState('');

    const [numberToCall, setNumberToCall] = useState('');
    const [showVoiceCallModal, setShowVoiceCallModal] = useState(false);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [showPackageImgModal, setShowPackageImgModal] = useState(false);
    const [showInvoicePreviewModal, setShowInvoicePreviewModal] = useState(false);

    const handleSort = (type) => {
        const sortingOrder = sortType.split("-")[1]
        if (sortingOrder === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (sortingOrder === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        setSortedResults(orders)
        setSortType('createdAt-desc')
    }, [orders, filterOrderStatus, startCityFilter, endCityFilter])

    return (
        <>
            {
                isTextCopied && <ToastContainer position='top-center' style={{ zIndex: '10000' }}>
                    <Toast bg='info' delay={3000} autohide style={{ margin: '20px', fontSize: '20px', textAlign: 'center' }}>
                        <Toast.Body>Item Copied</Toast.Body>
                    </Toast>
                </ToastContainer>
            }
            <Table onScroll={onScroll} bordered hover responsive className='mt-3' style={{ minHeight: '100vh', overflowY: 'scroll' }}>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th className='col-2'>
                            Order Details&nbsp;
                            {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                            }
                        </th>
                        <th className='col-4'>First Mile</th>
                        <th className='col-4'>Last Mile</th>
                        <th className='col-2'>
                            Weight
                        </th>
                        <th className='col-1'>Actions</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        fetching ?
                            <tr><td colSpan={8} style={{ width: '100%', textAlign: 'center', padding: '20px' }}>Loading...</td></tr>
                            :
                            sortedResults.length > 0 ?
                                sortedResults?.map((order, index) => {
                                    return (
                                        <OrderRow
                                            superuser={superuser}
                                            key={"apsrtc_order_" + index} setIsTextCopied={setIsTextCopied} order={order} index={index}
                                            setOrderData={setOrderData} setShowOrderDetail={setShowOrderDetail} setNumberToCall={setNumberToCall} setShowFileUploadModal={setShowFileUploadModal} setShowInvoicePreviewModal={setShowInvoicePreviewModal}
                                            setShowPackageImgModal={setShowPackageImgModal} setShowVoiceCallModal={setShowVoiceCallModal}
                                        />
                                    )
                                }) :
                                <tr><td colSpan={8} style={{ width: '100%', textAlign: 'center', padding: '20px' }}>No Orders to show</td></tr>
                    }
                </tbody>
            </Table>
            {showOrderDetail && orderData && <OrderDetail show={showOrderDetail} handleClose={() => { setShowOrderDetail(false); setOrderData(undefined) }} order={orderData} />}
            {showVoiceCallModal && <VoiceCall show={showVoiceCallModal} handleClose={() => setShowVoiceCallModal(false)} numberToCall={numberToCall} orderType='apsrtc' orderId={orderData?.sm_orderid} />}
        </>
    )
}

export default function RequestOrders() {
    // const { fetching } = useSelector(state => state.apsrtc);
    const { orders, totalPages, fetching } = useSelector(state => state.apsrtcBooking)
    const { adminUser } = useSelector(state => state.adminUser)
    const dispatch = useDispatch();
    const firstLoad = useRef(true)
    const searchInputFirstLoad = useRef(false);


    const [searchString, setSearchString] = useState('')
    const [page, setPage] = useState(1)
    const [allOrdersFound, setAllOrdersFound] = useState(false)
    const [exportBusy, setExportBusy] = useState(false)

    const [sortType, setSortType] = useState("createdAt-desc")
    const [pendingOrderType, setPendingOrderType] = useState('firstMile')

    const [sortedResults, setSortedResults] = useState(orders ? orders : [])
    const [firstMileStatus, setFirstMileStatus] = useState([]);
    const [lastMileStatus, setLastMileStatus] = useState([]);
    const [midMileStatus, setMidMileStatus] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterOrderStatusArr, setFilterOrderStatusArr] = useState([]);
    const [filterOrderStatus, setFilterOrderStatus] = useState('');
    const [startCityFilter, setStartCityFilter] = useState('');
    const [endCityFilter, setEndCityFilter] = useState('');
    const [orderFlowType, setOrderFlowType] = useState('')


    const nextPage = (e) => {
        setPage(page + 1)
    }

    const resetFilters = () => {
        setFilterOrderStatusArr([]);
        setStartCityFilter('');
        setEndCityFilter('');
        setStartDate(null);
        setEndDate(null);
    }

    const manageOrderStatusFilterArr = (status) => {
        if (status == 'remove') {
            setFilterOrderStatusArr([]);
            return;
        }

        let tempArr = filterOrderStatusArr;
        if (tempArr.includes(status)) {
            tempArr = tempArr.filter(e => e !== status)
        } else {
            tempArr.push(status)
        }

        setFilterOrderStatusArr(tempArr);

    }

    const getFilterQueryParams = () => {
        let queryParams = {};
        queryParams.page = page;
        queryParams.limit = 20;
        queryParams.shipperId = 'apsrtc'
        if (startCityFilter) {
            queryParams["startCity"] = startCityFilter;
        }
        if (endCityFilter) {
            queryParams["endCity"] = endCityFilter;
        }
        if (filterOrderStatus) {
            queryParams["orderStatus"] = filterOrderStatus;
        }
        if (startDate) {
            queryParams["startDate"] = startDate;
        }
        if (endDate) {
            queryParams["endDate"] = endDate;
        }
        if (searchString) {
            queryParams.searchString = searchString
        }
        if (orderFlowType) {
            queryParams["orderType"] = orderFlowType;
        }
        return queryParams
    }

    useEffect(() => {
        setPage(1)
        let queryParams = getFilterQueryParams();
        dispatch(fetchAllApsrtcRequestOrders(queryParams))
            .then(() => { })
            .catch(() => {
                setAllOrdersFound(true);
            })
    }, [startCityFilter, endCityFilter, filterOrderStatus, filterOrderStatusArr, startDate, endDate, orderFlowType])

    useEffect(() => {
        if (!firstLoad.current) {
            let queryParams = getFilterQueryParams();
            dispatch(fetchAllApsrtcRequestOrders(queryParams))
                .then(() => { })
                .catch(() => {
                    setAllOrdersFound(true);
                })
        } else {
            firstLoad.current = false
        }
    }, [page])


    // const fetchAllApsrtcOrdersForExport = () => {
    //     setExportBusy(true);
    //     let queryParams = {};
    //     if (startCityFilter) {
    //         queryParams["startCity"] = startCityFilter;
    //     }
    //     if (endCityFilter) {
    //         queryParams["endCity"] = endCityFilter;
    //     }
    //     if (filterOrderStatusArr.length) {
    //         queryParams["orderStatus"] = filterOrderStatusArr;
    //     }
    //     if (startDate) {
    //         queryParams["startDate"] = startDate;
    //     }
    //     if (endDate) {
    //         queryParams["endDate"] = endDate;
    //     }

    //     fetchAllAPSRTCOrdersWithoutPagingAPI(queryParams)
    //         .then(async (response) => {

    //             try {

    //                 // console.log(response.data)
    //                 if (!response.data.length > 0) {
    //                     alert("No orders found for selected filters");
    //                     return;
    //                 }
    //                 console.log(1);

    //                 let tableData = [
    //                     {
    //                         orderId: "Order ID",
    //                         bookingDate: "Booking Date",
    //                         bookingTime: "Booking Time",
    //                         fromLocation: "From Station",
    //                         ToLocation: "To Station",
    //                         senderName: "Sender Name",
    //                         senderMobile: "Sender Mobile",
    //                         senderAddress: "Sender Address",
    //                         recipientName: "Recipient Name",
    //                         recipientMobile: "Recipient Mobile",
    //                         recipientAddress: "Recipient Address",
    //                         LRnumber: "LR Number",
    //                         weightOfPackage: "Weight of Packages",
    //                         noOfPackage: "No of Packages",
    //                         priorityPickup: "Priority Pickup",
    //                         orderStatus: "Order Status",
    //                     }
    //                 ]

    //                 console.log(2);

    //                 for (let i = 0; i < response.data.length; i++) {
    //                     const orderData = response.data[i];

    //                     let totalWeight = 0;
    //                     for (let j = 0; j < orderData.packageData.length; j++) {
    //                         const packageEl = orderData.packageData[j];

    //                         totalWeight = totalWeight + packageEl.weight;
    //                     }
    //                     const dateObj = new Date(orderData.createdAt);

    //                     let lr_string = "Not generated";

    //                     if (orderData.lr_number.length > 0) {
    //                         lr_string = "";

    //                         for (let j = 0; j < orderData.lr_number.length; j++) {
    //                             const element = orderData.lr_number[j];
    //                             lr_string = lr_string + element;
    //                             if (j < (orderData.lr_number.length - 1)) {
    //                                 lr_string = lr_string + ","
    //                             }
    //                         }
    //                     }

    //                     let sheetRow = {
    //                         orderId: orderData.sm_orderid,
    //                         bookingDate: dateObj.toDateString(),
    //                         bookingTime: dateObj.getHours() + ":" + dateObj.getMinutes(),

    //                         fromLocation: orderData.midMile?.startStation?.address?.fromGoogle?.city,
    //                         ToLocation: orderData.midMile?.endStation?.address?.fromGoogle?.city,

    //                         senderName: orderData.start?.contact?.name,
    //                         senderMobile: orderData.start?.contact?.phone,
    //                         senderAddress: orderData.firstMile?.fromCounter ? `Booked from Counter - ${orderData.midMile?.startStation.address.fromGoogle.city}` : orderData.start?.address?.flatno
    //                             + ", " + orderData.start?.address?.building
    //                             + ", " + orderData.start?.address?.fromGoogle?.address
    //                         ,

    //                         recipientName: orderData.end?.contact?.name,
    //                         recipientMobile: orderData.end?.contact?.phone,
    //                         recipientAddress: orderData.end?.address?.flatno
    //                             + ", " + orderData.end?.address?.building
    //                             + ", " + orderData.end?.address?.fromGoogle?.address
    //                         ,

    //                         LRnumber: lr_string,
    //                         weightOfPackage: totalWeight,
    //                         noOfPackage: orderData.packageData?.length,
    //                         priorityPickup: orderData.firstMile?.priority_pickup ? "Yes" : "",
    //                         orderStatus: orderData.orderStatus,
    //                     }

    //                     tableData.push(sheetRow);

    //                 }

    //                 console.log(tableData);
    //                 console.log(3);
    //                 let wb = utils.book_new();
    //                 console.log(4);
    //                 let ws = utils.json_to_sheet(tableData, { skipHeader: 1 })
    //                 console.log(5);
    //                 utils.book_append_sheet(wb, ws, "APSRTC ORDERS");

    //                 writeFile(wb, "APSRTC ORDERS " + new Date().toDateString() + ".xlsx");

    //             } catch (e) {
    //                 console.err("error while importing", e);
    //             }
    //             // var wbout = write(wb, {bookType:'xlsx', type:'array'});
    //             // window.saveAs(new Blob([wbout],{type:"application/octet-stream"}), `${("APSRTC ORDERS "+new Date().toDateString)}.xlsx`);
    //             // window.open(URL.createObjectURL(new Blob([wbout],{type:"application/octet-stream"})));


    //         })
    //         .catch(() => {
    //             setAllOrdersFound(true);
    //         })
    //         .finally(() => {
    //             setExportBusy(false)
    //         })

    // }

    const updateBusinessOrderStatus = async (event, mileType, orderId, index) => {
        if (!event.target.value) {
            return
        }
        let body = {
            sm_orderid: orderId,
            payload: {
                orderStatus: event.target.value
            }
        }

        await dispatch(updateApsrtcOrder(body))
            .then(unwrapResult)
            .then(() => {
                // dispatch(fetchApsrtcBookingOrders());
            })
    }


    const CustomToggle = React.forwardRef(({ label, children, active, onClick }, ref) => (
        <div>
            <span style={{ fontWeight: 800, fontSize: '10px' }}><FontAwesomeIcon icon={faFilter} />&nbsp;{label}:</span>
            <Button
                // href=""
                // style={{ cursor: 'pointer' }}
                variant={active ? "primary" : 'outline-primary'}
                size='sm'
                style={{ width: '100%' }}
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {!active && <FontAwesomeIcon icon={faFilter} />}&nbsp;{children}
            </Button>
        </div>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                child.props.eventKey.toLowerCase().startsWith(value.toLowerCase()),
                        )}
                    </ul>
                </div>
            );
        },
    );

    useEffect(() => {
        const [attr, type] = sortType.split("-")
        let temp = [...sortedResults]
        if (attr === "updatedAt" || attr === "createdAt") {
            if (type === "asc") {
                temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                console.log(temp)
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) =>
                    new Date(b[attr]) - new Date(a[attr])
                )
                setSortedResults(temp)
            }
        } else {
            if (type === "asc") {
                temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                setSortedResults(temp)
            }
        }
    }, [sortType])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let queryParams = getFilterQueryParams();
            if (searchInputFirstLoad.current) {
                dispatch(fetchAllApsrtcRequestOrders(queryParams))
                    .then(() => { })
                    .catch(() => {
                        setAllOrdersFound(true);
                    })
            } else {
                searchInputFirstLoad.current = true
            }
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchString])


    return (
        <div style={{ padding: '30px' }}>
            <div className='d-flex'>
                <h3 style={{ flex: 1, textTransform: 'capitalize' }}>Request Orders</h3>
                <div>
                    <Button onClick={() => { let queryParams = getFilterQueryParams(); dispatch(fetchAllApsrtcRequestOrders(queryParams)) }} className='ms-2' variant='secondary'>{refreshIcon}&nbsp;Refresh</Button>
                </div>
                {/* <div>
                    <Button disabled={exportBusy} onClick={() => fetchAllApsrtcOrdersForExport()} className='ms-2' variant='success'><FontAwesomeIcon icon={faFileExcel} />&nbsp;{exportBusy ? "Exporting..." : "Export Sheet"}</Button>
                </div> */}
            </div>
            <hr />
            {/* <Row>
                <Col lg={2}>
                    <Dropdown style={{ display: 'inline-block', zIndex: '1000', width: '100%' }} onSelect={(orderFlow) => setOrderFlowType(orderFlow)}>
                        <Dropdown.Toggle
                            as={CustomToggle} id="order-flow-dropdown" label="Filter by Order Flow"
                            active={orderFlowType ? true : false}
                        >
                            {orderFlowType ? orderFlowType === 'inflow' ? 'First Mile' : 'LastMile' : "Apply filter"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomMenu}>
                            <Dropdown.Item eventKey="">Remove filter</Dropdown.Item>
                            <Dropdown.Item eventKey="inflow" active={orderFlowType === "inflow"}>First Mile</Dropdown.Item>
                            <Dropdown.Item eventKey="outflow" active={orderFlowType === "outflow"}>Last Mile</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row >
            <hr /> */}
            <div style={{ display: 'flex' }}>
                <Pagination style={{ marginRight: '20px' }}>
                    <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || fetching} />
                    <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || fetching} />
                    {page - 2 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                    {page - 1 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                    <Pagination.Item active>{page}</Pagination.Item>
                    {totalPages >= page + 1 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                    {totalPages >= page + 2 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                    <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || fetching} />
                    <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || fetching} />
                </Pagination>

                <div style={{ height: '38px', flex: 1 }}>
                    <input style={{ height: '100%' }} type="text" className='form-control' placeholder='Search order ID, phone number or LR number' value={searchString} onChange={e => setSearchString(e.currentTarget.value)} />
                </div>
                <div style={{ marginLeft: '10px', marginTop: '-22px' }}>
                    <div style={{ fontWeight: 800, fontSize: '10px', paddingTop: '8px' }}><FontAwesomeIcon icon={faFilter} />&nbsp;Filter by Date:</div>
                    <div style={{ display: 'flex' }}>
                        <Form.Group controlId="filter">
                            <Form.Control style={{ cursor: 'pointer', borderColor: startDate ? '#0d6efd' : undefined, color: startDate ? '#0d6efd' : undefined, borderRadius: '0.2rem' }} type="date" value={startDate} disabled={fetching} onChange={e => setStartDate(e.target.value)} />
                        </Form.Group>
                        <div>&nbsp;To</div>
                        <Form.Group className='ms-3' controlId="filter">
                            <Form.Control style={{ cursor: 'pointer', borderColor: endDate ? '#0d6efd' : undefined, color: endDate ? '#0d6efd' : undefined, borderRadius: '0.2rem' }} type="date" value={endDate} disabled={fetching} onChange={e => setEndDate(e.target.value)} />
                        </Form.Group>
                    </div>
                </div>
            </div >
            {sortedResults && <OrderTable superuser={adminUser.displayName == 'super'} sortType={sortType} setSortType={setSortType} setSortedResults={setSortedResults} sortedResults={sortedResults} firstMileStatus={firstMileStatus} lastMileStatus={lastMileStatus} midMileStatus={midMileStatus} pendingOrderType={pendingOrderType} />
            }

            <Pagination>
                <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || fetching} />
                <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || fetching} />
                {page - 2 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                {page - 1 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                <Pagination.Item active>{page}</Pagination.Item>
                {totalPages >= page + 1 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                {totalPages >= page + 2 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || fetching} />
                <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || fetching} />
            </Pagination>
        </div>
    )
}
