import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Container, Row, Col, Modal, Spinner } from 'react-bootstrap'
import BannerUpload from '../Popups/BannerUpload';
import { fetchBanners } from '../../app/features/banner/bannerSlice';
import BannerDetail from './BannerDetail';



export default function Banner() {
    const dispatch = useDispatch()
    const { fetching } = useSelector(state => state.banner)

    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) };
    const handleShow = () => setShow(true);

    return (
        <>
            <Container className='py-3'>
                <div className='text-end'>
                    <Button variant="success" onClick={handleShow}>Add Banner</Button>
                </div>
                <hr />
                <BannerUpload show={show} handleClose={handleClose} />
                <BannerDetail />
            </Container>
        </>
    )
}
