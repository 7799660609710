import React, { useState } from 'react'
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { createCountry } from '../../app/features/countries/countrySlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const lookup = require('country-code-lookup')

export default function AddCountry() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const countries = lookup.countries

    const [countryString, setCountryString] = useState("")
    const [countryText, setCountryText] = useState("")
    const [isoCode, setIsoCode] = useState("")
    const [isImport, setIsImport] = useState(false)

    const { fetching } = useSelector(state => state.country)

    const resetData = () => {
        setCountryString("")
        setCountryText("")
        setIsoCode("")
    }

    const handleCountrySubmit = (e) => {
        e.preventDefault();
        const reqBody = {
            countryString,
            countryText,
            canImport: isImport,
            alphaCode: isoCode.toLowerCase()
        }
        console.log(reqBody)
        dispatch(createCountry(reqBody))
            .then(() => resetData())
    }

    return (
        <div style={{ padding: '20px' }}>
            <Button variant='primary' onClick={() => navigate('/countries')}>All countries</Button>
            <hr />
            <Form onSubmit={handleCountrySubmit}>
                <Row>
                    <Col xs sm={6}>
                        <Form.Group className="mb-3" controlId="countryString">
                            <Form.Label>Country string</Form.Label>
                            <Form.Control type="text" value={countryString} onChange={(e) => setCountryString(e.target.value)} placeholder="Enter country string" required />
                        </Form.Group>
                    </Col>
                    <Col xs sm={6}>
                        <Form.Group className="mb-3" controlId="countryText">
                            <Form.Label>Country text</Form.Label>
                            <Form.Control type="text" value={countryText} onChange={(e) => setCountryText(e.target.value)} placeholder="Enter country text" required />
                        </Form.Group>
                    </Col>
                    <Col xs sm={6}>
                        <Form.Group className="mb-3" controlId="isoCode">
                            <Form.Label>Select ISO code</Form.Label>
                            <Form.Select aria-label="Default select example" value={isoCode} onChange={(e) => setIsoCode(e.target.value)} required>
                                <option value="" disabled>Open this select menu</option>
                                {
                                    countries.map((country, index) => {
                                        return (
                                            <option key={index} value={country.iso2}>{country.country}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs sm={6}>
                        <Form.Group className="mb-3" controlId="isImport">
                            <Form.Label>Can Import ?</Form.Label>
                            <Form.Check
                                type="switch"
                                id="isImport"
                                value={isImport}
                                onChange={() => setIsImport(!isImport)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <hr />
                <div className="w-100 text-center">
                    {
                        fetching ?
                            <Button disabled variant="primary" type="button">
                                <Spinner animation="border" role="status" variant="light" size="sm" />
                            </Button>
                            :
                            <Button variant="primary" type="submit">Submit</Button>
                    }
                </div>
            </Form>
        </div>
    )
}
