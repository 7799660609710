import React from 'react'
import Charges from '../Charges'

export default function PanIndiaExpressCharge() {
    return (
        <div style={{ padding: '30px' }}>
            <h4>Express platform charges</h4>
            <hr />
            <Charges serviceType='panindia_express' />
        </div>
    )
}
