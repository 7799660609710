import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { addCountryAPI, getAllCountriesAPI, getSingleCountryAPI, updateCountryAPI } from '../../api/countries';

const initialState = {
    fetching: false,
    countries: [],
    countryDetail: {}
}

export const getAllCountries = createAsyncThunk('/countries', async(obj, { rejectWithValue }) => {
    const response = await getAllCountriesAPI();
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const getSingleCountry = createAsyncThunk('/country/getSingleCountry', async(id, { rejectWithValue }) => {
    const res = await getSingleCountryAPI(id)
    if (res.success) return res.data
    return rejectWithValue("There seems to be an error while fetching the country!")
})

export const createCountry = createAsyncThunk('/countries/create', async(data, { rejectWithValue }) => {
    const response = await addCountryAPI(data);
    if (response.success) return response
    return rejectWithValue(response.message)
})

export const updateCountry = createAsyncThunk('/country/update', async(reqBody, { rejectWithValue }) => {
    const response = await updateCountryAPI(reqBody)
    if (response.success) return response
    return rejectWithValue("There seems to be an error while updating the country" + reqBody.countryText)
})


export const countrySlice = createSlice({
    name: 'country',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllCountries.pending, (state) => {
                state.fetching = true
            })
            .addCase(getAllCountries.fulfilled, (state, action) => {
                state.fetching = false
                state.countries = action.payload
            })
            .addCase(getAllCountries.rejected, (state, action) => {
                state.fetching = false
                alert("There was an error while fetching all countries")
            })
            .addCase(getSingleCountry.pending, (state) => {
                state.fetching = true
            })
            .addCase(getSingleCountry.fulfilled, (state, action) => {
                state.fetching = false
                state.countryDetail = action.payload
            })
            .addCase(getSingleCountry.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(createCountry.pending, (state) => {
                state.fetching = true
            })
            .addCase(createCountry.fulfilled, (state, action) => {
                state.fetching = false
                alert(action.payload.message)
            })
            .addCase(createCountry.rejected, (state, action) => {
                state.fetching = false
                alert("There was an error while adding country")
            })
            .addCase(updateCountry.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateCountry.fulfilled, (state, action) => {
                state.fetching = false
                alert("Country updated successfully!")
            })
            .addCase(updateCountry.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
    }

})