import { faBug, faCode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useEffect, useState } from 'react'
import { Accordion, Card, Form, Modal, Row, Spinner, Table, Col, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'


export default function MonitoringErrorDetail({ show, handleClose, errorData, busy, editShipper }) {
    const dispatch = useDispatch();

    const [skuDetails, setSKUDetails] = useState([])
    const [labelLoader, setLableLoader] = useState(false);
    const [fetching, setFetching] = useState(false);

    const { status, error, timeStamp } = errorData

    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg' centered style={{ backdropFilter: 'blur(20px)' }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span>Error details</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FontAwesomeIcon icon={faCode}/>&nbsp;Error Log:&nbsp;
                    <div style={{marginBottom:'10px', display:'flex', alignItems:'center', backgroundColor:'#333', color:"#fafafa", padding:'10px', borderRadius:'5px', overflowWrap:'anywhere', height:'400px', overflowY:'scroll',
                        font: '0.9rem Inconsolata, monospace'
                    }}>
                        {error}
                    </div>
                    <hr/>
                    <div style={{marginBottom:'10px'}}>
                        <FontAwesomeIcon icon={faBug}/>&nbsp;Error resolution Status:&nbsp;
                        <Form.Select disabled={busy} aria-label="Default select example" value={status?status:undefined}
                            onChange={(e)=>{
                                if(e.target.value==='resolved'){
                                    editShipper({integrationStatus: 'ok', lastError: {status: e.target.value, error: error, timeStamp: timeStamp}})
                                }else{
                                    editShipper({lastError: {status: e.target.value, error: error, timeStamp: timeStamp}})
                                }
                            }}
                        >
                            <option>Open this select menu</option>
                            <option value="new_error">New Error</option>
                            <option value="investigating">Investigating</option>
                            <option value="fixing">Fixing</option>
                            <option value="resolved">Resolved</option>
                        </Form.Select>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}