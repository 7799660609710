import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { unwrapResult } from '@reduxjs/toolkit'
import React from 'react'
import { useState } from 'react'
import { Col, Row, Modal, Form, Button, InputGroup, Spinner, Dropdown } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { createBusinessUser } from '../../../app/features/shipmantrabusiness/user/businessUserSlice'

export default function AddNewUser({ show, handleClose }) {
  const dispatch = useDispatch();

  const { createUserFetching } = useSelector(state => state.businessUser);
  const [showPassword, setShowPassword] = useState(false);
  // const [fetching, setFetching] = useState(false);

  const { handleSubmit, watch, control, getValues } = useForm({
    defaultValues: {
      fullName: '',
      companyName: '',
      gstIn: '',
      email: '',
      phone: '',
      password: '',
      customPlatformFee: false,
      amount: 0,
      type: 'flat'
    }
  })
  const watchEnabled = watch("customPlatformFee", false);

  const createNewUser = (data) => {
    // setFetching(true)
    const { fullName, email, password, phone, companyName, gstIn } = data
    let reqBody = {
      fullName: fullName,
      email: email,
      phone: phone,
      password: password,
      platformFee: undefined,
      companyName,
      gstIn
    }
    if (data.customPlatformFee && data.type) {
      reqBody.platformFee = {
        isActive: true,
        value: parseInt(data.amount) || 0,
        type: data.type
      }
    }
    dispatch(createBusinessUser({ reqBody }))
      .then(unwrapResult)
      .then(() => {
        alert("New business user added successfully");
        handleClose();
        // dispatch(createBusinessWallet(accessToken))
        //   .then(unwrapResult)
        //   .then(() => {
        //     setFetching(false);
        //   })
        //   .catch(err => {
        //     alert('There was some error while creating a business wallet')
        //     setFetching(false)
        //   })
      })
      .catch(err => {
        alert('There was some err while creating new business user: ', err)
        // setFetching(false);
      })
  }

  return (
    <div style={{ padding: '30px' }}>
      <Modal
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create new user</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(createNewUser)}>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" value={value} onChange={onChange} placeholder="Email" required />
                    </Form.Group>
                  )}
                />
              </Col>

              <Col sm={6}>
                <Controller
                  control={control}
                  name="password"
                  render={({ field: { onChange, value } }) => (
                    <Form.Group className="mb-3" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <Form.Control type={showPassword ? "text" : "password"} value={value} onChange={onChange} placeholder="Password" required />
                        <InputGroup.Text id="btnGroupAddon" style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}>
                          {!showPassword && <FontAwesomeIcon icon={faEye} />}
                          {showPassword && <FontAwesomeIcon icon={faEyeSlash} />}
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  )}
                />
              </Col>

              <Col sm={6}>
                <Controller
                  control={control}
                  name="companyName"
                  render={({ field: { onChange, value } }) => (
                    <Form.Group className="mb-3" controlId="companyName">
                      <Form.Label>Company name</Form.Label>
                      <Form.Control type="text" value={value} onChange={onChange} placeholder="Company Name" required />
                    </Form.Group>
                  )}
                />
              </Col>

              <Col sm={6}>
                <Controller
                  control={control}
                  name="gstIn"
                  render={({ field: { onChange, value } }) => (
                    <Form.Group className="mb-3" controlId="gstIn">
                      <Form.Label>GSTIN</Form.Label>
                      <Form.Control type="text" value={value} onChange={onChange} placeholder="GSTIN" />
                    </Form.Group>
                  )}
                />
              </Col>

              <Col sm={6}>
                <Controller
                  control={control}
                  name="fullName"
                  render={({ field: { onChange, value } }) => (
                    <Form.Group className="mb-3" controlId="fullName">
                      <Form.Label>POC name</Form.Label>
                      <Form.Control type="text" value={value} onChange={onChange} placeholder="Full Name" required />
                    </Form.Group>
                  )}
                />
              </Col>

              <Col sm={6}>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onChange, value } }) => (
                    <Form.Group className="mb-3" controlId="phone">
                      <Form.Label>Contact</Form.Label>
                      <Form.Control type="text" onChange={onChange} value={value} placeholder="Contact details" required />
                    </Form.Group>
                  )}
                />
              </Col>

              <Col sm={12}>
                <hr />
                <Controller
                  control={control}
                  name="customPlatformFee"
                  render={({ field: { onChange, value } }) => (
                    <Form.Group className="mb-3" controlId="phone">
                      <Form.Label>Custom Platform Charges</Form.Label>
                      <Form.Check
                        inline
                        checked={value}
                        onChange={onChange}
                        type='checkbox'
                        id={`disabled-default`}
                        size='xl'
                        style={{ marginLeft: '10px' }}
                      />
                    </Form.Group>
                  )}
                />
              </Col>

              <Col sm={6}>
                <Controller
                  control={control}
                  name="amount"
                  render={({ field: { onChange, value } }) => (
                    <Form.Group className="mb-3" controlId="phone">
                      <Form.Label>Amount (₹)</Form.Label>
                      <Form.Control disabled={!watchEnabled} type="number" min='0' onWheel={e => e.currentTarget.blur()} onChange={onChange} value={value} placeholder="Amount in rupees" required />
                    </Form.Group>
                  )}
                />
              </Col>

              <Col sm={6}>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <Form.Group className="mb-3" controlId="phone">
                      <Form.Label>Type</Form.Label>
                      <Form.Select aria-label="Default select example" disabled={!watchEnabled} value={value} onChange={onChange}>
                        <option value=''>Select</option>
                        <option value="flat">Flat</option>
                        <option value="percentage">Percentage</option>
                      </Form.Select>
                    </Form.Group>
                  )}
                />
              </Col>

            </Row>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: 'flex-start' }}>
            <Button type='submit' variant="primary" disabled={createUserFetching}>
              Submit&nbsp;
              {createUserFetching && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  )
}
