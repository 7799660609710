import { checkLogin } from "../../utils/firebase"

const localhostUrl = 'http://localhost:8000'
const baseUrl = process.env.REACT_APP_BASE_URL

export const registerAPI = async (reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
                // 'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const loginAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin/signin`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        })
            .then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const createNewAdminAPI = async (adminCreationPayload) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin/create-new`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(adminCreationPayload)
        })
            .then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const getAllAdminUserAPI = async ({ page }) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin?page=${page}`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const updateAdminUserAPI = async (data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const identifier = data.email
        const res = await fetch(`${baseUrl}/admin/update`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const disableAdminUserAPI = async (data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin/disable`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const deleteAdminUserAPI = async (data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin/delete`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}