import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchAllCitiesAPI, fetchOneCityAPI, addCityAPI, editCityAPI } from '../../api/city';

const initialState = {
    fetching: false,
    toggling: false,
    cities: [],
    city: {}
}

export const fetchAllCities = createAsyncThunk('/cities', async(obj, { rejectWithValue }) => {
    const response = await fetchAllCitiesAPI()
    if (response.success) return response.data
    return rejectWithValue('Opps there seems to be an error')
})

export const fetchOneCity = createAsyncThunk('/city/cityDetail', async(id, { rejectWithValue }) => {
    const response = await fetchOneCityAPI(id)
    if (response.success) return response.data
    return rejectWithValue('Opps there seems to be an error')
})

export const addOneCity = createAsyncThunk('/city/addCity', async(reqBody, { rejectWithValue }) => {
    const response = await addCityAPI(reqBody)
    if (response.success) return response
    return rejectWithValue('Opps there seems to be an error')
})

export const editCity = createAsyncThunk('/city/edit', async(reqBody, { rejectWithValue }) => {
    const response = await editCityAPI(reqBody)
    if (response.success) return response
    return rejectWithValue('Opps there seems to be an error')
})

export const citySlice = createSlice({
    name: 'city',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCities.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchAllCities.fulfilled, (state, action) => {
                state.fetching = false
                state.cities = action.payload
            })
            .addCase(fetchAllCities.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchOneCity.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchOneCity.fulfilled, (state, action) => {
                state.fetching = false
                state.city = action.payload
            })
            .addCase(fetchOneCity.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(addOneCity.pending, (state) => {
                state.fetching = true
            })
            .addCase(addOneCity.fulfilled, (state, action) => {
                state.fetching = false
                alert("City added Successfully")
            })
            .addCase(addOneCity.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(editCity.pending, (state) => {
                state.fetching = true
            })
            .addCase(editCity.fulfilled, (state, action) => {
                state.fetching = false
                alert("City edited Successfully")
            })
            .addCase(editCity.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
    }

})