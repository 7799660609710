import React from 'react'
import { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteCMSAnnouncementFeed, getCMSAnnouncementFeed } from '../../../app/features/cms/contentmanagementSlice'
import { sortIcon, sortAscIcon, sortDescIcon, deleteIcon, editIcon, addIcon, megaPhoneIcon } from '../../../FontAwesome'
import Loader from '../../Loader'

export default function AnnouncementFeeds() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { fetching, announcementfeeds } = useSelector(state => state.cms)

    const [sortType, setSortType] = useState("updatedAt-desc")
    const [sortedResults, setSortedResults] = useState(announcementfeeds ? announcementfeeds : [])

    useEffect(() => {
        dispatch(getCMSAnnouncementFeed())
    }, [])

    const handleSort = (type) => {
        const order = sortType.split("-")[1]
        if (order === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (order === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        if (!fetching) {
            const [attr, type] = sortType.split("-")
            let temp = [...announcementfeeds]
            if (attr === "updatedAt" || attr === "createdAt") {
                if (type === "asc") {
                    temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) =>
                        new Date(b[attr]) - new Date(a[attr])
                    )
                    setSortedResults(temp)
                }
            } else {
                if (type === "asc") {
                    temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                    setSortedResults(temp)
                }
            }
        }
    }, [sortType, fetching, announcementfeeds])

    const deleteFeed = (feed) => {
        const body = {
            id: feed._id
        }
        dispatch(deleteCMSAnnouncementFeed(body))
            .then(() => dispatch(getCMSAnnouncementFeed()))
    }

    if (fetching) return (
        <Loader />
    )

    return (
        <div style={{ padding: '30px' }}>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                <h4 className='m-0'>Announcement Feeds&nbsp;{megaPhoneIcon}</h4>
                <Button onClick={() => navigate('/cms/header/announcement-feed/add')}>{addIcon}&nbsp;Add new Announcement</Button>
            </div>
            <hr />
            <Table striped bordered hover responsive>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th>Feeds</th>
                        <th>
                            Created at&nbsp;
                            {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                            }
                        </th>
                        <th>
                            Updated at&nbsp;
                            {sortType.split("-")[0] !== "updatedAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortDescIcon}</span>
                            }
                        </th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sortedResults.map((feed, index) => {
                            const updatedAtDateString = new Date(feed.updatedAt);
                            const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();

                            const createdAtDateString = new Date(feed.createdAt);
                            const createdAtDate = createdAtDateString.toDateString() + ", " + createdAtDateString.toLocaleTimeString();
                            return (
                                <tr key={index}>
                                    <td>{feed.feed}</td>
                                    <td>{createdAtDate}</td>
                                    <td>{updatedAtDate}</td>
                                    <td>
                                        <Button variant='primary' onClick={() => navigate(`/cms/header/announcement-feed/edit/${feed._id}`)}>{editIcon}</Button>
                                    </td>
                                    <td>
                                        <Button variant='danger' onClick={() => deleteFeed(feed)}>{deleteIcon}</Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}
