import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAgentOrgAPI, deleteAgentAPI, getAllAgentsAPI, updateAgentOrgAPI } from '../../../api/saas/agents';


const initialState = {
    fetching: false,
    createAgentFetching: false,
    deleteAgentFetching: false,
    updateAgentOrgFetching: false,
    agents: [],
    agentDetail: {},
    errorMessage: '',
    totalPages: 1
}


export const createAgentOrg = createAsyncThunk('/saas/create-agent-org', async (agentCreationPayload, { rejectWithValue }) => {
    const response = await createAgentOrgAPI(agentCreationPayload);
    if (response?.success) return response.data
    return rejectWithValue(response.message)
})

export const updateAgentOrg = createAsyncThunk('/saas/update-agent-org', async (agentOrgUpdationPayload, { rejectWithValue }) => {
    const response = await updateAgentOrgAPI(agentOrgUpdationPayload);
    if (response?.success) return response.data
    return rejectWithValue(response.message)
})

export const getAllAgents = createAsyncThunk('/saas/get-all-agents', async ({ page, limit }, { rejectWithValue }) => {
    const response = await getAllAgentsAPI({ page, limit });
    if (response?.success) return response.data
    return rejectWithValue(response.message)
})

export const deleteAgent = createAsyncThunk('/saas/delete-agent', async (agentDeletionPayload, { rejectWithValue }) => {
    const response = await deleteAgentAPI(agentDeletionPayload);
    if (response?.success) return { response, uid: agentDeletionPayload.uid }
    return rejectWithValue(response.message)
})


export const saasAgentSlice = createSlice({
    name: 'saas_agents',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAgentOrg.pending, (state) => {
                state.createAgentFetching = true
                state.errorMessage = ''
            })
            .addCase(createAgentOrg.fulfilled, (state, action) => {
                state.createAgentFetching = false
                state.errorMessage = ''
            })
            .addCase(createAgentOrg.rejected, (state, action) => {
                state.createAgentFetching = false
                state.errorMessage = action.payload ? action.payload : 'Internal server error'
            })

            .addCase(updateAgentOrg.pending, (state) => {
                state.updateAgentOrgFetching = true
                state.errorMessage = ''
            })
            .addCase(updateAgentOrg.fulfilled, (state, action) => {
                state.updateAgentOrgFetching = false
                state.errorMessage = ''
            })
            .addCase(updateAgentOrg.rejected, (state, action) => {
                state.updateAgentOrgFetching = false
                state.errorMessage = action.payload ? action.payload : 'Internal server error'
            })

            .addCase(getAllAgents.pending, (state) => {
                state.fetching = true
            })
            .addCase(getAllAgents.fulfilled, (state, action) => {
                state.fetching = false
                state.agents = action.payload.results
                state.totalPages = action.payload.totalPages
            })
            .addCase(getAllAgents.rejected, (state, action) => {
                state.fetching = false
            })

            .addCase(deleteAgent.pending, (state) => {
                state.deleteAgentFetching = true
                state.errorMessage = ''
            })
            .addCase(deleteAgent.fulfilled, (state, action) => {
                state.deleteAgentFetching = false
                state.errorMessage = ''
                let tempAgents = state.agents
                for (let index = 0; index < tempAgents.length; index++) {
                    if (tempAgents[index]?.agentDetails?.uid === action.payload.uid) {
                        tempAgents.splice(index, 1);
                        break;
                    }
                }
            })
            .addCase(deleteAgent.rejected, (state, action) => {
                state.deleteAgentFetching = false
                state.errorMessage = action.payload?.message ? action.payload?.message : 'Internal server error'
            })

    }
})
