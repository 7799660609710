import React, { useState, useEffect } from 'react'
import { Container, Badge, Pagination, Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row, Spinner, Card, Table, Tabs, Toast, ToastContainer, Tooltip, InputGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { addIcon, copyIcon, deleteIcon, downloadIcon, phoneIcon, refreshIcon, sortAscIcon, sortDescIcon, sortIcon, viewIcon } from '../../FontAwesome';
import OrderDetail from './OrderDetails';
import { unwrapResult } from '@reduxjs/toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import VoiceCall from '../Misc/VoiceCall';
import { useRef } from 'react';
import PDFPreview from '../PDFPreview';
import { fetchAllApsrtcOrders, updateApsrtcOrder } from '../../app/features/apsrtc_booking/apsrtcBookingSlice';
import { useFieldArray, useForm, Controller } from 'react-hook-form';

import './index.css'
import ApsrtcInvoiceTemplate from '../Invoice/ApsrtcInvoiceTemplate';


const orderStatusRankings = {
    "cancelled": 0,
    "initiated": 1,
    "placed": 2,
    "out_for_pickup": 3.1,  //out for pickup till fm_not_ready would have the same rankings
    "pickup_failed": 3.1,
    "fm_delayed": 3.1,
    "fm_not_ready": 3.1,
    "picked_up": 3.2,
    "at_fm_agent_hub": 3.3,
    "fm_package_verified": 3.4,
    "fm_mismatch": 3.4,
    // "missed_connectivity": 6,
    "lr_generated_and_uploaded": 3.5,
    'manifested': 3.6,
    "handed_over_to_midmile_shipper": 3.7,
    "in_transit_to_destination_city": 4,
    "collection_from_midmile_failed": 5.1,
    // "failed_to_collect_mm": 5.1,
    "handed_over_to_agent": 5.1,
    "at_lm_agent_hub": 5.2,
    "lm_package_verified": 5.3,
    "lm_mismatch": 5.3,
    "out_for_delivery": 5.4,  //out for delivery till lm_not_ready would have the same rankings
    "delivery_failed": 5.4,
    "lm_delayed": 5.4,
    "lm_not_ready": 5.4,
    "delivered": 6,
    "delivered_at_neighbor": 6,
    "delivered_at_security": 6
}


function ActionHistoryModal({ show, handleClose, orderDetails }) {
    const orderStatusEvents = orderDetails?.orderStatusEvents?.slice().reverse() || [];

    return (
        <>
            <Modal size='lg' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Status Timeline</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="py-2">
                        <ul className="timeline-with-icons">
                            {
                                orderStatusEvents?.map((event, index) => {
                                    const createdAtDateObj = new Date(event.timeStamp)
                                    return (
                                        <li className="timeline-item mb-5" key={index}>
                                            <span className="timeline-icon">
                                                {/* <MDBIcon fas icon="rocket" color="primary" size="sm" /> */}
                                            </span>

                                            <h6 className="fw-bold">{createdAtDateObj.toDateString() + ", " + createdAtDateObj.toLocaleTimeString()}</h6>
                                            {/* <p className="text-muted mb-2 fw-bold">11 March 2020</p> */}
                                            <p className="text-muted">
                                                Status updated to <span className='fw-bold text-capitalize'>{event.status?.replace(/[-_]/g, " ")}</span> by {event.stakeholderName ? event.stakeholderName : event.uid} {event.stakeholderType ? "(" + event.stakeholderType + ")" : ""}
                                                {/* {event.message ? event.message : `Status updated to ${event.status} by ${event.uid}`} */}
                                            </p>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

function MismatchModal({ show, handleClose, updatedOrderStatus, orderDetails }) {
    const dispatch = useDispatch();

    const { fetchingSingle } = useSelector(state => state.apsrtcBooking);

    const { handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            newCostBreakdown: {
                firstMileCost: undefined,
                lastMileCost: undefined,
                midMileShipperCost: undefined,
                convenienceFee: undefined
            },
            updatedPackageData: [
                {
                    weight: undefined,
                    l: undefined,
                    b: undefined,
                    h: undefined,
                    pkgType: ''
                }
            ],
        }
    })

    const { fields: packagesArr, append: addPackage, remove: removePackage } = useFieldArray({
        control,
        name: 'updatedPackageData'
    })

    useEffect(() => {
        if (orderDetails?.package) {
            setValue("updatedPackageData", orderDetails.package);
        }
        if (orderDetails?.metadata?.mismatchOrderData) {
            const newCostBreakdown = orderDetails?.metadata?.mismatchOrderData?.newCostBreakdown
            setValue("newCostBreakdown.firstMileCost", newCostBreakdown?.firstMileCost)
            setValue("newCostBreakdown.lastMileCost", newCostBreakdown?.lastMileCost)
            setValue("newCostBreakdown.midMileShipperCost", newCostBreakdown?.midMileShipperCost)
            setValue("newCostBreakdown.convenienceFee", newCostBreakdown?.convenienceFee)
        }
    }, [orderDetails]);

    const updateMismatchDetails = async (data) => {
        console.log(data);

        let body = {
            sm_orderid: orderDetails.sm_orderid,
            payload: {
                orderStatus: updatedOrderStatus,
                ...data
            }
        }

        if (!orderDetails?.firstMile?.accepted || !orderDetails?.lastMile?.accepted) {
            if (updatedOrderStatus === 'fm_mismatch') {
                body.payload["firstMile.accepted"] = "yes"
            }
            else if (updatedOrderStatus === 'lm_mismatch') {
                body.payload["firstMile.accepted"] = "yes"
                body.payload["lastMile.accepted"] = "yes"
            }
        }

        await dispatch(updateApsrtcOrder(body))
            .then(unwrapResult)
            .then(() => {
                handleClose()
                // dispatch(fetchApsrtcBookingOrders());
            })
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg' backdrop="static">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(updateMismatchDetails)}>
                        <Card className='mb-3'>
                            <Card.Header>
                                <Card.Title>Package Details</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    {
                                        packagesArr?.map((packageEle, index) => {
                                            return (
                                                <Col xs={12} key={packageEle.id}>
                                                    <Card body className='mb-3'>
                                                        <Row>
                                                            {packagesArr.length > 1 &&
                                                                <div style={{ marginBottom: '10px' }}>
                                                                    <Button variant='danger' onClick={() => { removePackage(index) }} style={{ float: 'right', }} type='button'>{deleteIcon}</Button>
                                                                </div>
                                                            }
                                                            <Col>
                                                                <Controller
                                                                    control={control}
                                                                    name={`updatedPackageData.${index}.weight`}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <Form.Group className="mb-3" controlId="weight">
                                                                            <Form.Label>Weight</Form.Label>
                                                                            <InputGroup>
                                                                                <Form.Control value={value} onChange={(e) => onChange((parseFloat(e.currentTarget.value) || parseFloat(e.currentTarget.value) == 0) ? parseFloat(e.currentTarget.value) : "")} type="text" placeholder="weight" required />
                                                                                <InputGroup.Text id="weight_group">
                                                                                    kg
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                        </Form.Group>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Controller
                                                                    control={control}
                                                                    name={`updatedPackageData.${index}.l`}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <Form.Group className="mb-3" controlId="length">
                                                                            <Form.Label>Length</Form.Label>
                                                                            <InputGroup>
                                                                                <Form.Control value={value} onChange={(e) => onChange((parseFloat(e.currentTarget.value) || parseFloat(e.currentTarget.value) == 0) ? parseFloat(e.currentTarget.value) : "")} type="text" placeholder="length" required />
                                                                                <InputGroup.Text id="length_group">
                                                                                    cm
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                        </Form.Group>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Controller
                                                                    control={control}
                                                                    name={`updatedPackageData.${index}.b`}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <Form.Group className="mb-3" controlId="breadth">
                                                                            <Form.Label>Breadth</Form.Label>
                                                                            <InputGroup>
                                                                                <Form.Control value={value} onChange={(e) => onChange((parseFloat(e.currentTarget.value) || parseFloat(e.currentTarget.value) == 0) ? parseFloat(e.currentTarget.value) : "")} type="text" placeholder="breadth" required />
                                                                                <InputGroup.Text id="breadth_group">
                                                                                    cm
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                        </Form.Group>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Controller
                                                                    control={control}
                                                                    name={`updatedPackageData.${index}.h`}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <Form.Group className="mb-3" controlId="height">
                                                                            <Form.Label>Height</Form.Label>
                                                                            <InputGroup>
                                                                                <Form.Control value={value} onChange={(e) => onChange((parseFloat(e.currentTarget.value) || parseFloat(e.currentTarget.value) == 0) ? parseFloat(e.currentTarget.value) : "")} type="text" placeholder="height" required />
                                                                                <InputGroup.Text id="height_group">
                                                                                    cm
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                        </Form.Group>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Controller
                                                                    control={control}
                                                                    name={`updatedPackageData.${index}.pkgType`}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <Form.Group className="mb-3" controlId="packageType">
                                                                            <Form.Label>Package Type</Form.Label>
                                                                            <Form.Select onChange={onChange} value={value} required>
                                                                                <option value="" disabled>Select</option>
                                                                                <option value="Carton Box">Carton Box</option>
                                                                                <option value="Gunny bag">Gunny bag</option>
                                                                                <option value="Polythene bag">Polythene bag</option>
                                                                                <option value="Envelope">Envelope</option>
                                                                                <option value="other">Other</option>
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    )}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col >
                                            )
                                        })
                                    }
                                </Row>
                                {
                                    // packagesArr.length < 3 &&
                                    <Button variant='success' type='button' className='my-1' onClick={() => addPackage({
                                        l: undefined,
                                        b: undefined,
                                        h: undefined,
                                        weight: undefined,
                                        pkgType: ''
                                    })}>{addIcon}&nbsp;Add Package</Button>
                                }
                            </Card.Body>
                        </Card>

                        <Card>
                            <Card.Header>
                                <Card.Title>New Pricing</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Controller
                                        control={control}
                                        name={`newCostBreakdown.firstMileCost`}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Group className="mb-3" controlId="firstMileCost">
                                                <Form.Label>First Mile Cost</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text id="firstMileCost">
                                                        ₹
                                                    </InputGroup.Text>
                                                    <Form.Control value={value} onChange={(e) => onChange((parseFloat(e.currentTarget.value) || parseFloat(e.currentTarget.value) == 0) ? parseFloat(e.currentTarget.value) : "")} type="text" placeholder="Enter First Mile Cost" required />
                                                </InputGroup>
                                            </Form.Group>
                                        )}
                                    />
                                </Row>

                                <Row>
                                    <Controller
                                        control={control}
                                        name={`newCostBreakdown.lastMileCost`}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Group className="mb-3" controlId="lastMileCost">
                                                <Form.Label>Last Mile Cost</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text id="lastMileCost">
                                                        ₹
                                                    </InputGroup.Text>
                                                    <Form.Control value={value} onChange={(e) => onChange((parseFloat(e.currentTarget.value) || parseFloat(e.currentTarget.value) == 0) ? parseFloat(e.currentTarget.value) : "")} type="text" placeholder="Enter Last Mile Cost" required />
                                                </InputGroup>
                                            </Form.Group>
                                        )}
                                    />
                                </Row>

                                <Row>
                                    <Controller
                                        control={control}
                                        name={`newCostBreakdown.midMileShipperCost`}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Group className="mb-3" controlId="midMileShipperCost">
                                                <Form.Label>Mid Mile Cost</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text id="midMileShipperCost">
                                                        ₹
                                                    </InputGroup.Text>
                                                    <Form.Control value={value} onChange={(e) => onChange((parseFloat(e.currentTarget.value) || parseFloat(e.currentTarget.value) == 0) ? parseFloat(e.currentTarget.value) : "")} type="text" placeholder="Enter Midmile(APSRTC) Mile Cost" required />
                                                </InputGroup>
                                            </Form.Group>
                                        )}
                                    />
                                </Row>

                                <Row>
                                    <Controller
                                        control={control}
                                        name={`newCostBreakdown.convenienceFee`}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Group className="mb-3" controlId="convenienceFee">
                                                <Form.Label>Convenience Fee</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text id="convenienceFee">
                                                        ₹
                                                    </InputGroup.Text>
                                                    <Form.Control value={value} onChange={(e) => onChange((parseFloat(e.currentTarget.value) || parseFloat(e.currentTarget.value) == 0) ? parseFloat(e.currentTarget.value) : "")} type="text" placeholder="Enter Convenience Fee" required />
                                                </InputGroup>
                                            </Form.Group>
                                        )}
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                        <hr />
                        <Button disabled={fetchingSingle} type='submit'>Update Order</Button>

                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

function MisDeliveryReasonModal({ show, handleClose, updatedOrderStatus, order }) {
    const dispatch = useDispatch();

    const { fetchingSingle } = useSelector(state => state.apsrtcBooking);
    const [misDeliveryReason, setMisDeliveryReason] = useState('');

    useEffect(() => {
        if (updatedOrderStatus === 'pickup_failed') {
            setMisDeliveryReason(order?.firstMile?.misDeliveryReason || "")
        } else if (updatedOrderStatus === 'delivery_failed') {
            setMisDeliveryReason(order?.lastMile?.misDeliveryReason || "")
        }
    }, [updatedOrderStatus, order])

    const submitMisDeliveryReason = async () => {
        let body = {
            sm_orderid: order.sm_orderid,
            payload: {
                orderStatus: updatedOrderStatus,
                // misDeliveryReason: misDeliveryReason
            }
        }

        if (updatedOrderStatus === 'pickup_failed') {
            body.payload["firstMile.misDeliveryReason"] = misDeliveryReason
        } else if (updatedOrderStatus === 'delivery_failed') {
            body.payload["lastMile.misDeliveryReason"] = misDeliveryReason
        }

        if (!order?.firstMile?.accepted || !order?.lastMile?.accepted) {
            if (updatedOrderStatus === 'pickup_failed') {
                body.payload["firstMile.accepted"] = "yes"
            }
            else if (updatedOrderStatus === 'delivery_failed') {
                body.payload["firstMile.accepted"] = "yes"
                body.payload["lastMile.accepted"] = "yes"
            }
        }

        await dispatch(updateApsrtcOrder(body))
            .then(unwrapResult)
            .then(() => {
                handleClose()
                // dispatch(fetchApsrtcBookingOrders());
            })
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="misDeliveryReason" className="mb-3">
                        <Form.Label>Reason</Form.Label>
                        <Form.Control as="textarea" value={misDeliveryReason} onChange={e => setMisDeliveryReason(e.currentTarget.value)} rows={3} />
                    </Form.Group>
                    <hr />
                    <Button onClick={submitMisDeliveryReason} disabled={fetchingSingle}>Submit</Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

function GeneralCommentModal({ show, handleClose, orderDetails }) {
    const dispatch = useDispatch();

    const { fetchingSingle } = useSelector(state => state.apsrtcBooking);
    const [shortDesc, setShortDesc] = useState('');
    const [detailedDesc, setDetailedDesc] = useState('');


    useEffect(() => {
        if (orderDetails) {
            setShortDesc(orderDetails?.metadata?.generalComment?.shortDesc ? orderDetails?.metadata?.generalComment?.shortDesc : '')
            setDetailedDesc(orderDetails?.metadata?.generalComment?.detailedDesc ? orderDetails?.metadata?.generalComment?.detailedDesc : '')
        }
    }, [orderDetails])


    const addGeneralComment = async () => {
        if (!shortDesc) {
            alert('Please enter a valid comment before submitting!');
            return;
        }

        let body = {
            sm_orderid: orderDetails.sm_orderid,
            payload: {
                "metadata.generalComment": {
                    shortDesc,
                    detailedDesc
                }
            }
        }

        await dispatch(updateApsrtcOrder(body))
            .then(unwrapResult)
            .then((res) => {
                handleClose()
                // dispatch(fetchApsrtcBookingOrders());
            })
            .catch(err => {
                console.error(err)
                alert('Some error occured. Please refresh and try again or contact support')
            })
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add or Modify Comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="shortDesc" className="mb-3">
                        <Form.Label className='required fw-bold'>Short Description</Form.Label>
                        <Form.Control type='text' placeholder='Short Description' value={shortDesc} onChange={e => setShortDesc(e.currentTarget.value)} />
                    </Form.Group>
                    <Form.Group controlId="detailedDesc" className="mb-3">
                        <Form.Label className='fw-bold'>Detailed Description</Form.Label>
                        <Form.Control as='textarea' rows={3} placeholder='Detailed Description about the order' value={detailedDesc} onChange={e => setDetailedDesc(e.currentTarget.value)} />
                    </Form.Group>
                    <hr />
                    <Button onClick={addGeneralComment} disabled={fetchingSingle}>Submit</Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

function OrderRow({ superuser, order, setIsTextCopied, index, setOrderData, setShowOrderDetail, setNumberToCall, setShowFileUploadModal, setShowVoiceCallModal, setShowPackageImgModal, setShowInvoicePreviewModal }) {
    const dispatch = useDispatch();

    const { fetchingSingle } = useSelector(state => state.apsrtcBooking);
    const { adminUser } = useSelector(state => state.adminUser)


    const [busy, setBusy] = useState(false);

    const [orderStatus, setOrderStatus] = useState(order?.orderStatus ? order.orderStatus : '');
    const [openMisDeliveryReasonModal, setOpenMisDeliveryReasonModal] = useState(false);
    const [openCommentModal, setOpenCommentModal] = useState(false);
    const [openMismatchModal, setOpenMismatchModal] = useState(false);
    const [openActionHistoryModal, setOpenActionHistoryModal] = useState(false);
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);

    const [lr_number, setLrNumber] = useState(order?.package?.length ? order.package?.[0]?.awb : '');
    const [stateChanger, setStateChanger] = useState(0);

    const copyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        setIsTextCopied(true)
        setTimeout(() => {
            setIsTextCopied(false)
        }, 3000);
    }

    const onApsrtcOrderStatusChange = async (status) => {
        // if (busy) return;
        // setBusy(true);
        if (!status) {
            return
        }

        setOrderStatus(status);

        if (['pickup_failed', 'delivery_failed'].includes(status)) {
            setOpenMisDeliveryReasonModal(true);
            return;
        }

        if (['fm_mismatch', 'lm_mismatch'].includes(status)) {
            setOpenMismatchModal(true);
            return;
        }

        // updateApsrtcOrderStatus(status);

        let body = {
            sm_orderid: order.sm_orderid,
            payload: {
                orderStatus: status
            }
        }

        if (orderStatusRankings[status] < orderStatusRankings["handed_over_to_agent"]) {
            body.payload["firstMile.accepted"] = "yes"
        }
        else if (orderStatusRankings[status] > orderStatusRankings["handed_over_to_agent"]) {
            body.payload["firstMile.accepted"] = "yes"
            body.payload["lastMile.accepted"] = "yes"
        }

        await dispatch(updateApsrtcOrder(body))
            .then(unwrapResult)
            .then(() => {
                // dispatch(fetchApsrtcBookingOrders());
            })

        // setBusy(false);

    }

    useEffect(() => {
        if (order) {
            setOrderStatus(order.orderStatus ? order.orderStatus : '');
            setLrNumber(order?.package?.length ? order.package?.[0]?.awb : "");
        }
    }, [order])


    const createdAtDateObj = new Date(order.createdAt)
    const nowDateObj = new Date();
    var secondsDiff = (nowDateObj.getTime() - createdAtDateObj.getTime()) / 1000;
    const timeString = createdAtDateObj.toTimeString().split(' ')[0]
    const urgent = (createdAtDateObj.getHours() < 18) && (createdAtDateObj.getDay() !== 0);
    const doorToDoorCost = order.cost?.doorToDoor ? order.cost?.doorToDoor : 0
    const midMileCost = order.cost?.midMile ? order.cost?.midMile : 0
    const midMileInsuranceCost = order.cost?.midMileInsurance ? order.cost?.midMileInsurance : 0
    const gst = order.cost?.gst ? order.cost?.gst : 0
    const handlingCharges = order.cost?.handlingCharges ? order.cost?.handlingCharges : 0
    const totalCost = ((doorToDoorCost + midMileCost + midMileInsuranceCost + gst + handlingCharges) / 100).toFixed(2)
    const { packageImgURL, firstMile, lastMile } = order;
    const fromCounter = order.firstMile.fromCounter

    const handleDownloadLr = (docUrl) => {
        // window.location.href = docUrl
        const link = document.createElement('a');

        // Set the href to the URL of the file you want to download
        link.href = docUrl; // Replace with the actual path to your file

        // Set the download attribute to specify the desired file name
        link.download = 'Lr_manifest'; // Replace with the desired file name

        link.target = '_blank';

        // Programmatically trigger a click event on the link element
        link.click();
    }

    const addLr = async () => {
        if (!lr_number) {
            alert('Please add a valid LR number');
            return;
        }

        const tempPackage = JSON.parse(JSON.stringify(order.package));
        tempPackage[0].awb = lr_number

        let body = {
            sm_orderid: order.sm_orderid,
            payload: {
                package: tempPackage
            }
        }

        await dispatch(updateApsrtcOrder(body))
            .then(unwrapResult)
            .then(() => {
                // dispatch(fetchApsrtcBookingOrders());
            })
    }


    // const formattedOrder = {
    //     ...order,
    //     cost: {
    //         amountCollectedFromCustomer: order?.cost?.amountCollectedFromCustomer,
    //         gst: order?.cost?.amountCollectedFromCustomer - ((order?.cost?.amountCollectedFromCustomer || 0) / 1.18)
    //     }
    // }


    const orderid = order.sm_orderid.split("-")
    let totalWeight = 0, totalVolumetricWeight = 0;
    order?.package?.forEach(pkg => {
        totalWeight += pkg.weight;
        totalVolumetricWeight += (pkg.volumetricWeight ? pkg.volumetricWeight : parseFloat(((pkg.l * pkg.b * pkg.h) / 2500).toFixed(2)));
    })

    return (
        <>
            <tr>
                <td>
                    {createdAtDateObj.toDateString() + ", " + createdAtDateObj.toLocaleTimeString()}
                    <br />
                    {
                        order.paymentStatus === 'pending' ? <Badge pill bg="danger" style={{ textTransform: 'capitalize' }}>
                            Payment: Pending
                        </Badge> :
                            null
                    }
                </td>
                <td>
                    {/* {order.sm_orderid} */}
                    {orderid[0] + '-'}<OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="stn-tooltip" style={{ width: 'fit-content' }}>{order.firstMile.city}&nbsp;to&nbsp;{order.lastMile.city}</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <span ref={ref} {...triggerHandler} style={{ cursor: 'pointer', fontWeight: 600 }}>{orderid[1] + '-' + orderid[2]}</span>
                        )}
                    </OverlayTrigger>{'-' + orderid[3]}
                    <span style={{ cursor: 'pointer' }} onClick={() => copyText(order.sm_orderid)}>&nbsp;{copyIcon}</span>
                    <hr />
                    <div>
                        <span className='fw-bold'>First Mile Agent :&nbsp;
                            {
                                order.metadata.pickupMethod === 'self' ? <Badge pill bg="warning" style={{ textTransform: 'capitalize' }}>
                                    Customer Drop
                                </Badge> :
                                    order.metadata.pickupMethod === 'priority' ? <Badge pill bg="info" style={{ textTransform: 'capitalize' }}>
                                        Agent Pickup
                                    </Badge> :
                                        order.metadata.pickupMethod === 'onPremises' ? <Badge pill bg="info" style={{ textTransform: 'capitalize' }}>
                                            Shipper Pickup
                                        </Badge> : null
                            }
                        </span> <br />
                        <span>Company : </span>{order.stakeholders?.firstMileAgentOrgId?.companyDisplayName}
                        <br />
                        <span>Name : </span>{order?.firstMile?.end?.contact?.name}
                        <br />
                        <span>Contact : </span>{order?.firstMile?.end?.contact?.phone} <span style={{ cursor: 'pointer', float: 'right' }} onClick={() => { setNumberToCall(order.firstMile?.end?.contact?.phone); setShowVoiceCallModal(true) }}>&nbsp;{phoneIcon}</span>
                    </div>
                    <br />
                    <div>
                        <span className='fw-bold'>Last Mile Agent :&nbsp;
                            {
                                order.metadata.deliveryMethod === 'self' ? <Badge pill bg="info" style={{ textTransform: 'capitalize' }}>
                                    Customer Pickup
                                </Badge> :
                                    order.metadata.deliveryMethod === 'priority' ? <Badge pill bg="info" style={{ textTransform: 'capitalize' }}>
                                        Agent Drop
                                    </Badge> :
                                        order.metadata.deliveryMethod === 'onPremises' ? <Badge pill bg="info" style={{ textTransform: 'capitalize' }}>
                                            Shipper Drop
                                        </Badge> : null
                            }
                        </span> <br />
                        <span>Company : </span>{order.stakeholders?.lastMileAgentOrgId?.companyDisplayName}
                        <br />
                        <span>Name : </span>{order?.lastMile?.start?.contact?.name}
                        <br />
                        <span>Contact : </span>{order?.lastMile?.start?.contact?.phone} <span style={{ cursor: 'pointer', float: 'right' }} onClick={() => { setNumberToCall(order.lastMile?.start?.contact?.phone); setShowVoiceCallModal(true) }}>&nbsp;{phoneIcon}</span>
                    </div>
                </td>
                <td>
                    <div>
                        <b>From:</b>&nbsp;{order.start.contact.name}<br />{order.start.contact.phone}<span style={{ cursor: 'pointer', float: 'right' }} onClick={() => { setNumberToCall(order.start?.contact?.phone); setShowVoiceCallModal(true) }}>&nbsp;{phoneIcon}</span>
                        {
                            fromCounter &&
                            <Badge bg="primary">
                                Booked From Counter
                            </Badge>
                        }
                    </div>
                    <hr />
                    <div>
                        <b>To:</b>&nbsp;{order.end.contact.name}<br />{order.end.contact.phone}<span style={{ cursor: 'pointer', float: 'right' }} onClick={() => { setNumberToCall(order.end?.contact?.phone); setShowVoiceCallModal(true) }}>&nbsp;{phoneIcon}</span>
                    </div>
                    <hr />
                    <div>
                        <p className='mb-0' style={{ color: totalWeight > totalVolumetricWeight ? '#5826e7' : null }}><b>{totalWeight > totalVolumetricWeight ? '>' : null}Actual Weight:</b>&nbsp;{totalWeight} KG</p>
                        <p className='mt-1' style={{ color: totalVolumetricWeight > totalWeight ? '#5826e7' : null }}><b>{totalVolumetricWeight > totalWeight ? '>' : null}Vol. Weight:</b>&nbsp;{totalVolumetricWeight.toFixed(2)} KG</p>

                        {/* <OverlayTrigger overlay={<Tooltip id={"weight-info"}>{`Actual Weight: ${totalWeight}KG\r\nVolumetric Weight: ${totalVolumetricWeight}KG`}</Tooltip>}>
                            <div style={{ display: 'inline-block', marginLeft: '2px' }}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </div>
                        </OverlayTrigger>
                        <br />
                        {totalVolumetricWeight > totalWeight &&
                            <p className='mb-0 fw-bold' style={{ color: 'red', fontSize: '12px' }}><FontAwesomeIcon icon={faWarning} /> Note: Volumetric weight considered</p>
                        } */}
                    </div>
                </td>
                <td>
                    <Form.Select disabled={fetchingSingle} aria-label="Default select example" value={orderStatus} onChange={(e) => { onApsrtcOrderStatusChange(e.currentTarget.value) }}>
                        <option value="">{order.orderStatus}</option>
                        <option value="placed">Placed</option>
                        <option value="picked_up">Picked up</option>
                        <option value="pickup_failed">Pickup failed</option>
                        <option value="fm_mismatch" onClick={() => setOpenMismatchModal(true)}>First Mile Mismatch</option>
                        <option value="lr_generated_and_uploaded">LR Generated and Uploaded</option>
                        <option value="fm_package_verified">Package verified at first mile</option>
                        <option value="manifested">Manifested</option>
                        <option value="handed_over_to_midmile_shipper">Connected to Midmile</option>
                        <option value="in_transit_to_destination_city">In transit</option>
                        <option value="lm_mismatch">Last Mile Mismatch</option>
                        <option value="lm_package_verified">Package verified at last mile</option>
                        <option value="delivered">Delivered</option>
                        <option value="delivered_at_security">Delivered at security</option>
                        <option value="delivered_at_neighbor">Delivered at neighbour</option>
                        <option value="delivery_failed">Delivery failed</option>
                        <option value="cancelled">Cancelled</option>
                    </Form.Select>
                    <br />
                    <p>First Mile Accepted : <span className='fw-bold' style={{ color: order?.firstMile.accepted === 'yes' ? 'green' : 'red' }}>{order?.firstMile?.accepted === 'yes' ? 'Yes' : 'No'}</span></p>
                    <p>Last Mile Accepted : <span className='fw-bold' style={{ color: order?.lastMile.accepted === 'yes' ? 'green' : 'red' }}>{order?.lastMile?.accepted === 'yes' ? 'Yes' : 'No'}</span></p>
                </td>
                <td>
                    {
                        order.package?.[0]?.awb ?
                            <>
                                {order.package?.[0]?.awb}
                                {(order.package?.[0]?.awb && order.package?.[0]?.manifestDocUrl) && <Button size='sm' variant='dark' className='ms-1' onClick={() => { handleDownloadLr(order?.package?.[0]?.manifestDocUrl) }}>{downloadIcon}</Button>}
                            </>
                            : adminUser?.isSuperAdmin ?
                                <>
                                    <Form.Control type='text' value={lr_number} onChange={e => setLrNumber(e.currentTarget.value)} />
                                    <Button size='sm' type='button' onClick={addLr}>Add LR</Button>
                                </> : null
                    }
                    <hr />
                    {order?.metadata?.generalComment?.shortDesc ? "Comment: " + order?.metadata?.generalComment?.shortDesc : null}
                    {/* {
                        order?.package?.map((pkg, index) => {
                            return (
                                <>
                                    <p>
                                        <Form.Control type='text' readOnly={(!adminUser?.isSuperAdmin || pkg.awb) ? true : false} value={pkg.awb} />
                                        {(!pkg.awb) && <Button>Submit</Button>}
                                        {(pkg.awb && pkg.manifestDocUrl) && <Button variant='dark' className='ms-2' onClick={() => { handleDownloadLr(pkg.manifestDocUrl) }}>{downloadIcon}</Button>}
                                    </p>
                                </>
                            )
                        })
                    } */}
                </td>
                <td>
                    <Button
                        onClick={() => { setOrderData(order); setShowOrderDetail(true) }}
                        style={{ width: '100%', marginBottom: '5px' }}
                        variant='success'>Details
                    </Button> <br />
                    <Button
                        onClick={() => { setOpenCommentModal(true) }}
                        style={{ width: '100%', marginBottom: '5px' }}
                        variant='dark'>Comments
                    </Button> <br />
                    <Button
                        onClick={() => { setOpenActionHistoryModal(true) }}
                        style={{ width: '100%', marginBottom: '5px' }}
                        variant='info'>History
                    </Button> <br />
                    <Button
                        onClick={() => { setOpenInvoiceModal(true) }}
                        style={{ width: '100%', marginBottom: '5px' }}
                        variant='primary'>Invoice
                    </Button> <br />
                </td>
            </tr>
            {openMisDeliveryReasonModal && <MisDeliveryReasonModal show={openMisDeliveryReasonModal} handleClose={() => setOpenMisDeliveryReasonModal(false)} updatedOrderStatus={orderStatus} order={order} />}
            {openMismatchModal && <MismatchModal show={openMismatchModal} handleClose={() => setOpenMismatchModal(false)} updatedOrderStatus={orderStatus} orderDetails={order} />}
            {openCommentModal && <GeneralCommentModal show={openCommentModal} handleClose={() => setOpenCommentModal(false)} orderDetails={order} />}
            {openActionHistoryModal && <ActionHistoryModal show={openActionHistoryModal} handleClose={() => setOpenActionHistoryModal(false)} orderDetails={order} />}
            {openInvoiceModal && <PDFPreview fileName={order?.sm_orderid + "_Invoice"} show={openInvoiceModal} handleClose={() => setOpenInvoiceModal(false)} pdfTemplate={<ApsrtcInvoiceTemplate dataArr={[order]} />} />}
        </>
    )
}

function OrderTable({ superuser, onScroll, setSortedResults, sortType, sortedResults, setSortType, firstMileStatus, lastMileStatus, midMileStatus }) {
    const dispatch = useDispatch();
    const { orders, fetching } = useSelector(state => state.apsrtcBooking)

    const [isTextCopied, setIsTextCopied] = useState(false);
    const [showOrderDetail, setShowOrderDetail] = useState(false)
    const [orderData, setOrderData] = useState(undefined)

    const [filterOrderStatus, setFilterOrderStatus] = useState('');
    const [startCityFilter, setStartCityFilter] = useState('');
    const [endCityFilter, setEndCityFilter] = useState('');
    const [priorityPickupFilter, setPriorityPickupFilter] = useState('');

    const [numberToCall, setNumberToCall] = useState('');
    const [showVoiceCallModal, setShowVoiceCallModal] = useState(false);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [showPackageImgModal, setShowPackageImgModal] = useState(false);
    const [showInvoicePreviewModal, setShowInvoicePreviewModal] = useState(false);

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            // href=""
            // style={{ cursor: 'pointer' }}
            size='sm'
            style={{ marginLeft: '10px' }}
            ref={ref}
            variant='secondary'
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <FontAwesomeIcon icon={faFilter} />
        </Button>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.currentTarget.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                        )}
                    </ul>
                </div>
            );
        },
    );

    const handleSort = (type) => {
        const sortingOrder = sortType.split("-")[1]
        if (sortingOrder === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (sortingOrder === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        setSortedResults(orders)
        setSortType('createdAt-desc')
    }, [orders, filterOrderStatus, startCityFilter, endCityFilter])

    return (
        <>
            {
                isTextCopied && <ToastContainer position='top-center'>
                    <Toast bg='info' delay={3000} autohide style={{ margin: '20px', fontSize: '20px', textAlign: 'center' }}>
                        <Toast.Body>Item Copied</Toast.Body>
                    </Toast>
                </ToastContainer>
            }
            <Table onScroll={onScroll} bordered hover responsive className='mt-3' style={{ minHeight: '100vh', overflowY: 'scroll' }}>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th className='col-2' style={{ maxWidth: '10%' }}>
                            Booked at&nbsp;
                            {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                            }
                        </th>
                        <th className='col-3' style={{ maxWidth: '20%' }}>Order ID</th>
                        <th className='col-2' style={{ maxWidth: '10%' }}>
                            Customer Details
                        </th>
                        <th className='col-2' style={{ maxWidth: '13%' }}>
                            Status
                        </th>
                        <th className='col-1' style={{ maxWidth: '16%' }}>
                            LRN
                        </th>
                        <th className='col-1' style={{ maxWidth: '13%' }}>Actions</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        fetching ?
                            <tr><td colSpan={8} style={{ width: '100%', textAlign: 'center', padding: '20px' }}>Loading...</td></tr>
                            :
                            sortedResults.length > 0 ?
                                sortedResults?.map((order, index) => {
                                    // const updatedAtDateString = new Date();
                                    // const updatedAtDate = new Date().toDateString() + ", " + new Date().toLocaleTimeString();
                                    // const createdAtDateString = new Date(order.createdAt);
                                    // const createdAtDate = createdAtDateString.toDateString() + ", " + createdAtDateString.toLocaleTimeString();
                                    return (
                                        <OrderRow
                                            superuser={superuser}
                                            key={"apsrtc_order_" + index} setIsTextCopied={setIsTextCopied} order={order} index={index}
                                            setOrderData={setOrderData} setShowOrderDetail={setShowOrderDetail} setNumberToCall={setNumberToCall} setShowFileUploadModal={setShowFileUploadModal} setShowInvoicePreviewModal={setShowInvoicePreviewModal}
                                            setShowPackageImgModal={setShowPackageImgModal} setShowVoiceCallModal={setShowVoiceCallModal}
                                        />
                                    )
                                }) :
                                <tr><td colSpan={8} style={{ width: '100%', textAlign: 'center', padding: '20px' }}>No Orders to show</td></tr>
                    }
                </tbody>
            </Table >
            {showOrderDetail && orderData && <OrderDetail show={showOrderDetail} handleClose={() => { setShowOrderDetail(false); setOrderData(undefined) }} order={orderData} />
            }
            {showVoiceCallModal && <VoiceCall show={showVoiceCallModal} handleClose={() => setShowVoiceCallModal(false)} numberToCall={numberToCall} orderType='apsrtc' orderId={orderData?.sm_orderid} />}
            {/* {showFileUploadModal && orderData && <FileUploadModal show={showFileUploadModal} handleClose={() => setShowFileUploadModal(false)} order={orderData} />} */}
            {/* {showPackageImgModal && orderData && <PackageImg show={orderData?.packageImgURL && showPackageImgModal} handleClose={() => setShowPackageImgModal(false)} orderID={orderData?.sm_orderid} imageURL={orderData?.packageImgURL} />} */}
            {/* eslint-disable-next-line react/no-children-prop */}
            {/* {showInvoicePreviewModal && orderData && <PDFPreview fileName={orderData?.sm_orderid + "_Invoice"} show={showInvoicePreviewModal} handleClose={() => setShowInvoicePreviewModal(false)} pdfTemplate={<InvoiceTemplate dataArr={[orderData]} />} />} */}
        </>
    )
}

export default function Orders() {
    // const { fetching } = useSelector(state => state.apsrtc);
    const { orderType } = useParams();

    const { orders, totalPages, fetching } = useSelector(state => state.apsrtcBooking)
    const { adminUser } = useSelector(state => state.adminUser)
    const dispatch = useDispatch();
    const firstLoad = useRef(true)
    const searchInputFirstLoad = useRef(false);


    const [searchString, setSearchString] = useState('')
    const [page, setPage] = useState(1)
    const [allOrdersFound, setAllOrdersFound] = useState(false)
    const [exportBusy, setExportBusy] = useState(false)

    const [sortType, setSortType] = useState("createdAt-desc")
    const [pendingOrderType, setPendingOrderType] = useState('firstMile')

    const [sortedResults, setSortedResults] = useState(orders ? orders : [])
    const [firstMileStatus, setFirstMileStatus] = useState([]);
    const [lastMileStatus, setLastMileStatus] = useState([]);
    const [midMileStatus, setMidMileStatus] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterOrderStatusArr, setFilterOrderStatusArr] = useState([]);
    const [filterOrderStatus, setFilterOrderStatus] = useState('');
    const [startCityFilter, setStartCityFilter] = useState('');
    const [endCityFilter, setEndCityFilter] = useState('');
    const [orderFlowType, setOrderFlowType] = useState('')


    const nextPage = (e) => {
        setPage(page + 1)
    }

    const resetFilters = () => {
        setFilterOrderStatusArr([]);
        setStartCityFilter('');
        setEndCityFilter('');
        setStartDate(null);
        setEndDate(null);
    }

    const manageOrderStatusFilterArr = (status) => {
        if (status == 'remove') {
            setFilterOrderStatusArr([]);
            return;
        }

        let tempArr = filterOrderStatusArr;
        if (tempArr.includes(status)) {
            tempArr = tempArr.filter(e => e !== status)
        } else {
            tempArr.push(status)
        }

        setFilterOrderStatusArr(tempArr);

    }

    const getFilterQueryParams = () => {
        let queryParams = {};
        queryParams.page = page;
        queryParams.limit = 20;
        queryParams.shipperId = 'apsrtc'
        if (startCityFilter) {
            queryParams["startCity"] = startCityFilter;
        }
        if (endCityFilter) {
            queryParams["endCity"] = endCityFilter;
        }
        if (filterOrderStatus) {
            queryParams["orderStatus"] = filterOrderStatus;
        }
        if (startDate) {
            queryParams["startDate"] = startDate;
        }
        if (endDate) {
            queryParams["endDate"] = endDate;
        }
        if (searchString) {
            queryParams.searchString = searchString
        }
        if (orderFlowType) {
            queryParams["orderType"] = orderFlowType;
        }
        return queryParams
    }

    useEffect(() => {
        resetFilters();
        if (orderType === 'completed-orders') {
            setFilterOrderStatus('delivered');
        }
        else if (orderType === 'rejected-orders') {
            setFilterOrderStatus('rejected');
        }
        else if (orderType === 'new-orders') {
            setFilterOrderStatus('new');
        }
        else if (orderType === 'in-progress-orders') {
            setFilterOrderStatus('active')
        }
        else if (orderType === 'on-hold-orders') {
            setFilterOrderStatus('on_hold')
        }
        else if (orderType === 'all-orders') {
            setFilterOrderStatus('')
        }
    }, [orderType])

    useEffect(() => {
        setPage(1)
        let queryParams = getFilterQueryParams();
        dispatch(fetchAllApsrtcOrders(queryParams))
            .then(() => { })
            .catch(() => {
                setAllOrdersFound(true);
            })
    }, [startCityFilter, endCityFilter, filterOrderStatus, filterOrderStatusArr, startDate, endDate, orderFlowType])

    useEffect(() => {
        if (!firstLoad.current) {
            let queryParams = getFilterQueryParams();
            dispatch(fetchAllApsrtcOrders(queryParams))
                .then(() => { })
                .catch(() => {
                    setAllOrdersFound(true);
                })
        } else {
            firstLoad.current = false
        }
    }, [page])


    // const fetchAllApsrtcOrdersForExport = () => {
    //     setExportBusy(true);
    //     let queryParams = {};
    //     if (startCityFilter) {
    //         queryParams["startCity"] = startCityFilter;
    //     }
    //     if (endCityFilter) {
    //         queryParams["endCity"] = endCityFilter;
    //     }
    //     if (filterOrderStatusArr.length) {
    //         queryParams["orderStatus"] = filterOrderStatusArr;
    //     }
    //     if (startDate) {
    //         queryParams["startDate"] = startDate;
    //     }
    //     if (endDate) {
    //         queryParams["endDate"] = endDate;
    //     }

    //     fetchAllAPSRTCOrdersWithoutPagingAPI(queryParams)
    //         .then(async (response) => {

    //             try {

    //                 // console.log(response.data)
    //                 if (!response.data.length > 0) {
    //                     alert("No orders found for selected filters");
    //                     return;
    //                 }
    //                 console.log(1);

    //                 let tableData = [
    //                     {
    //                         orderId: "Order ID",
    //                         bookingDate: "Booking Date",
    //                         bookingTime: "Booking Time",
    //                         fromLocation: "From Station",
    //                         ToLocation: "To Station",
    //                         senderName: "Sender Name",
    //                         senderMobile: "Sender Mobile",
    //                         senderAddress: "Sender Address",
    //                         recipientName: "Recipient Name",
    //                         recipientMobile: "Recipient Mobile",
    //                         recipientAddress: "Recipient Address",
    //                         LRnumber: "LR Number",
    //                         weightOfPackage: "Weight of Packages",
    //                         noOfPackage: "No of Packages",
    //                         priorityPickup: "Priority Pickup",
    //                         orderStatus: "Order Status",
    //                     }
    //                 ]

    //                 console.log(2);

    //                 for (let i = 0; i < response.data.length; i++) {
    //                     const orderData = response.data[i];

    //                     let totalWeight = 0;
    //                     for (let j = 0; j < orderData.packageData.length; j++) {
    //                         const packageEl = orderData.packageData[j];

    //                         totalWeight = totalWeight + packageEl.weight;
    //                     }
    //                     const dateObj = new Date(orderData.createdAt);

    //                     let lr_string = "Not generated";

    //                     if (orderData.lr_number.length > 0) {
    //                         lr_string = "";

    //                         for (let j = 0; j < orderData.lr_number.length; j++) {
    //                             const element = orderData.lr_number[j];
    //                             lr_string = lr_string + element;
    //                             if (j < (orderData.lr_number.length - 1)) {
    //                                 lr_string = lr_string + ","
    //                             }
    //                         }
    //                     }

    //                     let sheetRow = {
    //                         orderId: orderData.sm_orderid,
    //                         bookingDate: dateObj.toDateString(),
    //                         bookingTime: dateObj.getHours() + ":" + dateObj.getMinutes(),

    //                         fromLocation: orderData.midMile?.startStation?.address?.fromGoogle?.city,
    //                         ToLocation: orderData.midMile?.endStation?.address?.fromGoogle?.city,

    //                         senderName: orderData.start?.contact?.name,
    //                         senderMobile: orderData.start?.contact?.phone,
    //                         senderAddress: orderData.firstMile?.fromCounter ? `Booked from Counter - ${orderData.midMile?.startStation.address.fromGoogle.city}` : orderData.start?.address?.flatno
    //                             + ", " + orderData.start?.address?.building
    //                             + ", " + orderData.start?.address?.fromGoogle?.address
    //                         ,

    //                         recipientName: orderData.end?.contact?.name,
    //                         recipientMobile: orderData.end?.contact?.phone,
    //                         recipientAddress: orderData.end?.address?.flatno
    //                             + ", " + orderData.end?.address?.building
    //                             + ", " + orderData.end?.address?.fromGoogle?.address
    //                         ,

    //                         LRnumber: lr_string,
    //                         weightOfPackage: totalWeight,
    //                         noOfPackage: orderData.packageData?.length,
    //                         priorityPickup: orderData.firstMile?.priority_pickup ? "Yes" : "",
    //                         orderStatus: orderData.orderStatus,
    //                     }

    //                     tableData.push(sheetRow);

    //                 }

    //                 console.log(tableData);
    //                 console.log(3);
    //                 let wb = utils.book_new();
    //                 console.log(4);
    //                 let ws = utils.json_to_sheet(tableData, { skipHeader: 1 })
    //                 console.log(5);
    //                 utils.book_append_sheet(wb, ws, "APSRTC ORDERS");

    //                 writeFile(wb, "APSRTC ORDERS " + new Date().toDateString() + ".xlsx");

    //             } catch (e) {
    //                 console.err("error while importing", e);
    //             }
    //             // var wbout = write(wb, {bookType:'xlsx', type:'array'});
    //             // window.saveAs(new Blob([wbout],{type:"application/octet-stream"}), `${("APSRTC ORDERS "+new Date().toDateString)}.xlsx`);
    //             // window.open(URL.createObjectURL(new Blob([wbout],{type:"application/octet-stream"})));


    //         })
    //         .catch(() => {
    //             setAllOrdersFound(true);
    //         })
    //         .finally(() => {
    //             setExportBusy(false)
    //         })

    // }

    // const updateApsrtcOrderStatus = async (event, mileType, orderId, index) => {
    //     if (!event.target.value) {
    //         return
    //     }
    //     let body = {
    //         sm_orderid: orderId,
    //         payload: {
    //             orderStatus: event.target.value
    //         }
    //     }

    //     await dispatch(updateApsrtcOrder(body))
    //         .then(unwrapResult)
    //         .then(() => {
    //             // dispatch(fetchApsrtcBookingOrders());
    //         })
    // }


    const CustomToggle = React.forwardRef(({ label, children, active, onClick }, ref) => (
        <div>
            <span style={{ fontWeight: 800, fontSize: '10px' }}><FontAwesomeIcon icon={faFilter} />&nbsp;{label}:</span>
            <Button
                // href=""
                // style={{ cursor: 'pointer' }}
                variant={active ? "primary" : 'outline-primary'}
                size='sm'
                style={{ width: '100%' }}
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {!active && <FontAwesomeIcon icon={faFilter} />}&nbsp;{children}
            </Button>
        </div>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.currentTarget.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                child.props.eventKey.toLowerCase().startsWith(value.toLowerCase()),
                        )}
                    </ul>
                </div>
            );
        },
    );

    useEffect(() => {
        const [attr, type] = sortType.split("-")
        let temp = [...sortedResults]
        if (attr === "updatedAt" || attr === "createdAt") {
            if (type === "asc") {
                temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) =>
                    new Date(b[attr]) - new Date(a[attr])
                )
                setSortedResults(temp)
            }
        } else {
            if (type === "asc") {
                temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                setSortedResults(temp)
            }
        }
    }, [sortType])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let queryParams = getFilterQueryParams();
            if (searchInputFirstLoad.current) {
                dispatch(fetchAllApsrtcOrders(queryParams))
                    .then(() => { })
                    .catch(() => {
                        setAllOrdersFound(true);
                    })
            } else {
                searchInputFirstLoad.current = true
            }
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchString])


    return (
        <div style={{ padding: '30px' }}>
            <div className='d-flex'>
                <h3 style={{ flex: 1, textTransform: 'capitalize' }}>APSRTC {orderType.replace(/[-_\s]/g, " ")}</h3>
                <div>
                    <Button onClick={() => { let queryParams = getFilterQueryParams(); dispatch(fetchAllApsrtcOrders(queryParams)) }} className='ms-2' variant='secondary'>{refreshIcon}&nbsp;Refresh</Button>
                </div>
                {/* <div>
                    <Button disabled={exportBusy} onClick={() => fetchAllApsrtcOrdersForExport()} className='ms-2' variant='success'><FontAwesomeIcon icon={faFileExcel} />&nbsp;{exportBusy ? "Exporting..." : "Export Sheet"}</Button>
                </div> */}
            </div>
            <hr />
            <Row>
                <Col lg={2}>
                    <Dropdown style={{ display: 'inline-block', zIndex: '1000', width: '100%' }} onSelect={(orderFlow) => setOrderFlowType(orderFlow)}>
                        <Dropdown.Toggle
                            as={CustomToggle} id="order-flow-dropdown" label="Filter by Order Flow"
                            active={orderFlowType ? true : false}
                        >
                            {orderFlowType ? orderFlowType === 'inflow' ? 'First Mile' : 'LastMile' : "Apply filter"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomMenu}>
                            <Dropdown.Item eventKey="">Remove filter</Dropdown.Item>
                            <Dropdown.Item eventKey="inflow" active={orderFlowType === "inflow"}>First Mile</Dropdown.Item>
                            <Dropdown.Item eventKey="outflow" active={orderFlowType === "outflow"}>Last Mile</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row >
            <hr />
            <div style={{ display: 'flex' }}>
                {<Pagination style={{ marginRight: '20px' }}>
                    <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || fetching} />
                    <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || fetching} />
                    {page - 2 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                    {page - 1 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                    <Pagination.Item active>{page}</Pagination.Item>
                    {totalPages >= page + 1 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                    {totalPages >= page + 2 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                    <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || fetching} />
                    <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || fetching} />
                </Pagination>
                }
                <div style={{ height: '38px', flex: 1 }}>
                    <input style={{ height: '100%' }} type="text" className='form-control' placeholder='Search order ID, phone number or LR number' value={searchString} onChange={e => setSearchString(e.currentTarget.value)} />
                </div>
                <div style={{ marginLeft: '10px', marginTop: '-22px' }}>
                    <div style={{ fontWeight: 800, fontSize: '10px', paddingTop: '8px' }}><FontAwesomeIcon icon={faFilter} />&nbsp;Filter by Date:</div>
                    <div style={{ display: 'flex' }}>
                        <Form.Group controlId="filter">
                            <Form.Control style={{ cursor: 'pointer', borderColor: startDate ? '#0d6efd' : undefined, color: startDate ? '#0d6efd' : undefined, borderRadius: '0.2rem' }} type="date" value={startDate} disabled={fetching} onChange={e => setStartDate(e.currentTarget.value)} />
                        </Form.Group>
                        <div>&nbsp;To</div>
                        <Form.Group className='ms-3' controlId="filter">
                            <Form.Control style={{ cursor: 'pointer', borderColor: endDate ? '#0d6efd' : undefined, color: endDate ? '#0d6efd' : undefined, borderRadius: '0.2rem' }} type="date" value={endDate} disabled={fetching} onChange={e => setEndDate(e.currentTarget.value)} />
                        </Form.Group>
                    </div>
                </div>
            </div >
            {sortedResults && <OrderTable superuser={adminUser.displayName == 'super'} sortType={sortType} setSortType={setSortType} setSortedResults={setSortedResults} sortedResults={sortedResults} orderType={orderType} firstMileStatus={firstMileStatus} lastMileStatus={lastMileStatus} midMileStatus={midMileStatus} pendingOrderType={pendingOrderType} />
            }

            {
                <Pagination>
                    <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || fetching} />
                    <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || fetching} />
                    {page - 2 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                    {page - 1 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                    <Pagination.Item active>{page}</Pagination.Item>
                    {totalPages >= page + 1 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                    {totalPages >= page + 2 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                    <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || fetching} />
                    <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || fetching} />
                </Pagination>}
            {/* <Button disabled={fetching || allOrdersFound} onClick={() => nextPage()} variant='secondary'>{refreshIcon}&nbsp;{allOrdersFound ? "No older orders" : (fetching ? "Loading" : "Load Older Orders")}</Button> */}
        </div>
    )
}
