import axios from "axios";
import { checkLogin } from "../../../utils/firebase";

const baseUrl = process.env.REACT_APP_BASE_URL

export const fetchAllBusinessOrdersAPI = async function ({ page, limit, searchString }) {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/business/getAllOrders/admin?page=${page}&limit=${limit}&searchString=${searchString}`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return res
    } catch (error) {
        console.log(error);
    }
}

export const fetchBusinessUserOrdersAPI = async function (uid) {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/business/getOrders/admin`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify({
                user: uid
            })
        }).then(res => res.json());
        return res
    } catch (error) {
        console.log(error);
    }
}

export const fetchSingleBusinessUserOrderAPI = async function (orderID) {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/business/getSingleOrder/${orderID}/admin`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return res
    } catch (error) {
        console.log(error);
    }
}

export const updateBusinessPanOrderStatusAPI = async function (reqBody) {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/business/pan-india/updateOrder/admin`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const getAllPendingOrdersAPI = async () => {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl}/business/getShipperPendingOrders/admin`,
            method: "GET",
            headers: {
                Accept: "application/json",
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}


export const searchHypShippersAPI = async (reqBody) => {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl}/admin/b2b/hyperlocal/search`,
            method: "POST",
            headers: {
                Accept: "application/json",
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            data: reqBody
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export const searchPanShippersAPI = async (reqBody) => {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl}/admin/b2b/pan-india/search`,
            method: "POST",
            headers: {
                Accept: "application/json",
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            data: reqBody
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export const allotExpressShipperAPI = async (reqBody) => {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl}/admin/b2b/pan-india/allotShipper`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            data: reqBody
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.data })
            });
    });
}

export const allotManualExpressShipperAPI = async (reqBody) => {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl}/admin/b2b/pan-india/allotManualShipper`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            data: reqBody
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.data })
            });
    });
}

export const allotHypShipperAPI = async (reqBody) => {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl}/admin/b2b/hyperlocal/allotShipper`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            data: reqBody
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.data })
            });
    });
}