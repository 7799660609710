import React from 'react'
import { Container, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom';

export default function Cities() {
    const navigate = useNavigate()
    return (
        <Container className='mt-3'>
            <ToggleButtonGroup size='lg' type="radio" name="options" defaultValue={1}>
                <ToggleButton id="tbg-radio-2" value={2} onClick={e => navigate('details')}>
                    All Cities
                </ToggleButton>
                <ToggleButton id="tbg-radio-1" value={1} onClick={e => navigate('addCity')}>
                    Add City
                </ToggleButton>
            </ToggleButtonGroup>
            <Outlet />
        </Container>
    )
}
