import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { addSupportPhoneNumbersAPI, callToConnectTwoNumbersAPI, getBulkCallDetailsAPI, getSupportPhoneNumbersAPI } from '../../api/general';
import { addWhatsappTemplateAPI, getWhatsappTemplatesAPI } from '../../api/misc';

const initialState = {
    fetching: false,
    toggling: false,
    supportPhoneNumbers: [],
    whatsappTemplates: [],
    fetchingCallDetails: false,
    callDetailsList: [],
    hasNextCallDetails: true,
    exotelNextPageUri: undefined
}


export const callToConnectTwoNumbers = createAsyncThunk('/connect-call', async (data, { rejectWithValue }) => {
    const response = await callToConnectTwoNumbersAPI(data);
    if (response.success) return response.data
    return rejectWithValue(response.message ? response.message : 'Opps there seems to be an error')
})

export const getBulkCallDetails = createAsyncThunk('/bulk-call-details', async (queryParams, { rejectWithValue }) => {
    const response = await getBulkCallDetailsAPI(queryParams);
    if (response.success) return response.data
    return rejectWithValue(response.message ? response.message : 'Opps there seems to be an error')
})

export const getSupportPhoneNumbers = createAsyncThunk('/get-support-number', async (data, { rejectWithValue }) => {
    const response = await getSupportPhoneNumbersAPI();
    if (response.success) return response.data
    return rejectWithValue(response.message ? response.message : 'Opps there seems to be an error')
})

export const addSupportPhoneNumbers = createAsyncThunk('/add-support-number', async (data, { rejectWithValue }) => {
    const response = await addSupportPhoneNumbersAPI(data);
    if (response.success) return response.data
    return rejectWithValue(response.message ? response.message : 'Opps there seems to be an error')
})


export const getWhatsappTemplates = createAsyncThunk('/get-whatsapp-templates', async (data, { rejectWithValue }) => {
    const response = await getWhatsappTemplatesAPI();
    if (response?.response?.data?.success) return response.response.data?.data
    return rejectWithValue(response.response?.data?.message ? response.response?.data?.message : 'Opps there seems to be an error')
})

export const addWhatsappTemplate = createAsyncThunk('/add-whatsapp-template', async (templateCreationPayload, { rejectWithValue }) => {
    const response = await addWhatsappTemplateAPI(templateCreationPayload);
    if (response?.response?.success) return response.response?.data
    return rejectWithValue(response?.response?.message ? response?.response?.message : 'Opps there seems to be an error')
})


export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        refreshCallDetails: (state) => {
            state.callDetailsList = []
            state.exotelNextPageUri = undefined
            state.hasNextCallDetails = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(callToConnectTwoNumbers.pending, (state) => {
                state.fetching = true
            })
            .addCase(callToConnectTwoNumbers.fulfilled, (state, action) => {
                state.fetching = false
            })
            .addCase(callToConnectTwoNumbers.rejected, (state, action) => {
                state.fetching = false
            })

            .addCase(getBulkCallDetails.pending, (state) => {
                state.fetchingCallDetails = true
            })
            .addCase(getBulkCallDetails.fulfilled, (state, action) => {
                state.fetchingCallDetails = false
                state.callDetailsList.push(...action.payload.Calls)
                if (action.payload.Metadata) {
                    const { NextPageUri } = action.payload.Metadata
                    let urlParams = new URLSearchParams(NextPageUri);
                    state.exotelNextPageUri = urlParams.get('After');
                    state.hasNextCallDetails = true
                } else {
                    state.hasNextCallDetails = false
                }
            })
            .addCase(getBulkCallDetails.rejected, (state, action) => {
                state.fetchingCallDetails = false
            })

            .addCase(getSupportPhoneNumbers.pending, (state) => {
                state.fetching = true
            })
            .addCase(getSupportPhoneNumbers.fulfilled, (state, action) => {
                state.fetching = false
                state.supportPhoneNumbers = action.payload
            })
            .addCase(getSupportPhoneNumbers.rejected, (state, action) => {
                state.fetching = false
            })

            .addCase(addSupportPhoneNumbers.pending, (state) => {
                state.fetching = true
            })
            .addCase(addSupportPhoneNumbers.fulfilled, (state, action) => {
                state.fetching = false
                state.supportPhoneNumbers.push(action.payload)
            })
            .addCase(addSupportPhoneNumbers.rejected, (state, action) => {
                state.fetching = false
            })


            .addCase(getWhatsappTemplates.pending, (state) => {
                state.fetching = true
            })
            .addCase(getWhatsappTemplates.fulfilled, (state, action) => {
                state.fetching = false
                state.whatsappTemplates = action.payload
            })
            .addCase(getWhatsappTemplates.rejected, (state, action) => {
                state.fetching = false
            })

            .addCase(addWhatsappTemplate.pending, (state) => {
                state.fetching = true
            })
            .addCase(addWhatsappTemplate.fulfilled, (state, action) => {
                state.fetching = false
                state.whatsappTemplates.unshift(action.payload)
            })
            .addCase(addWhatsappTemplate.rejected, (state, action) => {
                state.fetching = false
            })

    }
})

export const { refreshCallDetails } = generalSlice.actions

// export default bannerSlice.reducer