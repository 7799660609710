import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createApsrtcCityAPI, editApsrtcCityAPI, fetchAllApsrtcCitiesAPI, fetchSingleApsrtcCityAPI } from '../../api/apsrtc_cities';

const initialState = {
    fetching: false,
    fetchingSingle: false,
    cities: [],
    cityDetail: {},
    totalPages: 1,
}

export const fetchAllApsrtcCities = createAsyncThunk('/apsrtc/getAllCities', async ({ page, limit }, { rejectWithValue }) => {
    const response = await fetchAllApsrtcCitiesAPI({ page, limit });
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const fetchSingleApsrtcCity = createAsyncThunk('/apsrtc/getSingleCity', async (queryParams, { rejectWithValue }) => {
    const response = await fetchSingleApsrtcCityAPI(queryParams);
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const createApsrtcCity = createAsyncThunk('/apsrtc/createCity', async (data, { rejectWithValue }) => {
    const response = await createApsrtcCityAPI(data);
    if (response.success) return response.data
    rejectWithValue(response.message)
})

export const editApsrtcCity = createAsyncThunk('/apsrtc/editCity', async (updateBody, { rejectWithValue }) => {
    const response = await editApsrtcCityAPI(updateBody);
    if (response.success) return response.data
    rejectWithValue(response.message)
})


export const apsrtcCitySlice = createSlice({
    name: 'apsrtcCitySlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllApsrtcCities.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(fetchAllApsrtcCities.fulfilled, (state, action) => {
                state.fetching = false
                const { cities, totalPages } = action.payload
                state.cities = cities
                state.totalPages = totalPages
            })
            .addCase(fetchAllApsrtcCities.rejected, (state, action) => {
                state.fetching = false
            })

            .addCase(fetchSingleApsrtcCity.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(fetchSingleApsrtcCity.fulfilled, (state, action) => {
                state.fetching = false
                state.cityDetail = action.payload
            })
            .addCase(fetchSingleApsrtcCity.rejected, (state, action) => {
                state.fetching = false
            })

            .addCase(createApsrtcCity.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(createApsrtcCity.fulfilled, (state, action) => {
                state.fetching = false
                state.cityDetail = action.payload
                state.cities.unshift(action.payload)
            })
            .addCase(createApsrtcCity.rejected, (state, action) => {
                state.fetching = false
            })

            .addCase(editApsrtcCity.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(editApsrtcCity.fulfilled, (state, action) => {
                state.fetching = false
                state.cityDetail = action.payload
                for (let i = 0; i < state.cities.length; i++) {
                    if (state.cities[i].city_id == action.payload.city_id) {
                        console.log("Matched")
                        console.log(action.payload)
                        state.cities[i] = action.payload
                        break;
                    }
                }
            })
            .addCase(editApsrtcCity.rejected, (state, action) => {
                state.fetching = false
            })
    }
})