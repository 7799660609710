import { checkLogin } from "../../../utils/firebase";

const baseUrl = process.env.REACT_APP_BASE_URL

export const createBusinessWalletAPI = async function (accessToken) {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

        const res = await fetch(`${baseUrl}/business/createWallet`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'admin-authorization': 'Bearer ' + authToken,
                'Content-type': 'application/json'
            }
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error)
    }
}

export const rechargeBusinessWalletAPI = async function (reqBody) {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

        var rawBody = JSON.stringify({
            amount: reqBody.amount,
            user: reqBody.user,
            admin: reqBody.admin
        });

        var requestConfigOptions = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': 'Bearer ' + authToken,
            },
            body: rawBody
        };

        const res = fetch(`${baseUrl}/wallet/recharge/admin`, requestConfigOptions)
            .then(response => response.json())
        return res
    } catch (error) {
        console.error(error)
    }
}

export const getBusinessUserWalletTransactionsAPI = async function ({ uid, page, transactionType = undefined }) {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/wallet/getTransactions/admin?user=${uid}&page=${page}&transactionType=${transactionType}`, {
            method: 'GET',
            headers: {
                'admin-authorization': 'Bearer ' + authToken,
            }
        }).then(res => res.json())
        return res
    } catch (error) {
        console.error(error)
    }
}