import React from 'react'
import Charges from '../Charges'

export default function Hyp4WheelerCharges() {
    return (
        <div style={{ padding: '30px' }}>
            <h4>Hyperlocal 4 wheeler platform charges</h4>
            <hr />
            <Charges serviceType='hyperlocal_4w' />
        </div>
    )
}
