import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchAllHyperlocal2WheelerAPI, fetchAllHyperlocal3WheelerAPI, fetchAllHyperlocal4WheelerAPI, fetchAllHyperlocalOrdersAPI, fetchHyperlocalOrderByIdAPI, updateHyperlocalOrderAPI } from '../../api/orders/hyperlocalorders'
import { getAllImportOrdersAPI, getSingleImportOrderAPI, updateImportOrderAPI } from '../../api/orders/importorders'
import { createManualInternationalOrderAPI, fetchInternationalOrderAPI, fetchInternationalOrderByIdAPI, updateInternationalOrderAPI } from '../../api/orders/internationalorders'
import { createExpressDeliveryOrderAPI, fetchExpressDeliveryOrdersAPI, getSingleExpressOrderAPI, updateExpressOrderAPI } from '../../api/orders/panindiaorders/expressdeliveryorders'
import { fetchRentalOrdersAPI, fetchRentalOrderByIdAPI, updateRentalOrderAPI } from '../../api/orders/rentalorders'
import { fetchAllC2cRequestOrdersForAdminAPI } from '../../api/orders/requestorders'

const initialState = {
    fetching: false,
    orders: [],
    requestOrders: [],
    orderDetails: {},
    totalPages: 1,
}

export const createExpressDelOrder = createAsyncThunk('/orders/create/express-delivery', async (data, { rejectWithValue }) => {
    const res = await createExpressDeliveryOrderAPI(data)
    if (res.success) return res.data
    return rejectWithValue('There seems to be an error while creating an express order')
})

export const updateExpressOrder = createAsyncThunk('/orders/update/express-delivery', async ({ id, reqBody }, { rejectWithValue }) => {
    const res = await updateExpressOrderAPI(id, reqBody)
    if (res.success) return res.data
    return rejectWithValue('There seems to be an error while updating an express order')
})

export const fetchExpressDeliveryOrders = createAsyncThunk('/orders/express-delivery', async (obj, { rejectWithValue }) => {
    const res = await fetchExpressDeliveryOrdersAPI()
    if (res.success) return res.data
    return rejectWithValue('There seems to be an error while fetching express delivery orders')
})

export const fetchSingleExpressDeliveryOrder = createAsyncThunk('/orders/getSignleExpressOrder', async (id, { rejectWithValue }) => {
    const res = await getSingleExpressOrderAPI(id)
    if (res.success) return res.data
    return rejectWithValue('There seems to be an error while fetching the order')
})

export const fetchImportOrders = createAsyncThunk('/orders/import', async (obj, { rejectWithValue }) => {
    const res = await getAllImportOrdersAPI()
    if (res.success) return res.data;
    return rejectWithValue('Oops there seems to be an error')
})

export const fetchImportOrderById = createAsyncThunk('/orders/getImportOrderDetail', async (id, { rejectWithValue }) => {
    const res = await getSingleImportOrderAPI(id)
    if (res.success) return res.data;
    return rejectWithValue('Oops there seems to be an error')
})

export const updateImportOrder = createAsyncThunk('/orders/update/importOrder', async ({ id, data }, { rejectWithValue }) => {
    const res = await updateImportOrderAPI(id, data)
    if (res.success) return res.data;
    return rejectWithValue('Oops there seems to be an error')
})

export const fetchInternationalOrders = createAsyncThunk('/orders/international', async (obj, { rejectWithValue }) => {
    const res = await fetchInternationalOrderAPI()
    if (res.success) return res.data;
    return rejectWithValue('Oops there seems to be an error')
})

export const fetchInternationalOrderById = createAsyncThunk(`/order/getInternationalOrderDetail`, async (orderId, { rejectWithValue }) => {
    const res = await fetchInternationalOrderByIdAPI(orderId)
    if (res) return res
    return rejectWithValue('Oops there seems to be an error')
})

export const updateInternationalOrder = createAsyncThunk('/order/international/update', async ({ id, body }, { rejectWithValue }) => {
    const res = await updateInternationalOrderAPI(id, body)
    if (res.success) return res.message
    return rejectWithValue("Oops there seems to be an error")
})

export const createManualInternationalOrder = createAsyncThunk('/order/international/add', async ({ data }, { rejectWithValue }) => {
    const res = await createManualInternationalOrderAPI(data)
    if (res.success) return { message: res.message, data: res.data }
    return rejectWithValue("Oops there seems to be an error while creating an order")
})

export const fetchRentalOrders = createAsyncThunk('/orders/rentalorders', async (obj, { rejectWithValue }) => {
    const res = await fetchRentalOrdersAPI();
    if (res) return res.data;
    return rejectWithValue('Oops there seems to be an error')
})

export const fetchC2CRequestOrders = createAsyncThunk('/orders/c2c/request-orders', async (obj, { rejectWithValue }) => {
    const res = await fetchAllC2cRequestOrdersForAdminAPI();
    if (res) return res.data;
    return rejectWithValue('Oops there seems to be an error')
})

export const fetchRentalOrdersById = createAsyncThunk('/order/getRentalOrderDetail', async (id, { rejectWithValue }) => {
    const res = await fetchRentalOrderByIdAPI(id)
    if (res) {
        // let data = [];
        // data.push(res)
        // return data
        return res.data
    }
    return rejectWithValue("Oops there seems to be an error")
})

export const updateRentalOrder = createAsyncThunk('/order/rental/update', async ({ ordernum, data }, { rejectWithValue }) => {
    const res = await updateRentalOrderAPI(ordernum, data)
    console.log(res);
    if (res.success) return res
    return rejectWithValue("Oops there seems to be an error")
})

export const updateHyOrder = createAsyncThunk('/order/hyperlocal/update', async ({ id, data }, { rejectWithValue }) => {
    const res = await updateHyperlocalOrderAPI(id, data)
    console.log(res);
    if (res.success) return res
    return rejectWithValue("Oops there seems to be an error")
})

export const fetchAllHyperlocalOrders = createAsyncThunk('/orders/hyperlocal', async (obj, { rejectWithValue }) => {
    const res = await fetchAllHyperlocalOrdersAPI();
    if (res.success) return res.data
    return rejectWithValue("Oops there seems to be an error")
})

export const fetchAllHyperlocal2Wheeler = createAsyncThunk('/orders/hyperlocal2Wheeler', async (obj, { rejectWithValue }) => {
    const res = await fetchAllHyperlocal2WheelerAPI();
    if (res.success) return res.data
    rejectWithValue("Oops there seems to be an error")
})

export const fetchAllHyperlocal3Wheeler = createAsyncThunk('/orders/hyperlocal3Wheeler', async (obj, { rejectWithValue }) => {
    const res = await fetchAllHyperlocal3WheelerAPI();
    if (res.success) return res.data
    rejectWithValue("Oops there seems to be an error")
})

export const fetchAllHyperlocal4Wheeler = createAsyncThunk('/orders/hyperlocal4Wheeler', async (obj, { rejectWithValue }) => {
    const res = await fetchAllHyperlocal4WheelerAPI();
    if (res.success) return res.data
    rejectWithValue("Oops there seems to be an error")
})

export const fetchHyperlocalOrderById = createAsyncThunk('/order/getHyperlocalOrderDetail', async (id, { rejectWithValue }) => {
    const res = await fetchHyperlocalOrderByIdAPI(id)
    console.log(res)
    if (res.success) {
        // let data = [{ ...res.data }]
        // return data
        console.log(res)
        return res.data
    }
    rejectWithValue("Oops there seems to be an error")
})


export const orderSlice = createSlice({
    name: 'order',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchImportOrderById.pending, (state) => {
                state.fetching = true
                state.orders = []
            })
            .addCase(fetchImportOrderById.fulfilled, (state, action) => {
                state.fetching = false
                state.orderDetails = action.payload
            })
            .addCase(fetchImportOrderById.rejected, (state, action) => {
                state.fetching = false
                console.log(action.payload)
                alert(action.payload)
            })
            .addCase(updateImportOrder.pending, (state) => {
                state.fetching = true
                state.orders = []
            })
            .addCase(updateImportOrder.fulfilled, (state, action) => {
                state.fetching = false
                state.orderDetails = action.payload
            })
            .addCase(updateImportOrder.rejected, (state, action) => {
                state.fetching = false
                console.log(action.payload)
                alert(action.payload)
            })
            .addCase(fetchImportOrders.pending, (state) => {
                state.fetching = true
                state.orders = []
            })
            .addCase(fetchImportOrders.fulfilled, (state, action) => {
                state.fetching = false
                state.orders = action.payload
            })
            .addCase(fetchImportOrders.rejected, (state, action) => {
                state.fetching = false
                console.log(action.payload)
                alert(action.payload)
            })
            .addCase(fetchInternationalOrders.pending, (state) => {
                state.fetching = true
                state.orders = []
            })
            .addCase(fetchInternationalOrders.fulfilled, (state, action) => {
                state.fetching = false
                state.orders = action.payload
            })
            .addCase(fetchInternationalOrders.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchInternationalOrderById.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchInternationalOrderById.fulfilled, (state, action) => {
                state.fetching = false
                state.orderDetails = action.payload.data
            })
            .addCase(fetchInternationalOrderById.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(updateInternationalOrder.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateInternationalOrder.fulfilled, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(updateInternationalOrder.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(createManualInternationalOrder.pending, (state) => {
                state.fetching = true
            })
            .addCase(createManualInternationalOrder.fulfilled, (state, action) => {
                state.fetching = false
                alert(action.payload.message)
            })
            .addCase(createManualInternationalOrder.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchRentalOrders.pending, (state) => {
                state.fetching = true
                state.orders = []
            })
            .addCase(fetchRentalOrders.fulfilled, (state, action) => {
                state.fetching = false
                state.orders = action.payload
            })
            .addCase(fetchRentalOrders.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchRentalOrdersById.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchRentalOrdersById.fulfilled, (state, action) => {
                state.fetching = false
                state.orderDetails = action.payload
            })
            .addCase(fetchRentalOrdersById.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(updateRentalOrder.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateRentalOrder.fulfilled, (state, action) => {
                state.fetching = false
                alert("Rental order updated");
            })
            .addCase(updateRentalOrder.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchAllHyperlocalOrders.pending, (state) => {
                state.fetching = true
                state.orders = []
            })
            .addCase(fetchAllHyperlocalOrders.fulfilled, (state, action) => {
                state.fetching = false
                state.orders = action.payload
            })
            .addCase(fetchAllHyperlocalOrders.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchAllHyperlocal2Wheeler.pending, (state) => {
                state.fetching = true
                state.orders = []
            })
            .addCase(fetchAllHyperlocal2Wheeler.fulfilled, (state, action) => {
                state.fetching = false
                state.orders = action.payload
            })
            .addCase(fetchAllHyperlocal2Wheeler.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchAllHyperlocal3Wheeler.pending, (state) => {
                state.fetching = true
                state.orders = []
            })
            .addCase(fetchAllHyperlocal3Wheeler.fulfilled, (state, action) => {
                state.fetching = false
                state.orders = action.payload
            })
            .addCase(fetchAllHyperlocal3Wheeler.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchAllHyperlocal4Wheeler.pending, (state) => {
                state.fetching = true
                state.orders = []
            })
            .addCase(fetchAllHyperlocal4Wheeler.fulfilled, (state, action) => {
                state.fetching = false
                state.orders = action.payload
            })
            .addCase(fetchAllHyperlocal4Wheeler.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchHyperlocalOrderById.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchHyperlocalOrderById.fulfilled, (state, action) => {
                state.fetching = false
                state.orderDetails = action.payload
            })
            .addCase(fetchHyperlocalOrderById.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(updateHyOrder.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateHyOrder.fulfilled, (state, action) => {
                state.fetching = false
            })
            .addCase(updateHyOrder.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(createExpressDelOrder.pending, (state) => {
                state.fetching = true
            })
            .addCase(createExpressDelOrder.fulfilled, (state, action) => {
                state.fetching = false
                alert("Order created successfully")
            })
            .addCase(createExpressDelOrder.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchExpressDeliveryOrders.pending, (state) => {
                state.fetching = true
                state.orders = []
            })
            .addCase(fetchExpressDeliveryOrders.fulfilled, (state, action) => {
                state.fetching = false
                state.orders = action.payload
            })
            .addCase(fetchExpressDeliveryOrders.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchSingleExpressDeliveryOrder.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchSingleExpressDeliveryOrder.fulfilled, (state, action) => {
                state.fetching = false
                state.orderDetails = action.payload
            })
            .addCase(fetchSingleExpressDeliveryOrder.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(updateExpressOrder.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateExpressOrder.fulfilled, (state, action) => {
                state.fetching = false
            })
            .addCase(updateExpressOrder.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchC2CRequestOrders.pending, (state) => {
                state.fetching = true
                state.requestOrders = []
            })
            .addCase(fetchC2CRequestOrders.fulfilled, (state, action) => {
                state.fetching = false
                const { totalPages, orders } = action.payload
                state.requestOrders = orders
                state.totalPages = totalPages
            })
            .addCase(fetchC2CRequestOrders.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
    }
})