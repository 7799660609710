import { Button, Form, Row, Col, Spinner } from 'react-bootstrap'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";
import { addHyShipper } from '../../app/features/shipper/shipperSlice';
import { backButtonIcon } from '../../FontAwesome';
import AmazonS3Upload from '../AmazonS3Upload';

export default function AddNewHyShipper() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { fetching } = useSelector(state => state.shipper)

    const { register, handleSubmit, watch, setValue, control, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data)
        if (!data.logoURL) {
            alert("Please upload shipper logo")
        }
        else {
            dispatch(addHyShipper(data))
                .then(() => resetData())
        }
    }

    const resetData = () => {
        setValue('name', '')
        setValue('company', '')
        setValue('address', '')
        setValue('phone', '')
        setValue('email', '')
        setValue('desc', '')
        setValue('logoURL', '')
    }

    return (
        <div style={{ padding: '30px' }}>
            <h3 style={{ marginBottom: '30px' }}>Add new hyperlocal shipper</h3>
            {/* <Button variant='primary' className='me-2' onClick={e => { navigate(-1) }}>{backButtonIcon}&nbsp;Back</Button> */}
            <Button onClick={() => navigate('/shipper/hyperlocalShipper')}>All Shippers</Button>
            <hr />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter name" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="company"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter company" required />
                                </Form.Group>
                            )}
                        />

                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="address"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter address" />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="phone"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Contact number</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter contact" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" value={value} onChange={onChange} placeholder="Enter email" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="desc"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="desc">
                                    <Form.Label>Desc</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter desc" />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="logoURL"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="logoURL">
                                    <Form.Label>LogoURL</Form.Label>
                                    <AmazonS3Upload bucket='shipmantra-assets' S3Folder='3rd-party-logo' setFileName={onChange} />
                                    {/* <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter logo url" required /> */}
                                </Form.Group>
                            )}
                        />
                    </Col>
                </Row>
                <hr />
                <Button type='submit' disabled={fetching}>
                    Add shipper&nbsp;
                    {fetching && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />}
                </Button>
            </Form>
        </div>
    )
}
