import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Container, Button, Table, Spinner } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchAllReviews } from '../../app/features/reviews/reviewSlice'
import { refreshIcon, sortIcon, editIcon, sortAscIcon, sortDescIcon } from '../../FontAwesome'
import Loader from '../Loader'

export default function Customers() {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { fetching, allReviews } = useSelector(state => state.review)

    const [sortType, setSortType] = useState("updatedAt-desc")
    const [sortedResults, setSortedResults] = useState(allReviews ? allReviews : [])

    useEffect(() => {
        dispatch(fetchAllReviews())
    }, [])



    const handleSort = (type) => {
        const order = sortType.split("-")[1]
        if (order === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (order === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        const [attr, type] = sortType.split("-")
        let temp = [...allReviews]
        if (attr === "updatedAt" || attr === "createdAt") {
            if (type === "asc") {
                temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) =>
                    new Date(b[attr]) - new Date(a[attr])
                )
                setSortedResults(temp)
            }
        } else {
            if (type === "asc") {
                temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                setSortedResults(temp)
            }
        }
    }, [sortType, fetching, allReviews])


    if (fetching) return (
        <Loader />
    )

    return (
        <Container className='mt-3'>
            <hr />
            <div className='ms-auto text-end mb-3'>
                <Button variant="secondary" onClick={e => { dispatch(fetchAllReviews()) }}>
                    <span>{refreshIcon}</span>&nbsp;
                    Refresh
                </Button>
            </div>
            <LoadingOverlay
                active={false}
                spinner
                text='Disabling.....'
            >
                <div style={{ height: '100%', overflowY: 'auto' }}>
                    <Table striped bordered hover responsive className='mt-3'>
                        <thead style={{ verticalAlign: 'middle' }}>
                            <tr>
                                <th>Approved</th>
                                <th>Name</th>
                                <th>User Type</th>
                                <th>Service Type</th>
                                <th>Rating</th>
                                <th className='col-4'>Review</th>
                                <th>
                                    Created At&nbsp;
                                    {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                                    {
                                        (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                                    }
                                    {
                                        (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                                    }
                                </th>
                                <th>
                                    Updated At&nbsp;
                                    {sortType.split("-")[0] !== "updatedAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortIcon}</span>}
                                    {
                                        (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "asc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortAscIcon}</span>
                                    }
                                    {
                                        (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "desc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortDescIcon}</span>
                                    }
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortedResults.map((review, index) => {
                                    const createdAtDateString = new Date(review.createdAt);
                                    const createdAtDate = createdAtDateString.toDateString() + ", " + createdAtDateString.toLocaleTimeString();
                                    const updatedAtDateString = new Date(review.updatedAt);
                                    const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {
                                                    review.approved ?
                                                        <FontAwesomeIcon style={{ color: 'green' }} icon={faCircleCheck} /> :
                                                        <FontAwesomeIcon style={{ color: 'red' }} icon={faCircleExclamation} />
                                                }
                                            </td>
                                            <td>{review.name}</td>
                                            <td>{review.usertype}</td>
                                            <td>{review.servicetype}</td>
                                            <td>{review.rating}</td>
                                            <td>{review.content}</td>
                                            <td>{createdAtDate}</td>
                                            <td>{updatedAtDate}</td>
                                            <td>
                                                <Button variant="primary" onClick={() => navigate(`/reviews/${review._id}`)}>{editIcon}</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </LoadingOverlay>
        </Container>
    )
}
