import React from 'react'
import { Col, Row, Form, Modal } from 'react-bootstrap'

export default function EnquiryDetail({ show, handleClose, enquiry }) {
    const restricedKeys = ["__v", "updatedAt", "createdAt", "_id"]

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                Enquiry details
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {
                        Object.keys(enquiry).map(key => {
                            let keyName = key.replace(/[A-Z]/g, letter => " " + `${letter.toLowerCase()}`)
                            keyName = keyName.replace(/[-_]/g, " ")
                            return (
                                <>
                                    {!restricedKeys.includes(key) && <Col sm={12} md={6}>
                                        <Form.Group className="mb-3" controlId="orderID">
                                            <Form.Label style={{ textTransform: 'capitalize' }}>{keyName}</Form.Label>
                                            <Form.Control value={enquiry[key]} type="text" placeholder="Order id" readOnly />
                                        </Form.Group>
                                    </Col>}
                                </>
                            )
                        })
                    }
                </Row>
            </Modal.Body>
        </Modal>
    )
}
