import { checkLogin } from "../../../utils/firebase";

const baseUrl = process.env.REACT_APP_BASE_URL

export const fetchAllAPSRTCOrders = async (queryParams) => {
    let normalQueryParams = queryParams;
    let statusArr = queryParams.orderStatus ? queryParams.orderStatus : []
    let flagsArr = queryParams.flags ? queryParams.flags : []
    let queryString = Object.keys(normalQueryParams)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(normalQueryParams[k]))
        .join('&');

    let statusQueryString = statusArr
        .map(status => "orderStatus[]=" + encodeURIComponent(status))
        .join('&');

    let flagsQueryString = flagsArr
    .map(flag => "flags[]=" + encodeURIComponent(flag))
    .join('&');
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

        const res = await fetch(`${baseUrl}/admin/apsrtc-booking/get-all-orders?` + queryString + "&" + statusQueryString + "&" + flagsQueryString, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            // queryParams: queryParams
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const fetchAllAPSRTCOrdersWithoutPagingAPI = async (queryParams) => {
    let normalQueryParams = queryParams;
    let statusArr = queryParams.orderStatus ? queryParams.orderStatus : []
    let queryString = Object.keys(normalQueryParams)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(normalQueryParams[k]))
        .join('&');

    let statusQueryString = statusArr
        .map(status => "orderStatus[]=" + encodeURIComponent(status))
        .join('&');
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

        const res = await fetch(`${baseUrl}/admin/apsrtc-booking/get-all-orders-without-paging?` + queryString + "&" + statusQueryString, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            // queryParams: queryParams
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const updateAPSRTCOrderAPI = async (updateBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin/apsrtc-booking/update-order`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')

            },
            body: JSON.stringify(updateBody)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const uploadApsrtcLrmAPI = async (data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken });

        const res = await fetch(`${baseUrl}/admin/apsrtc-booking/upload-lrm`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
        return res
    } catch (error) {
        console.error(error)
    }
}


export const syncAPSRTCapi = async (lrn,orderid) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin/apsrtc-booking/sync-order`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')

            },
            body: JSON.stringify({lrn:lrn, order_id:orderid})
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}
export const generateAPSRTCapi = async (orderid, start_station_data, end_station_data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin/apsrtc-booking/generate-order`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')

            },
            body: JSON.stringify({start_station_data:start_station_data, order_id:orderid, end_station_data:end_station_data})
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}