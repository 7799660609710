import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { allotExpressShipperAPI, allotHypShipperAPI, allotManualExpressShipperAPI, fetchAllBusinessOrdersAPI, fetchBusinessUserOrdersAPI, fetchSingleBusinessUserOrderAPI, getAllPendingOrdersAPI, searchHypShippersAPI, searchPanShippersAPI, updateBusinessPanOrderStatusAPI } from '../../../api/shipmantrabusiness/orders';


const initialState = {
    fetching: false,
    fetchingAllOrders: false,
    orders: [],
    totalPages: 1,
    fetchingPendingOrders: false,
    orderDetail: {},
    hypSearchShippers: [],
    searchShipperFetching: false,
    intSearchShippers: [],
    panSearchShippers: [],
    bookShipperFetching: false
}

export const fetchAllBusinessOrders = createAsyncThunk('/business/allOrders', async (data, { rejectWithValue }) => {
    const { page = 1, limit = 10, searchString = '' } = data
    const response = await fetchAllBusinessOrdersAPI({ page, limit, searchString });
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const fetchBusinessUserOrders = createAsyncThunk('/business/userOrders', async (uid, { rejectWithValue }) => {
    const response = await fetchBusinessUserOrdersAPI(uid);
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const fetchSingleBusinessOrderDetail = createAsyncThunk('/business/user/singleOrder', async (orderID, { rejectWithValue }) => {
    const response = await fetchSingleBusinessUserOrderAPI(orderID);
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const updateBusinessOrderStatus = createAsyncThunk('/business/user/updatePanOrderStatus', async (reqBody, { rejectWithValue }) => {
    const response = await updateBusinessPanOrderStatusAPI(reqBody);
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const getAllPendingOrders = createAsyncThunk('/business/pending-orders', async (obj, { rejectWithValue }) => {
    const response = await getAllPendingOrdersAPI();
    return response.response
})


//Search shippers
export const searchHypShippers = createAsyncThunk('/business/shippers/hyp-search', async (reqBody, { rejectWithValue }) => {
    const response = await searchHypShippersAPI(reqBody);
    return response.response
})

export const searchPanShippers = createAsyncThunk('/business/shippers/pan-search', async (reqBody, { rejectWithValue }) => {
    const response = await searchPanShippersAPI(reqBody);
    return response.response
})

//Allot shipper
export const allotExpressShipper = createAsyncThunk('/business/express-order/shipper/allot', async (reqBody, { rejectWithValue }) => {
    try {
        const response = await allotExpressShipperAPI(reqBody);
        return response.response
    } catch (error) {
        console.log(error)
        return rejectWithValue(error.error)
    }
})

export const allotManualExpressShipper = createAsyncThunk('/business/express-order/shipper/allot/manual', async (reqBody, { rejectWithValue }) => {
    try {
        const response = await allotManualExpressShipperAPI(reqBody);
        return response.response
    } catch (error) {
        console.log(error)
        return rejectWithValue(error.error)
    }
})

export const allotHypShipper = createAsyncThunk('/business/hyp-order/shipper/allot', async (reqBody) => {
    const response = await allotHypShipperAPI(reqBody);
    return response.response
})


export const businessOrderSlice = createSlice({
    name: 'businessOrders',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBusinessUserOrders.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchBusinessUserOrders.fulfilled, (state, action) => {
                state.fetching = false
                state.orders = action.payload
            })
            .addCase(fetchBusinessUserOrders.rejected, (state) => {
                state.fetching = false
            })

            .addCase(fetchAllBusinessOrders.pending, (state) => {
                state.fetchingAllOrders = true
            })
            .addCase(fetchAllBusinessOrders.fulfilled, (state, action) => {
                state.fetchingAllOrders = false
                state.orders = action.payload.orders
                state.totalPages = action.payload.totalPages
            })
            .addCase(fetchAllBusinessOrders.rejected, (state) => {
                state.fetchingAllOrders = false
            })

            .addCase(fetchSingleBusinessOrderDetail.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchSingleBusinessOrderDetail.fulfilled, (state, action) => {
                state.fetching = false
                state.orderDetail = action.payload
            })
            .addCase(fetchSingleBusinessOrderDetail.rejected, (state) => {
                state.fetching = false
            })

            .addCase(updateBusinessOrderStatus.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateBusinessOrderStatus.fulfilled, (state, action) => {
                state.fetching = false
                state.orderDetail = action.payload
                let tempOrders = state.orders
                for (let index = 0; index < tempOrders.length; index++) {
                    const element = tempOrders[index];
                    if (element?.sm_orderid === action.payload?.sm_orderid) {
                        tempOrders[index] = action.payload
                        break;
                    }
                }
                state.orders = tempOrders
            })
            .addCase(updateBusinessOrderStatus.rejected, (state) => {
                state.fetching = false
            })

            .addCase(getAllPendingOrders.pending, (state) => {
                state.fetchingPendingOrders = true
            })
            .addCase(getAllPendingOrders.fulfilled, (state, action) => {
                state.fetchingPendingOrders = false
                state.orders = action.payload.data
            })
            .addCase(getAllPendingOrders.rejected, (state) => {
                state.fetchingPendingOrders = false
            })

            .addCase(searchHypShippers.pending, (state) => {
                state.searchShipperFetching = true
            })
            .addCase(searchHypShippers.fulfilled, (state, action) => {
                state.searchShipperFetching = false
                state.hypSearchShippers = action.payload.data
            })
            .addCase(searchHypShippers.rejected, (state) => {
                state.searchShipperFetching = false
            })

            .addCase(searchPanShippers.pending, (state) => {
                state.searchShipperFetching = true
            })
            .addCase(searchPanShippers.fulfilled, (state, action) => {
                state.searchShipperFetching = false
                state.panSearchShippers = action.payload.data
            })
            .addCase(searchPanShippers.rejected, (state) => {
                state.searchShipperFetching = false
            })

            .addCase(allotExpressShipper.pending, (state) => {
                state.bookShipperFetching = true
            })
            .addCase(allotExpressShipper.fulfilled, (state, action) => {
                state.bookShipperFetching = false
            })
            .addCase(allotExpressShipper.rejected, (state, action) => {
                state.bookShipperFetching = false
                console.log(action.payload)
            })

            .addCase(allotManualExpressShipper.pending, (state) => {
                state.bookShipperFetching = true
            })
            .addCase(allotManualExpressShipper.fulfilled, (state, action) => {
                state.bookShipperFetching = false
            })
            .addCase(allotManualExpressShipper.rejected, (state, action) => {
                state.bookShipperFetching = false
                console.log(action.payload)
            })

            .addCase(allotHypShipper.pending, (state) => {
                state.bookShipperFetching = true
            })
            .addCase(allotHypShipper.fulfilled, (state, action) => {
                state.bookShipperFetching = false
            })
            .addCase(allotHypShipper.rejected, (state) => {
                state.bookShipperFetching = false
            })
    }
})
