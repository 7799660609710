import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchAllAPSRTCOrdersAPI, fetchAllAPSRTCRequestOrdersAPI, updateApsrtcOrderAPI, updateRequestApsrtcOrderAPI } from '../../api/orders/apsrtc';


const initialState = {
    fetching: false,
    fetchingSingle: false,
    orders: [],
    ordersWithoutPaging: [],
    fetchingPendingOrders: false,
    orderDetail: {},
    totalPages: 1
}

export const fetchAllApsrtcOrders = createAsyncThunk('/apsrtc/all-orders', async (queryParams) => {
    const response = await fetchAllAPSRTCOrdersAPI(queryParams);
    return {
        data: response.data.results,
        page: queryParams.page ? queryParams.page : 1,
        totalPages: response.data.totalPages
    }
})

export const fetchAllApsrtcRequestOrders = createAsyncThunk('/apsrtc/get-all-request-orders', async (queryParams) => {
    const response = await fetchAllAPSRTCRequestOrdersAPI(queryParams);
    return {
        data: response.data.results,
        page: queryParams.page ? queryParams.page : 1,
        totalPages: response.data.totalPages
    }
})

export const updateApsrtcOrder = createAsyncThunk('/apsrtc/update-order', async (updateBody, { rejectWithValue }) => {
    const response = await updateApsrtcOrderAPI(updateBody);
    if (response.success) {
        return {
            orderDetail: response.data
        }
    }
    return rejectWithValue("There was an error while updating the order")
})

export const updateRequestApsrtcOrder = createAsyncThunk('/apsrtc/update-request-order', async (updateBody, { rejectWithValue }) => {
    const response = await updateRequestApsrtcOrderAPI(updateBody);
    if (response.success) {
        return {
            orderDetail: response.data
        }
    }
    return rejectWithValue("There was an error while updating the order")
})

export const apsrtcBookingSlice = createSlice({
    name: 'apsrtcBookingSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllApsrtcOrders.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchAllApsrtcOrders.fulfilled, (state, action) => {
                state.fetching = false
                const { data, totalPages } = action.payload
                state.orders = data
                state.totalPages = totalPages;
            })
            .addCase(fetchAllApsrtcOrders.rejected, (state) => {
                state.fetching = false
            })

            .addCase(fetchAllApsrtcRequestOrders.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchAllApsrtcRequestOrders.fulfilled, (state, action) => {
                state.fetching = false
                const { data, totalPages } = action.payload
                state.orders = data
                state.totalPages = totalPages;
            })
            .addCase(fetchAllApsrtcRequestOrders.rejected, (state) => {
                state.fetching = false
            })


            .addCase(updateApsrtcOrder.pending, (state) => {
                state.fetchingSingle = true
            })
            .addCase(updateApsrtcOrder.fulfilled, (state, action) => {
                state.fetchingSingle = false
                const { orderStatus, sm_orderid } = action.payload.orderDetail;
                // state.fetchingSingle = action.payload
                for (let i = 0; i < state.orders.length; i++) {
                    if (state.orders[i].sm_orderid == sm_orderid) {
                        state.orders[i] = action.payload?.orderDetail;
                        break;
                    }
                }
            })
            .addCase(updateApsrtcOrder.rejected, (state) => {
                state.fetchingSingle = false
            })

            .addCase(updateRequestApsrtcOrder.pending, (state) => {
                state.fetchingSingle = true
            })
            .addCase(updateRequestApsrtcOrder.fulfilled, (state, action) => {
                state.fetchingSingle = false
                const { sm_orderid } = action.payload.orderDetail;
                // state.fetchingSingle = action.payload
                for (let i = 0; i < state.orders.length; i++) {
                    if (state.orders[i].sm_orderid == sm_orderid) {
                        state.orders[i] = action.payload.orderDetail;
                        break;
                    }
                }
            })
            .addCase(updateRequestApsrtcOrder.rejected, (state) => {
                state.fetchingSingle = false
            })
    }
})