import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchHyperlocalOrderById, updateHyOrder } from '../../app/features/order/orderSlice'
import { Container, Button, Row, Col, Spinner, Form } from 'react-bootstrap'
import { backButtonIcon, refreshIcon } from '../../FontAwesome'
import RequestPayment from '../Payment/RequestPayment'
import { useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

export default function HyperlocalOrderDetail() {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const id = params.id

    useEffect(() => {
        dispatch(fetchHyperlocalOrderById(id))
    }, [])

    const { fetching, orders, orderDetails } = useSelector(state => state.order)

    const [tracking_url, setTrackingURL] = useState(orderDetails.tracking_url ? orderDetails?.tracking_url : '')
    const [showPaymentReqModal, setShowPaymentReqModal] = useState(false)


    const handleSubmit = e => {
        e.preventDefault();
        if (!/^https?:\/\//i.test(tracking_url)) {
            setTrackingURL('https://' + tracking_url);
        }
        const state = e.target.state.value
        const payment_status = e.target.payment_status.value
        const reqBody = { state: state, payment_status: payment_status, tracking_url: tracking_url }
        dispatch(updateHyOrder({ id, data: reqBody }))
            .then(unwrapResult)
            .then((res) => {
                alert("Order updated successfully")
                dispatch(fetchHyperlocalOrderById(id))
            })
    }

    useEffect(() => {
        setTrackingURL(orderDetails.tracking_url)
    }, [orderDetails])

    if (fetching) return (
        <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
            <Spinner animation="border" />
        </div>
    )
    // return (
    //     <div>HELLO</div>
    // )

    return (
        <>
            <Container className='my-4'>
                <Button variant='primary' onClick={e => { navigate(-1) }}>{backButtonIcon}&nbsp;Back</Button>
                <div className='w-100 text-end'>
                    <Button variant='secondary' onClick={e => { dispatch(fetchHyperlocalOrderById(id)) }}>{refreshIcon}&nbsp;Refresh</Button>
                </div>
                <Form onSubmit={handleSubmit}>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Label>Order num</Form.Label>
                            <Form.Control name='order_num' type="text" value={orderDetails.sm_orderid ? orderDetails.sm_orderid : orderDetails._id} placeholder="Order number" readOnly />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Label>State</Form.Label>
                            <Form.Select defaultValue={orderDetails?.state} name="state">
                                <option value="Initiated">Initiated</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Placed">Placed</option>
                                <option value="Delivered">Delivered</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="Under Hold">Under Hold</option>
                            </Form.Select>
                        </Col>
                        <Col sm={6}>
                            <Form.Label>Tracking Url</Form.Label>
                            <Form.Control type="text" value={tracking_url} onChange={(e) => setTrackingURL(e.target.value)} placeholder="Tracking url" />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="paymentStatus">
                                <Form.Label>Payment Status</Form.Label>
                                <Form.Select defaultValue={orderDetails.payment_status} name="payment_status">
                                    <option value="Initiated">Initiated</option>
                                    <option value="Successful">Successful</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Failed">Failed</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="shipperId">
                                <Form.Label>Shipper id</Form.Label>
                                <Form.Control type="text" value={orderDetails.shipperID} placeholder="ShipperID" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="user">
                                <Form.Label>User</Form.Label>
                                <Form.Control type="text" value={orderDetails.user} placeholder="User" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" value={orderDetails.city} placeholder="City" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="weight">
                                <Form.Label>Weight</Form.Label>
                                <Form.Control type="text" value={orderDetails.weight} placeholder="Weight" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="cost">
                                <Form.Label>Cost(₹)</Form.Label>
                                <Form.Control type="text" value={orderDetails.cost} placeholder="Cost" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="senderName">
                                <Form.Label>Sender Name</Form.Label>
                                <Form.Control type="text" value={orderDetails.start?.contact?.name} placeholder="Sender name" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="senderContact">
                                <Form.Label>Sender contact</Form.Label>
                                <Form.Control type="text" value={orderDetails.start?.contact?.phone} placeholder="Sender contact" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="receiverName">
                                <Form.Label>Receiver Name</Form.Label>
                                <Form.Control type="text" value={orderDetails.end?.contact?.name} placeholder="Receiver name" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="receiverContact">
                                <Form.Label>Receiver contact</Form.Label>
                                <Form.Control type="text" value={orderDetails.end?.contact?.phone} placeholder="Receiver contact" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="startAddr">
                                <Form.Label>Start Address</Form.Label>
                                <Form.Control type="text" value={orderDetails.start?.address?.fromGoogle?.address} placeholder="Start Address" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="endAddr">
                                <Form.Label>End Address</Form.Label>
                                <Form.Control type="text" value={orderDetails.end?.address?.fromGoogle?.address} placeholder="End Address" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="itemType">
                                <Form.Label>Item Type</Form.Label>
                                <Form.Control type="text" value={orderDetails.item?.length > 0 ? orderDetails.item[0] : ""} placeholder="Item Type" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="itemType">
                                <Form.Label>Order Type</Form.Label>
                                <Form.Control type="text" value={orderDetails.order_type === 'hyperlocal_2w' ? "Two wheeler" : (orderDetails.order_type === 'hyperlocal_3w' ? "Three wheeler" : "Four wheeler")} placeholder="Item Type" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant='primary' type="submit">Update</Button>
                    <Button variant='primary' type="button" style={{ marginLeft: '20px' }} onClick={() => { setShowPaymentReqModal(true) }}>Request Payment</Button>
                </Form>
            </Container >
            <RequestPayment order={orderDetails} show={showPaymentReqModal} handleClose={() => setShowPaymentReqModal(false)} />
        </>
    )
}
