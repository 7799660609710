import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter
} from "react-router-dom";


import { store } from './app/store'
import { Provider } from 'react-redux'




ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {/* <Routes>
          <Route path='/' element={<Login />} />
          <Route exact path='/portal' element={<App />} />
          <Route path='/user/register' element={<AddUser />} />
          <Route path='/orders/newOrders' element={<OrderDetails />} />
          <Route path='/orders/liveOrders' element={<OrderDetails />} />
          <Route path='/admin/banner' element={<Banner />} />
          <Route path='/enquiry' element={<Enquiry />} />
        </Routes> */}
        {/* {localStorage.getItem("email") ? <App /> : <Login />} */}
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
