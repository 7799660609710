import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { loginAPI, registerAPI, getAllAdminUserAPI, disableAdminUserAPI, updateAdminUserAPI } from '../../api/auth'

const fetchAdminUsers = {
    fetching: false,
    allAdminUsers: []
}

const initialState = {
    fetching: false,
    error: false,
    isAuth: false,
    disabling: false,
    updating: false,
    fetchAdminUsers,
    adminUser: {}
}

export const signIn = createAsyncThunk('/adminuser/login', async (obj, { rejectWithValue }) => {
    const response = await loginAPI()
    if (response.success) return response;
    return rejectWithValue(response.message)
})

export const signUp = createAsyncThunk('/adminuser/register', async (reqBody, { rejectWithValue }) => {
    const response = await registerAPI(reqBody)
    if (response.success) return response
    return rejectWithValue(response.message)
})


export const updateAdminUser = createAsyncThunk('/admin/update', async (data, { rejectWithValue }) => {
    const response = await updateAdminUserAPI(data)
    if (response.success) return response
    return rejectWithValue(response.message)
})

export const disableAdminUser = createAsyncThunk('/admin/disable', async (identifier, { rejectWithValue }) => {
    const response = await disableAdminUserAPI(identifier);
    if (response.success) return response
    return rejectWithValue(response.message)
})


export const userSlice = createSlice({
    name: 'adminUser',
    initialState,
    reducers: {
        loginFromSession: (state, action) => {
            state.isAuth = true
            state.adminUser = {
                ...state.adminUser,
                ...action.payload
            }
        },
        logout: (state, action) => {
            state.isAuth = false
            state.fetching = false
            state.error = false
            state.disabling = false
            state.updating = false
            state.fetchAdminUsers = {
                ...fetchAdminUsers
            }
            state.adminUser = {}
            localStorage.removeItem('shipmantra-admin-username')
            localStorage.removeItem('shipmantra-admin-email')
            localStorage.removeItem('shipmantra-admin-role')
            localStorage.removeItem('shipmantra-admin-displayName')
            localStorage.removeItem('shipmantra-admin-token')
        },
        updateProfile: (state, action) => { }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(signIn.fulfilled, (state, action) => {
                const { data } = action.payload
                state.fetching = false
                state.error = false
                state.isAuth = true
                state.adminUser = data
                // state.adminUser.username = data.username ? data.username : ""
                // state.adminUser.email = data.email ? data.email : ""
                // state.adminUser.role = data.role
                // state.adminUser.displayName = data.displayName ? data.displayName : ""
            })
            .addCase(signIn.rejected, (state, action) => {
                state.fetching = false
                state.error = true
                state.isAuth = false
            })
            .addCase(signUp.pending, (state) => {
                state.fetching = true
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.fetching = false
                state.error = false
                alert("User added Successfully")
                // console.log(action.payload);
            })
            .addCase(signUp.rejected, (state, action) => {
                state.fetching = false
                state.error = true
                alert(action.payload)
            })
            .addCase(updateAdminUser.pending, (state) => {
                state.updating = true
            })
            .addCase(updateAdminUser.fulfilled, (state, action) => {
                state.updating = false
                state.error = false
                state.adminUser = action.payload.data
                state.adminUser.username = action.payload.data.username ? action.payload.data.username : ""
                state.adminUser.email = action.payload.data.email ? action.payload.data.email : ""
                state.adminUser.role = action.payload.data.role
                state.adminUser.displayName = action.payload.data.displayName ? action.payload.data.displayName : ""
                alert(action.payload.message)
            })
            .addCase(updateAdminUser.rejected, (state, action) => {
                state.updating = false
                state.error = true
                // alert(action.payload)
                console.log(action.payload);
            })
            .addCase(disableAdminUser.pending, (state) => {
                state.disabling = true
            })
            .addCase(disableAdminUser.fulfilled, (state, action) => {
                state.disabling = false
                state.error = false
                alert(action.payload.message + " " + "Please refresh!!")
                console.log(action.payload);
            })
            .addCase(disableAdminUser.rejected, (state, action) => {
                state.disabling = false
                state.error = true
                alert(action.payload)
                console.log(action.payload);
            })
    }
})

export const { login, logout, updateProfile, loginFromSession } = userSlice.actions
// export default userSlice.reducer