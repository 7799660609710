import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { fetchInternationalOrderById, updateInternationalOrder } from '../../app/features/order/orderSlice';
import { backButtonIcon, refreshIcon } from '../../FontAwesome';
import RequestPayment from '../Payment/RequestPayment';

export default function InternationalOrderDetail() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams();

    const orderId = params.orderId

    const { fetching, orders, orderDetails } = useSelector(state => state.order)

    const [showPaymentReqModal, setShowPaymentReqModal] = useState(false)
    const [tracking_url, setTrackingURL] = useState(orderDetails?.tracking_url ? orderDetails?.tracking_url : '')
    const [dateOfArrival, setDOA] = useState(orderDetails.estimatedCompletionDate ? new Date(orderDetails.estimatedCompletionDate).toISOString().split("T")[0] : '')

    useEffect(async () => {
        dispatch(fetchInternationalOrderById(orderId))
    }, [])


    useEffect(() => {
        if (orderDetails) {
            setTrackingURL(orderDetails?.tracking_url ? orderDetails?.tracking_url : '')
            setDOA(orderDetails.estimatedCompletionDate ? new Date(orderDetails.estimatedCompletionDate).toISOString().split("T")[0] : '')
        }
    }, [orderDetails])

    const handleSubmit = e => {
        if (!/^https?:\/\//i.test(tracking_url)) {
            setTrackingURL('https://' + tracking_url);
        }
        e.preventDefault();
        const state = e.target.state.value
        const payment_status = e.target.payment_status.value
        const data = {
            id: orderId,
            body: {
                state: state,
                payment_status: payment_status,
                tracking_url: tracking_url,
                estimatedCompletionDate: dateOfArrival
            }
        }
        console.log(data);
        dispatch(updateInternationalOrder(data))
            .then(() => dispatch(fetchInternationalOrderById(orderId)))
    }

    if (fetching) return (
        <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
            <Spinner animation="border" />
        </div>
    )

    return (
        <>
            <Container className='my-4'>
                <h4 className='my-3'>International order-{orderDetails.sm_orderid ? orderDetails.sm_orderid : orderDetails._id}</h4>
                {/* <hr /> */}
                {/* <Button variant='primary' onClick={e => { navigate(-1) }}>{backButtonIcon}&nbsp;Back</Button> */}
                {/* <div className='w-100 text-end'>
                    <Button variant='secondary' onClick={e => { dispatch(fetchInternationalOrderById(orderId)) }}>{refreshIcon}&nbsp;Refresh</Button>
                </div> */}
                <div className='d-flex justify-content-between'>
                    <Button variant='primary' onClick={e => { navigate(-1) }}>{backButtonIcon}&nbsp;Back</Button>
                    <Button variant='secondary' onClick={e => { dispatch(fetchInternationalOrderById(orderId)) }}>{refreshIcon}&nbsp;Refresh</Button>
                </div>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Label>State</Form.Label>
                            <Form.Select defaultValue={orderDetails.state} name="state">
                                <option value="Initiated">Order Initiated</option>
                                <option value="Payment confirmed">Payment confirmed</option>
                                <option value="Pick up scheduled">Pick up scheduled</option>
                                <option value="Pick up failed">Pick up failed</option>
                                <option value="Pick up completed">Pick up completed</option>
                                <option value="Processing package">Processing package </option>
                                <option value="Weighing package">Weighing package</option>
                                <option value="Pending full payment">Pending full payment</option>
                                <option value="Full payment done">Full payment done</option>
                                <option value="Customs clearance at origin">Customs clearance at origin</option>
                                <option value="Package dispatched from Origin">Package dispatched from Origin</option>
                                <option value="In-Transit">In-Transit</option>
                                <option value="Placed">Placed</option>
                                <option value="Customs clearance at destination">Customs clearance at destination</option>
                                <option value="Shipment under hold">Shipment under hold</option>
                                <option value="Customs cleared">Customs cleared</option>
                                <option value="Out for delivery">Out for delivery</option>
                                <option value="Delivered">Delivered successfully</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="Under Hold">Under Hold</option>
                            </Form.Select>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="paymentStatus">
                                <Form.Label>Payment Status</Form.Label>
                                <Form.Select defaultValue={orderDetails.payment_status} name="payment_status">
                                    <option value="Initiated">Initiated</option>
                                    <option value="Successful">Successful</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Partial">Partial</option>
                                    <option value="Failed">Failed</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="tracking_url">
                                <Form.Label>Tracking URL</Form.Label>
                                <Form.Control type="text" value={tracking_url} onChange={(e) => setTrackingURL(e.target.value)} placeholder="Tracking url" />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="tracking_url">
                                <Form.Label>Estimated date of arrival</Form.Label>
                                <Form.Control type="date" value={dateOfArrival} onChange={(e) => setDOA(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="shipperId">
                                <Form.Label>Shipper id</Form.Label>
                                <Form.Control type="text" value={orderDetails.shipperID} placeholder="ShipperID" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="orderType">
                                <Form.Label>Order type</Form.Label>
                                <Form.Control type="text" value="Export" placeholder="Order type" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="senderName">
                                <Form.Label>Sender name</Form.Label>
                                <Form.Control type="text" value={orderDetails.start?.contact?.name} placeholder="Sender name" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="shipperId">
                                <Form.Label>Recipient name</Form.Label>
                                <Form.Control type="text" value={orderDetails.end?.contact?.name} placeholder="Sender email" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="senderName">
                                <Form.Label>Sender phone</Form.Label>
                                <Form.Control type="text" value={orderDetails.start?.contact?.phone} placeholder="Sender name" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="shipperId">
                                <Form.Label>Recipient phone</Form.Label>
                                <Form.Control type="text" value={orderDetails.end?.contact?.phone} placeholder="Sender email" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="senderName">
                                <Form.Label>Sender Email</Form.Label>
                                <Form.Control type="text" value={orderDetails.start?.contact?.email} placeholder="Sender name" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="shipperId">
                                <Form.Label>Recipient Email</Form.Label>
                                <Form.Control type="text" value={orderDetails.end?.contact?.email} placeholder="Sender email" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="senderName">
                                <Form.Label>Sender Address</Form.Label>
                                <Form.Control as="textarea" rows={3} value={`${orderDetails.start?.address?.flatno}, ${orderDetails.start?.address?.building},\n${orderDetails.start?.address?.fromGoogle.address}`} placeholder="Sender name" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="shipperId">
                                <Form.Label>Recipient Address</Form.Label>
                                <Form.Control as="textarea" rows={3} value={`${orderDetails.end?.address?.address?.flatno}, ${orderDetails.end?.address?.address?.building}, \n${orderDetails.end?.address?.address?.fromGoogle?.address}`} placeholder="Sender name" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>

                    <hr/>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="user">
                                <Form.Label>User</Form.Label>
                                <Form.Control type="text" value={orderDetails.user} placeholder="User" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" value={orderDetails.city} placeholder="City" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="destination">
                                <Form.Label>Destination</Form.Label>
                                <Form.Control type="text" value={orderDetails.destination} placeholder="Destination" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="cost">
                                <Form.Label>Cost(₹)</Form.Label>
                                <Form.Control type="text" value={orderDetails.cost} placeholder="Cost" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <Form.Group controlId="weight">
                                <Form.Label>Weight</Form.Label>
                                <Form.Control type="text" value={orderDetails.weight} placeholder="Weight" readOnly />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="paymentUrl">
                                <Form.Label>Payment Url</Form.Label>
                                <Form.Control type="text" value={orderDetails.paymentLinkData?.link} placeholder="Payment URL" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant='primary' type="submit">Update</Button>
                    <Button variant='primary' type="button" style={{ marginLeft: '20px' }} onClick={() => { setShowPaymentReqModal(true) }}>Request Payment</Button>
                </Form>
            </Container >
            <RequestPayment order={orderDetails} show={showPaymentReqModal} handleClose={() => setShowPaymentReqModal(false)} />
        </>
    )
}
