import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import EnquiryTable from './EnquiryTable';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { refreshIcon } from '../../FontAwesome';
import { useEffect } from 'react';
import { fetchBusinessEnquiries, fetchGeneralEnquiries, fetchShipperEnquiries } from '../../app/features/enquiries/enquirySlice';
import { unwrapResult } from '@reduxjs/toolkit';

export default function Enquiry() {
    const dispatch = useDispatch();

    const [hasMoreEnquiries, setHasMoreEnquiries] = useState(true)
    const [page, setPage] = useState(1);
    const [allEnquiries, setAllEnquiries] = useState([]);

    const { enquiryType } = useParams();
    const { enquiries, businessEnquiries, shipperEnquiries, fetching } = useSelector(state => state.enquiry);


    useEffect(() => {
        if (enquiryType === 'business-enquiry') {
            setAllEnquiries(businessEnquiries)
        } else if (enquiryType === 'shipper-enquiry') {
            setAllEnquiries(shipperEnquiries)
        } else if (enquiryType === 'general-enquiry') {
            setAllEnquiries(enquiries)
        }
    }, [enquiryType, enquiries, businessEnquiries, shipperEnquiries])

    const fetchEnquiries = async () => {
        if (enquiryType === 'business-enquiry') {
            dispatch(fetchBusinessEnquiries({ page }))
                .then(unwrapResult)
                .then(res => {
                    if (!res.data.next) {
                        setHasMoreEnquiries(false)
                    } else {
                        setHasMoreEnquiries(true)
                    }
                })
        } else if (enquiryType === 'shipper-enquiry') {
            dispatch(fetchShipperEnquiries({ page }))
                .then(unwrapResult)
                .then(res => {
                    if (!res.data.next) {
                        setHasMoreEnquiries(false)
                    } else {
                        setHasMoreEnquiries(true)
                    }
                })
        } else if (enquiryType === 'general-enquiry') {
            dispatch(fetchGeneralEnquiries({ page }))
                .then(unwrapResult)
                .then(res => {
                    if (!res.data.next) {
                        setHasMoreEnquiries(false)
                    } else {
                        setHasMoreEnquiries(true)
                    }
                })
        }
    }

    const nextPage = (e) => {
        setPage(page + 1)
    }

    const refreshEnquiries = async (e) => {
        setPage(1);
        setHasMoreEnquiries(true)
        await fetchEnquiries()
    }

    const refreshData = () => {
        setPage(1)
        setHasMoreEnquiries(true)
    }

    useEffect(async () => {
        setPage(1)
        // setHasMoreEnquiries(true)
        await fetchEnquiries()
    }, [enquiryType])

    useEffect(async () => {
        await fetchEnquiries()
    }, [page])

    return (
        < div style={{ padding: '30px', height: '100%' }}>
            <div className="d-flex justify-content-between">
                <h3 style={{ textTransform: 'capitalize' }}>{enquiryType.replace(/[_-]/g, " ")}</h3>
                <Button variant='secondary' onClick={refreshEnquiries} disabled={fetching}>{refreshIcon}&nbsp;Refresh</Button>
            </div>

            <hr />

            <EnquiryTable enquiryType={enquiryType} enquiries={allEnquiries} />

            <Button disabled={fetching || !hasMoreEnquiries} onClick={nextPage} variant='secondary'>{refreshIcon}&nbsp;{hasMoreEnquiries ? (fetching ? "Loading" : "Load Older Enquiries") : "No older orders"}</Button>
        </div>
    )
}
