import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Container, Form, Spinner, Row, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { fetchImportOrderById, updateImportOrder } from '../../app/features/order/orderSlice';
import { backButtonIcon } from '../../FontAwesome';

export default function ImportOrderDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const orderId = params.orderId

    const { fetching, orderDetails } = useSelector(state => state.order);


    const [showPaymentReqModal, setShowPaymentReqModal] = useState(false)
    const [tracking_url, setTrackingURL] = useState(orderDetails?.tracking_url ? orderDetails?.tracking_url : '')


    useEffect(() => {
        dispatch(fetchImportOrderById(orderId))
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!/^https?:\/\//i.test(tracking_url)) {
            setTrackingURL('https://' + tracking_url);
        }
        const state = e.target.state.value
        const payment_status = e.target.payment_status.value
        const data = {
            state,
            payment_status,
            tracking_url
        }
        dispatch(updateImportOrder({ id: orderId, data: data }))
            .then(unwrapResult)
            .then(res => {
                alert("Order updated successfully")
                dispatch(fetchImportOrderById(orderId))
            })
    }


    if (fetching) return (
        <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
            <Spinner animation="border" />
        </div>
    )


    return (
        <Container className='my-4'>
            <Button className='mb-2' onClick={() => navigate(-1)}>{backButtonIcon}&nbsp;Back</Button>
            <h4>Import Order: {orderDetails.sm_orderid ? orderDetails.sm_orderid : orderDetails._id}</h4>
            <hr />
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col sm={6} className='my-1'>
                        <Form.Label>State</Form.Label>
                        <Form.Select defaultValue={orderDetails.state} name="state">
                            <option value="Initiated">Order Initiated</option>
                            <option value="Payment confirmed">Payment confirmed</option>
                            <option value="Pick up scheduled">Pick up scheduled</option>
                            <option value="Pick up failed">Pick up failed</option>
                            <option value="Pick up completed">Pick up completed</option>
                            <option value="Processing package">Processing package </option>
                            <option value="Customs clearance at origin">Customs clearance at origin</option>
                            <option value="Package dispatched from Origin">Package dispatched from Origin</option>
                            <option value="In-Transit">In-Transit</option>
                            <option value="Placed">Placed</option>
                            <option value="Customs clearance at destination">Customs clearance at destination</option>
                            <option value="Shipment under hold">Shipment under hold</option>
                            <option value="Customs cleared">Customs cleared</option>
                            <option value="Out for delivery">Out for delivery</option>
                            <option value="Delivered">Delivered successfully</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Under Hold">Under Hold</option>
                        </Form.Select>
                    </Col>
                    <Col sm={6} className='my-1'>
                        <Form.Group controlId="paymentStatus">
                            <Form.Label>Payment Status</Form.Label>
                            <Form.Select defaultValue={orderDetails.payment_status} name="payment_status">
                                <option value="Initiated">Initiated</option>
                                <option value="Successful">Successful</option>
                                <option value="Pending">Pending</option>
                                <option value="Failed">Failed</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col sm={6} className='my-1'>
                        <Form.Group controlId="tracking_url">
                            <Form.Label>Tracking URL</Form.Label>
                            <Form.Control type="text" value={tracking_url} onChange={(e) => setTrackingURL(e.target.value)} placeholder="Tracking url" />
                        </Form.Group>
                    </Col>
                    <Col sm={6} className='my-1'>
                        <Form.Group controlId='importCountry'>
                            <Form.Label>Import country</Form.Label>
                            <Form.Control type='text' value={orderDetails.importCountry} readOnly />
                        </Form.Group>
                    </Col>
                    <Col sm={6} className='my-1'>
                        <Form.Group controlId='importCountry'>
                            <Form.Label>Shipper</Form.Label>
                            <Form.Control type='text' value={orderDetails.shipper} readOnly />
                        </Form.Group>
                    </Col>
                    <Col sm={6}></Col>
                    <Col sm={6} className='my-1'>
                        <Form.Group controlId="destinationAddress">
                            <Form.Label>Destination address</Form.Label>
                            <Form.Control type="text" value={orderDetails.destination?.address + ", " + orderDetails.destination?.fromGoogle?.address} placeholder="Destination address" readOnly />
                        </Form.Group>
                        <Form.Group controlId="destinationCity">
                            <Form.Label>Destination city</Form.Label>
                            <Form.Control type="text" value={orderDetails.destination?.city} placeholder="Destination city" readOnly />
                        </Form.Group>
                    </Col>
                    <Col sm={6} className='my-1'>
                        <Form.Group controlId="name">
                            <Form.Label>Receiver name</Form.Label>
                            <Form.Control type="text" value={orderDetails.destination?.contact?.name} placeholder="Name of the reciever" readOnly />
                        </Form.Group>
                        <Form.Group controlId="contact">
                            <Form.Label>Receiver contact</Form.Label>
                            <Form.Control type="text" value={orderDetails.destination?.contact?.phone} placeholder="Contact of the receiver" readOnly />
                        </Form.Group>
                    </Col>
                </Row>
                <Button className='mt-2' variant='primary' type="submit">Update</Button>
            </Form>
        </Container>
    )
}
