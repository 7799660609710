import { checkLogin } from "../../utils/firebase";

const localhostUrl = 'http://localhost:8000'
const baseUrl = process.env.REACT_APP_BASE_URL

export const toggleBannerStatusAPI = async ({ bannerId, lastUpdatedBy }) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const reqBody = {
            lastUpdatedBy
        }
        const response = await fetch(`${baseUrl}/banners/toggle/${bannerId}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return response
    } catch (error) {
        console.log(error);
    }
}

export const deleteBannerAPI = async (id) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/banners/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}

export const fetchBannersAPI = async () => {
    try {
        const response = await fetch(`${baseUrl}/banners`).then(res => res.json())
        return response
    } catch (error) {
        console.log(error);
    }
}