import { checkLogin } from "../../utils/firebase";

const baseUrl = process.env.REACT_APP_BASE_URL
const localhostUrl = 'http://localhost:8000'

export const addNewShipmantraChargeAPI = async (reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/shipmantra-charges`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error)
    }
}

export const getShipmantraChargesForServiceAPI = async (serviceType) => {
    try {
        const res = await fetch(`${baseUrl}/shipmantra-charges/${serviceType}`).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}