import React from 'react'
import { useEffect, useState } from 'react'
import { Accordion, Card, Form, Modal, Row, Spinner, InputGroup, Col, Button, Image } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import No_Image_Placeholder from '../../assets/images/No-Image-Placeholder.png'


function Details({ order }) {

    const createdAtDateObj = new Date(order.createdAt)
    const fromCounter = order.firstMile?.fromCounter
    let totalCost = order.cost?.amountCollectedFromCustomer

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="orderID">
                        <Form.Label>Order id</Form.Label>
                        <Form.Control value={order.sm_orderid ? order.sm_orderid : order._id} type="text" placeholder="Order id" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="cost">
                        <Form.Label>Cost (₹)</Form.Label>
                        <Form.Control
                            value={totalCost} type="text" placeholder="Cost" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>Shipper</Form.Label>
                        <Form.Control value={"APSRTC"} type="text" placeholder="Shipper" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>From Name</Form.Label>
                        <Form.Control value={order.start?.contact?.name} type="text" placeholder="Shipper" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>From Contact</Form.Label>
                        <Form.Control value={order.start?.contact?.phone} type="text" placeholder="Shipper" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="startAddr">
                        <Form.Label>From address</Form.Label>
                        {fromCounter ?
                            <Form.Control value={"Booked from Counter " + order.midMile?.startStation.address.fromGoogle.city} type="text" placeholder="Address" readOnly />
                            :
                            <Form.Control value={(order.start?.address?.houseNo + "") + " " + (order.start?.address?.buildingName || "") + " " + order.start?.address?.mapData?.address} type="text" placeholder="Address" readOnly />
                        }
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>To Name</Form.Label>
                        <Form.Control value={order.end?.contact?.name} type="text" placeholder="Shipper" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>To Contact</Form.Label>
                        <Form.Control value={order.end?.contact?.phone} type="text" placeholder="Shipper" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="endAddr">
                        <Form.Label>To address</Form.Label>
                        <Form.Control value={(order.end?.address?.houseNo || "") + " " + (order.end?.address?.buildingName || "") + " " + order.end?.address?.mapData?.address} type="text" placeholder="Address" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="bookedAt">
                        <Form.Label>Booked at</Form.Label>
                        <Form.Control value={createdAtDateObj.toDateString() + ", " + createdAtDateObj.getHours() + ":" + createdAtDateObj.getMinutes()} type="text" placeholder="Booked at" readOnly />
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}

function Package({ packageData, goodsCategory, value, packageImgURL }) {
    return (
        <>
            <Row sm={12}>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="manifestUrl">
                        <Form.Label>Package Image</Form.Label>
                        <div style={{ maxHeight: '200px' }}>
                            {
                                packageImgURL ?
                                    <Image src={packageImgURL} style={{ maxHeight: '200px', width: '100%' }} fluid rounded alt='package_image' />
                                    : <Image src={No_Image_Placeholder} style={{ maxHeight: '200px', width: '100%' }} thumbnail rounded alt='package_image' />
                            }
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            {
                packageData?.map((packageEl, i) => {
                    return (
                        <>
                            <Row sm={12}>
                                <Col sm={2} style={{ display: 'flex', alignItems: 'center' }}>
                                    Package #{i + 1}
                                </Col>
                                {/* <Col>
                                    <Row sm={6}> */}
                                <Col sm={3}>
                                    <Form.Group className="mb-3" controlId="weight">
                                        <Form.Label>LxBxH(in cm)</Form.Label>
                                        <Form.Control value={packageEl.l + " x " + packageEl.b + " x " + packageEl.h} type="text" placeholder="lxbxh" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group className="mb-3" controlId="weight">
                                        <Form.Label>Vol. Weight</Form.Label>
                                        <Form.Control value={(packageEl.volumetricWeight ? packageEl.volumetricWeight : (parseFloat(packageEl.l) * parseFloat(packageEl.b) * parseFloat(packageEl.h)) / 2500) + "KG"} type="text" placeholder="weight" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group className="mb-3" controlId="weight">
                                        <Form.Label>Weight</Form.Label>
                                        <Form.Control value={packageEl.weight + "KG"} type="text" placeholder="weight" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group className="mb-3" controlId="awb">
                                        <Form.Label>LR number</Form.Label>
                                        <Form.Control value={packageEl.awb} type="text" placeholder="Awb" readOnly />
                                    </Form.Group>
                                </Col>
                                {/* </Row>
                                </Col> */}
                            </Row>
                        </>
                    )
                })
            }
        </>
    )
}

function FirstMile({ order }) {

    const fromCounter = order?.fromCounter
    return (
        <>
            <Row>
                {!fromCounter && <Col sm={6}>
                    <Form.Group className="mb-3" controlId="cost">
                        <Form.Label>Cost</Form.Label>
                        <Form.Control value={order?.cost} type="text" placeholder="Cost" readOnly />
                    </Form.Group>
                </Col>}
                {!fromCounter && <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>Shipper</Form.Label>
                        <Form.Control value={order?.shipperUid} type="text" placeholder="Shipper" readOnly />
                    </Form.Group>
                </Col>}
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="startAddr">
                        {!fromCounter && <Form.Label>Start address</Form.Label>}
                        {fromCounter ?
                            <Form.Control value="Booked from Counter" type="text" placeholder="Address" readOnly />
                            :
                            <Form.Control value={(order.start?.address?.houseNo || "") + " " + (order.start?.address?.buildingName || "") + " " + order.start?.address?.mapData?.address} type="text" placeholder="Address" readOnly />
                        }
                    </Form.Group>
                </Col>
                {!fromCounter && <Col sm={12}>
                    <Form.Group className="mb-3" controlId="endAddr">
                        <Form.Label>End address</Form.Label>
                        <Form.Control value={(order.end?.address?.houseNo || "") + " " + (order.end?.address?.buildingName || "") + " " + order.end?.address?.mapData?.address} type="text" placeholder="Address" readOnly />
                    </Form.Group>
                </Col>}
            </Row>
        </>
    )
}

function MidMile({ order, cost }) {
    return (
        <>
            <Row>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="cost">
                        <Form.Label>Cost</Form.Label>
                        <Form.Control value={order?.cost} type="text" placeholder="Cost" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>Shipper</Form.Label>
                        <Form.Control value={order?.shipperUid} type="text" placeholder="Shipper" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="startAddr">
                        <Form.Label>Start station</Form.Label>
                        <Form.Control value={order.start?.address?.mapData?.city + " Station"} type="text" placeholder="Address" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="endAddr">
                        <Form.Label>End station</Form.Label>
                        <Form.Control value={order.end?.address?.mapData?.city + " Station"} type="text" placeholder="Address" readOnly />
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}

function LastMile({ order }) {
    return (
        <>
            <Row>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="cost">
                        <Form.Label>Cost</Form.Label>
                        <Form.Control value={order?.cost} type="text" placeholder="Cost" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>Shipper</Form.Label>
                        <Form.Control value={order?.shipperUid} type="text" placeholder="Shipper" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="startAddr">
                        <Form.Label>Start address</Form.Label>
                        <Form.Control value={(order.start?.address?.houseNo || "") + " " + (order.start?.address?.buildingName || "") + " " + order.start?.address?.mapData?.address} type="text" placeholder="Address" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="endAddr">
                        <Form.Label>End address</Form.Label>
                        <Form.Control value={(order.end?.address?.houseNo || "") + " " + (order.end?.address?.buildingName || "") + " " + order.end?.address?.mapData?.address} type="text" placeholder="Address" readOnly />
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}

function CostBreakdown({ cost }) {
    return (
        <>
            <Row>
                {
                    cost && Object.keys(cost).length && Object.keys(cost).map((key, index) => {
                        return (
                            cost[key] ? <Col sm={6} key={index}>
                                <Form.Group className="mb-3" controlId="cost">
                                    <Form.Label className='text-capitalize'>{key?.replace(/([a-z])([A-Z])/g, '$1 $2')}</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text id={key}>₹</InputGroup.Text>
                                        <Form.Control value={Number(cost[key])?.toFixed(2)} type="text" placeholder={key?.replace(/([a-z])([A-Z])/g, '$1 $2')} aria-describedby={key} readOnly />
                                    </InputGroup>
                                </Form.Group>
                            </Col> : null
                        )
                    })
                }
            </Row>
        </>
    )
}


export default function OrderDetail({ show, handleClose, order }) {
    const dispatch = useDispatch();

    const { fetching, orderDetail } = useSelector(state => state.businessOrder);

    const [skuDetails, setSKUDetails] = useState([])
    const [labelLoader, setLableLoader] = useState(false);

    const { firstMile, midMile, lastMile, package: packageData } = order

    let totalCost = (order?.cost?.midMile || 0) + (order?.cost?.midMileInsurance || 0)

    useEffect(() => {
    }, [order])

    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg' centered style={{ backdropFilter: 'blur(20px)' }}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>
                        <span>Order details</span>
                    </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    {
                        fetching ?
                            <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
                                <Spinner animation="border" />
                            </div>
                            :
                            <>
                                <Card className='mb-3'>
                                    <Card.Header className='fw-bold fs-5'>Order Details</Card.Header>
                                    <Card.Body>
                                        <Details order={order} />
                                    </Card.Body>
                                </Card>
                                <Card className='mb-3'>
                                    <Card.Header className='fw-bold fs-5'>Package</Card.Header>
                                    <Card.Body style={{ maxHeight: '400px', overflow: 'scroll' }}>
                                        <Package packageData={packageData} goodsCategory={order.goods_category} packageImgURL={order.packageImgURL} value={order.package_value} />
                                    </Card.Body>
                                </Card>
                                <Card className='mb-3'>
                                    <Card.Header className='fw-bold fs-5'>First Mile</Card.Header>
                                    <Card.Body>
                                        <FirstMile order={order?.firstMile} />
                                    </Card.Body>
                                </Card>
                                <Card className='mb-3'>
                                    <Card.Header className='fw-bold fs-5'>Mid Mile</Card.Header>
                                    <Card.Body>
                                        <MidMile order={order?.midMile} />
                                    </Card.Body>
                                </Card>
                                <Card className='mb-3'>
                                    <Card.Header className='fw-bold fs-5'>Last Mile</Card.Header>
                                    <Card.Body>
                                        <LastMile order={order?.lastMile} />
                                    </Card.Body>
                                </Card>
                                <Card className='mb-3'>
                                    <Card.Header className='fw-bold fs-5'>Cost Breakdown</Card.Header>
                                    <Card.Body>
                                        <CostBreakdown cost={order?.cost} />
                                    </Card.Body>
                                </Card>
                            </>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
