import { checkLogin } from "../../../../utils/firebase";

const localhostUrl = 'http://localhost:8000'
const baseUrl = process.env.REACT_APP_BASE_URL


export const createExpressDeliveryOrderAPI = async (reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/panindia-orders/express-order/admin/createOrder`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}

export const fetchExpressDeliveryOrdersAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/panindia-orders/express-orders`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}

export const getSingleExpressOrderAPI = async (id) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/panindia-orders/express-orders/${id}`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}

export const updateExpressOrderAPI = async (id, reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/panindia-orders/express-order/${id}`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}