import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { Col, Form, Row, Button } from 'react-bootstrap'
import { addCMSAnnouncementFeed, addContentManagement } from '../../../app/features/cms/contentmanagementSlice'
import { DefaultEditor } from 'react-simple-wysiwyg';
import { useEffect } from 'react'

export default function AddAnnouncementFeed() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { handleSubmit, control, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {
            feed: ''
        }
    })

    const resetData = () => {
        setValue('feed', '')
    }


    const submitDetails = (data) => {
        console.log(data)
        dispatch(addCMSAnnouncementFeed(data))
            .then(() => resetData())
    }

    return (
        <div style={{ padding: '30px' }}>
            <Form onSubmit={handleSubmit(submitDetails)}>
                <Row>
                    <Col xs={12}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="feed"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="feed">
                                    <Form.Label>Announcement bar</Form.Label>
                                    <DefaultEditor value={value} onChange={onChange} />
                                </Form.Group>
                            )}
                        />
                        {errors.feed && <div className='error-text'>This field is required</div>}
                    </Col>
                </Row>
                <hr />
                <Button type='submit'>Submit</Button>
                <Button className='ms-2' onClick={() => navigate('/cms/header/announcement-feeds')} type='button'>All Announcement Feeds</Button>
            </Form>
        </div>
    )
}
