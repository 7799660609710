import { checkLogin } from "../../../utils/firebase";

const ecsBaseUrl = process.env.REACT_APP_ECS_BASE_URL


export const getSingleAgentOrgAPI = async function ({ orgId }) {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })


    try {
        const res = await fetch(`${ecsBaseUrl}/get-organization-details?companyId=${orgId}&orgType=agent`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'authorization': "Bearer " + (authToken ? authToken : ''),
                "stakeholder": "legacy-admin"
            }
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error)
    }
}


export const createAgentOrgAPI = async function (agentOrgPayload) {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })


    try {
        const res = await fetch(`${ecsBaseUrl}/create-organization`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'authorization': "Bearer " + (authToken ? authToken : ''),
                "stakeholder": "legacy-admin"
            },
            body: JSON.stringify(agentOrgPayload)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error)
    }
}

export const updateAgentOrgAPI = async function (agentOrgPayload) {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })


    try {
        const res = await fetch(`${ecsBaseUrl}/update-organization`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'authorization': "Bearer " + (authToken ? authToken : ''),
                "stakeholder": "legacy-admin"
            },
            body: JSON.stringify(agentOrgPayload)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error)
    }
}


export const getAllAgentsAPI = async function ({ page, limit = 25 }) {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })


    try {
        const res = await fetch(`${ecsBaseUrl}/users?orgType=agent&page=${page}&limit=${limit}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'authorization': "Bearer " + (authToken ? authToken : ''),
                "stakeholder": "legacy-admin"
            }
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error)
    }
}

export const deleteAgentAPI = async function (deleteUserPayload) {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })


    try {
        const res = await fetch(`${ecsBaseUrl}/delete-user`, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'authorization': "Bearer " + (authToken ? authToken : ''),
                "stakeholder": "legacy-admin"
            },
            body: JSON.stringify(deleteUserPayload)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error)
    }
}