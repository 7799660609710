import React from 'react'
import Charges from '../../Charges'

export default function Hyp3WheelerB2BCharges() {
    return (
        <div style={{ padding: '30px' }}>
            <h4>Hyperlocal 3 wheeler platform charges for b2b</h4>
            <hr />
            <Charges serviceType='hyperlocal_3w_b2b' />
        </div>
    )
}
