import React, { useEffect, useState } from 'react'
import { Container, Button, Table, Spinner, Form } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchAllCities, fetchOneCity } from '../../app/features/cities/citySlice'
import { editIcon, refreshIcon, sortAscIcon, sortDescIcon, sortIcon } from '../../FontAwesome'
import Loader from '../Loader'

export default function CityDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { fetching, cities } = useSelector(state => state.city)

    const [sortType, setSortType] = useState("updatedAt-desc")
    const [sortedResults, setSortedResults] = useState(cities ? cities : [])
    const [searchString, setSearchString] = useState('')

    useEffect(() => {
        if (searchString) {
            let searchText = searchString.toLowerCase()
            let temp = []
            cities.map(city => {
                if (city.cityText?.toLowerCase().includes(searchText) || city.cityString.toLowerCase().includes(searchText) || city.state?.toLowerCase().includes(searchText)) {
                    temp.push(city)
                }
            })
            setSortedResults(temp)
            setSortType('updatedAt-desc')
        } else if (searchString === '') {
            setSortedResults(cities)
            setSortType('updatedAt-desc')
        }
    }, [searchString])

    useEffect(() => {
        dispatch(fetchAllCities())
    }, [])

    const handleClick = (event, id) => {
        event.preventDefault();
        dispatch(fetchOneCity(id))
            .then(res => {
                navigate(`/city/${id}`)
            })
    }

    const handleSort = (type) => {
        const order = sortType.split("-")[1]
        if (order === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (order === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        const [attr, type] = sortType.split("-")
        let temp = [...cities]
        if (attr === "updatedAt" || attr === "createdAt") {
            if (type === "asc") {
                temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) =>
                    new Date(b[attr]) - new Date(a[attr])
                )
                setSortedResults(temp)
            }
        } else {
            if (type === "asc") {
                temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                setSortedResults(temp)
            }
        }
    }, [sortType, fetching, cities])


    if (fetching) return (
        <Loader />
    )

    return (
        <Container className='mt-3'>
            <hr />
            <Form.Control type='text' className='mb-3' value={searchString} onChange={e => setSearchString(e.target.value)} placeholder='Search city...' />
            <div className='ms-auto text-end mb-3'>
                <Button variant="secondary" onClick={e => { dispatch(fetchAllCities()) }}>
                    <span>{refreshIcon}</span>&nbsp;
                    Refresh
                </Button>
            </div>
            <LoadingOverlay
                active={false}
                spinner
                text='Disabling.....'
            >
                <div style={{ height: '100%', overflowY: 'auto' }}>
                    <Table striped bordered hover responsive className='mt-3'>
                        <thead style={{ verticalAlign: 'middle' }}>
                            <tr>
                                <th>
                                    City Text&nbsp;
                                    {sortType.split("-")[0] !== "cityText" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("cityText")}>{sortIcon}</span>}
                                    {
                                        (sortType.split("-")[0] === "cityText" && sortType.split("-")[1] === "asc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("cityText")}>{sortAscIcon}</span>
                                    }
                                    {
                                        (sortType.split("-")[0] === "cityText" && sortType.split("-")[1] === "desc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("cityText")}>{sortDescIcon}</span>
                                    }
                                    {/* <span style={{ cursor: 'pointer' }} onClick={() => handleSort("cityText")}>{sortIcon}</span> */}
                                </th>
                                <th>City String</th>
                                <th>
                                    Created At&nbsp;
                                    {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                                    {
                                        (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                                    }
                                    {
                                        (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                                    }
                                    {/* <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span> */}
                                </th>
                                <th>
                                    Updated At&nbsp;
                                    {sortType.split("-")[0] !== "updatedAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortIcon}</span>}
                                    {
                                        (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "asc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortAscIcon}</span>
                                    }
                                    {
                                        (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "desc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortDescIcon}</span>
                                    }
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortedResults.map((city, index) => {
                                    const createdAtDateString = new Date(city.createdAt);
                                    const createdAtDate = createdAtDateString.toDateString() + ", " + createdAtDateString.toLocaleTimeString();
                                    const updatedAtDateString = new Date(city.updatedAt);
                                    const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();
                                    return (
                                        <tr key={index}>
                                            <td>{city.cityText}</td>
                                            <td>{city.cityString}</td>
                                            <td>{createdAtDate}</td>
                                            <td>{updatedAtDate}</td>
                                            <td>
                                                <Button onClick={(e) => handleClick(e, city._id)}>{editIcon}</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </LoadingOverlay>
        </Container>
    )
}
