import React, { useEffect, useState } from 'react'
import { Table, Form, Button, InputGroup, Accordion } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { addIcon } from '../../FontAwesome';

export default function AddSalesTarget() {
    const [burntoRevenueRatioMonths, setBurnToRevenueRatioMonths] = useState([]);

    const { handleSubmit: handleSalesTypeFormSubmit, control } = useForm({
        defaultValues: {
            burnPerMonth: 0,
            primaryOprCityCount: 0,
            secondaryOprCityCount: 0,
            avgBusOrdValue1to5Kg: 0,
            avgBusOrdValue5to20Kg: 0,
            avgBusOrdValue20to100Kg: 0,
            avgOrderValueonFTLandHTL: 0,
            avgOrderValueOnPackMove: 0,
            avgShipmnatraOrderValue1to20Kg: 0,
            avgShipmnatraOrderValue20to100Kg: 0,
            atsOnSaasSubsandOrderCommission: 0,
            atsOnOtherOrdersandCommission: 0,
            atsOnGPSUnitSales: 0,
            totalNoOfWorkingDays: 0
        }
    })


    useEffect(() => {
        const tempData = [];

        for (let index = 0; index < 6; index++) {
            const todaysDateObj = new Date();
            const todaysMonth = todaysDateObj.getMonth();
            tempData.push({
                date: new Date(todaysDateObj.setMonth(todaysMonth + index)),
                burnRatio: 0
            })
        }

        setBurnToRevenueRatioMonths(tempData);
    }, [])


    const addMoreBurnRatioMonths = () => {
        const tempData = [...burntoRevenueRatioMonths];

        const dateObj = tempData.length ? new Date(tempData.slice(-1)?.[0]?.date) : new Date();
        const month = dateObj.getMonth();
        tempData.push({
            date: new Date(dateObj.setMonth(month + 1)),
            burnRatio: 0
        })

        setBurnToRevenueRatioMonths(tempData);
    }


    return (
        <div style={{ padding: '30px' }}>
            <h4>Add Sales Target</h4>
            <hr />
            <div>
                <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Type Of Sales</Accordion.Header>
                        <Accordion.Body>
                            {/* <h5>Type Of Sales</h5> */}
                            {/* <hr /> */}
                            <Table bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Label</th>
                                        <th>Input</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Total Burn / month</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`burnPerMonth`}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputGroup>
                                                        <InputGroup.Text id="burnPerMonth">₹</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Primary operating cities</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`primaryOprCityCount`}
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control
                                                        type="text"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Secondary operating cities</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`secondaryOprCityCount`}
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control
                                                        type="text"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>ATS on Business order on 1 - 5Kilo</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`avgBusOrdValue1to5Kg`}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputGroup>
                                                        <InputGroup.Text id="avgBusOrdValue1to5Kg">₹</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>ATS on Business order on 5 - 20Kilo</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`avgBusOrdValue5to20Kg`}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputGroup>
                                                        <InputGroup.Text id="avgBusOrdValue5to20Kg">₹</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>ATS on Business order on 20 - 100Kilo</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`avgBusOrdValue20to100Kg`}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputGroup>
                                                        <InputGroup.Text id="avgBusOrdValue20to100Kg">₹</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>ATS on FTL & HTL</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`avgOrderValueonFTLandHTL`}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputGroup>
                                                        <InputGroup.Text id="avgOrderValueonFTLandHTL">₹</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>ATS on Packers & Movers (B2B and Domestic)</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`avgOrderValueOnPackMove`}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputGroup>
                                                        <InputGroup.Text id="avgOrderValueOnPackMove">₹</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>ATS on C2C  order 1 - 20 Kilo</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`avgShipmnatraOrderValue1to20Kg`}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputGroup>
                                                        <InputGroup.Text id="avgShipmnatraOrderValue1to20Kg">₹</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>ATS on C2C order 20 - 100Kilo</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`avgShipmnatraOrderValue20to100Kg`}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputGroup>
                                                        <InputGroup.Text id="avgShipmnatraOrderValue20to100Kg">₹</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Agent SaaS Subscription + Commission on Orders</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`atsOnSaasSubsandOrderCommission`}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputGroup>
                                                        <InputGroup.Text id="atsOnSaasSubsandOrderCommission">₹</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Other Orders and commission</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`atsOnOtherOrdersandCommission`}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputGroup>
                                                        <InputGroup.Text id="atsOnOtherOrdersandCommission">₹</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>GPS units sales</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`atsOnGPSUnitSales`}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputGroup>
                                                        <InputGroup.Text id="atsOnGPSUnitSales">₹</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Total Number of working  Days</td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`totalNoOfWorkingDays`}
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control
                                                        type="text"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </td>
                                    </tr>


                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Monthly Sales Data</Accordion.Header>
                        <Accordion.Body>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <td>Months</td>
                                        {burntoRevenueRatioMonths?.map((item, index) => (
                                            <td key={index}>
                                                {item.date.toLocaleString('en-US', { month: 'short' }) + item.date.getFullYear()}
                                            </td>
                                        ))}
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Burn / Revenue Ratio</td>
                                        {burntoRevenueRatioMonths?.map((item, index) => (
                                            <td key={index}>
                                                <InputGroup>
                                                    <Form.Control
                                                        type="text"
                                                        // value={value}
                                                        // onChange={onChange}
                                                    />
                                                    <InputGroup.Text id="burnRatio">%</InputGroup.Text>
                                                </InputGroup>
                                            </td>
                                        ))}
                                        <td onClick={addMoreBurnRatioMonths}>{addIcon}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}
