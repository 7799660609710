import React from 'react'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { addIcon } from '../../FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import { addSupportPhoneNumbers } from '../../app/features/general/generalSlice';
import { unwrapResult } from '@reduxjs/toolkit';

export default function AddSupportNumber({ show, handleClose }) {
    const dispatch = useDispatch();

    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            phone: ''
        }
    });

    const { fetching } = useSelector(state => state.general);


    const addSupportNumber = (data) => {
        const reqBody = {
            name: data.name,
            phone: data.phone?.replace(/[\s]/g, "")?.trim()
        }
        dispatch(addSupportPhoneNumbers(reqBody))
            .then(unwrapResult)
            .then(() => {
                alert(`Added ${data.name} to the list`)
                handleClose()
            })
            .catch(err => {
                alert(`Some error occured while adding ${data.name} to the list`)
            })
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(addSupportNumber)}>
                        <Controller
                            control={control}
                            name="name"
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" onChange={onChange} value={value} placeholder="Name of the support member" />
                                    {errors.name && <div className='error-text'>{errors.name.message}</div>}
                                </Form.Group>
                            )}
                        />
                        <Controller
                            control={control}
                            name="phone"
                            rules={{ required: { value: true, message: 'This field is required' }, pattern: { value: /^(\+\d{1,2})?\d{10}$/g, message: 'Make sure no special characters or space exists' } }}
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="text" onChange={onChange} value={value} placeholder="Phone number" />
                                    {errors.phone && <div className='error-text'>{errors.phone.message}</div>}
                                </Form.Group>
                            )}
                        />
                        <Button type='submit' disabled={fetching}>
                            Add&nbsp;{addIcon}&nbsp;
                            {fetching && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
