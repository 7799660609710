import axios from "axios";
import { checkLogin } from "../../utils/firebase";
const baseUrl = process.env.REACT_APP_BASE_URL

export const sendWhatsappPromotion = async ({ numberData, template_uid }) => {

    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl}/admin/send-whatsapp-promotion`,
            method: "POST",
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            data: {
                numberData: numberData,
                template_uid,
                // imageURL: "https://shipmantra-test-bucket.s3.ap-south-1.amazonaws.com/1680409589159.jpeg"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    })
}

export const getWhatsappTemplatesAPI = async () => {

    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl}/get-whatsapp-template/admin`,
            method: "GET",
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    })
}

export const addWhatsappTemplateAPI = async (templateCreationPayload) => {

    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl}/add-whatsapp-template/admin`,
            method: "POST",
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            data: templateCreationPayload
        })
            .then((res) => {
                resolve({ response: res?.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    })
}