import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner, Table, Button } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { toggleBannerStatus, fetchBanners, deleteBanner } from '../../app/features/banner/bannerSlice'
import { sortIcon, deleteIcon, disableIcon, refreshIcon, enableIcon, sortAscIcon, sortDescIcon } from '../../FontAwesome'
import Loader from '../Loader';
import AWS from 'aws-sdk'

const S3_BUCKET = 'shipmantra-assets';
const REGION = 'ap-south-1';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

export default function BannerDetail() {
    const dispatch = useDispatch()
    const { fetching, toggling, allBanners } = useSelector(state => state.banner)
    const { adminUser } = useSelector(state => state.adminUser)

    const [sortType, setSortType] = useState("updatedAt-desc")
    const [sortedResults, setSortedResults] = useState(allBanners ? allBanners : [])

    useEffect(() => {
        dispatch(fetchBanners())
    }, [])

    useEffect(() => {
        const [attr, type] = sortType.split("-")
        let temp = [...allBanners]
        if (attr === "updatedAt" || attr === "createdAt") {
            if (type === "asc") {
                temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) =>
                    new Date(b[attr]) - new Date(a[attr])
                )
                setSortedResults(temp)
            }
        } else {
            if (type === "asc") {
                temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                setSortedResults(temp)
            }
        }
    }, [sortType, fetching, allBanners])


    const handleSort = (type) => {
        const order = sortType.split("-")[1]
        if (order === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (order === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    const handleDisableBanner = async (bannerId) => {
        console.log(bannerId);
        const lastUpdatedBy = adminUser.email
        dispatch(toggleBannerStatus({ bannerId, lastUpdatedBy }))
            .then(() => dispatch(fetchBanners()))
    }

    function getFilename(fullPath) {
        // eslint-disable-next-line no-useless-escape
        return fullPath.replace(/^.*[\\\/]/, '');
    }

    const handleDeleteBanner = (bannerId, bannerImageURL) => {
        const imageURL = getFilename(bannerImageURL)
        const params = {
            Bucket: S3_BUCKET,
            Key: `banners/${imageURL}`
        }
        myBucket.deleteObject(params, function (err, data) {
            if (data) {
                dispatch(deleteBanner(bannerId))
                    .then(async () => {
                        alert('Banner deleted successfully')
                        dispatch(fetchBanners())
                    })
            } else {
                alert("There was some err while deleting the banner")
            }
        })

        // dispatch(deleteBanner(bannerId))
        //     .then(async () => {
        //         const imageURL = getFilename(bannerImageURL)
        //         console.log(imageURL)
        //         const params = {
        //             Bucket: S3_BUCKET,
        //             Key: `banners/${imageURL}`
        //         }
        //         myBucket.deleteObject(params, function (err, data) {
        //             if (data) {
        //                 alert("Banner deleted successfully")
        //             } else {
        //                 alert("There was some err while")
        //             }
        //         })
        //         // alert('Banner deleted successfully')
        //         dispatch(fetchBanners())
        //     })
    }


    if (fetching) {
        return (<Loader />)
    }


    return (
        <>
            <LoadingOverlay
                active={toggling}
                spinner
                text='Updating.....'
            >
                <div className='ms-auto text-end mb-3'>
                    <Button variant="secondary" onClick={e => { dispatch(fetchBanners()) }}>
                        <span>{refreshIcon}</span>&nbsp;
                        Refresh
                    </Button>
                </div>
                <div>
                    <Table striped bordered hover responsive>
                        <thead style={{ verticalAlign: 'middle' }}>
                            <tr>
                                {/* <th>#</th> */}
                                <th>Thumbnail</th>
                                <th>Image URL</th>
                                <th>Active</th>
                                <th>Last Updated by</th>
                                <th>
                                    Created At&nbsp;
                                    {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                                    {
                                        (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                                    }
                                    {
                                        (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                                    }
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortedResults.map((banner, index) => {
                                    const dateString = new Date(banner.createdAt);
                                    const date = dateString.toDateString() + ", " + dateString.toLocaleTimeString();
                                    return (
                                        <tr key={index}>
                                            {/* <td>{index + 1}</td> */}
                                            <td className='col-2'>
                                                <img style={{ height: '200px', width: '250px' }} src={banner.imgURL} alt="" srcSet="" />
                                            </td>
                                            <td className='col-2'><a target="_blank" href={banner.imgURL} rel="noreferrer">{banner.imgURL}</a></td>
                                            <td className='col-2'>{banner.isActive?.toString()}</td>
                                            <td className='col-2'>{banner.lastUpdatedBy}</td>
                                            <td className='col-2'>{date}</td>
                                            <td className='col-2'>
                                                {
                                                    banner.isActive ?
                                                        <Button variant="outline-danger me-2" onClick={() => { handleDisableBanner(banner._id) }}>{disableIcon}</Button>
                                                        :
                                                        <Button variant="outline-success me-3" onClick={() => { handleDisableBanner(banner._id) }}>{enableIcon}</Button>
                                                }
                                                <Button variant='danger' onClick={() => handleDeleteBanner(banner._id, banner.imgURL)}>{deleteIcon}</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </LoadingOverlay>

        </>
    )
}
