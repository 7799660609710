import { checkLogin } from "../../utils/firebase";

const localhostUrl = 'http://localhost:8000'
const baseUrl = 'https://api.shipmantra.com'

export const fetchAllReviewsAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/reviews`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return res;
    } catch (err) {
        console.log(err);
    }
}

export const fetchReviewByIdAPI = async (id) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/reviews/${id}`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return res;
    } catch (err) {
        console.log(err);
    }
}

export const updateReviewsAPI = async (id, data) => {
    try {
        const res = await fetch(`${baseUrl}/reviews/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': '' + data.token //pass token variable here
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err);
    }
}