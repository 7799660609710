import { checkLogin } from "../../../utils/firebase";

const baseUrl = process.env.REACT_APP_BASE_URL
export const fetchAllHyperlocalOrdersAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/orders`, {
            method: 'POST',
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const fetchAllHyperlocal2WheelerAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/orders`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify({
                vehicle: 2
            })
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const fetchAllHyperlocal3WheelerAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/orders`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify({
                vehicle: 3
            })
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const fetchAllHyperlocal4WheelerAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/orders`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify({
                vehicle: 4
            })
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const fetchHyperlocalOrderByIdAPI = async (id) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/orders/getOrderDetails/${id}`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const updateHyperlocalOrderAPI = async (id, data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        // const res = await fetch(`${baseUrl}/orders/update/:id/${id}`, {
        const res = await fetch(`${baseUrl}/orders/update/${id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}