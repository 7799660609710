import { checkLogin } from "../../../utils/firebase";

const baseUrl = process.env.REACT_APP_BASE_URL

export const fetchRentalOrdersAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/hyperlocal-4-wheelers/rental/admin/getOrders`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const fetchRentalOrderByIdAPI = async (id) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/hyperlocal-4-wheelers/getOrderDetails/${id}`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

//Filter

//Update Order
export const updateRentalOrderAPI = async (ordernum, data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        console.log(ordernum, data);
        const res = await fetch(`${baseUrl}/rentalorders/update/${ordernum}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}