import { addIntShipperAPI, fetchAllIntShipperAPI, fetchSingleIntShipperAPI, updateIntShipperAPI } from "../../api/shippers/internationalshippers";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { addHyShipperAPI, fetchAllHyShippersAPI, fetchSingleHyShipperAPI, updateHyShippperAPI } from "../../api/shippers/hyperlocalshippers";
import { addNewPanIndiaShipperAPI, fetchAllPanindiaShippersAPI, fetchSinglePanindiaShippersAPI, updatePanIndiaShipperAPI } from "../../api/shippers/panindiashippers";


const initialState = {
    fetching: false,
    shippers: [],
    shipperDetail: {}
}

export const fetchAllIntShipper = createAsyncThunk('/shippers/international', async(obj, { rejectWithValue }) => {
    const res = await fetchAllIntShipperAPI()
    if (res.success) return res.data;
    return rejectWithValue('There seems to be an error while fetching international shippers')
})

export const fetchSingleIntShipper = createAsyncThunk('/shippers/international/fetchOne', async({ shipperID }, { rejectWithValue }) => {
    const res = await fetchSingleIntShipperAPI(shipperID)
    if (res.success) return res.data;
    return rejectWithValue('There seems to be an error while fetching an international shipper')
})

export const addIntShipper = createAsyncThunk('/shipper/add/international', async(data, { rejectWithValue }) => {
    const res = await addIntShipperAPI(data)
    if (res.success) return res;
    return rejectWithValue('Oops there seems to be an error')
})

export const updateIntShipper = createAsyncThunk('/shipper/update/international', async(data, { rejectWithValue }) => {
    const id = data._id
    const res = await updateIntShipperAPI(id, data)
    if (res.success) return res;
    return rejectWithValue('There seems to be an error while updating international shipper')
})

export const fetchAllHyShipper = createAsyncThunk('/shippers/hyperlocal', async(obj, { rejectWithValue }) => {
    const res = await fetchAllHyShippersAPI()
    if (res.success) return res.data;
    return rejectWithValue('There seems to be an error while fetching hyperlocal shippers')
})

export const fetchSingleHyShipper = createAsyncThunk('/shippers/hyperlocal/fetchOne', async({ shipperID }, { rejectWithValue }) => {
    const res = await fetchSingleHyShipperAPI(shipperID)
    if (res.success) return res.data;
    return rejectWithValue('There seems to be an error while fetching a hyperlocal shipper')
})

export const addHyShipper = createAsyncThunk('/shipper/add/hyperlocal', async(data, { rejectWithValue }) => {
    const res = await addHyShipperAPI(data)
    if (res.success) return res;
    return rejectWithValue('Oops there seems to be an error')
})

export const updateHyShipper = createAsyncThunk('/shipper/update/hyperlocal', async(data, { rejectWithValue }) => {
    const id = data._id
    const res = await updateHyShippperAPI(id, data)
    if (res.success) return {data:data, res:res}
    return rejectWithValue("There seems to be an error while updating the hyperlocal shipper")
})

export const fetchAllPanindiaShipper = createAsyncThunk('/shippers/panindia', async(obj, { rejectWithValue }) => {
    const res = await fetchAllPanindiaShippersAPI()
    if (res.success) return res.data
    return rejectWithValue("There seems to be an error while fetching all pan india shippers")
})

export const fetchSinglePanindiaShipper = createAsyncThunk('/shippers/single/panindia', async(id, { rejectWithValue }) => {
    const res = await fetchSinglePanindiaShippersAPI(id)
    if (res.success) return res.data
    return rejectWithValue("There seems to be an error while fetching single pan india shipper")
})


export const addPanIndiaShipper = createAsyncThunk('/shipper/add/pan-india', async(data, { rejectWithValue }) => {
    const res = await addNewPanIndiaShipperAPI(data)
    if (res.success) return res.data
    return rejectWithValue("There seems to be an error while adding the shipper")
})

export const updatePanIndiaShipper = createAsyncThunk('/shipper/update/pan-india', async({ id, reqBody }, { rejectWithValue }) => {
    const res = await updatePanIndiaShipperAPI(id, reqBody)
    if (res.success) return res.data
    return rejectWithValue("There seems to be an error while updating the shipper")
})

export const shipperSlice = createSlice({
    name: 'shipper',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllIntShipper.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchAllIntShipper.fulfilled, (state, action) => {
                state.fetching = false
                state.shippers = action.payload
            })
            .addCase(fetchAllIntShipper.rejected, (state, action) => {
                state.fetching = false
                state.shippers = []
                alert(action.payload)
            })
            .addCase(fetchSingleIntShipper.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchSingleIntShipper.fulfilled, (state, action) => {
                state.fetching = false
                state.shipperDetail = action.payload
            })
            .addCase(fetchSingleIntShipper.rejected, (state, action) => {
                state.fetching = false
                state.shipperDetail = {}
                alert(action.payload)
            })
            .addCase(addIntShipper.pending, (state) => {
                state.fetching = true
            })
            .addCase(addIntShipper.fulfilled, (state, action) => {
                state.fetching = false
                alert("Shipper added successfully")
            })
            .addCase(addIntShipper.rejected, (state, action) => {
                state.fetching = false
                alert("There was an error while adding an international shipper")
            })
            .addCase(updateIntShipper.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateIntShipper.fulfilled, (state, action) => {
                state.fetching = false
                alert("Shipper updated succesfully")
            })
            .addCase(updateIntShipper.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(fetchAllHyShipper.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchAllHyShipper.fulfilled, (state, action) => {
                state.fetching = false
                state.shippers = action.payload
            })
            .addCase(fetchAllHyShipper.rejected, (state, action) => {
                state.fetching = false
                state.shippers = []
                alert(action.payload)
            })
            .addCase(fetchSingleHyShipper.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchSingleHyShipper.fulfilled, (state, action) => {
                state.fetching = false
                state.shipperDetail = action.payload
            })
            .addCase(fetchSingleHyShipper.rejected, (state, action) => {
                state.fetching = false
                state.shipperDetail = {}
                alert(action.payload)
            })
            .addCase(addHyShipper.pending, (state) => {
                state.fetching = true
            })
            .addCase(addHyShipper.fulfilled, (state, action) => {
                state.fetching = false
                alert("Shipper added successfully")
            })
            .addCase(addHyShipper.rejected, (state, action) => {
                state.fetching = false
                alert("There was an error while adding an hyperlocal shipper")
            })
            .addCase(updateHyShipper.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateHyShipper.fulfilled, (state, action) => {
                state.fetching = false
                const { data, res } = action.payload
                for (let i = 0; i < state.shippers.length; i++) {
                    if(state.shippers[i]._id == data._id){
                        let updatedShipper = {
                            ...state.shippers[i],
                            ...data
                        }
                        state.shippers[i] = updatedShipper;
                        break
                    }
                    
                }
                // alert("Shipper updated successfully")
            })
            .addCase(updateHyShipper.rejected, (state, action) => {
                state.fetching = false
                alert("There was an error while updating a hyperlocal shipper")
            })
            .addCase(fetchAllPanindiaShipper.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(fetchAllPanindiaShipper.fulfilled, (state, action) => {
                state.fetching = false
                state.shippers = action.payload
            })
            .addCase(fetchAllPanindiaShipper.rejected, (state, action) => {
                state.fetching = false
                state.shippers = []
                alert(action.payload)
            })
            .addCase(fetchSinglePanindiaShipper.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(fetchSinglePanindiaShipper.fulfilled, (state, action) => {
                state.fetching = false
                state.shipperDetail = action.payload
            })
            .addCase(fetchSinglePanindiaShipper.rejected, (state, action) => {
                state.fetching = false
                state.shipperDetail = {}
                alert(action.payload)
            })
            .addCase(addPanIndiaShipper.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(addPanIndiaShipper.fulfilled, (state, action) => {
                state.fetching = false
                alert(`Shipper ${action.payload.company} added successfully`)
            })
            .addCase(addPanIndiaShipper.rejected, (state, action) => {
                state.fetching = false
                state.shippers = []
                alert(action.payload)
            })
            .addCase(updatePanIndiaShipper.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(updatePanIndiaShipper.fulfilled, (state, action) => {
                state.fetching = false
                for (let i = 0; i < state.shippers.length; i++) {
                    if(state.shippers[i]._id == action.payload._id){
                        state.shippers[i] = action.payload;
                        break
                    }
                    
                }
                // alert(`Shipper ${action.payload.company} edited successfully`)
            })
            .addCase(updatePanIndiaShipper.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
    }
})