import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAllUsersAPI, fetchUserByIdAPI, registerNewCustomerAPI, updateUserByAdminAPI } from '../../api/customers';


const initialState = {
    error: false,
    fetching: false,
    allAppUsers: [],
    customerDetails: {}
}

export const fetchAllUsers = createAsyncThunk('/users', async(obj, { rejectWithValue }) => {
    const response = await fetchAllUsersAPI();
    if (response.success) return response.data
    return rejectWithValue('There was some error while processing the request');
})

export const fetchUserById = createAsyncThunk('/users/getUserById', async(id, { rejectWithValue }) => {
    const response = await fetchUserByIdAPI(id);
    if (response.success) return response.data
    return rejectWithValue('There was some error while processing the request');
})

export const updateUserByAdmin = createAsyncThunk('/users/updateByAdmin', async(data, { rejectWithValue }) => {
    console.log(data);
    const response = await updateUserByAdminAPI(data.uid, data.requestBody);
    if (response.success) return response
    return rejectWithValue(response.message)
})

export const registerNewCustomer = createAsyncThunk('/users/register', async(data, { rejectWithValue }) => {
    const response = await registerNewCustomerAPI(data);
    if (response.success) return response.data
    return rejectWithValue('There was some error while creating new user');
})

export const appUserSlice = createSlice({
    name: 'appusers',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllUsers.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchAllUsers.fulfilled, (state, action) => {
                state.fetching = false
                state.allAppUsers = action.payload
            })
            .addCase(fetchAllUsers.rejected, (state, action) => {
                state.fetching = false
                console.log("Rejected: ", JSON.stringify(action.payload));
            })
            .addCase(fetchUserById.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                state.fetching = false
                state.customerDetails = action.payload
            })
            .addCase(fetchUserById.rejected, (state, action) => {
                state.fetching = false
                console.log("Rejected: ", JSON.stringify(action.payload));
            })
            .addCase(updateUserByAdmin.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateUserByAdmin.fulfilled, (state, action) => {
                state.fetching = false
                state.customerDetails = action.payload
                console.log(action.payload);
                alert('User Updated');
            })
            .addCase(updateUserByAdmin.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(registerNewCustomer.pending, (state) => {
                state.fetching = true
            })
            .addCase(registerNewCustomer.fulfilled, (state, action) => {
                state.fetching = false
            })
            .addCase(registerNewCustomer.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
    }
})