import { checkLogin } from "../../utils/firebase"

const baseUrl = process.env.REACT_APP_BASE_URL
const localhostUrl = 'http://localhost:8000'

export const fetchNoOptionEnquiriesAPI = async () => {
    try {
        const res = await fetch(`${baseUrl}/no-option-enquiry`).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const fetchBusinessEnquiriesAPI = async (page = 1) => {
    try {
        const res = await fetch(`${baseUrl}/get-business-enquiries?page=${page}`).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const updateBusinessEnquiryAPI = async (data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/update-business-enquiries`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}


export const fetchShipperEnquiriesAPI = async (page = 1) => {
    try {
        const res = await fetch(`${baseUrl}/get-shipper-enquiries?page=${page}`).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const updateShipperEnquiryAPI = async (data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/update-shipper-enquiries`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const fetchGeneralEnquiriesAPI = async (page = 1) => {
    try {
        const res = await fetch(`${baseUrl}/get-general-enquiries?page=${page}`).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const updateGeneralEnquiryAPI = async (data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/update-general-enquiries`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const updateNoOptionEnquiryAPI = async (id, data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/no-option-enquiry/update/${id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify({
                status: data.status,
                enquiredBy: data.enquiredBy?.split("@")[0]
            })
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}
