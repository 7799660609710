import React from 'react'
import { useEffect } from 'react'
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { backButtonIcon, openLinkOnNewPageIcon } from '../../FontAwesome'
import { useSelector } from 'react-redux'
import { fetchAllPanindiaShipper } from '../../app/features/shipper/shipperSlice'
import { calculateGoogleAddress } from '../../app/api/general'
import { createExpressDelOrder } from '../../app/features/order/orderSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { useState } from 'react'
import MapInput from '../MapInput'


export default function AddNewExpressDeliveryOrder() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [senderAddr, setSenderAddr] = useState({})
    const [receiverAddr, setReceiverAddr] = useState({})

    const { handleSubmit, control, setFocus, formState: { errors } } = useForm({
        defaultValues: {
            pkg: [{ l: '', b: '', h: '', weight: '' }],
            fragile: false,
            shipperID: '',
            user: '',
            weight: 0,
            cost: '',
            tracking_url: '',
            senderName: '',
            senderContact: '',
            senderEmail: '',
            senderFlatno: '',
            senderBuilding: '',
            receiverName: '',
            receiverContact: '',
            receiverEmail: '',
            receiverFlatno: '',
            receiverBuilding: '',
            bookedAt: '',
            gst: '',
            monetary_value: '',
            payment_status: 'Initiated',
            shipmentPurpose: 'personal',
            goodsCategory: ''
        }
    })

    const { shippers } = useSelector(state => state.shipper)
    const { fetching } = useSelector(state => state.order)

    const { fields, append, remove } = useFieldArray({
        control,
        name: "pkg",
    });

    useEffect(() => {
        dispatch(fetchAllPanindiaShipper())
    }, [])

    useEffect(() => {
        const firstError = Object.keys(errors).reduce((field, a) => {
            return errors[field] ? field : a;
        }, null);

        if (firstError) {
            setFocus(firstError);
        }
    }, [errors, setFocus]);

    const createOrder = async (data) => {
        console.log(data)
        // const senderAddrDetails = await calculateGoogleAddress(data.senderAddress)
        // const receiverAddrDetails = await calculateGoogleAddress(data.receiverAddress)
        // console.log(senderAddrDetails, receiverAddrDetails)

        // let senderDetails = {
        //     pincode: '',
        //     state: '',
        //     city: '',
        //     fromGoogle: {
        //         lat: '',
        //         lng: '',
        //         address: '',
        //         pincode: ''
        //     }
        // }
        // let receiverDetails = {
        //     pincode: '',
        //     state: '',
        //     city: '',
        //     fromGoogle: {
        //         lat: '',
        //         lng: '',
        //         address: '',
        //         pincode: ''
        //     }
        // }

        // if (senderAddrDetails.status === 'OK' && receiverAddrDetails.status === 'OK' && senderAddrDetails.results.length > 0 && receiverAddrDetails.results.length > 0) {
        //     const senderAddressComp = senderAddrDetails.results[0].address_components
        //     const receiverAddressComp = receiverAddrDetails.results[0].address_components
        //     senderDetails.fromGoogle.lat = senderAddrDetails.results[0].geometry.location.lat
        //     senderDetails.fromGoogle.lng = senderAddrDetails.results[0].geometry.location.lng
        //     senderDetails.fromGoogle.address = senderAddrDetails.results[0].formatted_address

        //     receiverDetails.fromGoogle.lat = receiverAddrDetails.results[0].geometry.location.lat
        //     receiverDetails.fromGoogle.lng = receiverAddrDetails.results[0].geometry.location.lng
        //     receiverDetails.fromGoogle.address = receiverAddrDetails.results[0].formatted_address
        //     if (senderAddressComp) {
        //         for (let i = 0; i < senderAddressComp.length; i++) {
        //             const typesArray = senderAddressComp[i].types
        //             for (let j = 0; j < typesArray.length; j++) {
        //                 if (typesArray[j] === "postal_code") {
        //                     senderDetails.pincode = senderAddressComp[i].long_name
        //                     senderDetails.fromGoogle.pincode = senderAddressComp[i].long_name
        //                 }
        //                 if (typesArray[j] === "administrative_area_level_1")
        //                     senderDetails.state = senderAddressComp[i].long_name
        //                 if (typesArray[j] === "locality")
        //                     senderDetails.city = senderAddressComp[i].long_name
        //             }
        //         }
        //     }

        //     if (receiverAddressComp) {
        //         for (let i = 0; i < receiverAddressComp.length; i++) {
        //             const typesArray = receiverAddressComp[i].types
        //             for (let j = 0; j < typesArray.length; j++) {
        //                 if (typesArray[j] === "postal_code") {
        //                     receiverDetails.pincode = receiverAddressComp[i].long_name
        //                     receiverDetails.fromGoogle.pincode = receiverAddressComp[i].long_name
        //                 }
        //                 if (typesArray[j] === "administrative_area_level_1")
        //                     receiverDetails.state = receiverAddressComp[i].long_name
        //                 if (typesArray[j] === "locality")
        //                     receiverDetails.city = receiverAddressComp[i].long_name
        //             }
        //         }
        //     }

        //     const reqBody = {
        //         shipperID: data.shipperID,
        //         user: data.user,
        //         weight: data.weight,
        //         cost: data.cost,
        //         pkg: data.pkg,
        //         bookedAt: data.bookedAt,
        //         fragile: data.fragile,
        //         gst: data.gst,
        //         monetary_value: data.monetary_value,
        //         order_type: 'pi_express',
        //         payment_status: data.payment_status,
        //         shipmentPurpose: data.shipmentPurpose,
        //         goodsCategory: data.goodsCategory,
        //         start: {
        //             contact: {
        //                 name: data.senderName,
        //                 phone: data.senderContact,
        //                 email: data.senderEmail
        //             },
        //             startLocation: {
        //                 address: data.senderAddress,
        //                 fromGoogle: senderDetails.fromGoogle,
        //                 state: senderDetails.state,
        //                 city: senderDetails.city,
        //                 pincode: senderDetails.pincode
        //             }
        //         },
        //         end: {
        //             contact: {
        //                 name: data.receiverName,
        //                 phone: data.receiverContact,
        //                 email: data.receiverEmail
        //             },
        //             endLocation: {
        //                 address: data.receiverAddress,
        //                 fromGoogle: receiverDetails.fromGoogle,
        //                 state: receiverDetails.state,
        //                 city: receiverDetails.city,
        //                 pincode: receiverDetails.pincode
        //             }
        //         },
        //         state: "Initiated"
        //     }
        //     console.log("REQ BODY = ", reqBody)
        //     dispatch(createExpressDelOrder(reqBody))
        //         .then(unwrapResult)
        //         .then((res) => {
        //             console.log(res)
        //             navigate('/orders/panIndiaOrders/express-orders')
        //         })
        // } else {
        //     alert("Please check the sender and receiver address")
        //     setError('senderAddress', { type: 'custom', message: 'Please check address' })
        //     setError('receiverAddress', { type: 'custom', message: 'Please check address' })
        // }

        if ((Object.keys(senderAddr) && !senderAddr.pincode) || (Object.keys(receiverAddr) && !receiverAddr.pincode)) {
            alert('Please enter sender and receiver addresses with a valid pincode')
        } else {
            const reqBody = {
                shipperID: data.shipperID,
                user: data.user,
                weight: data.weight,
                cost: data.cost,
                pkg: data.pkg,
                bookedAt: data.bookedAt,
                fragile: data.fragile,
                gst: data.gst,
                monetary_value: data.monetary_value,
                order_type: 'pi_express',
                payment_status: data.payment_status,
                shipmentPurpose: data.shipmentPurpose,
                goodsCategory: data.goodsCategory,
                start: {
                    contact: {
                        name: data.senderName,
                        phone: data.senderContact,
                        email: data.senderEmail
                    },
                    startLocation: {
                        address: data.senderFlatno + data.senderBuilding,
                        fromGoogle: senderAddr,
                        state: senderAddr.state,
                        city: senderAddr.city,
                        pincode: senderAddr.pincode
                    }
                },
                end: {
                    contact: {
                        name: data.receiverName,
                        phone: data.receiverContact,
                        email: data.receiverEmail
                    },
                    endLocation: {
                        address: data.receiverFlatno + data.receiverBuilding,
                        fromGoogle: receiverAddr,
                        state: receiverAddr.state,
                        city: receiverAddr.city,
                        pincode: receiverAddr.pincode
                    }
                },
                state: "Initiated"
            }
            console.log("REQ BODY = ", reqBody)
            dispatch(createExpressDelOrder(reqBody))
                .then(unwrapResult)
                .then((res) => {
                    console.log(res)
                    navigate('/orders/panIndiaOrders/express-orders')
                })
        }
    }

    return (
        <div style={{ padding: '30px' }}>
            <Button onClick={() => navigate(-1)}>{backButtonIcon}&nbsp;Back</Button>
            <h4 className='my-3'>Add Express delivery order</h4>
            <hr />
            <Form onSubmit={handleSubmit(createOrder)}>
                <Row>
                    <Col sm={6}>
                        <Form.Label>Select shipper</Form.Label>
                        <Controller
                            control={control}
                            name="shipperID"
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Select ref={ref} aria-label="Default select example" onChange={onChange} value={value}>
                                    <option value="">Select</option>
                                    {
                                        shippers?.map((shipper, index) => {
                                            return (
                                                <option key={index} value={shipper.shipperUID}>{shipper.company}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            )}
                        />
                        {errors.shipperID && <div className='error-text'>{errors.shipperID.message}</div>}
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="user"
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="user">
                                    <Form.Label>User UID&nbsp;<Link to="/customers" target="_blank">Get User details&nbsp;{openLinkOnNewPageIcon}</Link></Form.Label>
                                    <Form.Control ref={ref} type="text" placeholder="Enter user uid" onChange={onChange} value={value} />
                                    {errors.user && <div className='error-text'>{errors.user.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="weight"
                            rules={{ required: { value: true, message: 'This field is required' }, validate: value => value > 0 || 'Weight should be greater than 0', min: { value: 0, message: 'Weight should be greater than 0kg' } }}
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="weight">
                                    <Form.Label>Weight of the shipment</Form.Label>
                                    <Form.Control ref={ref} type="number" placeholder="Enter weight" onChange={onChange} value={value} />
                                    {errors.weight && <div className='error-text'>{errors.weight.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="cost"
                            rules={{ required: { value: true, message: 'This field is required' }, validate: (value) => value > 0 || 'Cost cannot be less than 0' }}
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="cost">
                                    <Form.Label>Cost&nbsp;(₹)</Form.Label>
                                    <Form.Control ref={ref} type="text" onChange={onChange} value={value} />
                                    {errors.cost && <div className='error-text'>{errors.cost.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="tracking_url"
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="tracking_url">
                                    <Form.Label>Tracking Url</Form.Label>
                                    <Form.Control ref={ref} type="text" placeholder="Enter tracking URL" onChange={onChange} value={value} />
                                </Form.Group>
                            )}
                        />
                    </Col>
                </Row>

                {/* Sender details */}
                <Row>
                    <Form.Label className='text-decoration-underline'>Sender details</Form.Label>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="senderName"
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="senderName">
                                    <Form.Label>Sender name</Form.Label>
                                    <Form.Control ref={ref} type="text" placeholder="Enter name of the sender" onChange={onChange} value={value} />
                                    {errors.senderName && <div className='error-text'>{errors.senderName.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="senderContact"
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="senderContact">
                                    <Form.Label>Sender contact</Form.Label>
                                    <Form.Control ref={ref} type="text" placeholder="Enter contact of the sender" onChange={onChange} value={value} />
                                    {errors.senderContact && <div className='error-text'>{errors.senderContact.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="senderEmail"
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="senderEmail">
                                    <Form.Label>Sender email</Form.Label>
                                    <Form.Control ref={ref} type="text" placeholder="Enter email of the sender" onChange={onChange} value={value} />
                                    {errors.senderEmail && <div className='error-text'>{errors.senderEmail.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderAddress">
                                        Sender address&nbsp;
                                    </Form.Label>
                                    <MapInput setMapData={setSenderAddr} value={senderAddr.address} id='int-sender-addr' />
                                    {(Object.keys(senderAddr).length !== 0 && !senderAddr?.pincode) && <div className='error-text'>Please enter a address with a valid pincode</div>}
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="flatNo">Flat no/House no/Apartment no</Form.Label>
                                    <Controller
                                        control={control}
                                        name="senderFlatno"
                                        rules={{ required: { value: true, message: 'This field is required' } }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Control ref={ref} type="text" placeholder="Enter flat no" onChange={onChange} value={value} />
                                        )}
                                    />
                                    {errors.senderFlatno && <div className='error-text'>{errors.senderFlatno.message}</div>}
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="buildingName">Building name</Form.Label>
                                    <Controller
                                        control={control}
                                        name="senderBuilding"
                                        rules={{ required: { value: true, message: 'This field is required' } }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Control ref={ref} type="text" placeholder="Enter building" onChange={onChange} value={value} />
                                        )}
                                    />
                                    {errors.senderBuilding && <div className='error-text'>{errors.senderBuilding.message}</div>}
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderCity">City</Form.Label>
                                    <Form.Control readOnly type='text' value={senderAddr?.city} />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderState">State</Form.Label>
                                    <Form.Control readOnly type='text' value={senderAddr?.state} />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderCountry">Country</Form.Label>
                                    <Form.Control readOnly type='text' value={senderAddr?.country} />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderPincode">Pincode</Form.Label>
                                    <Form.Control readOnly type='text' value={senderAddr?.pincode} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>

                </Row>

                {/* Receiver details */}
                <Row>
                    <Form.Label className='text-decoration-underline'>Receiver details</Form.Label>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="receiverName"
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="receiverName">
                                    <Form.Label>Receiver name</Form.Label>
                                    <Form.Control ref={ref} type="text" placeholder="Enter name of the receiver" onChange={onChange} value={value} />
                                    {errors.receiverName && <div className='error-text'>{errors.receiverName.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="receiverContact"
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="receiverContact">
                                    <Form.Label>Receiver contact</Form.Label>
                                    <Form.Control ref={ref} type="text" placeholder="Enter contact of the receiver" onChange={onChange} value={value} />
                                    {errors.receiverContact && <div className='error-text'>{errors.receiverContact.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="receiverEmail"
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="receiverEmail">
                                    <Form.Label>Receiver email</Form.Label>
                                    <Form.Control ref={ref} type="text" placeholder="Enter email of the receiver" onChange={onChange} value={value} />
                                    {errors.receiverEmail && <div className='error-text'>{errors.receiverEmail.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderAddress">
                                        Receiver address&nbsp;
                                    </Form.Label>
                                    <MapInput setMapData={setReceiverAddr} value={receiverAddr?.address} id='int-receiver-addr' />
                                    {(Object.keys(receiverAddr).length !== 0 && !receiverAddr?.pincode) && <div className='error-text'>Please enter a address with a valid pincode</div>}
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="flatNo">Flat no/House no/Apartment no</Form.Label>
                                    <Controller
                                        control={control}
                                        name="receiverFlatno"
                                        rules={{ required: { value: true, message: 'This field is required' } }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Control ref={ref} type="text" placeholder="Enter flat no" onChange={onChange} value={value} />
                                        )}
                                    />
                                    {errors.receiverFlatno && <div className='error-text'>{errors.receiverFlatno.message}</div>}
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="buildingName">Building name</Form.Label>
                                    <Controller
                                        control={control}
                                        name="receiverBuilding"
                                        rules={{ required: { value: true, message: 'This field is required' } }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Control ref={ref} type="text" placeholder="Enter building" onChange={onChange} value={value} />
                                        )}
                                    />
                                    {errors.receiverBuilding && <div className='error-text'>{errors.receiverBuilding.message}</div>}
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderCity">City</Form.Label>
                                    <Form.Control readOnly type='text' value={receiverAddr?.city} />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderState">State</Form.Label>
                                    <Form.Control readOnly type='text' value={receiverAddr?.state} />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderCountry">Country</Form.Label>
                                    <Form.Control readOnly type='text' value={receiverAddr?.country} />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderPincode">Pincode</Form.Label>
                                    <Form.Control readOnly type='text' value={receiverAddr?.pincode} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Package details */}
                <Row>
                    <Col sm={12}>
                        <Row>
                            <Form.Label className='text-decoration-underline'>Package details</Form.Label>
                            {
                                fields.map((item, index) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <Col sm={3}>
                                                <Controller
                                                    defaultValue={item.l}
                                                    control={control}
                                                    rules={{ required: { value: true, message: 'This field is required' } }}
                                                    name={`pkg.${index}.l`}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Form.Group className="mb-3" controlId="packageLength">
                                                            <Form.Label className='text-muted'>Length(cm)</Form.Label>
                                                            <Form.Control ref={ref} type="text" placeholder="Enter length of the package" onChange={onChange} value={value} required />
                                                            {/* {(errors.pkg && errors.pkg[index].l) && <div className='error-text'>{errors.pkg[index].l.message}</div>} */}
                                                        </Form.Group>
                                                    )}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <Controller
                                                    defaultValue={item.b}
                                                    control={control}
                                                    rules={{ required: { value: true, message: 'This field is required' } }}
                                                    name={`pkg.${index}.b`}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Form.Group className="mb-3" controlId="packageBreadth">
                                                            <Form.Label className='text-muted'>Breadth(cm)</Form.Label>
                                                            <Form.Control ref={ref} type="text" placeholder="Enter breadth of the package" onChange={onChange} value={value} required />
                                                            {/* {(errors.pkg && errors.pkg[index].b) && <div className='error-text'>{errors.pkg[index].b.message}</div>} */}
                                                        </Form.Group>
                                                    )}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <Controller
                                                    defaultValue={item.h}
                                                    control={control}
                                                    rules={{ required: { value: true, message: 'This field is required' } }}
                                                    name={`pkg.${index}.h`}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Form.Group className="mb-3" controlId="packageHeight">
                                                            <Form.Label className='text-muted'>Height(cm)</Form.Label>
                                                            <Form.Control ref={ref} type="text" placeholder="Enter height of the package" onChange={onChange} value={value} required />
                                                            {/* {(errors.pkg && errors.pkg[index].h) && <div className='error-text'>{errors.pkg[index].h.message}</div>} */}
                                                        </Form.Group>
                                                    )}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <Controller
                                                    defaultValue={item.weight}
                                                    control={control}
                                                    rules={{ required: { value: true, message: 'This field is required' } }}
                                                    name={`pkg.${index}.weight`}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Form.Group className="mb-3" controlId="packageLength">
                                                            <Form.Label className='text-muted'>Approx. Weight(kgs)</Form.Label>
                                                            <Form.Control ref={ref} type="text" placeholder="Enter weight of the package" onChange={onChange} value={value} required />
                                                            {/* {(errors.pkg && errors.pkg[index].weight) && <div className='error-text'>{errors.pkg[index].weight.message}</div>} */}
                                                        </Form.Group>
                                                    )}
                                                />
                                            </Col>
                                            <Col xs={12}>
                                                {fields.length !== 1 && <Button variant='danger' type='button' className='mb-3' onClick={() => remove(index)}>Remove</Button>}
                                            </Col>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Row>
                        <Button variant='success' className='mb-3' onClick={() => append({ l: '', b: '', h: '', weight: '' })}>Add</Button>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            name="bookedAt"
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="receiverAddress">
                                    <Form.Label>Booked at</Form.Label>
                                    <Form.Control ref={ref} type="datetime-local" onChange={onChange} value={value} />
                                    {errors.bookedAt && <div className='error-text'>{errors.bookedAt.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            defaultValue={false}
                            name="fragile"
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="fragile">
                                    <Form.Label>Fragile</Form.Label>
                                    <Form.Select ref={ref} onChange={onChange} value={value}>
                                        <option value='true'>True</option>
                                        <option value='false'>False</option>
                                    </Form.Select>
                                    {/* {errors.fragile && <div className='error-text'>{errors.fragile.message}</div>} */}
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="gst"
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="gst">
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control ref={ref} type="text" placeholder="Enter gst" onChange={onChange} value={value} />
                                </Form.Group>
                            )}
                        />
                        {/* {errors.gst && <div className='error-text'>{errors.gst.message}</div>} */}
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="monetary_value"
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="monetary_value">
                                    <Form.Label>Monetary value</Form.Label>
                                    <Form.Control ref={ref} type="text" placeholder="Enter monetary value" onChange={onChange} value={value} />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="payment_status"
                            defaultValue='Initiated'
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="payment_status">
                                    <Form.Label>Payment status</Form.Label>
                                    <Form.Select ref={ref} onChange={onChange} value={value}>
                                        <option value='Initiated'>Initiated</option>
                                        <option value='Successful'>Successful</option>
                                        <option value='Pending'>Pending</option>
                                    </Form.Select>
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="shipmentPurpose"
                            defaultValue='personal'
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="shipmentPurpose">
                                    <Form.Label>Shipment Purpose</Form.Label>
                                    <Form.Select ref={ref} onChange={onChange} value={value}>
                                        <option value='personal'>Personal</option>
                                        <option value='business'>Business</option>
                                    </Form.Select>
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="goodsCategory"
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="goodsCategory">
                                    <Form.Label>Category of goods</Form.Label>
                                    <Form.Control ref={ref} type="text" placeholder="Enter category of goods" onChange={onChange} value={value} />
                                </Form.Group>
                            )}
                        />
                    </Col>
                </Row>

                <Button type='submit' disabled={fetching}>
                    Submit&nbsp;
                    {
                        fetching && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                </Button>
            </Form>
        </div>
    )
}
