import React from 'react'
import { useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'

export default function InvoiceEmail({ show, handleClose, sendEmail, loading = false }) {
    const [email, setEmail] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        sendEmail(email)
    }
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                        <Button variant="secondary" className='me-2' onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' disabled={loading} >
                            {
                                loading ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </> :
                                    "Send"
                            }
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
