const axios = require('axios')

export const getShipmantraDetails = () => {
    const name = 'GoLintu Services Pvt. Ltd'
    const companyGST = '37AAHCG9147L1ZZ'
    const companyAddress = {
        line1: 'NASSCOM CoE I To& U AT',
        line2: 'AU North Campus, Andhra University',
        line3: 'Visakhapatnam Andhra Pradesh 530003 India'
    }
    const companyCIN = 'U63000AP2019PTC112471'
    return { name, companyAddress, companyGST, companyCIN }
}

export const sendInvoiceMail = async (base64, email) => {
    await axios({
        method: 'post',
        url: 'https://mandrillapp.com/api/1.0/messages/send-template',
        data: {
            "key": "Oji3AJZ51JDbndEpkF0RKQ",
            "template_name": "invoice",
            "template_content": [

            ],
            "message": {
                "html": "",
                "text": "",
                "subject": "",
                "from_email": "",
                "from_name": "",
                "to": [
                    { "email": email, "type": "to" },
                    { "email": "support@shipmantra.com", "type": "cc" },
                    { "email": "dinesh@golintu.com", "type": "bcc" },
                    { "email": "rkrishnachaitanya@golintu.com", "type": "bcc" }
                ],
                "headers": {

                },
                "important": false,
                "track_opens": false,
                "track_clicks": false,
                "auto_text": false,
                "auto_html": false,
                "inline_css": false,
                "url_strip_qs": false,
                "preserve_recipients": false,
                "view_content_link": false,
                "bcc_address": "",
                "tracking_domain": "",
                "signing_domain": "",
                "return_path_domain": "",
                "merge": false,
                "merge_language": "mailchimp",
                "global_merge_vars": [],
                "merge_vars": [

                ],
                "tags": [

                ],
                "subaccount": "shipmantra_support",
                "google_analytics_domains": [

                ],
                "google_analytics_campaign": "",
                "metadata": {
                    "website": ""
                },
                "recipient_metadata": [

                ],
                "attachments": [{
                    "type": "application/pdf",
                    "name": "Invoice",
                    "content": base64
                }],
                "images": [

                ]
            },
            "async": false,
            "ip_pool": "",
            "send_at": ""
        }
    })
        .then(function (response, error) {
            console.log('Inside Email Then');
            if (error) {
                console.log('Some Error Occured: ' + error);
                let json = {
                    success: false,
                    message: 'Some error occured: ' + error
                }
                return json;
            } else {
                console.log(response.data);
                let json = {
                    success: true,
                    message: 'Mail was sent successfully'
                }
                return json;
            }
        })
        .catch(err => {
            console.log(err);
        })
}

function convertNumberToWords(number) {
    var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    if ((number = number.toString()).length > 9) return 'overflow';
    const n = ('000000000' + number).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
    return str;
}

export const convertDecimalNumbersToWords = (number) => {
    var nums = number?.toString()?.split('.')
    var whole = convertNumberToWords(nums[0])
    if (nums.length == 2) {
        var fraction = convertNumberToWords(nums[1])
        return whole + 'Rupees ' + 'and ' + fraction + (fraction ? 'paise' : '') + ' only';
    } else {
        return whole + 'Rupees only';
    }
}


