import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { fetchUserById, updateUserByAdmin } from '../../app/features/customers/customerSlice';
import { backButtonIcon } from '../../FontAwesome';

export default function CustomerDetails() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams();

    const customerUID = params.uid

    const { fetching, customerDetails } = useSelector(state => state.customer)
    const { adminUser } = useSelector(state => state.adminUser)

    const [customerDetail, setCustomerDetail] = useState({});
    const [username, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userAddress, setUserAddress] = useState([]);
    const [userBlocked, isUserBlocked] = useState("");
    const [isAdmin, setIsAdmin] = useState(false)


    useEffect(async () => {
        // dispatch(fetchUserById(orderId))
        dispatch(fetchUserById(customerUID))
    }, [])

    useEffect(() => {
        setCustomerDetail(customerDetails)
        setUserName(customerDetails.username)
        setUserAddress(customerDetails.address)
        setUserEmail(customerDetails.email)
        isUserBlocked(customerDetails.blocked)
        setIsAdmin(customerDetails.isAdmin)
    }, [customerDetails])


    const handleSubmit = e => {
        e.preventDefault();
        const data = {
            uid: customerUID,
            requestBody: {
                username: username,
                email: userEmail,
                address: userAddress,
                blocked: userBlocked,
                token: adminUser.token,
                isAdmin: isAdmin
            }
        }
        console.log(data);
        dispatch(updateUserByAdmin(data))
            .then(() => dispatch(fetchUserById(customerUID)))
    }

    if (fetching) return (
        <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
            <Spinner animation="border" />
        </div>
    )

    return (
        <Container className='mt-4'>
            <Button variant='primary' onClick={e => { navigate(-1) }}>{backButtonIcon}&nbsp;Back</Button>
            <hr />
            <Form onSubmit={handleSubmit}>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="isAdmin">
                            <Form.Label>Is Admin ? </Form.Label>
                            <Form.Check
                                checked={isAdmin}
                                onChange={() => setIsAdmin(!isAdmin)}
                                type="switch"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" value={userEmail} placeholder="Email" onChange={(e => setUserEmail(e.currentTarget.value))} />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" value={username} placeholder="Username" onChange={(e => setUserName(e.currentTarget.value))} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="blocked">
                            <Form.Label>Blocked</Form.Label>
                            <Form.Check type="switch" value={userBlocked} checked={userBlocked} placeholder="Blocked" onChange={(e => isUserBlocked(!userBlocked))} />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="contact">
                            <Form.Label>Contact</Form.Label>
                            <Form.Control type="text" value={customerDetails.contact} placeholder="Contact" readOnly />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="address">
                            <Form.Label>Address</Form.Label>
                            {
                                (userAddress && userAddress.length > 0) && userAddress?.map((address, index) => {
                                    return (
                                        <Form.Control key={index} type="text" value={(address.flatno + ", " + address.building + ", " + address.fromGoogle.address + ", " + address.country)} placeholder="Address" readOnly />
                                    )
                                })
                            }
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="uid">
                            <Form.Label>UID</Form.Label>
                            <Form.Control type="text" value={customerDetails.uid} placeholder="UID" readOnly />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant='primary' type="submit">Submit</Button>
            </Form>
        </Container >
    )
}
