import { checkLogin } from "../../utils/firebase";

const baseUrl = process.env.REACT_APP_BASE_URL
const localhostUrl = 'http://localhost:8000'

export const requestPaymentAPI = async (reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = fetch(`${baseUrl}/generatePaymentLink/razorpay`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Headers': "*",
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error)
    }
}