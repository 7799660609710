import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useState } from 'react';
import { Alert, Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import OtpInput from "react-otp-input";
import { loginWithPhone, loginWithPhoneOTP } from '../../utils/firebase';
import { backButtonIcon, copyIcon } from '../../FontAwesome';
import { loginCustomerAPI } from '../../app/api/customers';
import { useDispatch } from 'react-redux';
import { fetchAllUsers, registerNewCustomer } from '../../app/features/customers/customerSlice';
import { unwrapResult } from '@reduxjs/toolkit';


export default function RegisterCustomer({ show, handleClose }) {
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [userDetailsAlert, setUserDetailsAlert] = useState(false)
    const [userExistsAlert, setUserExistsAlert] = useState(false)
    const [userCreatedAlert, setUserCreatedAlert] = useState(false)


    const [user, setUser] = useState({})
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [signInState, setSignInState] = useState('number');
    const [phoneExpectedLength, setPhoneExpectedLength] = useState(0)
    const [isPhoneValid, setIsPhoneValid] = useState(false)

    const [otp, setOtp] = useState('');
    const [otpErr, setOtpErr] = useState(false);


    const submitNumber = () => {
        setUserDetailsAlert(false)
        if (isPhoneValid && email) {
            setIsLoading(true)
            setSignInState('number-submitted');
            console.log(phoneNumber)
            loginWithPhone("+" + phoneNumber)
                .then(async () => {
                    setSignInState('otp');
                    setIsLoading(false);
                }).catch((err) => {
                    console.log("LOGIN ERR: ", err);
                    setIsLoading(false);
                    setSignInState('number');
                })
        } else {
            setUserDetailsAlert(true)
        }
    }

    const submitOTP = () => {
        setUserDetailsAlert(false)
        if (otp.length === 6) {
            setIsLoading(true);
            loginWithPhoneOTP(otp)
                .then((res) => {
                    console.log("USER :", res);
                    const token = res.accessToken
                    loginCustomerAPI({ token })
                        .then(res => {
                            console.log(res)
                            setSignInState('user-exists')
                            setUserExistsAlert(true)
                            setUser(res.response.data)
                            setIsLoading(false)
                        })
                        .catch(err => {
                            console.log(err)
                            if (err.error === 404) {
                                const userData = {
                                    token: token,
                                    email: email,
                                    phone: "+" + phoneNumber
                                }
                                dispatch(registerNewCustomer(userData))
                                    .then(unwrapResult)
                                    .then((res) => {
                                        console.log(res)
                                        alert("User registered successfully")
                                        handleClose()
                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })
                            }
                        })
                    // loginNew({ token: res.stsTokenManager.accessToken })
                    //     .then(
                    //         async (res) => {
                    //             console.log("DATA", res);
                    //             dispatch(loginFromDB(res.response));
                    //             if (callback) {
                    //                 callback()
                    //             }
                    //             setOpened(false)
                    //         }
                    //     ).catch((err) => {
                    //         console.log("DATA", err);
                    //         if (err.error === 403) {
                    //             setIsLoading(false)
                    //             // setBlocked(true)
                    //             // alert("User forbidden")
                    //             dispatch(setBlocked(true))
                    //             setSignInState('number')
                    //             setOtp('')
                    //             setOpened(false)
                    //             // if(callback){
                    //             //   callback()
                    //             // }
                    //             navigate('/')
                    //         }
                    //         else
                    //             if (err.error === 404) {
                    //                 setToken(res.stsTokenManager.accessToken);
                    //                 setIsLoading(false);
                    //                 setSignInState('new');
                    //             } else {
                    //                 setIsLoading(false);
                    //             }
                    //     })
                }
                ).catch((err) => {
                    console.log("LOGIN ERR: ", err);
                    setIsLoading(false);
                    setSignInState('otp-err');
                }
                )
        } else {
            setOtpErr(true);
        }

    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                {
                    (signInState === 'number' || signInState === 'number-submitted') &&
                    <>
                        {
                            userDetailsAlert &&
                            <>
                                <Alert variant='danger' onClose={() => setUserDetailsAlert(false)} dismissible>Please fill all the details</Alert>
                            </>
                        }

                        <div>Enter email address</div>
                        <input type="email" className='form-control' value={email} onChange={e => setEmail(e.currentTarget.value)} style={{ marginTop: '10px' }} />
                        <div className='mt-3'>Enter phone number</div>
                        <PhoneInput
                            inputStyle={{
                                width: '100%',
                            }}
                            containerClass={((!isPhoneValid && phoneNumber) ? "error-border" : "")}
                            containerStyle={{ marginTop: '10px' }}
                            country={'in'}
                            enableSearch={true}
                            disableDropdown={false}
                            placeholder=""
                            countryCodeEditable={false}
                            value={phoneNumber}
                            onChange={(inputPhone, countryData) => {
                                setPhoneNumber(inputPhone)
                                const expectedPhoneLength = countryData.format.replaceAll(/[\s-()+]/g, '').length
                                setPhoneExpectedLength(expectedPhoneLength)
                                setIsPhoneValid(expectedPhoneLength === inputPhone.length)
                            }}
                        />
                        {(!isPhoneValid && phoneNumber) && <div className="error-text mt-1"><FontAwesomeIcon icon={faCircleExclamation} />&nbsp;Please enter a valid phone number.</div>}
                        <Button id='phone-signin-btn' onClick={submitNumber} className='mt-3' disabled={isLoading}>
                            {
                                isLoading ?
                                    <Spinner animation='border' />
                                    : "Get OTP"
                            }
                        </Button>
                    </>
                }

                {(signInState === "otp" || signInState === 'otp-err') &&
                    <>
                        <Button variant='secondary' onClick={() => setSignInState('number')} style={{ marginLeft: '1rem' }} type='button'>{backButtonIcon}&nbsp;Back</Button>
                        <hr />
                        <OtpInput
                            value={otp} shouldAutoFocus={true}
                            onChange={(otp) => setOtp(otp)}
                            numInputs={6} isInputNum={true}
                            hasErrored={otpErr || signInState === 'otp-err'}
                            inputStyle="otp-input"
                            errorStyle="otp-error"
                        // containerStyle="otp-wrapper"
                        />
                        {otpErr && <div className="error-text mt-1" style={{ marginLeft: '1rem' }}><FontAwesomeIcon icon={faCircleExclamation} />&nbsp;Incorrect otp.</div>}
                        <div className='d-flex justify-content-center align-items-center'>
                            <Button variant="primary" onClick={submitOTP} className='mt-3' disabled={isLoading}>
                                {isLoading ?
                                    <>
                                        <Spinner
                                            as="border"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />&nbsp;
                                        Creating user...
                                    </>
                                    : "Submit"
                                }
                            </Button>
                        </div>
                    </>
                }

                {
                    signInState === 'user-exists' &&
                    <>
                        {
                            userExistsAlert &&
                            <>
                                <Alert variant='success' onClose={() => setUserExistsAlert(false)} dismissible>User already exists.</Alert>
                            </>
                        }
                        <Row>
                            <Col sm={3}>UID</Col>
                            <Col sm={9}>{user.uid}&nbsp;<span style={{ cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText(user.uid)}>{copyIcon}</span></Col>
                        </Row>

                        <Row>
                            <Col sm={3}>Username</Col>
                            <Col sm={9}>{user.username}</Col>
                        </Row>

                        <Row>
                            <Col sm={3}>Email</Col>
                            <Col sm={9}>{user.email}</Col>
                        </Row>

                        <Row>
                            <Col sm={3}>Contact</Col>
                            <Col sm={9}>{user.contact}</Col>
                        </Row>
                    </>
                }

                {/* {
                    signInState === 'user-created' &&
                    <>
                        {
                            userCreatedAlert &&
                            <>
                                <Alert variant='success' onClose={() => setUserCreatedAlert(false)} dismissible>User registerd successfully.</Alert>
                            </>
                        }
                        User uid: {user.uid}&nbsp;<span style={{ cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText(user.uid)}>{copyIcon}</span>
                    </>
                } */}
            </Modal.Body>
        </Modal>
    )
}
