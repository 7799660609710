import { unwrapResult } from '@reduxjs/toolkit'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Alert, Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { calculateGoogleAddress } from '../../app/api/general'
import { getAllCountries } from '../../app/features/countries/countrySlice'
import { createManualInternationalOrder } from '../../app/features/order/orderSlice'
import { fetchAllIntShipper } from '../../app/features/shipper/shipperSlice'
import { backButtonIcon, openLinkOnNewPageIcon } from '../../FontAwesome'
import MapInput from '../MapInput'

export default function AddNewInternationalOrder() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [senderAddr, setSenderAddr] = useState({})
    const [receiverAddr, setReceiverAddr] = useState({})

    const { handleSubmit, control, setValue, setFocus, getValues, formState: { errors } } = useForm({
        defaultValues: {
            shipperID: '',
            user: '',
            email: '',
            senderName: '',
            senderContact: '',
            senderEmail: '',
            senderFlatno: '',
            senderBuilding: '',
            receiverName: '',
            receiverContact: '',
            receiverEmail: '',
            receiverFlatno: '',
            receiverBuilding: '',
            city: '',
            items: [{ name: '', qty: '' }],
            pkg: [{ l: '', b: '', h: '' }],
            bookedAt: '',
            cost: '',
            weight: 0,
            order_type: 'int_export',
            payment_status: 'Initiated',
            coupon: '',
            destination: '',
            tracking_url: '',
            state: 'Initiated'
        }
    })

    const {
        fields: pkgFields,
        append: pkgAppend,
        remove: pkgRemove } = useFieldArray({
            control,
            name: "pkg",
        });

    const {
        fields: itemsFields,
        append: itemsAppend,
        remove: itemsRemove } = useFieldArray({
            control,
            name: "items",
        });


    const { shippers } = useSelector(state => state.shipper)
    const { countries } = useSelector(state => state.country)
    const { fetching } = useSelector(state => state.order)

    useEffect(() => {
        dispatch(fetchAllIntShipper())
    }, [])

    useEffect(() => {
        dispatch(getAllCountries())
    }, [])

    useEffect(() => {
        const firstError = Object.keys(errors).reduce((field, a) => {
            return errors[field] ? field : a;
        }, null);

        if (firstError) {
            setFocus(firstError);
        }
    }, [errors, setFocus]);

    const createOrder = async (data) => {
        console.log(data)
        // const senderAddrDetails = await calculateGoogleAddress(data.senderAddress)
        // const receiverAddrDetails = await calculateGoogleAddress(data.receiverAddress)
        // console.log(senderAddrDetails, receiverAddrDetails)

        // let senderDetails = {
        //     pincode: '',
        //     state: '',
        //     city: '',
        //     fromGoogle: {
        //         lat: '',
        //         lng: '',
        //         address: '',
        //         pincode: ''
        //     }
        // }
        // let receiverDetails = {
        //     pincode: '',
        //     state: '',
        //     city: '',
        //     fromGoogle: {
        //         lat: '',
        //         lng: '',
        //         address: '',
        //         pincode: ''
        //     }
        // }

        if ((Object.keys(senderAddr) && !senderAddr.pincode) || (Object.keys(receiverAddr) && !receiverAddr.pincode)) {
            alert('Please enter sender and receiver addresses with a valid pincode')
        } else {
            const reqBody = {
                shipperID: data.shipperID,
                user: data.user,
                email: data.email,
                city: senderAddr.city,
                items: data.items,
                weight: data.weight,
                cost: data.cost,
                pkg: data.pkg,
                bookedAt: data.bookedAt,
                order_type: 'int_export',
                payment_status: data.payment_status,
                start: {
                    contact: {
                        name: data.senderName,
                        phone: data.senderContact,
                        email: data.senderEmail
                    },
                    address: {
                        flatno: data.senderFlatno,
                        building: data.senderBuilding,
                        fromGoogle: senderAddr,
                    }
                },
                end: {
                    contact: {
                        name: data.receiverName,
                        phone: data.receiverContact,
                        email: data.receiverEmail
                    },
                    address: {
                        city: receiverAddr.city,
                        zip: receiverAddr.pincode,
                        address: {
                            flatno: data.receiverFlatno,
                            building: data.receiverBuilding,
                            fromGoogle: receiverAddr
                        }
                    }
                },
                coupon: data.coupon,
                destination: data.destination,
                state: 'Initiated'
            }
            console.log("REQ BODY = ", reqBody)
            dispatch(createManualInternationalOrder({ data: reqBody }))
                .then(unwrapResult)
                .then(() => navigate('/orders/internationalOrders'))
        }
        // if (senderAddrDetails.status === 'OK' && receiverAddrDetails.status === 'OK' && senderAddrDetails.results.length > 0 && receiverAddrDetails.results.length > 0) {
        //     const senderAddressComp = senderAddrDetails.results[0].address_components
        //     const receiverAddressComp = receiverAddrDetails.results[0].address_components
        //     senderDetails.fromGoogle.lat = senderAddrDetails.results[0].geometry.location.lat
        //     senderDetails.fromGoogle.lng = senderAddrDetails.results[0].geometry.location.lng
        //     senderDetails.fromGoogle.address = senderAddrDetails.results[0].formatted_address

        //     receiverDetails.fromGoogle.lat = receiverAddrDetails.results[0].geometry.location.lat
        //     receiverDetails.fromGoogle.lng = receiverAddrDetails.results[0].geometry.location.lng
        //     receiverDetails.fromGoogle.address = receiverAddrDetails.results[0].formatted_address
        //     if (senderAddressComp) {
        //         for (let i = 0; i < senderAddressComp.length; i++) {
        //             const typesArray = senderAddressComp[i].types
        //             for (let j = 0; j < typesArray.length; j++) {
        //                 if (typesArray[j] === "postal_code") {
        //                     senderDetails.pincode = senderAddressComp[i].long_name
        //                     senderDetails.fromGoogle.pincode = senderAddressComp[i].long_name
        //                 }
        //                 if (typesArray[j] === "administrative_area_level_1")
        //                     senderDetails.state = senderAddressComp[i].long_name
        //                 if (typesArray[j] === "locality")
        //                     senderDetails.city = senderAddressComp[i].long_name
        //             }
        //         }
        //     }

        //     if (receiverAddressComp) {
        //         for (let i = 0; i < receiverAddressComp.length; i++) {
        //             const typesArray = receiverAddressComp[i].types
        //             for (let j = 0; j < typesArray.length; j++) {
        //                 if (typesArray[j] === "postal_code") {
        //                     receiverDetails.pincode = receiverAddressComp[i].long_name
        //                     receiverDetails.fromGoogle.pincode = receiverAddressComp[i].long_name
        //                 }
        //                 if (typesArray[j] === "administrative_area_level_1")
        //                     receiverDetails.state = receiverAddressComp[i].long_name
        //                 if (typesArray[j] === "locality")
        //                     receiverDetails.city = receiverAddressComp[i].long_name
        //             }
        //         }
        //     }

        //     const reqBody = {
        //         shipperID: data.shipperID,
        //         user: data.user,
        //         email: data.email,
        //         city: senderDetails.city,
        //         items: data.items,
        //         weight: data.weight,
        //         cost: data.cost,
        //         pkg: data.pkg,
        //         bookedAt: data.bookedAt,
        //         order_type: 'int_export',
        //         payment_status: data.payment_status,
        //         start: {
        //             contact: {
        //                 name: data.senderName,
        //                 phone: data.senderContact,
        //                 email: data.senderEmail
        //             },
        //             address: {
        //                 flatno: data.senderFlatno,
        //                 building: data.senderBuilding,
        //                 fromGoogle: senderAddr,
        //             }
        //         },
        //         end: {
        //             contact: {
        //                 name: data.receiverName,
        //                 phone: data.receiverContact,
        //                 email: data.receiverEmail
        //             },
        //             address: {
        //                 city: receiverAddr.city,
        //                 zip: receiverAddr.pincode,
        //                 address: {
        //                     flatno: data.receiverFlatno,
        //                     building: data.receiverBuilding,
        //                     fromGoogle: receiverAddr
        //                 }
        //             }
        //         },
        //         coupon: data.coupon,
        //         destination: data.destination,
        //         state: 'Initiated'
        //     }
        //     console.log("REQ BODY = ", reqBody)
        //     dispatch(createManualInternationalOrder({ data: reqBody }))
        //         .then(unwrapResult)
        //         .then(() => navigate('/orders/internationalOrders'))
        // } else {
        //     alert("Please check the sender and receiver address")
        //     setError('senderAddress', { type: 'custom', message: 'Please check address' })
        //     setError('receiverAddress', { type: 'custom', message: 'Please check address' })
        // }
    }


    return (
        <div style={{ padding: '30px' }}>
            <Button onClick={() => navigate(-1)}>{backButtonIcon}&nbsp;Back</Button>
            <h4 className='my-3'>Add International delivery order</h4>
            <hr />
            <Form onSubmit={handleSubmit(createOrder)}>
                <Row>
                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="shipperID">Select shipper</Form.Label>
                            <Controller
                                control={control}
                                name="shipperID"
                                rules={{ required: { value: true, message: 'This field is required' } }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Form.Select ref={ref} aria-label="Select shipper" onChange={onChange} value={value}>
                                        <option value="">Select</option>
                                        {
                                            shippers?.map((shipper, index) => {
                                                return (
                                                    <option key={index} value={shipper.intShipperUID}>{shipper.shipper}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                )}
                            />
                            {errors.shipperID && <div className='error-text'>{errors.shipperID.message}</div>}
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="user">Enter user UID&nbsp;<Link to="/customers" target="_blank">Get User details&nbsp;{openLinkOnNewPageIcon}</Link></Form.Label>
                            <Controller
                                control={control}
                                name="user"
                                rules={{ required: { value: true, message: 'This field is required' } }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Form.Control type="text" ref={ref} placeholder="Enter user uid" onChange={onChange} value={value} />
                                )}
                            />
                            {errors.user && <div className='error-text'>{errors.user.message}</div>}
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="email">Enter email</Form.Label>
                            <Controller
                                control={control}
                                name="email"
                                rules={{ required: { value: true, message: 'This field is required' } }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Form.Control ref={ref} type="email" placeholder="Enter email" onChange={onChange} value={value} />
                                )}
                            />
                            {errors.email && <div className='error-text'>{errors.email.message}</div>}
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="tracking_url">Enter tracking Url</Form.Label>
                            <Controller
                                control={control}
                                name="tracking_url"
                                render={({ field: { onChange, value, ref } }) => (
                                    <Form.Control ref={ref} type="text" placeholder="Enter tracking url" onChange={onChange} value={value} />
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {/* Sender details */}
                <Row>
                    <Form.Label className='text-decoration-underline'>Sender details</Form.Label>
                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="senderName">Enter sender name</Form.Label>
                            <Controller
                                control={control}
                                name="senderName"
                                rules={{ required: { value: true, message: 'This field is required' } }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Form.Control ref={ref} type="text" placeholder="Enter sender name" onChange={onChange} value={value} />
                                )}
                            />
                            {errors.senderName && <div className='error-text'>{errors.senderName.message}</div>}
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="senderContact">Enter sender contact</Form.Label>
                            <Controller
                                control={control}
                                name="senderContact"
                                rules={{ required: { value: true, message: 'This field is required' } }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Form.Control ref={ref} type="text" placeholder="Enter sender contact" onChange={onChange} value={value} />
                                )}
                            />
                            {errors.senderContact && <div className='error-text'>{errors.senderContact.message}</div>}
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Row>
                            {/* <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderAddress">Enter sender address</Form.Label>
                                    <Controller
                                        control={control}
                                        name="senderAddress"
                                        rules={{ required: { value: true, message: 'This field is required' } }}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Control type="text" placeholder="Enter sender address" onChange={onChange} value={value} />
                                        )}
                                    />
                                    {errors.senderAddress && <div className='error-text'>{errors.senderAddress.message}</div>}
                                </Form.Group>
                            </Col> */}
                            <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderAddress">
                                        Sender address&nbsp;
                                        {/* <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    Enter address with a valid pincode. You can just enter the pincode as well.
                                                </Tooltip>
                                            }
                                        >
                                            <Button size='sm' variant='dark' style={{ borderRadius: '50%' }}>
                                                <FontAwesomeIcon icon={faInfo} />
                                            </Button>
                                        </OverlayTrigger> */}
                                    </Form.Label>
                                    <MapInput setMapData={setSenderAddr} value={senderAddr.address} id='int-sender-addr' />
                                    {(Object.keys(senderAddr).length !== 0 && !senderAddr?.pincode) && <div className='error-text'>Please enter a address with a valid pincode</div>}
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="flatNo">Flat no/House no/Apartment no</Form.Label>
                                    <Controller
                                        control={control}
                                        name="senderFlatno"
                                        rules={{ required: { value: true, message: 'This field is required' } }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Control ref={ref} type="text" placeholder="Enter flat no" onChange={onChange} value={value} />
                                        )}
                                    />
                                    {errors.senderFlatno && <div className='error-text'>{errors.senderFlatno.message}</div>}
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="buildingName">Building name</Form.Label>
                                    <Controller
                                        control={control}
                                        name="senderBuilding"
                                        rules={{ required: { value: true, message: 'This field is required' } }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Control ref={ref} type="text" placeholder="Enter building" onChange={onChange} value={value} />
                                        )}
                                    />
                                    {errors.senderBuilding && <div className='error-text'>{errors.senderBuilding.message}</div>}
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderCity">City</Form.Label>
                                    <Form.Control readOnly type='text' value={senderAddr?.city} />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderState">State</Form.Label>
                                    <Form.Control readOnly type='text' value={senderAddr?.state} />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderCountry">Country</Form.Label>
                                    <Form.Control readOnly type='text' value={senderAddr?.country} />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderPincode">Pincode</Form.Label>
                                    <Form.Control readOnly type='text' value={senderAddr?.pincode} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="senderEmail">Enter sender email&nbsp;<Alert.Link href="#" onClick={e => { e.preventDefault(), setValue('senderEmail', getValues('email')) }}>Same as above</Alert.Link></Form.Label>
                            <Controller
                                control={control}
                                name="senderEmail"
                                rules={{ required: { value: true, message: 'This field is required' } }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Form.Control ref={ref} type="email" placeholder="Enter sender email" onChange={onChange} value={value} />
                                )}
                            />
                            {errors.senderEmail && <div className='error-text'>{errors.senderEmail.message}</div>}
                        </Form.Group>
                    </Col>
                </Row>

                {/* Receiver details */}
                <Row>
                    <Form.Label className='text-decoration-underline'>Receiver details</Form.Label>
                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="receiverName">Enter receiver name</Form.Label>
                            <Controller
                                control={control}
                                name="receiverName"
                                rules={{ required: { value: true, message: 'This field is required' } }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Form.Control ref={ref} type="text" placeholder="Enter receiver name" onChange={onChange} value={value} />
                                )}
                            />
                            {errors.receiverName && <div className='error-text'>{errors.receiverName.message}</div>}
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="receiverContact">Enter receiver contact</Form.Label>
                            <Controller
                                control={control}
                                name="receiverContact"
                                rules={{ required: { value: true, message: 'This field is required' } }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Form.Control ref={ref} type="text" placeholder="Enter receiver contact" onChange={onChange} value={value} />
                                )}
                            />
                            {errors.receiverContact && <div className='error-text'>{errors.receiverContact.message}</div>}
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Controller
                            control={control}
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            name="destination"
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="destination">
                                    <Form.Label>Destination</Form.Label>
                                    <Form.Select ref={ref} aria-label="Select destination" onChange={onChange} value={value}>
                                        <option value="">Select</option>
                                        {
                                            countries?.map((shipper, index) => {
                                                return (
                                                    <option key={index} value={shipper.countryString}>{shipper.countryText}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                    {errors.destination && <div className='error-text'>{errors.destination.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="receiverEmail">Enter receiver email</Form.Label>
                            <Controller
                                control={control}
                                name="receiverEmail"
                                render={({ field: { onChange, value, ref } }) => (
                                    <Form.Control ref={ref} type="email" placeholder="Enter receiver email" onChange={onChange} value={value} />
                                )}
                            />
                            {errors.receiverEmail && <div className='error-text'>{errors.receiverEmail.message}</div>}
                        </Form.Group>
                    </Col>


                    <Col xs={12} sm={6}>
                        <Row>
                            {/* <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderAddress">Enter sender address</Form.Label>
                                    <Controller
                                        control={control}
                                        name="senderAddress"
                                        rules={{ required: { value: true, message: 'This field is required' } }}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Control type="text" placeholder="Enter sender address" onChange={onChange} value={value} />
                                        )}
                                    />
                                    {errors.senderAddress && <div className='error-text'>{errors.senderAddress.message}</div>}
                                </Form.Group>
                            </Col> */}
                            <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderAddress">
                                        Receiver address&nbsp;
                                        {/* <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    Enter address with a valid pincode. You can just enter the pincode as well.
                                                </Tooltip>
                                            }
                                        >
                                            <Button size='sm' variant='dark' style={{ borderRadius: '50%' }}>
                                                <FontAwesomeIcon icon={faInfo} />
                                            </Button>
                                        </OverlayTrigger> */}
                                    </Form.Label>
                                    <MapInput setMapData={setReceiverAddr} value={receiverAddr?.address} id='int-receiver-addr' />
                                    {(Object.keys(receiverAddr).length !== 0 && !receiverAddr?.pincode) && <div className='error-text'>Please enter a address with a valid pincode</div>}
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="flatNo">Flat no/House no/Apartment no</Form.Label>
                                    <Controller
                                        control={control}
                                        name="receiverFlatno"
                                        rules={{ required: { value: true, message: 'This field is required' } }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Control ref={ref} type="text" placeholder="Enter flat no" onChange={onChange} value={value} />
                                        )}
                                    />
                                    {errors.receiverFlatno && <div className='error-text'>{errors.receiverFlatno.message}</div>}
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="buildingName">Building name</Form.Label>
                                    <Controller
                                        control={control}
                                        name="receiverBuilding"
                                        rules={{ required: { value: true, message: 'This field is required' } }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Control ref={ref} type="text" placeholder="Enter building" onChange={onChange} value={value} />
                                        )}
                                    />
                                    {errors.receiverBuilding && <div className='error-text'>{errors.receiverBuilding.message}</div>}
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderCity">City</Form.Label>
                                    <Form.Control readOnly type='text' value={receiverAddr?.city} />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderState">State</Form.Label>
                                    <Form.Control readOnly type='text' value={receiverAddr?.state} />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderCountry">Country</Form.Label>
                                    <Form.Control readOnly type='text' value={receiverAddr?.country} />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="senderPincode">Pincode</Form.Label>
                                    <Form.Control readOnly type='text' value={receiverAddr?.pincode} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Package details */}
                <Row>
                    <Col sm={12}>
                        <Row>
                            <Form.Label className='text-decoration-underline'>Package details</Form.Label>
                            {
                                pkgFields.map((item, index) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <Col sm={4}>
                                                <Controller
                                                    defaultValue={item.l}
                                                    control={control}
                                                    rules={{ required: { value: true, message: 'This field is required' } }}
                                                    name={`pkg.${index}.l`}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Form.Group className="mb-3" controlId="packageLength">
                                                            <Form.Label className='text-muted'>Length(cm)</Form.Label>
                                                            <Form.Control ref={ref} type="text" placeholder="Enter length of the package" onChange={onChange} value={value} required />
                                                            {/* {(errors.pkg && errors.pkg[index].l) && <div className='error-text'>{errors.pkg[index].l.message}</div>} */}
                                                        </Form.Group>
                                                    )}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <Controller
                                                    defaultValue={item.b}
                                                    control={control}
                                                    rules={{ required: { value: true, message: 'This field is required' } }}
                                                    name={`pkg.${index}.b`}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Form.Group className="mb-3" controlId="packageBreadth">
                                                            <Form.Label className='text-muted'>Breadth(cm)</Form.Label>
                                                            <Form.Control ref={ref} type="text" placeholder="Enter breadth of the package" onChange={onChange} value={value} required />
                                                            {/* {(errors.pkg && errors.pkg[index].b) && <div className='error-text'>{errors.pkg[index].b.message}</div>} */}
                                                        </Form.Group>
                                                    )}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <Controller
                                                    defaultValue={item.h}
                                                    control={control}
                                                    rules={{ required: { value: true, message: 'This field is required' } }}
                                                    name={`pkg.${index}.h`}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Form.Group className="mb-3" controlId="packageHeight">
                                                            <Form.Label className='text-muted'>Height(cm)</Form.Label>
                                                            <Form.Control ref={ref} type="text" placeholder="Enter height of the package" onChange={onChange} value={value} required />
                                                            {/* {(errors.pkg && errors.pkg[index].h) && <div className='error-text'>{errors.pkg[index].h.message}</div>} */}
                                                        </Form.Group>
                                                    )}
                                                />
                                            </Col>
                                            <Col xs={12}>
                                                {pkgFields.length !== 1 && <Button variant='danger' type='button' className='mb-3' onClick={() => pkgRemove(index)}>Remove</Button>}
                                            </Col>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Row>
                        <Button variant='success' className='mb-3' onClick={() => pkgAppend({ l: '', b: '', h: '' })}>Add</Button>
                    </Col>
                </Row>

                {/* Item details */}
                <Row>
                    <Col sm={12}>
                        <Row>
                            <Form.Label className='text-decoration-underline'>Item details</Form.Label>
                            {
                                itemsFields.map((item, index) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <Col sm={6}>
                                                <Controller
                                                    defaultValue={item.l}
                                                    control={control}
                                                    rules={{ required: { value: true, message: 'This field is required' } }}
                                                    name={`items.${index}.name`}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Form.Group className="mb-3" controlId="itemName">
                                                            <Form.Label className='text-muted'>Name of the item</Form.Label>
                                                            <Form.Control ref={ref} type="text" placeholder="Enter name of the item" onChange={onChange} value={value} required />
                                                            {/* {(errors.pkg && errors.pkg[index].l) && <div className='error-text'>{errors.pkg[index].l.message}</div>} */}
                                                        </Form.Group>
                                                    )}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Controller
                                                    defaultValue={item.h}
                                                    control={control}
                                                    rules={{ required: { value: true, message: 'This field is required' } }}
                                                    name={`items.${index}.qty`}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Form.Group className="mb-3" controlId="itemQty">
                                                            <Form.Label className='text-muted'>Quantity</Form.Label>
                                                            <Form.Control ref={ref} type="text" placeholder="Enter quantity" onChange={onChange} value={value} required />
                                                            {/* {(errors.pkg && errors.pkg[index].h) && <div className='error-text'>{errors.pkg[index].h.message}</div>} */}
                                                        </Form.Group>
                                                    )}
                                                />
                                            </Col>
                                            <Col xs={12}>
                                                {itemsFields.length !== 1 && <Button variant='danger' type='button' className='mb-3' onClick={() => itemsRemove(index)}>Remove</Button>}
                                            </Col>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Row>
                        <Button variant='success' className='mb-3' onClick={() => itemsAppend({ name: '', qty: '' })}>Add</Button>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={6}>
                        <Controller
                            control={control}
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            name="bookedAt"
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="bookedAt">
                                    <Form.Label>Booked at</Form.Label>
                                    <Form.Control ref={ref} type="datetime-local" onChange={onChange} value={value} />
                                    {errors.bookedAt && <div className='error-text'>{errors.bookedAt.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col xs={12} sm={6}>
                        <Controller
                            control={control}
                            rules={{ required: { value: true, message: 'This field is required' } }}
                            name="cost"
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="cost">
                                    <Form.Label>Cost</Form.Label>
                                    <Form.Control ref={ref} type="text" onChange={onChange} value={value} />
                                    {errors.cost && <div className='error-text'>{errors.cost.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col xs={12} sm={6}>
                        <Controller
                            control={control}
                            rules={{ required: { value: true, message: 'This field is required' }, validate: value => value > 0 || 'Weight should be greater than 0' }}
                            name="weight"
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="weight">
                                    <Form.Label>Weight</Form.Label>
                                    <Form.Control ref={ref} type="text" onChange={onChange} value={value} />
                                    {errors.weight && <div className='error-text'>{errors.weight.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col xs={12} sm={6}>
                        <Controller
                            control={control}
                            name="coupon"
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="coupon">
                                    <Form.Label>Coupon</Form.Label>
                                    <Form.Control ref={ref} type="text" onChange={onChange} value={value} />
                                    {errors.coupon && <div className='error-text'>{errors.coupon.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col xs={12} sm={6}>
                        <Controller
                            control={control}
                            name="payment_status"
                            defaultValue='Initiated'
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="payment_status">
                                    <Form.Label>Payment status</Form.Label>
                                    <Form.Select ref={ref} onChange={onChange} value={value}>
                                        <option value='Initiated'>Initiated</option>
                                        <option value='Successful'>Successful</option>
                                        <option value='Pending'>Pending</option>
                                        <option value='Failed'>Failed</option>
                                    </Form.Select>
                                </Form.Group>
                            )}
                        />
                    </Col>
                </Row>

                <Button type='submit' disabled={fetching}>
                    Submit&nbsp;
                    {
                        fetching && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                </Button>

            </Form>
        </div>
    )
}
