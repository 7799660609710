import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getDashboardOrderDetailsAPI } from "../../api/dashboard"

const initialState = {
    fetching: false,
    orderData: {}
}

export const fetchDashboardDetailsAction = createAsyncThunk('/admin/dashboard', async ({ startDate, endDate, graphDataStartDate, graphDataEndDate }, { rejectWithValue }) => {
    const res = await getDashboardOrderDetailsAPI(startDate, endDate, graphDataStartDate, graphDataEndDate);
    if (res.success) return res.data
    return rejectWithValue('Oops there seems to be an error while fetching data')
})

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchDashboardDetailsAction.pending, (state) => {
            state.fetching = true
        })
        builder.addCase(fetchDashboardDetailsAction.fulfilled, (state, action) => {
            state.fetching = false
            state.orderData = action.payload
        })
        builder.addCase(fetchDashboardDetailsAction.rejected, (state) => {
            state.fetching = false
        })
    }
}) 