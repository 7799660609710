import axios, { AxiosResponse } from "axios";
import { checkLogin } from "../../utils/firebase";

const localhostUrl = 'http://localhost:8000'
const baseUrl = process.env.REACT_APP_BASE_URL

export const fetchAllUsersAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/users`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const fetchUserByIdAPI = async (id) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        //change
        const res = await fetch(`${baseUrl}/users/${id}`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const updateUserByAdminAPI = async (uid, data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

        console.log(uid + ":" + data);
        const res = await fetch(`${baseUrl}/admin/userupdate/${uid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': '' + data.token, //pass token variable here
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err);
    }
}

export const registerNewCustomerAPI = async (data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

        const res = await fetch(`${baseUrl}/firebase/create`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'x-access-token': data.token,
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}

export const loginCustomerAPI = async (data) => {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl}/firebase/auth`,
            method: "POST",
            headers: {
                Accept: "application/json",
                'admin-authorization': "Bearer " + (authToken ? authToken : ''),
                "x-access-token": data.token
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}