import React, { useEffect, useState } from 'react'
import { Container, Form, Row, Col, Button, ToggleButtonGroup, ToggleButton, Spinner } from 'react-bootstrap'
import { Outlet, useNavigate } from 'react-router-dom';
import { createCoupon } from '../../app/features/coupons/couponSlice'
import { useDispatch, useSelector } from 'react-redux'
import { backButtonIcon } from '../../FontAwesome';

export default function AddCoupon() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const [hyperlocal, setHyperlocal] = useState([{ value: null }]);
    // const [panIndia, setPanIndia] = useState([{ value: null }]);
    // const [international, setInternational] = useState([{ value: null }]);
    // const [hyperlocal4Wheelers, setHyperlocal4Wheelers] = useState([{ value: null }]);
    // const [hyperlocalRental, setHyperlocalRental] = useState([{ value: null }]);

    const { fetching, couponDetails } = useSelector(state => state.coupon)

    const [couponDetail, setCouponDetails] = useState({});
    const [coupon, setCoupon] = useState("");
    const [desc, setDescription] = useState("");
    const [expiry, setExpriy] = useState("");
    const [flatRate, setFlatRate] = useState("");
    const [percent, setPercent] = useState("");
    const [serviceType, setServiceType] = useState("");
    const [upperlimit, setUpperlimit] = useState("");
    const [isActive, setIsActive] = useState(false)
    const [isCustomCoupon, setIsCustomCoupon] = useState(false)
    const [displayRate, setDisplayRate] = useState(true)
    const [displayPercent, setDisplayPercent] = useState(true)
    const [loading, setIsLoading] = useState(false)

    const [hideAddCity, setHideAddCity] = useState(false)
    const [hideAllCity, setHideAllCity] = useState(true)

    useEffect(() => {
        if (!percent) {
            setDisplayRate(true)
        }
        else {
            setDisplayRate(false)
        }
        if (!flatRate) {
            setDisplayPercent(true)
        }
        else {
            setDisplayPercent(false)
        }
    }, [percent, flatRate])

    const resetData = () => {
        setCoupon('')
        setDescription('')
        setExpriy('0000-00-00T00:00')
        setFlatRate('')
        setPercent('')
        setServiceType('')
        setUpperlimit('')
        setIsActive(false)
        setIsCustomCoupon(false)
        setDisplayRate('')
        setDisplayPercent('')
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const data = {
            coupon: coupon.trim(),
            desc: desc,
            serviceType: serviceType,
            expiry: expiry ? new Date(expiry).toISOString() : "",
            isActive: isActive,
            isCustomCoupon: isCustomCoupon,
            flatRate: flatRate ? flatRate : "",
            percent: percent ? percent : "",
            upperlimit: upperlimit ? upperlimit : ""
        }
        console.log("Submitted data:", data);

        //dispatch code.....
        dispatch(createCoupon(data))
            .then(() => {
                setIsLoading(false)
                resetData()
                alert('Coupon added successfully')
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    return (
        <Container className='p-3'>
            {/* <Button variant='primary' onClick={e => { navigate(-1) }}>{backButtonIcon}&nbsp;Back</Button> */}
            <hr />
            <Form onSubmit={handleSubmit}>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="coupon">
                            <Form.Label>Coupon</Form.Label>
                            <Form.Control type="text" value={coupon} placeholder="Coupon" onChange={(e => setCoupon(e.currentTarget.value))} required />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="username">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" value={desc} placeholder="Description" onChange={(e => setDescription(e.currentTarget.value))} required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Row>
                            <Col xs={12} sm={6}>
                                <Form.Group controlId="blocked">
                                    <Form.Label>Activate</Form.Label>
                                    <Form.Check type="switch" value={isActive} checked={isActive} placeholder="isActive" onChange={(e => setIsActive(!isActive))} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Form.Group controlId="blocked">
                                    <Form.Label>Is Custom Coupon ?</Form.Label>
                                    <Form.Check type="switch" value={isCustomCoupon} checked={isCustomCoupon} placeholder="isCustomCoupon" onChange={(e => setIsCustomCoupon(!isCustomCoupon))} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="address">
                            <Form.Label>Valid Till</Form.Label>
                            <Form.Control type="datetime-local" value={expiry} placeholder="Expiring On" onChange={(e => setExpriy(e.currentTarget.value))} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="address">
                            <Form.Label>Flat Rate</Form.Label>
                            <Form.Control type="text" value={flatRate} readOnly={!displayRate} placeholder="Flat Rate" onChange={(e => setFlatRate(e.currentTarget.value))} />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="address">
                            <Form.Label>Percent</Form.Label>
                            <Form.Control type="text" value={percent} readOnly={!displayPercent} placeholder="Percent" onChange={(e => setPercent(e.currentTarget.value))} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="address">
                            <Form.Label>Minimum discount(in Rs.)</Form.Label>
                            <Form.Control type="text" value={upperlimit} placeholder="Upper Limit" onChange={(e => setUpperlimit(e.currentTarget.value))} />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="address">
                            <Form.Label>Availed For</Form.Label>
                            {/* Change this to dropdown */}
                            <Form.Select defaultValue={serviceType} name="serviceType" onChange={e => setServiceType(e.target.value)} required>
                                <option value="">Select</option>
                                <option value="Hyperlocal">Hyperlocal</option>
                                <option value="Hyperlocal2W">Hyperlocal - 2 wheeler</option>
                                <option value="Hyperlocal3W">Hyperlocal - 3 wheeler</option>
                                <option value="Hyperlocal4W">Hyperlocal - 4 wheeler</option>
                                <option value="International">International</option>
                                <option value="IntExport">International - export</option>
                                <option value="PanIndia">Pan india</option>
                                <option value="PanIndiaExpress">Pan india - Express</option>
                                <option value="PanIndiaPackers&Movers">Pan india - Packers & movers</option>
                                <option value="PanIndiaColdChain">Pan india - Cold chain</option>
                                <option value="PanIndiaPetTransport">Pan india - Pet transport</option>
                            </Form.Select>
                            {/* <Form.Control type="text" value={serviceType} placeholder="Availed For" onChange={(e => setServiceType(e.currentTarget.value))} /> */}
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant='primary' type="submit" disabled={loading}>
                    {
                        loading ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />&nbsp;
                                Loading...
                            </> :
                            "Submit"
                    }
                </Button>
            </Form>
        </Container>
    )
}
