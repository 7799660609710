import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAllReviewsAPI, fetchReviewByIdAPI, updateReviewsAPI } from '../../api/reviews';

const initialState = {
    error: false,
    fetching: false,
    allReviews: [],
    reviewDetails: {}
}

export const fetchAllReviews = createAsyncThunk('/reviews', async(obj, { rejectWithValue }) => {
    const response = await fetchAllReviewsAPI();
    if (response.success) return response.data
    return rejectWithValue('There was some error while processing the request');
})

export const fetchReviewById = createAsyncThunk('/reviews/getReviewById', async(id, { rejectWithValue }) => {
    const response = await fetchReviewByIdAPI(id);
    if (response.success) return response.data
    return rejectWithValue('There was some error while processing the request');
})

export const updateReview = createAsyncThunk('/users/updateReview', async(data, { rejectWithValue }) => {
    console.log(data);
    const response = await updateReviewsAPI(data.id, data.requestBody);
    if (response.success) return response
    return rejectWithValue(response.message)
})

export const reviewSlice = createSlice({
    name: 'reviews',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllReviews.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchAllReviews.fulfilled, (state, action) => {
                state.fetching = false
                state.allReviews = action.payload
            })
            .addCase(fetchAllReviews.rejected, (state, action) => {
                state.fetching = false
                console.log("Rejected: ", JSON.stringify(action.payload));
            })
            .addCase(fetchReviewById.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchReviewById.fulfilled, (state, action) => {
                state.fetching = false
                state.reviewDetails = action.payload
                console.log("PAYLOAD: ", JSON.stringify(action.payload));
            })
            .addCase(fetchReviewById.rejected, (state, action) => {
                state.fetching = false
                console.log("Rejected: ", JSON.stringify(action.payload));
            })
            .addCase(updateReview.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateReview.fulfilled, (state, action) => {
                state.fetching = false
                state.reviewDetails = action.payload
                console.log(action.payload);
                alert('Review Updated');
            })
            .addCase(updateReview.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
    }
})