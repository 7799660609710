import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchSingleIntShipper, updateIntShipper } from '../../app/features/shipper/shipperSlice'
import { addIcon, backButtonIcon, deleteIcon, downloadIcon } from '../../FontAwesome'
import Loader from '../Loader'
import XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import Excel from 'sheetjs-style';


export default function EditIntShipper() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { shipperID } = useParams()

    const { fetching, shipperDetail } = useSelector(state => state.shipper)

    const [isActive, setIsActive] = useState(false)
    const [shipmentType, setShipmentType] = useState(shipperDetail.shipmentType ? shipperDetail.shipmentType : [""])

    const { register, handleSubmit, control, reset, setValue, watch, getValues } = useForm({
        defaultValues: {
            intShipperUID: shipperDetail.intShipperUID ? shipperDetail.intShipperUID : '',
            economyrates: shipperDetail.economyrates ? [...shipperDetail.economyrates] : [],
            shipper: shipperDetail.shipper ? shipperDetail.shipper : '',
            rates: shipperDetail.rates ? [...shipperDetail.rates] : [],
            logoURL: shipperDetail.logoURL ? shipperDetail.logoURL : '',
            packaging: shipperDetail.packaging ? shipperDetail.packaging : false,
            tracking: shipperDetail.tracking ? shipperDetail.tracking : false,
            pickup: shipperDetail.pickup ? shipperDetail.pickup : false,
            contact: shipperDetail.contact ? shipperDetail.contact : '',
            email: shipperDetail.email ? shipperDetail.email : '',
            ior: shipperDetail.ior ? shipperDetail.ior : false,
            imports: shipperDetail.imports ? shipperDetail.imports : false,
            isActive: shipperDetail.isActive ? shipperDetail.isActive : false
        }
    })

    const rate = watch('rates')
    const economyRate = watch('economyrates')

    const editShipper = (data) => {
        console.log(data)
        const body = {
            ...data,
            shipmentType
        }
        dispatch(updateIntShipper(body))
            .then(res => dispatch(fetchSingleIntShipper({ shipperID })))
    }


    useEffect(() => {
        dispatch(fetchSingleIntShipper({ shipperID }))
        const rateDiv = document.getElementById("rates-div")
        const economyRateDiv = document.getElementById("economyRates-div")


        if (rateDiv) {
            rateDiv.style.display = 'block'
            rateDiv.innerText = ''
            rateDiv.innerText = JSON.stringify(rate, undefined, 7)
        }
        if (economyRateDiv) {
            economyRateDiv.innerText = ''
            economyRateDiv.style.display = 'block'
            economyRateDiv.innerText = JSON.stringify(economyRate, undefined, 7)
        }
    }, [])

    useEffect(() => {
        reset(shipperDetail)
    }, [shipperDetail])

    function uploadFile(elementId) {
        var input = document.getElementById(elementId);
        var files = input.files
        if (files && files[0]) {
            console.log(files[0].type);
            if (files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                handleFile(files[0], elementId)
            } else {
                alert("only excel sheets in .XLSX format accepted");
            }
            console.log('File was imported');
        } else {
            alert("Please select a file")
        }
    }

    function handleFile(file, elementId) {
        console.log('Inside handle File');
        var reader = new FileReader();
        reader.onload = function (e) {
            console.log('Reader loaded');
            var data = e.target.result;
            var workbook = XLSX.read(data, {
                type: 'binary'
            })

            workbook.SheetNames.forEach(function (sheetName) {
                // Here is your object
                var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                readData(XL_row_object, elementId);
            })

        };

        reader.onerror = function (ex) {
            console.log(ex.target.error.message);
        };

        reader.readAsBinaryString(file)
    }

    function readData(data, elementId) {
        const rateDiv = document.getElementById(elementId + "-div")
        rateDiv.style.display = 'block'
        rateDiv.innerText = ''
        console.log('Inside Read Data');
        console.log(data.length);
        var JSONArray = [];
        for (let i = 0; i < data.length; i++) {
            let jsonElement = {};
            let rates = {};
            for (var key in data[i]) {
                let element = data[i]
                if (key === 'Weight Slab') {
                    jsonElement["weight"] = JSON.stringify(element[key])
                }
                else {
                    let country = key;
                    rates[country] = parseFloat(element[key])
                }
            }
            jsonElement["cost"] = rates;
            JSONArray.push(jsonElement);
        }
        rateDiv.innerText += "[\n"
        JSONArray.map((e, i) => {
            rateDiv.innerText += JSON.stringify(e, undefined, 7) + ","
        })
        rateDiv.innerText += "\n]"
        if (elementId === 'rates') {
            setValue('rates', JSONArray)
        } else if (elementId === 'economyRates') {
            setValue('economyrates', JSONArray)
        }
        // setRates(JSONArray)
        console.log(JSONArray)
    }

    const handleRemoveShipmentType = (index) => {
        console.log(shipmentType)
        console.log(index)
        let newShipmentTypes = [...shipmentType]
        newShipmentTypes.splice(index, 1)
        console.log(newShipmentTypes)
        setShipmentType(newShipmentTypes)
    }

    const updateShipmentValue = (value, index) => {
        const newShipmentTypes = [...shipmentType]
        newShipmentTypes[index] = value
        setShipmentType(newShipmentTypes)
    }

    const shipmentOptions = [
        { value: 'personal', label: 'Personal' },
        { value: 'business', label: 'Business' }
    ]

    const downloadRatesExcel = (type) => {
        const rates = getValues('rates');
        const economyRates = getValues('economyrates');

        if (type === 'rates' && rates && rates.length !== 0) {
            const userFileName = shipperDetail.shipper + " Rates"
            const fileType = 'application/vnd.openxml-formats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx'
            let fileData = []
            rates.map(ele => {
                let tempObj = {}
                tempObj["Weight Slab"] = ele.weight
                for (let country in ele.cost) {
                    tempObj[country] = ele.cost[country]
                }
                fileData.push(tempObj)
            })
            const ws = Excel.utils.json_to_sheet(fileData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = Excel.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, userFileName + fileExtension);
        } else if (type === 'economy' && economyRates && economyRates.length !== 0) {
            const userFileName = shipperDetail.shipper + " Economy Rates"
            const fileType = 'application/vnd.openxml-formats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx'
            let fileData = []
            economyRates.map(ele => {
                let tempObj = {}
                tempObj["Weight Slab"] = ele.weight
                for (let country in ele.cost) {
                    tempObj[country] = ele.cost[country]
                }
                fileData.push(tempObj)
            })
            const ws = Excel.utils.json_to_sheet(fileData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = Excel.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, userFileName + fileExtension);
        } else {
            alert("No rates are available");
        }
    }


    if (fetching) {
        return <Loader />
    }

    return (
        <div style={{ padding: '30px' }}>
            <h4 className='mb-4'>Update Shipper : {shipperDetail.shipper}</h4>
            <Button variant='primary' onClick={() => navigate(-1)}>{backButtonIcon}&nbsp;Back</Button>
            <hr />
            <Form onSubmit={handleSubmit(editShipper)}>
                <Row>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="shipper"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="shipperName">
                                    <Form.Label>Shipper</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Shipper" required />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="intShipperUID"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="shipperUID">
                                    <Form.Label>Shipper UID</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Shipper UID" readOnly required />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="logoURL"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="logoURL">
                                    <Form.Label>Logo URL</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Logo URL" />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={6}></Col>

                    <Col sm={2}>
                        <Controller
                            control={control}
                            name="packaging"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="packaging">
                                    <Form.Label>Packaging</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={2}>
                        <Controller
                            control={control}
                            name="tracking"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="tracking">
                                    <Form.Label>Tracking</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={2}>
                        <Controller
                            control={control}
                            name="pickup"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="pickup">
                                    <Form.Label>Pick up</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={2}>
                        <Controller
                            control={control}
                            name="ior"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="ior">
                                    <Form.Label>IOR</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={2}>
                        <Controller
                            control={control}
                            name="imports"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="imports">
                                    <Form.Label>Imports</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={2}>
                        <Controller
                            control={control}
                            name="isActive"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="isActive">
                                    <Form.Label>isActive</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col xs={12}>
                        <Row>
                            <Form.Label>Shipment types</Form.Label>
                            {
                                shipmentType && shipmentType?.map((type, index) => {
                                    return (
                                        <Col sm={4} key={index}>
                                            <Form.Group className="mb-3" controlId="imports">
                                                <div className='d-flex'>
                                                    <Form.Select value={type} onChange={(e) => updateShipmentValue(e.target.value, index)}>
                                                        <option value="">Select</option>
                                                        {
                                                            shipmentOptions.map((opt, index) => {
                                                                return (
                                                                    <option key={index} value={opt.value}>{opt.label}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    {shipmentType.length !== 1 && <Button variant='danger' className='ms-1' onClick={() => handleRemoveShipmentType(index)}>{deleteIcon}</Button>}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    )
                                })
                            }
                            {(shipmentType.length < shipmentOptions.length) && <Col xs={12}>
                                <Button variant='success' className='mb-3' onClick={() => setShipmentType([...shipmentType, ""])}>{addIcon}&nbsp;Add</Button>
                            </Col>}
                        </Row>
                    </Col>

                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="contact"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="contact">
                                    <Form.Label>Contact Details</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Contact" required />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="email"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" value={value} onChange={onChange} placeholder="Email" required />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="rates">
                            <Form.Label>Rates</Form.Label>
                            <div className="d-flex flex-column flex-md-row">
                                <Form.Control type="file" className='me-4 align-self-center' />
                                <Button variant='secondary' onClick={() => uploadFile("rates")}>Click to import</Button>
                            </div>
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <div style={{
                            // display: 'none',
                            height: '400px',
                            // background: '#002b36',
                            padding: '20px',
                            background: '#242424',
                            color: '#fff',
                            // color: '#81900f',
                            overflowY: 'auto',
                            marginBottom: '20px'
                        }}
                            id="rates-div">
                            <Button variant='secondary' style={{ marginLeft: 'auto', display: 'block' }} onClick={() => downloadRatesExcel('rates')}>{downloadIcon}</Button>
                            <pre>
                                {JSON.stringify(rate, undefined, 2)}
                            </pre>
                        </div>
                    </Col>

                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="economyRates">
                            <Form.Label>Economy Rates</Form.Label>
                            <div className="d-flex flex-column flex-md-row">
                                <Form.Control type="file" className='me-4 align-self-center' />
                                <Button variant='secondary' onClick={() => uploadFile("economyRates")}>Click to import</Button>
                            </div>
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <div style={{
                            // display: 'none',
                            height: '400px',
                            // background: '#002b36',
                            padding: '20px',
                            background: '#242424',
                            color: '#fff',
                            // color: '#81900f',
                            overflowY: 'auto',
                            marginBottom: '20px'
                        }}
                            id="economyRates-div">
                            <Button variant='secondary' style={{ marginLeft: 'auto', display: 'block' }} onClick={() => downloadRatesExcel('economy')}>{downloadIcon}</Button>
                            <pre>
                                {JSON.stringify(economyRate, undefined, 2)}
                            </pre>
                        </div>
                    </Col>
                </Row>
                <Button type='submit' disabled={fetching}>
                    Update shipper&nbsp;
                    {fetching && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />}
                </Button>
            </Form>
        </div>
    )
}
