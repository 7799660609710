import { checkLogin } from "../../utils/firebase";

const baseUrl = process.env.REACT_APP_BASE_URL


export const fetchAllApsrtcCitiesAPI = async ({ page, limit }) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin/apsrtc-booking/get-all-apsrtc-cities?page=${page}&limit=${limit}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res;

    } catch (error) {
        console.log(error)
    }
}

export const fetchSingleApsrtcCityAPI = async (queryParams) => {
    try {
        const { city_id } = queryParams
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin/apsrtc-booking/get-single-apsrtc-city?city_id=${city_id}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res;

    } catch (error) {
        console.log(error)
    }
}

export const createApsrtcCityAPI = async (data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin/apsrtc-booking/create-apsrtc-city`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res;

    } catch (error) {
        console.log(error)
    }
}

export const editApsrtcCityAPI = async (updateBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/admin/apsrtc-booking/edit-apsrtc-city`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(updateBody)
        }).then(res => res.json())
        return res;

    } catch (error) {
        console.log(error)
    }
}