import React, { useEffect } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchSinglePanindiaShipper, updatePanIndiaShipper } from '../../app/features/shipper/shipperSlice'
import { backButtonIcon } from '../../FontAwesome'
import Loader from '../Loader'
import { useForm, Controller, useFieldArray } from 'react-hook-form'


export default function EditPanIndiaShipper() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const params = useParams()
    const id = params.shipperID

    const { fetching, shipperDetail } = useSelector(state => state.shipper)

    const { handleSubmit, control, setValue, formState: { errors } } = useForm({
        defaultValues: {
            company: '',
            address: '',
            phone: '',
            email: '',
            desc: '',
            logoURL: '',
            services: [{ value: '', label: '' }],
            fuelCharges: 0,
            packaging: false,
            doorToDoorDelivery: false,
            tracking: false,
            isActive: false,
            isBookingApiAvailable:false
        }
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'services'
    })

    useEffect(() => {
        dispatch(fetchSinglePanindiaShipper(id))
    }, [])

    useEffect(() => {
        setValue('address', shipperDetail.address ? shipperDetail.address : '')
        setValue('company', shipperDetail.company ? shipperDetail.company : '')
        setValue('phone', shipperDetail.phone ? shipperDetail.phone : '')
        setValue('email', shipperDetail.email ? shipperDetail.email : '')
        setValue('desc', shipperDetail.desc ? shipperDetail.desc : '')
        setValue('logoURL', shipperDetail.logoURL ? shipperDetail.logoURL : '')
        setValue('services', shipperDetail.services ? shipperDetail.services : [])
        setValue('fuelCharges', shipperDetail.fuelCharges ? shipperDetail.fuelCharges : 0)
        setValue('packaging', shipperDetail.packaging ? shipperDetail.packaging : false)
        setValue('tracking', shipperDetail.tracking ? shipperDetail.tracking : false)
        setValue('doorToDoorDelivery', shipperDetail.doorToDoorDelivery ? shipperDetail.doorToDoorDelivery : false)
        setValue('isBookingApiAvailable', shipperDetail.isBookingApiAvailable ? shipperDetail.isBookingApiAvailable : false)
        setValue('isActive', shipperDetail.isActive ? shipperDetail.isActive : false)
    }, [shipperDetail])

    const editShipper = (data) => {
        // console.log(data)
        dispatch(updatePanIndiaShipper({ id: id, reqBody: data }))
            .then(() => dispatch(fetchSinglePanindiaShipper(id)))
    }

    if (fetching) {
        return <Loader />
    }

    return (
        <div style={{ padding: '30px' }}>
            <h3>Editing {shipperDetail.company}</h3>
            <Button className='mt-2' onClick={() => navigate(-1)}>{backButtonIcon}&nbsp;Back</Button>
            <hr />
            <Form onSubmit={handleSubmit(editShipper)}>
                <Row>
                    <Col sm={12}>
                        <Controller
                            control={control}
                            name="isActive"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="isActive">
                                    <Form.Label>Active</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="company"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter company name" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="address"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter company address" />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="phone"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Contact number</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter contact" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" value={value} onChange={onChange} placeholder="Enter email" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="desc"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="desc">
                                    <Form.Label>Desc</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter desc" />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="logoURL"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="logoURL">
                                    <Form.Label>LogoURL</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter company logo url" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs={12} sm={2}>
                        <Controller
                            control={control}
                            name="packaging"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="packaging">
                                    <Form.Label>Packing</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs={12} sm={2}>
                        <Controller
                            control={control}
                            name="tracking"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="tracking">
                                    <Form.Label>Tracking</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs={12} sm={2}>
                        <Controller
                            control={control}
                            name="doorToDoorDelivery"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="doorToDoorDelivery">
                                    <Form.Label>Door to door delivery</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs={12} sm={2}>
                        <Controller
                            control={control}
                            name="isBookingApiAvailable"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="isBookingApiAvailable">
                                    <Form.Label>Booking Api available?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <Form.Label>Services</Form.Label>
                        <Row>
                            {
                                fields.map((service, index) => {
                                    return (
                                        <Col xs={4} key={service.id}>
                                            <Controller
                                                control={control}
                                                defaultValue={service.value}
                                                name={`services.${index}.value`}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Group className="mb-2" controlId="services">
                                                        <Form.Select onChange={(data) => { onChange(data); setValue(`services.${index}.label`, data.target.options[data.target.selectedIndex].innerHTML); }} value={value} required>
                                                            <option value="" disabled>Select</option>
                                                            <option value="surface-delivery">Surface delivery</option>
                                                            <option value="express-delivery">Express delivery</option>
                                                            <option value="critical-logistics">Critical logistics</option>
                                                            <option value="pet-logistics">Pet logistics</option>
                                                            <option value="cold-chain">Cold chain</option>
                                                            <option value="packers-and-movers">Packers and movers</option>
                                                            <option value="container">Cross country containers</option>
                                                            <option value="ftl">Full truck Load(FTL)</option>
                                                            <option value="htl">Half truck Load(HTL)</option>
                                                            <option value="ltl">Low truck Load(LTL)</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                )}
                                            />
                                            {fields.length !== 1 && <Button variant='danger' type='button' className='mb-3' onClick={() => remove(index)}>Remove</Button>}
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <Button variant='success' className='mt-2 mb-3' onClick={() => append({ value: '', label: '' })}>Add</Button>
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="fuelCharges"
                            rules={{ validate: value => value >= 0 || 'Value should be greater than equal to 0' }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="fuelCharges">
                                    <Form.Label>Fuel charges (in %)</Form.Label>
                                    <Form.Control type="number" value={value} onChange={onChange} placeholder="Enter fuel charges" />
                                    {errors.fuelCharges && <div className='error-text'>{errors.fuelCharges.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>
                </Row>
                <hr />
                <Button type='submit' disabled={fetching}>
                    Update shipper&nbsp;
                    {fetching && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />}
                </Button>
            </Form>
        </div>
    )
}
