//Latest Invoice template: dated 22 Jan 2024

import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react'
import Inter from '../../assets/fonts/Inter-VariableFont_slnt,wght.ttf'
import Inter_Bold from '../../assets/fonts/Inter-Bold.ttf'
import SHIPMANTRA_LOGO from '../../assets/images/shipmantra_logo_with_name.png'
import { convertDecimalNumbersToWords } from '../../utils/helper';
import gst_state_code_list from '../../data_files/gst_state_code_list.json'
import hsn_code_list from '../../data_files/hsn_code_list.json'

Font.register({
    family: 'Inter',
    format: "truetype",
    // src: Inter,
    fonts: [
        { src: Inter }, // font-style: normal, font-weight: normal
        {
            src: Inter_Bold,
            fontWeight: 700
        }
    ]
})

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Inter',
        padding: '30px',
    },
    companyAddrDetails: {
        fontSize: '10px'
    },
    container_padding_5: {
        padding: '5px'
    },
    font_10: {
        fontSize: '10px'
    },
    fw_bold: {
        fontWeight: 'bold'
    },
    tableHead: {
        borderRight: "1px solid black",
        padding: "5px 2px",
        backgroundColor: "rgb(216, 216, 216)",
        // color: "white",
        verticalAlign: "middle",
        fontFamily: 'Inter',
        fontWeight: "bold",
        // flex: 1,
        // fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: '10px'
    },
    table: {
        borderTop: "1px solid black",
        borderLeft: "1px solid black",
        borderCollapse: "none",
        display: "flex",
        flexDirection: "column",
        marginTop: '30px',
        fontSize: '10px'
    },
    tableCell: {
        padding: "5px 2px",
        // borderLeft: "1px solid black",
        borderRight: "1px solid black",
        // textAlign: "center",
        display: "table-cell",
        verticalAlign: "middle",
        // flex: 1,
        fontSize: '10px'
    },
    tableRow: {
        // borderTop: "1px solid black",
        borderBottom: "1px solid black",
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        fontSize: "10px",
    },
    border: {
        border: '1px solid black'
    }
})


export default function WalletRechargeInvoiceTemplate({ dataArr }) {
    return (
        <Document title={dataArr?.length ? dataArr?.[0]?.sm_orderid + "_Invoice" : "Consolidated_Invoice"} author='Shipmantra' creator='Shipmantra' subject={dataArr?.length ? dataArr?.[0]?.sm_orderid + "_Invoice" : "Consolidated_Invoice"}>
            {
                dataArr?.map((data, index) => {
                    let totalCost = Number((data?.cost?.amountCollectedFromCustomer ? (data?.cost?.amountCollectedFromCustomer || 0) : (data?.cost || 0)).toFixed(2))

                    const costInWords = convertDecimalNumbersToWords(totalCost)
                    const stateName = data.firstMile?.fromCounter ? data.midMile?.startStation?.address?.mapData?.state : data.start?.address?.mapData?.state

                    return (
                        <Page style={styles.page} key={index}>
                            <View style={{ border: '1px solid black' }}>

                                {/* Header and shipmantra company details */}
                                <View style={{ ...styles.container_padding_5, borderBottom: '1px solid black', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                        <Image src={SHIPMANTRA_LOGO} style={{ height: '25px' }} />
                                        <View style={{ marginTop: '10px', ...styles.font_10 }}>
                                            <Text style={{ ...styles.fw_bold, fontSize: '12px' }}>GoLintu Services Pvt. Ltd</Text>
                                            <Text>NASSCOM CoE - IoT & AI</Text>
                                            <Text>AU North Campus, Andhra University</Text>
                                            <Text>Visakhapatnam Andhra Pradesh 530003 India</Text>
                                            <Text>GSTIN 37AAHCG9147L1ZZ</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '50%', textAlign: 'right' }}><Text>TAX INVOICE</Text></View>
                                </View>

                                {/* Invoice details */}
                                <View style={{ borderBottom: '1px solid black', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'stretch' }}>
                                    <View style={{ ...styles.container_padding_5, ...styles.font_10, borderRight: '1px solid black', display: 'flex', flexDirection: 'row', flex: 1 }}>
                                        <View style={{ width: '30%' }}>
                                            <Text>#</Text>
                                            <Text>Invoice date</Text>
                                        </View>
                                        <View style={{ width: '70%', ...styles.fw_bold }}>
                                            <Text>: {data.sm_orderid}</Text>
                                            <Text>: {new Date(data.createdAt).getDate() + "/" + (new Date(data.createdAt).getMonth() + 1) + "/" + new Date(data.createdAt).getFullYear()}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.container_padding_5, ...styles.font_10, display: 'flex', flexDirection: 'row', flex: 1 }}>
                                        <View style={{ flex: 1 }}>
                                            <Text>Place of supply</Text>
                                        </View>
                                        <View style={{ flex: 1, ...styles.fw_bold }}>
                                            <Text>: {stateName} ({gst_state_code_list[stateName]})</Text>
                                        </View>
                                    </View>
                                </View>

                                {/* Shipping details */}
                                <View style={{ ...styles.font_10, borderBottom: '1px solid black', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'stretch' }}>
                                    <View style={{ borderRight: '1px solid black', display: 'flex', flexDirection: 'column', flex: 1 }}>
                                        <Text style={{ ...styles.container_padding_5, ...styles.fw_bold, backgroundColor: 'rgb(216,216,216)', borderBottom: '1px solid black' }}>Bill To</Text>
                                        <View style={styles.container_padding_5}>
                                            <Text style={{ fontWeight: 'semibold' }}>{data.start?.contact?.name}</Text>
                                            {
                                                data.firstMile?.fromCounter ?
                                                    <Text>Booked from Counter</Text> :
                                                    <>
                                                        <Text>{(data.start?.address?.houseNo || "") + " " + (data.start?.address?.buildingName || "")}</Text>
                                                        <Text>{data.start?.address?.mapData?.address}</Text>
                                                        <Text>{data.start?.address?.mapData?.pincode}</Text>
                                                        <Text>{data.start?.address?.mapData?.city}</Text>
                                                        <Text>{data.start?.address?.mapData?.country}</Text>
                                                    </>
                                            }
                                        </View>
                                    </View>
                                    {/* <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                        <Text style={{ ...styles.container_padding_5, ...styles.fw_bold, backgroundColor: 'rgb(216,216,216)', borderBottom: '1px solid black' }}>Ship To</Text>
                                        <View style={styles.container_padding_5}>
                                            <Text style={{ fontWeight: 'semibold' }}>{data.end?.contact?.name}</Text>
                                            <Text>{(data.end?.address?.houseNo || "") + " " + (data.end?.address?.buildingName || "")}</Text>
                                            <Text>{data.end?.address?.mapData?.address}</Text>
                                            <Text>{data.end?.address?.mapData?.pincode}</Text>
                                            <Text>{data.end?.address?.mapData?.city}</Text>
                                            <Text>{data.end?.address?.mapData?.country}</Text>
                                        </View>
                                    </View> */}
                                </View>

                                {/* Table description */}
                                <View
                                    style={styles.table}
                                >
                                    <View
                                        style={styles.tableRow}
                                    >
                                        <Text style={{ ...styles.tableHead, width: '10%' }}>#</Text>
                                        <Text style={{ ...styles.tableHead, width: '40%' }}>Item</Text>
                                        <Text style={{ ...styles.tableHead, width: '10%', textAlign: 'center' }}>Qty</Text>
                                        <Text style={{ ...styles.tableHead, width: '10%', textAlign: 'center' }}>Rate</Text>
                                        <Text style={{ ...styles.tableHead, width: '10%', textAlign: 'center' }}>Discount</Text>
                                        <Text style={{ ...styles.tableHead, width: '20%', textAlign: 'center' }}>Amount</Text>
                                    </View>

                                    {/* table body */}
                                    <View
                                        style={styles.tableRow}
                                    >
                                        <Text style={{ ...styles.tableCell, width: '10%' }}>1</Text>
                                        <Text style={{ ...styles.tableCell, width: '40%' }}>
                                            <Text>{"Wallet Topup"}</Text>{"\n"}
                                            <Text style={{ fontSize: '8px', color: '#333' }}>HSN:&nbsp;{"9965"}</Text>
                                        </Text>
                                        <Text style={{ ...styles.tableCell, width: '10%', textAlign: 'center' }}>1.00</Text>
                                        <Text style={{ ...styles.tableCell, width: '10%', textAlign: 'center' }}>{(parseFloat(totalCost))}</Text>
                                        <Text style={{ ...styles.tableCell, width: '10%', textAlign: 'center' }}>0.00</Text>
                                        <Text style={{ ...styles.tableCell, width: '20%', textAlign: 'center' }}>{(parseFloat(totalCost))}</Text>
                                    </View>
                                </View>

                                {/* PDF  Footer */}
                                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', ...styles.font_10 }}>
                                    <View style={{ ...styles.container_padding_5, width: '50%', borderRight: '1px solid black' }}>
                                        <View style={{ margin: '10px 0' }}>
                                            <Text>Total in Words</Text>
                                            <Text style={{ textTransform: 'capitalize', ...styles.fw_bold }}>
                                                {costInWords}
                                            </Text>
                                        </View>

                                        <Text style={{ margin: '10px 0' }}>Thank you for the payment. You just made our day</Text>

                                        <View style={{ margin: '10px 0' }}>
                                            <Text>Account Name: Golintu Services Pvt Ltd</Text>
                                            <Text>Account Number: 249405500165</Text>
                                            <Text>Bank Name: ICICI</Text>
                                            <Text>Account Type: Current</Text>
                                            <Text>IFSC Code: ICIC0002494</Text>
                                        </View>
                                    </View>

                                    <View style={{ ...styles.container_padding_5, width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        {/* Labels */}
                                        <View style={{ flex: 1, textAlign: 'right' }}>
                                            <Text style={{ margin: '5px 0' }}>Sub Total</Text>
                                            {/* {
                                                stateName === 'Andhra Pradesh' ?
                                                    <>
                                                        <Text style={{ margin: '5px 0' }}>CGST (9.00%)</Text>
                                                        <Text style={{ margin: '5px 0' }}>SGST (9.00%)</Text>
                                                    </> :
                                                    <Text style={{ margin: '5px 0' }}>IGST (18.00%)</Text>
                                            } */}
                                            <Text style={{ margin: '5px 0' }}>Discount</Text>
                                            <Text style={{ fontWeight: 'heavy', margin: '5px 0' }}>Total</Text>
                                            <Text style={{ margin: '5px 0' }}>Payment Made</Text>
                                            <Text style={{ ...styles.fw_bold, margin: '5px 0' }}>Balance Due</Text>
                                        </View>

                                        {/* Values */}
                                        <View style={{ flex: 1, textAlign: 'left', marginLeft: '20px' }}>
                                            <Text style={{ margin: '5px 0' }}>{(totalCost - parseFloat(data?.cost?.gst || 0)).toFixed(2)}</Text>
                                            {/* {
                                                stateName === 'Andhra Pradesh' ?
                                                    <>
                                                        <Text style={{ margin: '5px 0' }}>{((data?.cost?.gst || 0) / 2).toFixed(2)}</Text>
                                                        <Text style={{ margin: '5px 0' }}>{((data?.cost?.gst || 0) / 2).toFixed(2)}</Text>
                                                    </> :
                                                    <Text style={{ margin: '5px 0' }}>{(data?.cost?.gst || 0).toFixed(2)}</Text>
                                            } */}
                                            <Text style={{ margin: '5px 0' }}>0.00</Text>
                                            {/*Total*/}
                                            <Text style={{ ...styles.fw_bold, margin: '5px 0' }}>
                                                {totalCost}
                                            </Text>
                                            {/*Payment Made*/}
                                            <Text style={{ color: 'red', margin: '5px 0' }}>
                                                (-)&nbsp;{totalCost}
                                            </Text>
                                            {/*Balance due*/}
                                            <Text style={{ ...styles.fw_bold, margin: '5px 0' }}>₹ 0.00</Text>
                                        </View>
                                    </View>
                                </View>

                            </View>
                        </Page>
                    )
                })
            }
        </Document>
    )
}
