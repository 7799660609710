import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { fetchReviewById, updateReview } from '../../app/features/reviews/reviewSlice';
import { backButtonIcon } from '../../FontAwesome';

export default function CustomerDetails() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams();

    const reviewID = params.id

    const { fetching, reviewDetails } = useSelector(state => state.review)
    const { adminUser } = useSelector(state => state.adminUser)

    const [reviewDetail, setReviewDetail] = useState({});
    const [content, setContent] = useState("");
    const [approved, isApproved] = useState("");

    useEffect(async () => {
        // dispatch(fetchUserById(orderId))
        dispatch(fetchReviewById(reviewID))
    }, [])

    useEffect(()=>{
        setReviewDetail(reviewDetails)
        setContent(reviewDetails.content)
        isApproved(reviewDetails.approved)
    },[reviewDetails])
    const handleSubmit = e => {
        e.preventDefault();
        const data = {
            id: reviewID,
            requestBody:{
                content: content,
                approved: approved,
                // token: adminUser.token
            }
        }
        console.log(data);
        dispatch(updateReview(data))
            .then(() => dispatch(fetchReviewById(reviewID)))
    }

    if (fetching) return (
        <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
            <Spinner animation="border" />
        </div>
    )

    return (
        <Container className='mt-4'>
            <Button variant='primary' onClick={e => { navigate(-1) }}>{backButtonIcon}&nbsp;Back</Button>
            <hr />
            <Form onSubmit={handleSubmit}>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={reviewDetails.name} placeholder="Name" readOnly />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="rating">
                            <Form.Label>Rating</Form.Label>
                            <Form.Control type="text" value={reviewDetails.rating} placeholder="Rating" readOnly/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="usertype">
                            <Form.Label>User Type</Form.Label>
                            <Form.Control type="text" value={reviewDetails.usertype} placeholder="User Type" readOnly />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="servicetype">
                            <Form.Label>Service Type</Form.Label>
                            <Form.Control type="text" value={reviewDetails.servicetype} placeholder="Service Type" readOnly/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="approved">
                            <Form.Label>Approved</Form.Label>
                            <Form.Check type="switch" value={approved} checked={approved} placeholder="Approved" onChange={(e=>isApproved(!approved))}/>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="content">
                            <Form.Label>Content</Form.Label>
                            <Form.Control type="text" value={content} placeholder="Content"  onChange={(e=>setContent(e.currentTarget.value))}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant='primary' type="submit">Submit</Button>
            </Form>
        </Container >
    )
}
