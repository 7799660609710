import { checkLogin } from "../../utils/firebase";

const baseUrl = process.env.REACT_APP_BASE_URL
const localhostUrl = 'http://localhost:8000'

export const fetchAllCitiesAPI = async () => {
    try {
        const response = await fetch(`${baseUrl}/cities`).then(res => res.json());
        return response
    } catch (error) {
        console.log(error);
    }
}

export const fetchOneCityAPI = async (id) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const response = await fetch(`${baseUrl}/cities/${id}`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return response
    } catch (error) {
        console.log(error);
    }
}

export const addCityAPI = async (reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const response = await fetch(`${baseUrl}/cities/create`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json());
        return response
    } catch (error) {
        console.log(error);
    }
}

export const editCityAPI = async (reqBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const response = await fetch(`${baseUrl}/cities`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json());
        return response
    } catch (error) {
        console.log(error);
    }
}