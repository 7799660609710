import React, { useState } from 'react'
import { useEffect } from 'react';
import { Container, Form, Row, Col, Button, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { addOneCity, fetchOneCity } from '../../app/features/cities/citySlice';
import CityDetails from './CityDetails';

export default function AddCity() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [hyperlocal, setHyperlocal] = useState([""]);
    const [panIndia, setPanIndia] = useState([""]);
    const [international, setInternational] = useState([""]);
    const [hyperlocal4Wheelers, setHyperlocal4Wheelers] = useState([""]);
    const [hyperlocalRental, setHyperlocalRental] = useState([""]);
    const [cityAliases, setCityAliases] = useState([""]);
    const [vehicles, setVehicles] = useState([{ value: "", text: "" }]);
    const [state, setState] = useState("");
    const [cityText, setCityText] = useState("")
    const [swLatLng, setswLatLng] = useState({ lat: undefined, lng: undefined })
    const [neLatLng, setneLatLng] = useState({ lat: undefined, lng: undefined })

    const resetData = () => {
        setHyperlocal([''])
        setPanIndia([''])
        setInternational([''])
        setHyperlocal4Wheelers([''])
        setHyperlocalRental([''])
        setCityAliases([''])
        setVehicles([{ value: "", text: "" }])
        setState("")
        setCityText("")
        setswLatLng({ lat: "", lng: "" })
        setneLatLng({ lat: "", lng: "" })
    }

    const handleInputChange = (e, index, prevList, setData) => {
        const list = [...prevList];
        // list[index].value = e.target.value;
        list[index] = e.target.value
        setData(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index, prevList, setData) => {
        const list = [...prevList];
        list.splice(index, 1);
        setData(list);
    };

    // handle click event of the Add button
    const handleAddClick = (prevList, setData) => {
        const values = [...prevList];
        values.push("")
        console.log(values);
        // values.push({ value: null });
        setData(values);
    };


    const handleVehicleInputChange = (e, index) => {
        const select = e.target
        // console.log(e.target.options[e.target.selectedIndex].text);
        const selectedOptionText = select.options[select.selectedIndex].text
        const list = [...vehicles];
        list[index].value = e.target.value;
        list[index].text = selectedOptionText;
        setVehicles(list);
    };

    // handle click event of the Remove button
    const handleVehicleRemoveClick = index => {
        const list = [...vehicles];
        list.splice(index, 1);
        setVehicles(list);
    };

    // handle click event of the Add button
    const handleVehicleAddClick = () => {
        const values = [...vehicles];
        values.push({ value: "", text: "" });
        setVehicles(values);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            type: {
                hyperlocal: (hyperlocal.length === 1 ? (hyperlocal[0] === "" ? [] : hyperlocal) : hyperlocal),
                panIndia: (panIndia.length === 1 ? (panIndia[0] === "" ? [] : panIndia) : panIndia),
                international: (international.length === 1 ? (international[0] === "" ? [] : international) : international),
                hyperlocal4Wheelers: (hyperlocal4Wheelers.length === 1 ? (hyperlocal4Wheelers[0] === "" ? [] : hyperlocal4Wheelers) : hyperlocal4Wheelers),
                hyperlocalRental: (hyperlocalRental.length === 1 ? (hyperlocalRental[0] === "" ? [] : hyperlocalRental) : hyperlocalRental),
                vehicles: (vehicles.length === 1 ? ((vehicles[0].value === "" || vehicles[0].text === "") ? [] : vehicles) : vehicles)
            },
            aliases: cityAliases,
            ne: neLatLng,
            sw: swLatLng,
            state,
            cityText
        }
        console.log("Submitted data:", data);

        dispatch(addOneCity(data))
            .then(res => {
                resetData()
            })
    }

    return (
        <Container className='p-3'>
            <Form onSubmit={handleSubmit}>
                <div className='text-end'><Button type='submit'>Submit</Button></div>
                {/* <hr /> */}
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="state">
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" value={state} placeholder="State" onChange={e => setState(e.target.value)} required />
                    </Form.Group>

                    <Form.Group as={Col} controlId="cityText">
                        <Form.Label>CityText</Form.Label>
                        <Form.Control type="text" value={cityText} placeholder="CityText" onChange={e => setCityText(e.target.value)} required />
                    </Form.Group>
                </Row>
                <hr />
                <fieldset>
                    <legend>City aliases</legend>
                    <Row>
                        {
                            cityAliases.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>Alias</Form.Label>
                                                <Form.Control autoFocus type="text" placeholder="Enter alias" value={x || ""} onChange={e => handleInputChange(e, i, cityAliases, setCityAliases)} />
                                            </Form.Group>
                                            <div>
                                                {cityAliases.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleRemoveClick(i, cityAliases, setCityAliases)}>Remove</Button>}
                                                {cityAliases.length - 1 === i && <Button variant='outline-success' onClick={e => handleAddClick(cityAliases, setCityAliases)}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>South-west(sw)</legend>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="sw-lat">
                                <Form.Label visuallyHidden>Lat</Form.Label>
                                <Form.Control type="text" value={swLatLng.lat} placeholder="lat" onChange={e => setswLatLng(prev => ({ ...prev, lat: e.target.value.trim() }))} required />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group className="mb-3" controlId="sw-lng">
                                <Form.Label visuallyHidden>Lng</Form.Label>
                                <Form.Control type="text" value={swLatLng.lng} placeholder="lng" onChange={e => setswLatLng(prev => ({ ...prev, lng: e.target.value.trim() }))} required />
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>North-east(ne)</legend>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="ne-lat">
                                <Form.Label visuallyHidden>Lat</Form.Label>
                                <Form.Control type="text" value={neLatLng.lat} placeholder="lat" onChange={e => setneLatLng(prev => ({ ...prev, lat: e.target.value.trim() }))} required />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group className="mb-3" controlId="ne-lng">
                                <Form.Label visuallyHidden>Lng</Form.Label>
                                <Form.Control type="text" value={neLatLng.lng} placeholder="lng" onChange={e => setneLatLng(prev => ({ ...prev, lng: e.target.value.trim() }))} required />
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>Hyperlocal</legend>
                    <Row>
                        {
                            hyperlocal.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>shipper</Form.Label>
                                                <Form.Control autoFocus type="text" placeholder="Enter shipper" value={x || ""} onChange={e => handleInputChange(e, i, hyperlocal, setHyperlocal)} required={(i > 0 || hyperlocal.length > 1) ? true : false} />
                                            </Form.Group>
                                            <div>
                                                {hyperlocal.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleRemoveClick(i, hyperlocal, setHyperlocal)}>Remove</Button>}
                                                {hyperlocal.length - 1 === i && <Button variant='outline-success' onClick={e => handleAddClick(hyperlocal, setHyperlocal)}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>Pan India</legend>
                    <Row>
                        {
                            panIndia.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>shipper</Form.Label>
                                                <Form.Control autoFocus type="text" placeholder="Enter shipper" value={x || ""} onChange={e => handleInputChange(e, i, panIndia, setPanIndia)} required={(i > 0 || panIndia.length > 1) ? true : false} />
                                            </Form.Group>
                                            <div>
                                                {panIndia.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleRemoveClick(i, panIndia, setPanIndia)}>Remove</Button>}
                                                {panIndia.length - 1 === i && <Button variant='outline-success' onClick={e => handleAddClick(panIndia, setPanIndia)}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>International</legend>
                    <Row>
                        {
                            international.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>shipper</Form.Label>
                                                <Form.Control autoFocus type="text" placeholder="Enter shipper" value={x || ""} onChange={e => handleInputChange(e, i, international, setInternational)} required={(i > 0 || international.length > 1) ? true : false} />
                                            </Form.Group>
                                            <div>
                                                {international.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleRemoveClick(i, international, setInternational)}>Remove</Button>}
                                                {international.length - 1 === i && <Button variant='outline-success' onClick={e => handleAddClick(international, setInternational)}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>Hyperlocal 4 wheeler</legend>
                    <Row>
                        {
                            hyperlocal4Wheelers.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>shipper</Form.Label>
                                                <Form.Control autoFocus type="text" placeholder="Enter shipper" value={x || ""} onChange={e => handleInputChange(e, i, hyperlocal4Wheelers, setHyperlocal4Wheelers)} required={(i > 0 || hyperlocal4Wheelers.length > 1) ? true : false} />
                                            </Form.Group>
                                            <div>
                                                {hyperlocal4Wheelers.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleRemoveClick(i, hyperlocal4Wheelers, setHyperlocal4Wheelers)}>Remove</Button>}
                                                {hyperlocal4Wheelers.length - 1 === i && <Button variant='outline-success' onClick={() => handleAddClick(hyperlocal4Wheelers, setHyperlocal4Wheelers)}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>Hyperlocal Rental</legend>
                    <Row>
                        {
                            hyperlocalRental.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>shipper</Form.Label>
                                                <Form.Control autoFocus type="text" placeholder="Enter shipper" value={x || ""} onChange={e => handleInputChange(e, i, hyperlocalRental, setHyperlocalRental)} required={(i > 0 || hyperlocalRental.length > 1) ? true : false} />
                                            </Form.Group>
                                            <div>
                                                {hyperlocalRental.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleRemoveClick(i, hyperlocalRental, setHyperlocalRental)}>Remove</Button>}
                                                {hyperlocalRental.length - 1 === i && <Button variant='outline-success' onClick={() => handleAddClick(hyperlocalRental, setHyperlocalRental)}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <hr />
                <fieldset>
                    <legend>Vehicles</legend>
                    <Row>
                        {
                            vehicles.map((x, i) => {
                                return (
                                    <Col className='p-2' sm={4} key={i}>
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label visuallyHidden>shipper</Form.Label>
                                                <Form.Select autoFocus aria-label="Default select example" value={x.value || ""} onChange={e => handleVehicleInputChange(e, i)} required={(i > 0 || vehicles.length > 1) ? true : false} >
                                                    <option disabled value="">Open this select menu</option>
                                                    <option value="tataAce">Tata Ace</option>
                                                    <option value="bolero">Bolero</option>
                                                    <option value="tata407">Tata 407</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <div>
                                                {vehicles.length !== 1 && <Button variant='outline-danger'
                                                    className="me-3"
                                                    onClick={() => handleVehicleRemoveClick(i)}>Remove</Button>}
                                                {vehicles.length - 1 === i && <Button variant='outline-success' onClick={handleVehicleAddClick}>Add</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </fieldset>
                <div className='text-center'><Button type='submit'>Submit</Button></div>
            </Form>

            {/* <CityDetails hideAllCity={hideAllCity} /> */}
        </Container>
    )
}
