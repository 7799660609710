import { checkLogin } from "../../utils/firebase"

let GMAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : ''
const baseUrl = process.env.REACT_APP_BASE_URL


export const calculateGoogleAddress = (address) => {
    const res = fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GMAP_API_KEY}`)
        .then(res => res.json())
    return res
}

export const printLabelApi = async (orderId) => {
    const res = await fetch(`${baseUrl}/label?order_id=${orderId}`).then(res => res.json())
    return res
}

export const callToConnectTwoNumbersAPI = async (data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken });

        const res = await fetch(`${baseUrl}/call-connect-two-number/exotel`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
        return res
    } catch (error) {
        console.error(error)
    }
}

export const getBulkCallDetailsAPI = async (queryParams) => {
    const { exotelNextPageUri } = queryParams
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken });

        const res = await fetch(`${baseUrl}/get-bulk-call-details/exotel?&exotelNextPageUri=${exotelNextPageUri}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        })
            .then(res => res.json())
        return res
    } catch (error) {
        console.error(error)
        return null
    }
}

export const getSupportPhoneNumbersAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken });

        const res = await fetch(`${baseUrl}/get-support-number/admin`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        })
            .then(res => res.json())
        return res
    } catch (error) {
        console.error(error)
    }
}

export const addSupportPhoneNumbersAPI = async (data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken });

        const res = await fetch(`${baseUrl}/add-support-number/admin`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
        return res
    } catch (error) {
        console.error(error)
    }
}