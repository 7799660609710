import axios, { AxiosResponse } from "axios";
import { checkLogin } from "../../../utils/firebase";

const baseUrl = process.env.REACT_APP_BASE_URL


export const fetchAllBusinessUsersAPI = async function () {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/business/getUsers/admin`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const createNewBusinessUserAPI = async function (data) {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

    const { reqBody } = data
    console.log(data)
    try {
        const res = await fetch(`${baseUrl}/business/createUser`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchBusinessUserDetailsAPI = async function (id) {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/business/getUserDetail/admin/${id}`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return res
    } catch (error) {
        console.log(error);
    }
}

export const updateBusinessUserAPI = async function (id, reqBody) {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/business/updateUser/${id}/admin`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            },
            body: JSON.stringify(reqBody)
        }).then(res => res.json());
        return res
    } catch (error) {
        console.log(error);
    }
}

export const generateApiKeyForBusinessAPI = async function (uid) {
    let authToken = '';
    await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl}/business/generateAPIkey/${uid}/admin`,
            method: "POST",
            headers: {
                Accept: "application/json",
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    })
}