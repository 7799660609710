import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Table, Button } from 'react-bootstrap'
import { getAllCountries } from '../../app/features/countries/countrySlice'
import { editIcon, sortAscIcon, sortDescIcon, sortIcon } from '../../FontAwesome'
import Loader from '../Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'


export default function Countries() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { countries, fetching } = useSelector(state => state.country)


    const [sortType, setSortType] = useState("updatedAt-desc")
    const [sortedResults, setSortedResults] = useState(countries ? countries : [])

    const handleSort = (type) => {
        const order = sortType.split("-")[1]
        if (order === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (order === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        if (!fetching) {
            const [attr, type] = sortType.split("-")
            let temp = [...countries]
            if (attr === "updatedAt" || attr === "createdAt") {
                if (type === "asc") {
                    temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) =>
                        new Date(b[attr]) - new Date(a[attr])
                    )
                    setSortedResults(temp)
                }
            } else {
                if (type === "asc") {
                    temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                    setSortedResults(temp)
                }
                else if (type === "desc") {
                    temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                    setSortedResults(temp)
                }
            }
        }
    }, [sortType, fetching, countries])

    useEffect(() => {
        dispatch(getAllCountries())
    }, [])

    if (fetching) return (
        <Loader />
    )

    return (
        <>
            <div style={{ height: '100%', overflowY: 'auto', padding: '25px' }}>
                <Button variant="primary" onClick={() => navigate('/country/add')}>Add country</Button>
                <hr />
                <Table striped bordered hover responsive className='mt-3'>
                    <thead style={{ verticalAlign: 'middle' }}>
                        <tr>
                            <th>
                                Country name&nbsp;
                                {sortType.split("-")[0] !== "countryText" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("countryText")}>{sortIcon}</span>}
                                {
                                    (sortType.split("-")[0] === "countryText" && sortType.split("-")[1] === "asc") &&
                                    <span style={{ cursor: 'pointer' }} onClick={() => handleSort("countryText")}>{sortAscIcon}</span>
                                }
                                {
                                    (sortType.split("-")[0] === "countryText" && sortType.split("-")[1] === "desc") &&
                                    <span style={{ cursor: 'pointer' }} onClick={() => handleSort("countryText")}>{sortDescIcon}</span>
                                }
                            </th>
                            <th>Import</th>
                            <th>Alpha code</th>
                            <th>
                                Created At&nbsp;
                                {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                                {
                                    (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                    <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                                }
                                {
                                    (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                    <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                                }
                            </th>
                            <th>
                                Updated At&nbsp;
                                {sortType.split("-")[0] !== "updatedAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortIcon}</span>}
                                {
                                    (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "asc") &&
                                    <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortAscIcon}</span>
                                }
                                {
                                    (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "desc") &&
                                    <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortDescIcon}</span>
                                }
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sortedResults.map((country, index) => {
                                const createdAtDateString = new Date(country.createdAt);
                                const createdAtDate = createdAtDateString.toDateString() + ", " + createdAtDateString.toLocaleTimeString();
                                const updatedAtDateString = new Date(country.updatedAt);
                                const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();
                                return (
                                    <tr key={index}>
                                        <td>{country.countryText}</td>
                                        <td>{country.canImport.toString()}</td>
                                        <td>{country.alphaCode}</td>
                                        <td>{createdAtDate}</td>
                                        <td>{updatedAtDate}</td>
                                        <td>
                                            <Button variant="primary" onClick={() => navigate(`/country/update/${country._id}`)}>{editIcon}</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}
