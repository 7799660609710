/* eslint-disable react/react-in-jsx-scope */
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import './App.css';
import Login from './components/Authentication/Login';
import SideNav from './components/NavBar/SideNav';
import TopNav from './components/NavBar/TopNav';
import Banner from './components/Banner/Banner';
import OrderDetails from './components/OrderDetails';
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap';
import AddCity from './components/Cities/AddCity';
import InternationalOrders from './components/Orders/InternationalOrders';
import InternationalOrderDetail from './components/Orders/InternationalOrderDetail';
import CityDetails from './components/Cities/CityDetails';
import Cities from './components/Cities/Cities';
import Orders from './components/Orders/Orders';
import RentalOrders from './components/Orders/RentalOrders';
import RentalOrderDetail from './components/Orders/RentalOrderDetail';
import Hyperlocal from './components/Orders/Hyperlocal';
import HyperlocalOrderDetail from './components/Orders/HyperlocalOrderDetail';
import Customers from './components/Customers/Customers';
import CustomerDetails from './components/Customers/CustomerDetails';
import Coupons from './components/Coupons/Coupons'
import CouponDetails from './components/Coupons/CouponDetails'
import AddCoupon from './components/Coupons/AddCoupon'
import EditCoupon from './components/Coupons/EditCoupn'
import Reviews from './components/Reviews/Reviews'
import ReviewDetails from './components/Reviews/ReviewDetails'
import EditCity from './components/Cities/EditCity';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginFromSession, logout, signIn } from './app/features/user/userSlice';
import Dashboard from './components/Dashboard/Dashboard';
import AddCountry from './components/Countries/AddCountry';
import Countries from './components/Countries/Countries';

import InternationalShipperMonitoring from './components/Monitoring/InternationalShipperMonitoring';
import PanIndiaShipperMonitoring from './components/Monitoring/PanIndiaShipperMonitoring';
import HyperlocalShippersMonitoring from './components/Monitoring/HyperlocalShipperMonitoring';

import InternationalShipper from './components/Shippers/InternationalShipper';
import AddNewIntShipper from './components/Shippers/AddNewIntShipper';
import RequestPayment from './components/Payment/RequestPayment';
import HyperlocalShippers from './components/Shippers/HyperlocalShippers';
import AddNewHyShipper from './components/Shippers/AddNewHyShipper';
import EditIntShipper from './components/Shippers/EditIntShipper';
import EditHyShipper from './components/Shippers/EditHyShipper';
import EditCountry from './components/Countries/EditCountry';
import PanIndiaShippers from './components/Shippers/PanIndiaShippers';
import AddNewPanIndiaShipper from './components/Shippers/AddNewPanIndiaShipper';
import AddAnnouncementFeed from './components/CMS/Header/AddAnnouncementFeed';
import AnnouncementFeeds from './components/CMS/Header/AnnouncementFeeds';
import EditAnnouncementFeed from './components/CMS/Header/EditAnnouncementFeed';
import ExpressDeliveryOrders from './components/Orders/ExpressDeliveryOrders';
import EditExpressDeliveryOrder from './components/Orders/EditExpressDeliveryOrder';
import AddNewExpressDeliveryOrder from './components/Orders/AddNewExpressDeliveryOrder';
import EditPanIndiaShipper from './components/Shippers/EditPanIndiaShipper';
import Hyp2WheelerCharges from './components/ShipmantraCharges/Hyperlocal/Hyp2WheelerCharges';
import Hyp3WheelerCharges from './components/ShipmantraCharges/Hyperlocal/Hyp3WheelerCharges';
import Hyp4WheelerCharges from './components/ShipmantraCharges/Hyperlocal/Hyp4WheelerCharges';
import PanIndiaExpressCharge from './components/ShipmantraCharges/PanIndia/Express';
import InternationalExportsCharge from './components/ShipmantraCharges/International/Exports';
import PanIndiaDefaultCharge from './components/ShipmantraCharges/PanIndia/Default';
import Invoice from './components/Invoice/Invoice';
import AddNewInternationalOrder from './components/Orders/AddNewInternationalOrder';
import ImportOrders from './components/Orders/ImportOrders';
import ImportOrderDetail from './components/Orders/ImportOrderDetail';
import BusinessUser from './components/ShipmantraBusiness/User/Users';
import EditBusinessUser from './components/ShipmantraBusiness/User/EditBusinessUser';
import PendingOrders from './components/ShipmantraBusiness/Orders/PendingOrders';
import APSRTC_Orders from './components/APSRTC/Orders'
import WhatsappPromotion from './components/Misc/WhatsappPromotion';
import { checkLogin, signout } from './utils/firebase';
import { unwrapResult } from '@reduxjs/toolkit';
import NoOptionEnquiry from './components/Enquiry/NoOptionEnquiry';
import Enquiry from './components/Enquiry/Enquiry';
import VoiceCallForm from './components/Misc/VoiceCallForm';
import { getSupportPhoneNumbers } from './app/features/general/generalSlice';
import ApsrtcCities from './components/APSRTC/Cities';
import ApsrtcInvoice from './components/APSRTC/ApsrtcInvoice';
import CallList from './components/Misc/CallList';
import Hyp2WheelerB2BCharges from './components/ShipmantraCharges/Hyperlocal/Business/Hyp2WheelerB2BCharges';
import Hyp3WheelerB2BCharges from './components/ShipmantraCharges/Hyperlocal/Business/Hyp3WheelerB2BCharges';
import Hyp4WheelerB2BCharges from './components/ShipmantraCharges/Hyperlocal/Business/Hyp4WheelerB2BCharges';
import PanIndiaB2BExpressCharge from './components/ShipmantraCharges/PanIndia/Business/ExpressB2B';
import { AllBusinessOrders } from './components/ShipmantraBusiness/Orders/Orders';
import APSRTC_Agents from './components/APSRTC/Agents';
import RequestOrders from './components/APSRTC/RequestOrders';
import AllAdminsList from './components/Admin/AllAdmins';
import SalesReport from './components/Misc/SalesReport';
import AddSalesTarget from './components/Misc/AddSalesTarget';
import WalletRechargeInvoiceInput from './components/Invoice/WalletRechargeInvoiceInput';
import C2CRequestOrders from './components/Orders/C2CRequestOrders';

function RequireAuth({ children }) {
  const { isAuth } = useSelector(state => state.adminUser)

  let location = useLocation();

  if (!isAuth) {
    return <Navigate to={"/login?redirect=" + location.pathname} replace />;
  }

  return children;
}

// function APSRTC_Routes() {
//   return (

//   )
// }

// function Business_Routes() {
//   return (

//   )
// }

// function Shipmantra_C2C_Routes() {
//   return (

//   )
// }

// function Misc_Routes() {
//   return (

//   )
// }

// function Admin_Routes() {
//   return (
//     <React.Fragment>
//       {/* Shipmantra Admin Routes */}
//       <Route path='/admin' element={<RequireAuth><AllAdminsList /></RequireAuth>} />
//     </React.Fragment>
//   )
// }

function App() {
  const dispatch = useDispatch()


  const { isAuth, adminUser } = useSelector(state => state.adminUser)
  const username = localStorage.getItem('shipmantra-admin-username');
  const email = localStorage.getItem('shipmantra-admin-email');
  const role = localStorage.getItem('shipmantra-admin-role');
  const displayName = localStorage.getItem('shipmantra-admin-displayName');
  const token = localStorage.getItem('shipmantra-admin-token');


  useEffect(async () => {

    dispatch(signIn())
      .then(unwrapResult)
      .then((res) => {
        const adminUser = {
          ...res.data,
          displayName: res.data.displayName,
          username: res.data.username,
          email: res.data.email,
          role: res.data.role,
          token: ""
        }
        dispatch(loginFromSession(adminUser))
      })


    // if (username && email && role && token) {
    //   const data = { username, email, role, displayName, token }
    //   dispatch(loginFromSession(data))
    // }
  }, [])

  useEffect(() => {
    dispatch(getSupportPhoneNumbers())
  }, [])


  if(!window.location.host?.includes("shipmantra.com")){
    console.log(window.location.host)
    return null
  }


  return (
    <>
      <div className='d-flex'>
        <SideNav />
        <main style={{ width: '100%', height: "100vh", overflowY: 'auto', overflowX: 'hidden' }} className='d-flex flex-column'>
          <TopNav />
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/' element={<RequireAuth><Dashboard /></RequireAuth>} />


            {/* Shipmantra C2C Routes */}
            {(adminUser?.isSuperAdmin || adminUser?.modules?.includes('shipmantra_c2c')) &&
              <React.Fragment>
                <Route path='city' element={<RequireAuth><Cities /></RequireAuth>}>
                  <Route index element={<RequireAuth><CityDetails /></RequireAuth>} />
                  <Route path='details' element={<RequireAuth><CityDetails /></RequireAuth>} />
                  {/* <Route path=':city' element={<EditCity />} /> */}
                  <Route path='addCity' element={<RequireAuth><AddCity /></RequireAuth>} />
                </Route>
                <Route path='city/:id' element={<RequireAuth><EditCity /></RequireAuth>}></Route>

                <Route path="/country/add" element={<RequireAuth><AddCountry /></RequireAuth>} />
                <Route path="/countries" element={<RequireAuth><Countries /></RequireAuth>} />
                <Route path="/country/update/:countryId" element={<RequireAuth><EditCountry /></RequireAuth>} />


                {/* Orders routes for all modules */}
                <Route path='orders' element={<RequireAuth><Orders /></RequireAuth>}>
                  <Route index element={<InternationalOrders />} />
                  <Route path='internationalOrders' element={<RequireAuth><InternationalOrders /></RequireAuth>} />
                  <Route path='importOrders' element={<RequireAuth><ImportOrders /></RequireAuth>} />
                  <Route path='importOrders/:orderId' element={<RequireAuth><ImportOrderDetail /></RequireAuth>} />
                  <Route path='internationalOrders/:orderId' element={<RequireAuth><InternationalOrderDetail /></RequireAuth>} />
                  <Route path='internationalOrders/add' element={<RequireAuth><AddNewInternationalOrder /></RequireAuth>} />
                  <Route path='rentalOrders' element={<RequireAuth><RentalOrders /></RequireAuth>} />
                  <Route path='rentalOrders/:id' element={<RequireAuth><RentalOrderDetail /></RequireAuth>} />
                  <Route path='hyperlocalOrders' element={<RequireAuth><Hyperlocal /></RequireAuth>} />
                  <Route path='hyperlocalOrders/:id' element={<RequireAuth><HyperlocalOrderDetail /></RequireAuth>} />
                  <Route path='panIndiaOrders/express-orders' element={<RequireAuth><ExpressDeliveryOrders /></RequireAuth>} />
                  <Route path='panIndiaOrders/express-orders/add' element={<RequireAuth><AddNewExpressDeliveryOrder /></RequireAuth>} />
                  <Route path='panIndiaOrders/express-orders/:id' element={<RequireAuth><EditExpressDeliveryOrder /></RequireAuth>} />
                  <Route path='c2cRequestOrders' element={<RequireAuth><C2CRequestOrders /></RequireAuth>} />
                </Route>

                {/* International shipper routes */}
                <Route path='/shipper/internationalShipper' element={<RequireAuth><InternationalShipper /></RequireAuth>} />
                <Route path='/shipper/add/internationalShipper' element={<RequireAuth><AddNewIntShipper /></RequireAuth>} />
                <Route path='/shipper/internationalShipper/:shipperID' element={<RequireAuth><EditIntShipper /></RequireAuth>} />

                {/* Hyperlocal shipper routes */}
                <Route path='/shipper/hyperlocalShipper' element={<RequireAuth><HyperlocalShippers /></RequireAuth>} />
                <Route path='/shipper/add/hyperlocalShipper' element={<RequireAuth><AddNewHyShipper /></RequireAuth>} />
                <Route path='/shipper/hyperlocalShipper/:shipperID' element={<RequireAuth><EditHyShipper /></RequireAuth>} />

                {/* Pan india shipper routes */}
                <Route path='/shipper/panIndiaShipper' element={<RequireAuth><PanIndiaShippers /></RequireAuth>} />
                <Route path='/shipper/add/panIndiaShipper' element={<RequireAuth><AddNewPanIndiaShipper /></RequireAuth>} />
                <Route path='/shipper/panIndiaShipper/:shipperID' element={<RequireAuth><EditPanIndiaShipper /></RequireAuth>} />


                {/* Shipmantra charges routes */}
                <Route path='/shipmantra-charges/hyperlocal-2-wheeler' element={<RequireAuth><Hyp2WheelerCharges /></RequireAuth>} />
                <Route path='/shipmantra-charges/hyperlocal-3-wheeler' element={<RequireAuth><Hyp3WheelerCharges /></RequireAuth>} />
                <Route path='/shipmantra-charges/hyperlocal-4-wheeler' element={<RequireAuth><Hyp4WheelerCharges /></RequireAuth>} />
                <Route path='/shipmantra-charges/international/exports' element={<RequireAuth><InternationalExportsCharge /></RequireAuth>} />
                <Route path='/shipmantra-charges/pan-india/express-delivery' element={<RequireAuth><PanIndiaExpressCharge /></RequireAuth>} />
                <Route path='/shipmantra-charges/pan-india/default-delivery' element={<RequireAuth><PanIndiaDefaultCharge /></RequireAuth>} />

              </React.Fragment>
            }

            {/* Business Routes */}
            {(adminUser?.isSuperAdmin || adminUser?.modules?.includes('business')) &&
              <React.Fragment>
                {/* Shipmantra Business Routes */}
                <Route path='/business/users' element={<RequireAuth><BusinessUser /></RequireAuth>} />
                <Route path='/business/orders' element={<RequireAuth><AllBusinessOrders /></RequireAuth>} />
                <Route path='/business/user/edit/:userID' element={<RequireAuth><EditBusinessUser /></RequireAuth>} />
                <Route path='/business/pending-orders' element={<RequireAuth><PendingOrders /></RequireAuth>} />
                {/* B2b charges routes */}
                <Route path='/business-charges/hyperlocal/two-wheeler' element={<RequireAuth><Hyp2WheelerB2BCharges /></RequireAuth>} />
                <Route path='/business-charges/hyperlocal/three-wheeler' element={<RequireAuth><Hyp3WheelerB2BCharges /></RequireAuth>} />
                <Route path='/business-charges/hyperlocal/four-wheeler' element={<RequireAuth><Hyp4WheelerB2BCharges /></RequireAuth>} />
                {/* <Route path='/business-charges/international/exports' element={<RequireAuth><InternationalExportsCharge /></RequireAuth>} /> */}
                <Route path='/business-charges/pan-india/express-delivery' element={<RequireAuth><PanIndiaB2BExpressCharge /></RequireAuth>} />
                <Route path='/business-charges/pan-india/default-delivery' element={<RequireAuth><PanIndiaDefaultCharge /></RequireAuth>} />
              </React.Fragment>}


            {/* APSRTC Routes */}
            {(adminUser?.isSuperAdmin || adminUser?.modules?.includes('apsrtc')) &&
              <React.Fragment>
                {/* Shipmantra Vendor APSRTC Routes */}
                {/* <Route path='/vendors/apsrtc' element={<RequireAuth><APSRTC /></RequireAuth>} /> */}
                {/* <Route path='/vendors/apsrtc/payouts' element={<RequireAuth><APSRTC_Payouts /></RequireAuth>} /> */}
                <Route path='/apsrtc/:orderType' element={<RequireAuth><APSRTC_Orders /></RequireAuth>} />
                <Route path='/apsrtc/request-orders' element={<RequireAuth><RequestOrders /></RequireAuth>} />
                <Route path='/apsrtc/agents' element={<RequireAuth><APSRTC_Agents /></RequireAuth>} />
                <Route path='/apsrtc/all-cities' element={<RequireAuth><ApsrtcCities /></RequireAuth>} />
                <Route path='/apsrtc/invoice' element={<RequireAuth><ApsrtcInvoice /></RequireAuth>} />
              </React.Fragment>}


            {/* Miscellaneous Routes */}
            {(adminUser?.isSuperAdmin || adminUser?.modules?.includes('misc')) &&
              <React.Fragment>
                {/* Miscellaneous */}
                <Route path='/misc/whatsapp-promotion' element={<RequireAuth><WhatsappPromotion /></RequireAuth>} />
                <Route path='/misc/voice-call' element={<RequireAuth><VoiceCallForm /></RequireAuth>} />
                <Route path='/misc/call-details' element={<RequireAuth><CallList /></RequireAuth>} />
              </React.Fragment>}


            {/* Sales & Marketing Routes */}
            {(adminUser?.isSuperAdmin || adminUser?.modules?.includes('sales_and_marketing')) && <React.Fragment>
              <Route path='banner' element={<RequireAuth><Banner /></RequireAuth>} />
              <Route path='/enquiry/no-option-enquiry' element={<RequireAuth><NoOptionEnquiry /></RequireAuth>} />
              <Route path='/enquiry/:enquiryType' element={<RequireAuth><Enquiry /></RequireAuth>} />
              <Route path='customers' element={<RequireAuth><Customers /></RequireAuth>}></Route>
              <Route path='customer/:uid' element={<RequireAuth><CustomerDetails /></RequireAuth>}></Route>

              {/* Reviews routes */}
              <Route path='reviews' element={<RequireAuth><Reviews /></RequireAuth>}></Route>
              <Route path='reviews/:id' element={<RequireAuth><ReviewDetails /></RequireAuth>}></Route>

              {/* Invoices routes */}
              <Route path='/invoices' element={<RequireAuth><Invoice /></RequireAuth>} />
              <Route path='/wallet-recharge-invoices' element={<RequireAuth><WalletRechargeInvoiceInput /></RequireAuth>} />

              {/* CMS Activities */}
              <Route path='/cms/header/announcement-feed/add' element={<RequireAuth><AddAnnouncementFeed /></RequireAuth>} />
              <Route path='/cms/header/announcement-feed/edit/:id' element={<RequireAuth><EditAnnouncementFeed /></RequireAuth>} />
              <Route path='/cms/header/announcement-feeds' element={<RequireAuth><AnnouncementFeeds /></RequireAuth>} />

              {/* Coupons routes */}
              <Route path='coupons' element={<RequireAuth><Coupons /></RequireAuth>}>
                <Route index element={<RequireAuth><CouponDetails /></RequireAuth>} />
                <Route path='details' element={<RequireAuth><CouponDetails /></RequireAuth>} />
                <Route path='addCoupon' element={<RequireAuth><AddCoupon /></RequireAuth>} />
              </Route>
              <Route path='coupons/:coupon' element={<RequireAuth><EditCoupon /></RequireAuth>} />

              {/* Request payment from customers if pending */}
              <Route path='/request-payment' element={<RequireAuth><RequestPayment /></RequireAuth>} />

            </React.Fragment>}


            {/* Admin Routes */}
            {(adminUser?.isSuperAdmin || adminUser?.modules?.includes('admin')) && <Route path='/admin' element={<RequireAuth><AllAdminsList /></RequireAuth>} />}


            {/* API Monitoring */}
            {(adminUser?.isSuperAdmin || adminUser?.modules?.includes('api_monitoring')) &&
              <React.Fragment>
                {/* API monitoring */}
                <Route path='/monitoring/hyperlocalShipper' element={<RequireAuth><HyperlocalShippersMonitoring /></RequireAuth>} />
                <Route path='/monitoring/panIndiaShipper' element={<RequireAuth><PanIndiaShipperMonitoring /></RequireAuth>} />
                <Route path='/monitoring/internationalShipper' element={<RequireAuth><InternationalShipperMonitoring /></RequireAuth>} />
              </React.Fragment>
            }


            {/* Sales Report Routes */}
            <Route path='/daily-sales-report' element={<RequireAuth><SalesReport /></RequireAuth>} />
            {(adminUser?.isSuperAdmin || adminUser?.modules?.includes('daily_sales_report')) &&
              <React.Fragment>
                <Route path='/add-sales-target' element={<RequireAuth><AddSalesTarget /></RequireAuth>} />
              </React.Fragment>
            }

          </Routes>
        </main>
      </div>
    </>
  );
}

export default App;
