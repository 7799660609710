import React, { useEffect } from 'react'
import { Row, Col, Form, Button, Dropdown } from 'react-bootstrap'
import { downloadIcon } from '../../FontAwesome'
import PDFPreview from '../PDFPreview'
import InvoiceTemplate from '../Invoice/InvoiceTemplate'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllAPSRTCOrdersWithoutPaging } from '../../app/features/old_apsrtc_booking/apsrtcBookingSlice'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faEye, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons'

export default function ApsrtcInvoice() {
    const dispatch = useDispatch();
    const { ordersWithoutPaging, fetching } = useSelector(state => state.apsrtcBooking)

    const [showInvoicePreviewModal, setShowInvoicePreviewModal] = useState(false);
    const [searchString, setSearchString] = useState('')

    const [timespan, setTimespan] = useState('This Month')

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        switch (timespan) {
            case 'This Month':
                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                var firstDay = new Date(y, m, 2);
                var lastDay = new Date(y, m + 1, 1);
                setStartDate(firstDay.toISOString().split('T')[0]);
                setEndDate(date.toISOString().split('T')[0]);
                break;

            case 'Previous Month':
                var dateLM = new Date(), yLM = dateLM.getFullYear(), mLM = dateLM.getMonth();
                var firstDayLM = new Date(yLM, mLM - 1, 2);
                var lastDayLM = new Date(yLM, mLM, 1);
                setStartDate(firstDayLM.toISOString().split('T')[0]);
                setEndDate(lastDayLM.toISOString().split('T')[0]);
                break;

            default:
                break;
        }
    }, [timespan])


    const previewInvoice = () => {
        //fetch all the apsrtc orders 
        let queryParams = {};
        if (searchString) {
            queryParams.searchString = searchString
        }

        dispatch(fetchAllAPSRTCOrdersWithoutPaging(queryParams))

        //show pdf preview modal
        setShowInvoicePreviewModal(true)
    }

    const downloadBulkInvoice = () => {
        //fetch all the apsrtc orders 
        let queryParams = {};
        if (startDate) {
            queryParams["startDate"] = startDate;
        }
        if (endDate) {
            queryParams["endDate"] = endDate;
        }

        dispatch(fetchAllAPSRTCOrdersWithoutPaging(queryParams))

        //show pdf preview modal
        setShowInvoicePreviewModal(true)
    }

    const CustomToggle = React.forwardRef(({ label, children, active, onClick }, ref) => (
        <div>
            <Button
                // href=""
                // style={{ cursor: 'pointer' }}
                variant={active ? "primary" : 'outline-primary'}
                size='sm'
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}&nbsp;<FontAwesomeIcon icon={faChevronDown} />
            </Button>
        </div>
    ));

    return (
        <div style={{ padding: '30px' }}>
            <div className='d-flex'>
                <h3 style={{ flex: 1, textTransform: 'capitalize' }}>APSRTC INVOICES</h3>
            </div>
            <hr />
            <div style={{ fontWeight: 800, paddingBottom: '12px' }}><FontAwesomeIcon icon={faSearch} />&nbsp;Search Order Invoice</div>
            <div className='d-flex'>
                <div style={{ height: '38px', flex: 1 }}>
                    <input style={{ height: '100%' }} type="text" className='form-control' placeholder='Search order ID or LR number' value={searchString} onChange={e => setSearchString(e.currentTarget.value)} />
                </div>
                <Button onClick={previewInvoice} className='ms-2' variant='success' disabled={fetching}><FontAwesomeIcon icon={faEye} />&nbsp;Preview invoice</Button>
            </div>
            <hr />
            <div style={{ fontWeight: 800, paddingBottom: '12px' }}>{downloadIcon}&nbsp;Bulk Download Invoices</div>
            <Row>
                <Col sm={12} lg={3}>
                    <div style={{ fontWeight: 800, fontSize: '12px', paddingBottom: '8px' }}><FontAwesomeIcon icon={faFilter} />&nbsp;Select Timespan:</div>
                    <Dropdown style={{ display: 'inline-block', zIndex: '1000', width: '100%' }} onSelect={(value) => setTimespan(value)} >
                        <Dropdown.Toggle
                            label="Select Timespan"
                            active={false}
                            as={CustomToggle}
                        >
                            {timespan ? timespan : "Apply filter"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="">Select</Dropdown.Item>
                            <Dropdown.Item eventKey="This Month" active={timespan === "This Month"}>This Month</Dropdown.Item>
                            <Dropdown.Item eventKey="Previous Month" active={timespan === "Previous Month"}>Previous Month</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col sm={12} lg={5}>
                    <div style={{ fontWeight: 800, fontSize: '10px', paddingBottom: '8px' }}><FontAwesomeIcon icon={faFilter} />&nbsp;Filter by Date Manually:</div>
                    <div style={{ display: 'flex' }}>
                        <Form.Group controlId="filter">
                            <Form.Control style={{ cursor: 'pointer', borderColor: startDate ? '#0d6efd' : undefined, color: startDate ? '#0d6efd' : undefined, borderRadius: '0.2rem' }} type="date" value={startDate} disabled={fetching}
                                onChange={e => { setStartDate(e.target.value); setTimespan("Custom") }} />
                        </Form.Group>
                        <div>&nbsp;&nbsp;To</div>
                        <Form.Group className='ms-3' controlId="filter">
                            <Form.Control style={{ cursor: 'pointer', borderColor: endDate ? '#0d6efd' : undefined, color: endDate ? '#0d6efd' : undefined, borderRadius: '0.2rem' }} type="date" value={endDate} disabled={fetching}
                                onChange={e => { setEndDate(e.target.value); setTimespan("Custom") }} />
                        </Form.Group>
                    </div>
                </Col>
                <Col sm={12} lg={12} style={{ paddingTop: '10px' }}>
                    {/* <div style={{ fontWeight: 800, fontSize: '10px', paddingBottom: '8px' }}><FontAwesomeIcon icon={faFilter} />&nbsp;Preview & download:</div> */}
                    <Button onClick={downloadBulkInvoice} disabled={fetching}>{downloadIcon}&nbsp;Download invoice</Button>
                </Col>
            </Row>
            <PDFPreview fileName={'Consolidated PDF' + "_" + startDate + "_" + endDate} show={showInvoicePreviewModal} handleClose={() => setShowInvoicePreviewModal(false)} pdfTemplate={<InvoiceTemplate dataArr={ordersWithoutPaging} />} />
        </div>
    )
}
