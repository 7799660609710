import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { backButtonIcon } from '../../FontAwesome';
import { Container, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRentalOrdersById, updateRentalOrder } from '../../app/features/order/orderSlice';
import RequestPayment from '../Payment/RequestPayment';

export default function RentalOrderDetail() {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { fetching, orders, orderDetails } = useSelector(state => state.order)
  const id = params.id

  const [order, setOrder] = useState({})
  const [orderState, setOrderState] = useState("")
  const [tracking_url, setTrackingLink] = useState(orderDetails.tracking_url ? orderDetails.tracking_url : "")
  const [driverContact, setDriverContact] = useState("")
  const [auditedKM, setAuditedKM] = useState("")

  // const [showPaymentReqModal, setShowPaymentReqModal] = useState(false)


  const handleSubmit = e => {
    e.preventDefault();
    if (!/^https?:\/\//i.test(tracking_url)) {
      setTrackingLink('https://' + tracking_url);
    }
    const data = {
      state: orderState,
      tracking_url,
      driverContact,
      auditedKM
    }
    const ordernum = order._id
    dispatch(updateRentalOrder({ ordernum: id, data }))
      .then(() => { dispatch(fetchRentalOrdersById(id)) })
  };

  useEffect(() => {
    dispatch(fetchRentalOrdersById(id))
  }, [])


  useEffect(() => {
    setOrder(orderDetails)
    setOrderState(orderDetails.state)
    setAuditedKM(orderDetails.auditedKM)
    setTrackingLink(orderDetails.tracking_url)
    setDriverContact(orderDetails.driverContact)
  }, [orderDetails])


  if (fetching) return (
    <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
      <Spinner animation="border" />
    </div>
  )

  return (
    <>
      <Container className='my-4'>
        <Button variant='primary' className='mb-2' onClick={e => { navigate(-1) }}>{backButtonIcon}&nbsp;Back</Button>&nbsp;

        <h3>Order: {order.sm_orderid ? order.sm_orderid : order._id}</h3>
        <hr />

        <Form onSubmit={handleSubmit}>
          <Row className='mb-3'>
            <Col sm={6}>
              <Form.Label>State</Form.Label>
              <Form.Select name="state" value={orderState} onChange={e => setOrderState(e.currentTarget.value)}>
                <option>Select</option>
                <option value="Initiated">Initiated</option>
                <option value="Booked">Booked</option>
                <option value="WaitingConfirmation">Waiting for confirmation</option>
                <option value="Confirmed">Confirmed</option>
                <option value="VehicleDelivered">Vehicle delivered</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Under Hold">Under Hold</option>
              </Form.Select>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="tracking_url">
                <Form.Label>Tracking Link</Form.Label>
                <Form.Control type="text" value={tracking_url} placeholder="Tracking link" onChange={e => setTrackingLink(e.currentTarget.value)} />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col sm={6}>
              <Form.Group controlId="driverContact">
                <Form.Label>Driver Contact</Form.Label>
                <Form.Control type="text" value={driverContact} placeholder="Driver Contact" onChange={e => setDriverContact(e.currentTarget.value)} />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="auditedKM">
                <Form.Label>Audited KM</Form.Label>
                <Form.Control type="number" value={auditedKM} placeholder="Audited KM" onChange={e => setAuditedKM(e.currentTarget.value)} />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col sm={6}>
              <Form.Group controlId="shipperId">
                <Form.Label>Shipper id</Form.Label>
                <Form.Control type="text" value={order.shipperID} placeholder="ShipperID" readOnly />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="orderNum">
                <Form.Label>Order number</Form.Label>
                <Form.Control type="text" value={order.sm_orderid ? order.sm_orderid : order._id} placeholder="Order number" readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col sm={6}>
              <Form.Group controlId="paymentStatus">
                <Form.Label>Payment Status</Form.Label>
                <Form.Control type="text" value={order.payment_status} placeholder="Payment Status" readOnly />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="rentalPeriod">
                <Form.Label>Rental Period</Form.Label>
                <Form.Control type="text" value={order.rentalPeriod} placeholder="Rental Period" readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col sm={6}>
              <Form.Group controlId="user">
                <Form.Label>User</Form.Label>
                <Form.Control type="text" value={order.user} placeholder="User" readOnly />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control type="text" value={order.city} placeholder="City" readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col sm={6}>
              <Form.Group controlId="pickupDate">
                <Form.Label>Pickup date</Form.Label>
                <Form.Control type="text" value={order.pickupDate} placeholder="Pickup date" readOnly />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="pickupTime">
                <Form.Label>Pickup time</Form.Label>
                <Form.Control type="text" value={order.pickupTime} placeholder="Pickup time" readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col sm={6}>
              <Form.Group controlId="vehicle">
                <Form.Label>Vehicle</Form.Label>
                <Form.Control type="text" value={order.vehicle} placeholder="Vehicle" readOnly />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="phone">
                <Form.Label>Contact</Form.Label>
                <Form.Control type="text" value={order.start?.contact?.phone} placeholder="Contact" readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col sm={6}>
              <Form.Group controlId="transactionID">
                <Form.Label>Transaction Id</Form.Label>
                <Form.Control type="text" value={order.transactionID} placeholder="Transaction ID" readOnly />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" value={order.start?.contact?.email} placeholder="Email" readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Button variant='primary' type="submit">Update</Button>
          {/* <Button variant='primary' type="button" style={{ marginLeft: '20px' }} onClick={() => { setShowPaymentReqModal(true) }}>Request Payment</Button> */}
        </Form>
      </Container >
      {/* <RequestPayment order={orders[0]} show={showPaymentReqModal} handleClose={() => setShowPaymentReqModal(false)} /> */}
    </>
  )
}
