import { checkLogin } from "../../utils/firebase";

const baseUrl = 'https://api.shipmantra.com'
const localhostUrl = 'http://localhost:8000'

export const fetchAllCouponsAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/getAdminCoupons`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const fetchCouponByIdAPI = async (coupon) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        //change
        const res = await fetch(`${baseUrl}/coupons/${coupon}`, {
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json());
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const createCouponAPI = async (data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/coupons/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
                //'x-access-token': ''+data.token//pass token variable here
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err);
    }
}

export const updateCouponAPI = async (coupon, data) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/coupons/update/${coupon}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
                //'x-access-token': ''+data.token//pass token variable here
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err);
    }
}

export const deleteCouponAPI = async (couponName) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${baseUrl}/coupons/delete/${couponName}`, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res
    } catch (err) {
        console.log(err)
    }
}