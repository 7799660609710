import { Button, Card, Col, Row, Form, InputGroup, Modal, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { read, utils, } from 'xlsx';
import { sendWhatsappPromotion } from '../../app/api/misc';
import { addIcon } from '../../FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import { addWhatsappTemplate, getWhatsappTemplates } from '../../app/features/general/generalSlice';
import AmazonS3Upload from '../AmazonS3Upload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const languageCodeLabel = {
    'en': 'English',
    'te': 'Telugu',
    'ta': 'Tamil',
    'hi': 'Hindi'
}


function AddNewWaTemplate({ show, handleClose }) {
    const dispatch = useDispatch();

    const { fetching } = useSelector(state => state.general);

    const [templateName, setTemplateName] = useState('');
    const [mediaUrl, setMediaUrl] = useState('');
    const [templateLanguage, setTemplateLanguage] = useState('en')

    const handleAddTemplateFormSubmit = (e) => {
        e.preventDefault();

        const templateCreationPayload = {
            templateName,
            language: templateLanguage,
            templateData: {
                header: {
                    image: mediaUrl,
                }
            }
        }

        dispatch(addWhatsappTemplate(templateCreationPayload))
            .then((res) => {
                if (!res.error) {
                    alert('Template added successfully');
                    handleClose();
                }
            })
            .catch(err => {
                alert('Something went wrong')
            })
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
            >
                <Modal.Header>Add New Whatsapp Template</Modal.Header>

                <Modal.Body>

                    <Form onSubmit={e => handleAddTemplateFormSubmit(e)}>
                        <Form.Group className="mb-3" controlId="templateName">
                            <Form.Label className='required'>
                                Name of the Template
                                <OverlayTrigger overlay={<Tooltip id={"template_name_info"}>{"Copy paste the exact template name from freshdesk"}</Tooltip>}>
                                    <div style={{ display: 'inline-block', marginLeft: '2px' }}>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </div>
                                </OverlayTrigger>
                            </Form.Label>
                            <Form.Control type='text' onChange={e => setTemplateName(e.currentTarget.value)} value={templateName} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="templateName">
                            <Form.Label className='required'>
                                Language
                            </Form.Label>
                            <Form.Select onChange={(e) => { setTemplateLanguage(e.currentTarget.value) }} value={templateLanguage} required>
                                <option value="" disabled>Select</option>
                                <option value="en">English</option>
                                <option value="te">Telugu</option>
                                <option value="ta">Tamil</option>
                                <option value="hi">Hindi</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="templateName">
                            <Form.Label>
                                Upload Image
                                <OverlayTrigger overlay={<Tooltip id={"template_name_info"}>{"If the template contains an image you are requested to upload an image here"}</Tooltip>}>
                                    <div style={{ display: 'inline-block', marginLeft: '2px' }}>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </div>
                                </OverlayTrigger>
                            </Form.Label>
                            <AmazonS3Upload bucket={process.env.REACT_APP_WHATSAPP_PROMOTION_TEMPLATES || 'shipmantra-test-bucket'} S3Folder={'Whatsapp_Template_Media'} setFileName={setMediaUrl} allowedFileTypes={['image/jpeg', 'image/png']} />
                        </Form.Group>

                        <hr />

                        <Button type='submit' disabled={fetching}>
                            Add&nbsp;
                            {
                                fetching && <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            }
                        </Button>

                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}


function GroupedNumbersCard({ groupedNumbers, sendWhatsapp, currentSent, index, busy, failed }) {

    return (

        <Col
            lg={12}
        >
            <Card
                style={{ marginBottom: '5px', padding: '10px', display: 'flex', flexDirection: 'row' }}
            >
                <div>
                    Group&nbsp;{index + 1}
                </div>
                <div style={{ flex: 1, paddingLeft: '20px', paddingRight: '10px' }}>
                    <Row>

                        {groupedNumbers && groupedNumbers.map((number, jindex) => {
                            return <Col sm={4} key={"number_" + index + "_" + jindex} style={{ fontSize: '10px' }}>
                                {number.phone_number}
                            </Col>
                        })}
                    </Row>
                </div>
                {currentSent == index ?
                    (failed ?
                        <Button variant={"danger"}
                            disabled={busy}
                            onClick={() => sendWhatsapp(index)}
                        >
                            Retry
                        </Button>
                        :
                        <Button variant={"warning"}
                        >
                            Sending
                        </Button>)
                    :
                    <Button variant={(currentSent < index) ? "primary" : "success"}
                        disabled={currentSent > index}
                    >
                        {(currentSent < index) ? "Ready" : "Success"}
                    </Button>
                }
            </Card>
        </Col>
    )
}

export default function WhatsappPromotion() {
    const dispatch = useDispatch();

    const { whatsappTemplates } = useSelector(state => state.general);

    const [groupedNumbersArr, setGroupedNumbersArr] = useState([]);
    const [currentSent, setCurrentSentGroup] = useState(-1);
    const [busy, setBusy] = useState(false);
    const [failed, setFailed] = useState(false);
    const [selectedWaTemplateName, setSelectedWaTemplateName] = useState('');
    const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);

    useEffect(() => {
        dispatch(getWhatsappTemplates());
    }, [])


    const resetData = () => {
        setGroupedNumbersArr([]);
        setCurrentSentGroup(-1);
        setBusy(false);
        setFailed(false);
        setSelectedWaTemplateName('')
    }

    const sendWhatsapp = (number) => {
        if (busy) {
            return;
        }
        if (!groupedNumbersArr.length) {
            alert('No numbers added');
            return;
        }
        if (number >= groupedNumbersArr.length) {
            setCurrentSentGroup(number + 1)
            alert("Sent to every group");
            setBusy(false);
            return;
        }
        setBusy(true);
        setFailed(false);
        console.log("Sending to group " + (number + 1));
        setCurrentSentGroup(number);
        sendWhatsappPromotion({ numberData: groupedNumbersArr[number], template_uid: selectedWaTemplateName })
            .then(async () => {
                setBusy(false);
                await setTimeout(() => {
                    sendWhatsapp(number + 1);
                }, 500);
            }).catch(() => {
                alert("Error while sending whatsapp for group " + (number + 1));
                setFailed(true);
                setBusy(false);
            })
    }

    const sendFromBeginning = () => {
        if (!groupedNumbersArr.length) {
            alert('No numbers added');
            return;
        }
        setCurrentSentGroup(0);
        sendWhatsapp(0);
    }

    const manageWhatsappData = (data) => {

        let totalArr = [];

        const recipientLimit = 10;
        let recipientArr = [];

        let dataLength = 0;


        for (let i = 0; i < data.length; i++) {
            if (data[i] === undefined || data[i][0] === undefined) {
                break;
            }
            dataLength = dataLength + 1;
        }


        console.log(dataLength);

        for (let i = 0; i < dataLength; i++) {
            let rownum = data[i][0] + ""
            console.log(rownum, i);

            const whatsappNumber = "+91" + rownum.slice(-10);
            const recipientEl = {
                "phone_number": whatsappNumber
            }
            if (recipientArr.length < recipientLimit) {
                recipientArr.push(recipientEl)
            } else {
                totalArr.push(recipientArr);
                recipientArr = [];
                recipientArr.push(recipientEl)
            }

            if ((i + 1) == dataLength) {
                totalArr.push(recipientArr);

            }
        }

        setGroupedNumbersArr(totalArr);
        console.log(totalArr);


    }

    const handleFile = (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = async e => {
            /* Parse data */
            const bstr = (e.target).result;
            const wb = read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            //   console.log(rABS, wb);
            /* Convert array of arrays */
            const data = await utils.sheet_to_json(ws, { header: 1 });

            console.log(data);
            /* Update state */
            manageWhatsappData(data);
            // readXLSX(data);
            //   this.setState({ data: data, cols: make_cols(ws["!ref"]) });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    }

    const importXLSS = () => {
        const input = document.getElementById('whatsapp-sheet-input');
        const files = (input).files;
        if (files && files[0]) {
            console.log(files[0].type);
            if (files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                handleFile(files[0])
            } else {
                alert("Only excel sheets in .XLSX format accepted");
            }
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Add New Whatsapp Template
        </Tooltip>
    );

    return (
        <div style={{ padding: '30px' }}>
            <h3 style={{ marginBottom: '30px' }}>Whatsapp Promotion</h3>
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <div style={{ flex: '1.5 1.5 0' }} className='pe-4'>
                    <InputGroup>
                        <Form.Control id={'whatsapp-sheet-input'} type="file" />
                        {/* <input /> */}
                        <Button
                            variant="primary" onClick={e => importXLSS()}
                            disabled={groupedNumbersArr.length > 0}
                        >
                            Import
                        </Button>
                    </InputGroup>
                </div>
                <div style={{ flex: 1, textAlign: 'center' }}>
                    <InputGroup>
                        <Form.Select onChange={e => setSelectedWaTemplateName(e.currentTarget.value)} value={selectedWaTemplateName} required>
                            <option value="" disabled>Select Template</option>
                            {
                                whatsappTemplates?.map((template, index) => {
                                    return (
                                        <option key={index} value={template.templateName + "_" + template.language}>{template.templateName} ({languageCodeLabel[template.language]})</option>
                                    )
                                })
                            }
                        </Form.Select>
                        <OverlayTrigger
                            placement="top"
                            // delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <Button variant='outline-primary' onClick={() => setShowAddTemplateModal(true)}>{addIcon}</Button>
                        </OverlayTrigger>
                    </InputGroup>
                </div>
                <div style={{ flex: 1, textAlign: 'right' }}>
                    {
                        currentSent == -1 ?
                            <Button
                                variant="success" onClick={() => sendFromBeginning()}
                                disabled={busy}
                            >
                                Send Whatsapp
                            </Button>
                            :

                            currentSent < groupedNumbersArr.length &&
                            <Button
                                variant="success" onClick={() => sendWhatsapp(currentSent)}
                                disabled={busy}
                            >
                                Continue
                            </Button>
                    }
                    <Button onClick={resetData} variant='secondary' className='ms-2'>Clear</Button>
                </div>
            </div>
            <hr />
            <div style={{ padding: '15px', borderRadius: '10px', backgroundColor: '#f8f8f8' }}>
                <Row>
                    {
                        groupedNumbersArr.map((groupedNumbers, index) => {
                            return (
                                <GroupedNumbersCard groupedNumbers={groupedNumbers} key={'grouped_numbers_card_' + index} currentSent={currentSent} index={index} busy={busy} failed={failed} sendWhatsapp={sendWhatsapp} />
                            )
                        })
                    }
                </Row>
            </div>
            {showAddTemplateModal && <AddNewWaTemplate show={showAddTemplateModal} handleClose={() => setShowAddTemplateModal(false)} />}
        </div>
    )
}
