import React, { useState, useEffect } from 'react'
import { addIcon, deleteIcon, editIcon, phoneIcon, refreshIcon } from '../../FontAwesome';
import { Button, Table, Pagination, Dropdown } from 'react-bootstrap';
import AddNewAgentModal from './AddNewAgent';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAgent, getAllAgents } from '../../app/features/saas/agent/agentSlice';
import VoiceCall from '../Misc/VoiceCall';
import EditAgentOrgModal from './EditAgentOrg';


function AgentRow({ agent }) {
    const dispatch = useDispatch();

    const { deleteAgentFetching, errorMessage } = useSelector(state => state.saasAgent);

    const createdAtDateObj = new Date(agent?.agentDetails?.createdAt);
    const createdAtDate = createdAtDateObj.toDateString() + ", " + createdAtDateObj.toLocaleTimeString();

    const [numberToCall, setNumberToCall] = useState('');
    const [showVoiceCallModal, setShowVoiceCallModal] = useState(false);
    const [showEditOrgModal, setShowEditOrgModal] = useState(false);

    const deleteAgentUser = () => {
        if (confirm(`Confirm you want to delete Agent ${agent?.agentDetails?.name}`)) {
            const body = {
                orgType: "agent",
                uid: agent?.agentDetails?.uid
            }
            dispatch(deleteAgent(body))
                .then(() => {
                    alert(`Agent ${agent?.agentDetails?.name} deleted successfully`)
                })
                .catch(err => {
                    console.error(err)
                    alert(`Some error occured while deleting agent ${agent?.agentDetails?.name}`)
                })
        }
    }

    return (
        <>
            <tr>
                <td>
                    <p><span className='fw-bold'>Name</span>: {agent?.agentDetails?.name}</p>
                    <p><span className='fw-bold'>Contact</span>: {agent?.agentDetails?.contact} <span style={{ cursor: 'pointer' }} onClick={() => { setNumberToCall(agent?.agentDetails?.contact); setShowVoiceCallModal(true) }}>{phoneIcon}</span></p>
                    <p><span className='fw-bold'>Email</span>: {agent?.agentDetails?.email}</p>
                    <p><span className='fw-bold'>Uid</span>: {agent?.agentDetails?.uid}</p>
                    <p><span className='fw-bold'>User Type</span>: {agent?.agentDetails?.userType}</p>
                </td>
                <td style={{ textTransform: 'capitalize' }}>
                    {agent?.agentDetails?.organizationId?.companyDisplayName}
                </td>
                <td style={{ textTransform: 'capitalize' }}>
                    {parseFloat(agent?.walletBalance) / 100}
                </td>
                <td style={{ textTransform: 'capitalize' }}>
                    {agent?.orderCount}
                </td>
                <td>{createdAtDate}</td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item as="button" onClick={deleteAgentUser} disabled={deleteAgentFetching}>{deleteIcon}&nbsp;Delete Agent</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => setShowEditOrgModal(true)}>{editIcon}&nbsp;Edit Organization</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {/* <Button variant='danger' onClick={deleteAgentUser} disabled={deleteAgentFetching}>{deleteIcon}</Button> */}
                </td>
            </tr>
            <VoiceCall show={showVoiceCallModal} handleClose={() => setShowVoiceCallModal(false)} numberToCall={numberToCall} />
            {showEditOrgModal && <EditAgentOrgModal show={showEditOrgModal} handleClose={() => setShowEditOrgModal(false)} orgDetails={agent?.agentDetails?.organizationId} />}
        </>
    )
}

function AgentTable() {
    const { fetching, agents } = useSelector(state => state.saasAgent);


    return (
        <Table bordered hover responsive className='mt-3' style={{ minHeight: '100vh', overflowY: 'scroll' }}>
            <thead style={{ verticalAlign: 'middle' }}>
                <tr>
                    <th className='col-4'>Agent Details</th>
                    <th className='col-2'>Org Name</th>
                    <th className='col-1'>Wallet Balance</th>
                    <th className='col-1'>Order Count</th>
                    <th className='col-3'>Created At</th>
                    <th className='col-1'>Actions</th>
                </tr>
            </thead>

            <tbody>
                {
                    fetching ?
                        <tr><td colSpan={8} style={{ width: '100%', textAlign: 'center', padding: '20px' }}>Loading...</td></tr>
                        :
                        agents.length > 0 ?
                            agents?.map((agentDetails, index) => {
                                return (
                                    <AgentRow
                                        key={"apsrtc_agent_" + index}
                                        agent={agentDetails}
                                    />
                                )
                            }) :
                            <tr><td colSpan={8} style={{ width: '100%', textAlign: 'center', padding: '20px' }}>No Agents to show</td></tr>
                }
            </tbody>
        </Table>

    )
}

export default function APSRTC_Agents() {
    const dispatch = useDispatch();
    const { fetching, totalPages } = useSelector(state => state.saasAgent);


    const [openNewAgentModal, setOpenNewAgentModal] = useState(false);
    const [page, setPage] = useState(1);


    useEffect(() => {
        dispatch(getAllAgents({ page }));
    }, [page])

    return (
        <div style={{ padding: '30px' }}>
            <div className='d-flex justify-content-md-between'>
                <h3>Agents</h3>
                <div>
                    <Button onClick={() => setOpenNewAgentModal(true)}>{addIcon}&nbsp;Add new agent</Button>
                    <Button className='ms-2' variant='secondary'>{refreshIcon}&nbsp;Refresh</Button>
                </div>
            </div>
            <hr />

            <Pagination style={{ marginRight: '20px' }}>
                <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || fetching} />
                <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || fetching} />
                {page - 2 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                {page - 1 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                <Pagination.Item active>{page}</Pagination.Item>
                {totalPages >= page + 1 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                {totalPages >= page + 2 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || fetching} />
                <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || fetching} />
            </Pagination>

            <AgentTable />

            <Pagination style={{ marginRight: '20px' }}>
                <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || fetching} />
                <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || fetching} />
                {page - 2 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                {page - 1 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                <Pagination.Item active>{page}</Pagination.Item>
                {totalPages >= page + 1 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                {totalPages >= page + 2 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || fetching} />
                <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || fetching} />
            </Pagination>


            {openNewAgentModal && <AddNewAgentModal show={openNewAgentModal} handleClose={() => setOpenNewAgentModal(false)} />}

        </div>
    )
}
