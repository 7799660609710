import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { addIcon } from '../../FontAwesome';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { addNewShipmantraCharge, getShipmantraChargeForServiceType } from '../../app/features/shipmantracharges/shipmantraChargeSlice';
import Loader from '../Loader';
import { useSelector } from 'react-redux';

export default function Charges({ serviceType }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { fetching, allCharges } = useSelector(state => state.shipmantra_charge)

    const { handleSubmit, control, setValue, formState: { errors } } = useForm({
        defaultValues: {
            gst: 0,
            charges: [{ lowerLimit: 0, platformFee: 0 }],
        }
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'charges'
    })

    const renderLowerLimitInfo = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Enter lower limit of the range. For example if range is 100-150 enter 100 below
        </Tooltip>
    );

    const addCharges = (data) => {
        console.log(data)
        const reqBody = {
            serviceType,
            pricing: data.charges,
            gst: data.gst
        }
        dispatch(addNewShipmantraCharge(reqBody))
            .then(() => { dispatch(getShipmantraChargeForServiceType(serviceType)) })
    }

    useEffect(() => {
        dispatch(getShipmantraChargeForServiceType(serviceType))
        // setValue('charges', allCharges)
    }, [])

    useEffect(() => {
        if (allCharges) {
            setValue('gst', allCharges.gst ? allCharges.gst : 0)
            setValue('charges', allCharges.pricing ? allCharges.pricing : [])
        }
    }, [allCharges])

    if (fetching) {
        <Loader />
    }

    return (
        <>
            <Form onSubmit={handleSubmit(addCharges)}>
                <Col xs={12}>
                    <Form.Group className="mb-3" controlId="gst">
                        <Form.Label>
                            GST (in %)&nbsp;
                        </Form.Label>
                        <Controller
                            control={control}
                            defaultValue={0}
                            rules={{ validate: value => value >= 0 || 'GST value should be greater than or equal to 0' }}
                            name='gst'
                            render={({ field: { onChange, value } }) => (
                                <Form.Control type="number" value={value} onChange={(event) => onChange(parseFloat(event.target.value))} placeholder="Enter GST" />
                            )}
                        />
                        {(errors && errors.gst) && <div className='error-text'>{errors.gst.message}</div>}
                    </Form.Group>
                </Col>
                {
                    fields.map((charge, index) => {
                        return (
                            <Row key={charge.id}>
                                <Col>
                                    <Form.Group className="mb-3" controlId="lowerLimit">
                                        <Form.Label>
                                            Lower limit&nbsp;
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderLowerLimitInfo}
                                            >
                                                <span>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            defaultValue={charge.lowerLimit}
                                            rules={{ validate: value => value >= 0 || 'Lower limit should be greater than 0', required: { value: true, message: 'This field is required' } }}
                                            name={`charges.${index}.lowerLimit`}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Form.Control type="number" value={value} onChange={(event) => onChange(parseFloat(event.target.value))} placeholder="Enter lower limit of the range" />
                                            )}
                                        />
                                        {(errors.charges && errors.charges[index]?.lowerLimit) && <div className='error-text'>{errors.charges[index].lowerLimit.message}</div>}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="platformFee">
                                        <Form.Label>
                                            Platform fee&nbsp;
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            rules={{ validate: value => value >= 0 || 'Platform fee should be greater than 0', required: { value: true, message: 'This field is required' } }}
                                            defaultValue={charge.platformFee}
                                            name={`charges.${index}.platformFee`}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Form.Control type="number" value={value} onChange={(event) => onChange(parseFloat(event.target.value))} placeholder="Enter platform fee" />
                                            )}
                                        />
                                        {(errors.charges && errors.charges[index]?.platformFee) && <div className='error-text'>{errors.charges[index].platformFee.message}</div>}
                                    </Form.Group>
                                </Col>
                                {/* <Col>
                                    <Form.Group className="mb-3" controlId="gst">
                                        <Form.Label>
                                            GST (in %)&nbsp;
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            rules={{ validate: value => value >= 0 || 'GST value should be greater than 0', required: { value: true, message: 'This field is required' } }}
                                            defaultValue={charge.gst}
                                            name={`charges.${index}.gst`}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Form.Control type="number" value={value} onChange={(event) => onChange(parseFloat(event.target.value))} placeholder="Enter GST" />
                                            )}
                                        />
                                        {(errors.charges && errors.charges[index]?.gst) && <div className='error-text'>{errors.charges[index].gst.message}</div>}
                                    </Form.Group>
                                </Col> */}
                                <Col className='align-self-center'>
                                    {fields.length !== 1 && <Button variant='danger' type='button' onClick={() => remove(index)}>Remove</Button>}
                                </Col>
                            </Row>
                        )
                    })
                }
                <Button variant='success' onClick={() => append({ lowerLimit: 0, platformFee: 0 })}>{addIcon}</Button>
                <hr />
                <Button type='submit'>Submit</Button>
            </Form>
        </>
    )
}
