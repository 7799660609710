import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithPhoneNumber, signOut, RecaptchaVerifier, signInWithEmailAndPassword, createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTS_ID
};

// const saasFirebaseConfig = {
//     apiKey: process.env.REACT_APP_SAAS_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_SAAS_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_SAAS_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_SAAS_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_SAAS_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_SAAS_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_SAAS_FIREBASE_MEASUREMENTS_ID
// }


// Initialize Firebase
// const analytics = getAnalytics(app);
const app = initializeApp(firebaseConfig);
// const saasApp = initializeApp(saasFirebaseConfig, 'saas_firebaseApp');
// import { getAnalytics } from "firebase/analytics";

const auth = getAuth(app);
// export const saas_auth = getAuth(saasApp);

export function loginWithPhone(number) {

    return new Promise((resolve, reject) => {

        window.recaptchaVerifier = new RecaptchaVerifier('phone-signin-btn', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                console.log("CAPTCHA", response)
            }
        }, auth);

        const appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(auth, number, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                resolve();
                // ...
            }).catch((error) => {
                reject(error);
                // Error; SMS not sent
                // ...
            });
        // ...
    })

}

export function loginWithPhoneOTP(otp) {

    return new Promise((resolve, reject) => {

        const code = otp;

        window.confirmationResult.confirm(code).then((result) => {
            // User signed in successfully.
            const user = result.user;
            resolve(user);
            console.log("PHONE USER", user);
            // ...
        }).catch((error) => {
            reject(error);
            // User couldn't sign in (bad verification code?)
            // ...
        })
        // ...
    })

}

// export function loginWithEmailPassword(email, password) {

//     return new Promise((resolve, reject) => {


//         fetchSignInMethodsForEmail(auth, email)
//             .then((userRecord) => {
//                 // See the UserRecord reference doc for the contents of userRecord.
//                 if (userRecord.length === 0) {
//                     createUserWithEmailAndPassword(auth, email, password)
//                         .then((userCredential) => {
//                             // Signed in 
//                             const user = userCredential.user;
//                             console.log(user)
//                             resolve(user)
//                             // ...
//                         })
//                         .catch((error) => {
//                             const errorCode = error.code;
//                             const errorMessage = error.message;
//                             reject(error);
//                         });
//                 }
//                 else {
//                     // signInWithEmailAndPassword(auth, email, password)
//                     //     .then((userCredential) => {
//                     //         // Signed in 
//                     //         const user = userCredential.user;
//                     //         console.log(user)
//                     //         resolve(user)
//                     //         // ...
//                     //     })
//                     //     .catch((error) => {
//                     //         const errorCode = error.code;
//                     //         const errorMessage = error.message;
//                     //         reject(error);
//                     //     });
//                 }
//                 // resolve(userRecord)
//             })
//             .catch((error) => {
//                 console.log('Error fetching user data:', error);
//                 reject(error)
//             });
//     })

// }

export function adminLoginWithEmailPassword(email, password) {

    return new Promise((resolve, reject) => {

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                resolve(user)
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                reject();
            });

    })

}

export function checkLogin() {

    return new Promise((resolve, reject) => {

        onAuthStateChanged(auth, async (user) => {
            // console.log(await auth.currentUser.getIdToken())

            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                // const uid = user.uid;
                resolve(user)
                // ...
            } else {
                reject();
                // User is signed out
                // ...
            }
        });

    })

}

// export function checkSaasLogin() {

//     return new Promise((resolve, reject) => {

//         onAuthStateChanged(saas_auth, async (user) => {
//             // console.log(await auth.currentUser.getIdToken())

//             if (user) {
//                 // User is signed in, see docs for a list of available properties
//                 // https://firebase.google.com/docs/reference/js/firebase.User
//                 // const uid = user.uid;
//                 resolve(user)
//                 // ...
//             } else {
//                 reject();
//                 // User is signed out
//                 // ...
//             }
//         });

//     })

// }

export function signout() {

    return new Promise((resolve, reject) => {

        signOut(auth).then(() => {
            // Sign-out successful.
            resolve();
        }).catch((error) => {
            // An error happened.
            reject(error);
        });

    })

}