import React, { useState } from 'react'
import { Nav, Navbar, Container } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { logout } from '../../app/features/user/userSlice'
import { logoutIcon, userIcon } from '../../FontAwesome'
import Profile from '../Popups/Profile'
import { signout } from '../../utils/firebase'

export default function TopNav() {
    // if (!localStorage.getItem("email")) return null
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const handleLogout = (e) => {
        localStorage.clear();
        signout();
        dispatch(logout())
        navigate('/login')
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (location.pathname == '/login') {
        return <></>
    }
    else {
        return (
            <>
                <Navbar bg="dark" variant="dark" style={{ width: '100%' }} className='align-self-start'>
                    <Container>
                        <Nav className="ms-auto">
                            <Nav.Link onClick={handleLogout}>
                                {logoutIcon}&nbsp;
                                <span>Logout</span>
                            </Nav.Link>
                            <Nav.Link onClick={handleShow}>
                                {userIcon}&nbsp;
                                <span>Profile</span>
                            </Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
                <Profile show={show} handleClose={handleClose} />
            </>
        )
    }
}
