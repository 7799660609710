import { faArrowCircleLeft, faArrowCircleRight, faBuilding, faUsers, faUsersGear, faBriefcase, faPersonCircleQuestion, faPeopleArrows, faGears, faEllipsis, faBullhorn, faStore, faBoxesStacked, faPeopleGroup, faFilePen, faLock, faLightbulb, faChartLine, faMagnifyingGlassChart, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { ProSidebar, SidebarHeader, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { dashboardIcon, ordersIcon, newOrderIcon, completedOrderIcon, liveOrderIcon, userIcon, bannerIcon, enquiryIcon, cityIcon, orderIcon, internationalOrderIcon, rentalOrderIcon, hyperlocalOrderIcon, customersIcon, couponIcon, reviewIcon, countryIcon, shipperIcon, internationalShipperIcon, requestPaymentIcon, hyperlocalShipperIcon, panIndiaShipperIcon, contentManagementIcon, cmsHeaderIcon, megaPhoneIcon, panIndiaOrderIcon, expressDeliveryOrderIcon, shipmantraChargesIcon, hyperlocal3WheelerIcon, hyperlocal4WheelerIcon, hyperlocal2WheelerIcon, exportIcon, normalPanIndiaDeliveryIcon, invoiceIcon, importOrderIcon, businessUserIcon, pendingOrderIcon, briefcaseIcon, phoneIcon, listIcon, cautionIcon, cancelledOrderIcon, onHoldOrderIcon } from '../../FontAwesome';

import SHIPMANTRA_ICON from "../../assets/images/shipmantra_logo.svg"

import "./css/index.css"

export default function SideNav() {
    const navigate = useNavigate()
    const location = useLocation()
    const { adminUser } = useSelector(state => state.adminUser)
    const role = adminUser.role
    // if (!localStorage.getItem("email")) return null

    const [menuCollapse, setMenuCollapse] = useState(false)

    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    if (location.pathname == '/login') {
        return <></>
    }
    else {
        return (
            <>
                <ProSidebar style={{ height: '100vh' }} collapsed={menuCollapse}>
                    <SidebarHeader className='d-flex justify-content-center align-items-center py-4'>
                        <img src={SHIPMANTRA_ICON} style={{ height: '40px', width: '40px' }} alt="" />
                        &nbsp;&nbsp;
                        {!menuCollapse && <span>ADMIN PORTAL</span>}
                        <div
                            className='close-menu-icon'
                            onClick={menuIconClick}>
                            {menuCollapse ? (
                                <FontAwesomeIcon icon={faArrowCircleRight} />
                            ) : (
                                <FontAwesomeIcon icon={faArrowCircleLeft} />
                            )}
                        </div>
                    </SidebarHeader>
                    <Menu iconShape="circle" popperArrow={true}>
                        <MenuItem active={window.location.pathname === "/"} icon={dashboardIcon}><Link to='/' />Dashboard</MenuItem>

                        {/* <SubMenu title="SaaS" icon={<FontAwesomeIcon icon={faBriefcase} />}>
                            <MenuItem active={window.location.pathname === "/saas/users"} icon={businessUserIcon}><Link to='/saas/users' />SaaS users</MenuItem>
                        </SubMenu> */}

                        {
                            (adminUser?.isSuperAdmin || adminUser?.modules?.includes('api_monitoring')) && <SubMenu title="API Monitoring" icon={<FontAwesomeIcon icon={faGears} />}>
                                <MenuItem active={window.location.pathname === "/monitoring/hyperlocalShipper"} icon={businessUserIcon}><Link to='/monitoring/hyperlocalShipper' />Hyperlocal Shipper API</MenuItem>
                                <MenuItem active={window.location.pathname === "/monitoring/panIndiaShipper"} icon={businessUserIcon}><Link to='/monitoring/panIndiaShipper' />Pan India Shipper API</MenuItem>
                                {/* <MenuItem active={window.location.pathname === "/banner"} icon={businessUserIcon}><Link to='/monitoring/internationalShipper' />International Shipper API</MenuItem> */}
                            </SubMenu>
                        }

                        {
                            (adminUser?.isSuperAdmin || adminUser?.modules?.includes('admin')) &&
                            <MenuItem active={window.location.pathname === "/admin"} icon={<FontAwesomeIcon icon={faLock} />}><Link to='/admin' />Admin</MenuItem>
                        }

                        {
                            (adminUser?.isSuperAdmin || adminUser?.modules?.includes('apsrtc')) &&
                            <SubMenu title="APSRTC" icon={<FontAwesomeIcon icon={faBuilding} />}>
                                <SubMenu title="Orders" icon={<FontAwesomeIcon icon={faBoxesStacked} />}>
                                    {/* <MenuItem active={window.location.pathname === "/banner"} icon={orderIcon}><Link to='/vendors/apsrtc' />Orders</MenuItem> */}
                                    {/* <MenuItem active={window.location.pathname === "/banner"} icon={<FontAwesomeIcon icon={faMoneyBillWave} />}><Link to='/vendors/apsrtc/payouts' />Payouts</MenuItem> */}
                                    {/* <MenuItem active={window.location.pathname === "/banner"} icon={pendingOrderIcon}><Link to='/apsrtc/pending-orders' />Pending Orders</MenuItem> */}
                                    {/* <MenuItem active={window.location.pathname === "/banner"} icon={liveOrderIcon}><Link to='/apsrtc/apsrtc-live-orders' />Live Orders - APSRTC</MenuItem> */}
                                    <MenuItem active={window.location.pathname === "/apsrtc/all-orders"} icon={orderIcon}><Link to='/apsrtc/all-orders' />All orders</MenuItem>
                                    <MenuItem active={window.location.pathname === "/apsrtc/new-orders"} icon={newOrderIcon}><Link to='/apsrtc/new-orders' />New orders</MenuItem>
                                    <MenuItem active={window.location.pathname === "/apsrtc/in-progress-orders"} icon={pendingOrderIcon}><Link to='/apsrtc/in-progress-orders' />In-Progress orders</MenuItem>
                                    <MenuItem active={window.location.pathname === "/apsrtc/completed-orders"} icon={completedOrderIcon}><Link to='/apsrtc/completed-orders' />Completed orders</MenuItem>
                                    <MenuItem active={window.location.pathname === "/apsrtc/on-hold-orders"} icon={onHoldOrderIcon}><Link to='/apsrtc/on-hold-orders' />On Hold orders</MenuItem>
                                    <MenuItem active={window.location.pathname === "/apsrtc/rejected-orders"} icon={cancelledOrderIcon}><Link to='/apsrtc/rejected-orders' />Rejected orders</MenuItem>
                                    {/* <MenuItem active={window.location.pathname === "/apsrtc/cancelled-orders"} icon={cancelledOrderIcon}><Link to='/apsrtc/cancelled-orders' />Cancelled orders</MenuItem> */}
                                </SubMenu>
                                <MenuItem active={window.location.pathname === "/apsrtc/request-orders"} icon={<FontAwesomeIcon icon={faFilePen} />}><Link to='/apsrtc/request-orders' />Request orders</MenuItem>
                                <MenuItem active={window.location.pathname === "/apsrtc/agents"} icon={<FontAwesomeIcon icon={faUsers} />}><Link to='/apsrtc/agents' />Agents</MenuItem>
                                <MenuItem active={window.location.pathname === "/apsrtc/all-cities"} icon={cityIcon}><Link to='/apsrtc/all-cities' />Cities</MenuItem>
                                <MenuItem active={window.location.pathname === "/apsrtc/invoice"} icon={invoiceIcon}><Link to='/apsrtc/invoice' />Invoice</MenuItem>
                                {/* <MenuItem active={window.location.pathname === "/banner"} icon={orderIcon}><Link to='/apsrtc/active-orders' />Active Orders</MenuItem> */}
                            </SubMenu>
                        }

                        {
                            (adminUser?.isSuperAdmin || adminUser?.modules?.includes('business')) &&
                            <SubMenu title="Business" icon={<FontAwesomeIcon icon={faBriefcase} />}>
                                <MenuItem active={window.location.pathname === "/business/users"} icon={businessUserIcon}><Link to='/business/users' />Business users</MenuItem>
                                <MenuItem active={window.location.pathname === "/business/orders"} icon={<FontAwesomeIcon icon={faBoxesStacked} />}><Link to='/business/orders' />Orders</MenuItem>
                                <MenuItem active={window.location.pathname === "/business/pending-orders"} icon={orderIcon}><Link to='/business/pending-orders' />Pending Orders</MenuItem>
                                <SubMenu title="Platform Charges" icon={shipmantraChargesIcon}>
                                    <SubMenu title="Hyperlocal" icon={hyperlocalOrderIcon}>
                                        <MenuItem active={window.location.pathname === "/business-charges/hyperlocal/two-wheeler"} icon={hyperlocal2WheelerIcon}><Link to='/business-charges/hyperlocal/two-wheeler' />Hyperlocal 2 wheeler</MenuItem>
                                        <MenuItem active={window.location.pathname === "/business-charges/hyperlocal/three-wheeler"} icon={hyperlocal3WheelerIcon}><Link to='/business-charges/hyperlocal/three-wheeler' />Hyperlocal 3 wheeler</MenuItem>
                                        <MenuItem active={window.location.pathname === "/business-charges/hyperlocal/four-wheeler"} icon={hyperlocal4WheelerIcon}><Link to='/business-charges/hyperlocal/four-wheeler' />Hyperlocal 4 wheeler</MenuItem>
                                    </SubMenu>

                                    {/* <SubMenu title="International" icon={internationalOrderIcon}>
                                    <MenuItem active={window.location.pathname === "/business-charges/international/exports"} icon={exportIcon}><Link to='/business-charges/international/exports' />Exports</MenuItem>
                                </SubMenu> */}

                                    <SubMenu title="Pan india" icon={panIndiaOrderIcon}>
                                        <MenuItem active={window.location.pathname === "/business-charges/pan-india/express-delivery"} icon={expressDeliveryOrderIcon}><Link to='/business-charges/pan-india/express-delivery' />Express</MenuItem>
                                    </SubMenu>
                                </SubMenu>
                            </SubMenu>
                        }

                        {
                            (adminUser?.isSuperAdmin || adminUser?.modules?.includes('shipmantra_c2c')) &&
                            <SubMenu title="Shipmantra" icon={<FontAwesomeIcon icon={faUsersGear} />}>
                                {/* {role == '0' && <MenuItem active={window.location.pathname === "/banner"} icon={userIcon}><Link to='/user' />Admin user</MenuItem>} */}
                                <SubMenu title="Orders" icon={orderIcon}>
                                    <MenuItem active={window.location.pathname === "/orders/internationalOrders"} icon={internationalOrderIcon}><Link to='/orders/internationalOrders' />International Orders</MenuItem>
                                    <MenuItem active={window.location.pathname === "/orders/importOrders"} icon={importOrderIcon}><Link to='/orders/importOrders' />Import Orders</MenuItem>
                                    <MenuItem active={window.location.pathname === "/orders/rentalOrders"} icon={rentalOrderIcon}><Link to='/orders/rentalOrders' />Rental Orders</MenuItem>
                                    <MenuItem active={window.location.pathname === "/orders/hyperlocalOrders"} icon={hyperlocalOrderIcon}><Link to='/orders/hyperlocalOrders' />Hyperlocal Orders</MenuItem>
                                    <MenuItem active={window.location.pathname === "/orders/c2cRequestOrders"} icon={<FontAwesomeIcon icon={faFilePen} />}><Link to='/orders/c2cRequestOrders' />Request Orders</MenuItem>
                                    <SubMenu title="Pan india Orders" icon={panIndiaOrderIcon}>
                                        <MenuItem active={window.location.pathname === "/orders/panIndiaOrders/express-orders"} icon={expressDeliveryOrderIcon}><Link to='/orders/panIndiaOrders/express-orders' />Express delivery</MenuItem>
                                    </SubMenu>
                                </SubMenu>

                                <SubMenu title="Shippers" icon={shipperIcon}>
                                    <MenuItem active={window.location.pathname === "/shipper/internationalShipper"} icon={internationalShipperIcon}><Link to='/shipper/internationalShipper' />International Shippers</MenuItem>
                                    <MenuItem active={window.location.pathname === "/shipper/hyperlocalShipper"} icon={hyperlocalShipperIcon}><Link to='/shipper/hyperlocalShipper' />Hyperlocal Shippers</MenuItem>
                                    <MenuItem active={window.location.pathname === "/shipper/panIndiaShipper"} icon={panIndiaShipperIcon}><Link to='/shipper/panIndiaShipper' />Pan India Shippers</MenuItem>
                                </SubMenu>

                                <SubMenu title="Shipmantra Charges" icon={shipmantraChargesIcon}>
                                    <SubMenu title="Hyperlocal" icon={hyperlocalOrderIcon}>
                                        <MenuItem active={window.location.pathname === "/shipmantra-charges/hyperlocal-2-wheeler"} icon={hyperlocal2WheelerIcon}><Link to='/shipmantra-charges/hyperlocal-2-wheeler' />Hyperlocal 2 wheeler</MenuItem>
                                        <MenuItem active={window.location.pathname === "/shipmantra-charges/hyperlocal-3-wheeler"} icon={hyperlocal3WheelerIcon}><Link to='/shipmantra-charges/hyperlocal-3-wheeler' />Hyperlocal 3 wheeler</MenuItem>
                                        <MenuItem active={window.location.pathname === "/shipmantra-charges/hyperlocal-4-wheeler"} icon={hyperlocal4WheelerIcon}><Link to='/shipmantra-charges/hyperlocal-4-wheeler' />Hyperlocal 4 wheeler</MenuItem>
                                    </SubMenu>

                                    <SubMenu title="International" icon={internationalOrderIcon}>
                                        <MenuItem active={window.location.pathname === "/shipmantra-charges/international/exports"} icon={exportIcon}><Link to='/shipmantra-charges/international/exports' />Exports</MenuItem>
                                    </SubMenu>

                                    <SubMenu title="Pan india" icon={panIndiaOrderIcon}>
                                        <MenuItem active={window.location.pathname === "/shipmantra-charges/pan-india/express-delivery"} icon={expressDeliveryOrderIcon}><Link to='/shipmantra-charges/pan-india/express-delivery' />Express</MenuItem>
                                        <MenuItem active={window.location.pathname === "/shipmantra-charges/pan-india/default-delivery"} icon={normalPanIndiaDeliveryIcon}><Link to='/shipmantra-charges/pan-india/default-delivery' />Normal</MenuItem>
                                    </SubMenu>
                                </SubMenu>

                                <MenuItem icon={cityIcon} active={window.location.pathname === "/city"}><Link to='/city' />Cities</MenuItem>
                                <MenuItem icon={countryIcon} active={window.location.pathname === "/countries"}><Link to='/countries' />Countries</MenuItem>
                            </SubMenu>
                        }

                        {
                            (adminUser?.isSuperAdmin || adminUser?.modules?.includes('sales_and_marketing')) &&
                            <SubMenu title="Sales & Marketing" icon={<FontAwesomeIcon icon={faLightbulb} />}>
                                <MenuItem icon={bannerIcon} active={window.location.pathname === "/banner"}><Link to='/banner' />Banner</MenuItem>
                                <MenuItem icon={customersIcon} active={window.location.pathname === "/customers"}><Link to='/customers' />Customers</MenuItem>
                                <MenuItem icon={couponIcon} active={window.location.pathname === "/coupons"}><Link to='/coupons' />Coupons</MenuItem>
                                <SubMenu title="Content Management System" icon={contentManagementIcon}>
                                    <SubMenu title="Header" icon={contentManagementIcon}>
                                        <MenuItem active={window.location.pathname === "/cms/header/announcement-feed/add"} icon={megaPhoneIcon}><Link to='/cms/header/announcement-feed/add' />Announcement Feed</MenuItem>
                                    </SubMenu>
                                </SubMenu>
                                <SubMenu title="Enquiry" icon={enquiryIcon}>
                                    <MenuItem icon={enquiryIcon} active={window.location.pathname === "/enquiry/no-option-enquiry"}><Link to='/enquiry/no-option-enquiry' />No Option Enquiry</MenuItem>
                                    <MenuItem icon={briefcaseIcon} active={window.location.pathname === "/enquiry/business-enquiry"}><Link to='/enquiry/business-enquiry' />Business Enquiry</MenuItem>
                                    <MenuItem icon={<FontAwesomeIcon icon={faPeopleArrows} />} active={window.location.pathname === "/enquiry/shipper-enquiry"}><Link to='/enquiry/shipper-enquiry' />Shipper Enquiry</MenuItem>
                                    <MenuItem icon={<FontAwesomeIcon icon={faPersonCircleQuestion} />} active={window.location.pathname === "/enquiry/general-enquiry"}><Link to='/enquiry/general-enquiry' />General Enquiry</MenuItem>
                                </SubMenu>
                                <SubMenu title={"Invoices"} icon={invoiceIcon}>
                                    <MenuItem active={window.location.pathname === "/invoices"} icon={invoiceIcon}><Link to='/invoices' />Invoices</MenuItem>
                                    <MenuItem active={window.location.pathname === "/wallet-recharge-invoices"} icon={invoiceIcon}><Link to='/wallet-recharge-invoices' />Wallet Recharge Invoice</MenuItem>
                                </SubMenu>
                                <MenuItem icon={reviewIcon} active={window.location.pathname === "/reviews"}><Link to='/reviews' />Reviews</MenuItem>
                                <MenuItem active={window.location.pathname === "/request-payment"} icon={requestPaymentIcon}><Link to='/request-payment' />Request Payment</MenuItem>
                            </SubMenu>
                        }



                        {/* <SubMenu title="Daily Sales Report" icon={<FontAwesomeIcon icon={faChartLine} />}>
                            {
                                (adminUser?.isSuperAdmin || adminUser?.modules?.includes('daily_sales_report')) &&
                                <MenuItem active={window.location.pathname === "/add-sales-target"} icon={<FontAwesomeIcon icon={faCalendarPlus} />}><Link to='/add-sales-target' />Add Sales Targets</MenuItem>
                            }
                            <MenuItem active={window.location.pathname === "/view-daily-sales-data"} icon={<FontAwesomeIcon icon={faMagnifyingGlassChart} />}><Link to='/view-daily-sales-data' />Daily Sales Data</MenuItem>
                        </SubMenu> */}

                        {
                            (adminUser?.isSuperAdmin || adminUser?.modules?.includes('misc')) &&
                            <SubMenu title="Misc" icon={<FontAwesomeIcon icon={faEllipsis} />}>
                                <MenuItem icon={<FontAwesomeIcon icon={faBullhorn} />} active={window.location.pathname === "/misc/whatsapp-promotion"}><Link to='/misc/whatsapp-promotion' />Whatsapp Promotion</MenuItem>
                                <MenuItem icon={phoneIcon} active={window.location.pathname === "/misc/voice-call"}><Link to='/misc/voice-call' />Voice Call</MenuItem>
                                <MenuItem icon={listIcon} active={window.location.pathname === "/misc/call-details"}><Link to='/misc/call-details' />Call Details</MenuItem>
                            </SubMenu>
                        }

                    </Menu>
                </ProSidebar>
            </>
        )
    }
}
