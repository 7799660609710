import React, { useEffect } from 'react'
import { addIcon, editIcon, refreshIcon } from '../../FontAwesome'
import { Button, Table, Pagination } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { fetchAllApsrtcCities } from '../../app/features/apsrtc_booking/apsrtcCitySlice'
import CityDetails from './CityDetails'

function CityRow({ city }) {
    const createdAtDateObj = new Date(city?.createdAt)
    const [showCityDetail, setShowCityDetail] = useState(false)

    return (
        <>
            <tr>
                <td>
                    {city?.active?.toString()}
                </td>
                <td style={{ textTransform: 'capitalize' }}>
                    {city?.city_id}
                </td>
                <td>
                    <span style={{ fontWeight: 'bold' }}>Counter ID</span>: {city?.station?.loginCounterID} <br />
                    <span style={{ fontWeight: 'bold' }}>Counter Name</span>: {city?.station?.counterName}
                </td>
                <td>{createdAtDateObj.toDateString()}<br />{createdAtDateObj.getHours() + ":" + createdAtDateObj.getMinutes()}</td>
                <td>
                    <Button onClick={() => { setShowCityDetail(true) }}>{editIcon}&nbsp;</Button> <br />
                </td>
            </tr>
            <CityDetails show={showCityDetail} handleClose={() => setShowCityDetail(false)} isEdit={true} cityDetails={city} />
        </>
    )
}

function CityTable() {
    const dispatch = useDispatch();
    const { cities, fetching } = useSelector(state => state.apsrtcCity);

    const [sortedResults, setSortedResults] = useState(cities ? cities : [])
    const [sortType, setSortType] = useState("createdAt-desc")

    useEffect(() => {
        setSortedResults(cities)
    }, [cities])

    useEffect(() => {
        const [attr, type] = sortType.split("-")
        let temp = [...sortedResults]
        if (attr === "updatedAt" || attr === "createdAt") {
            if (type === "asc") {
                temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) =>
                    new Date(b[attr]) - new Date(a[attr])
                )
                setSortedResults(temp)
            }
        } else {
            if (type === "asc") {
                temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                setSortedResults(temp)
            }
        }
    }, [sortType])

    return (
        <>
            <Table bordered hover responsive className='mt-3' style={{ minHeight: '100vh', overflowY: 'scroll' }}>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th className='col-2'>Active</th>
                        <th className='col-2'>City</th>
                        <th className='col-3'>Station Details</th>
                        <th className='col-3'>Created At</th>
                        <th className='col-2'>Action</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        fetching ?
                            <tr><td colSpan={8} style={{ width: '100%', textAlign: 'center', padding: '20px' }}>Loading...</td></tr>
                            :
                            sortedResults?.length > 0 ?
                                sortedResults?.map((cityDetails, index) => {
                                    return (
                                        <CityRow
                                            key={"apsrtc_city_" + index}
                                            city={cityDetails}
                                        />
                                    )
                                }) :
                                <tr><td colSpan={8} style={{ width: '100%', textAlign: 'center', padding: '20px' }}>No Cities to show</td></tr>
                    }
                </tbody>
            </Table>
            {/* {showOrderDetail && <OrderDetail show={showOrderDetail} handleClose={() => { setShowOrderDetail(false); setOrderData(undefined) }} order={orderData} />} */}
        </>
    )
}

export default function ApsrtcCities() {
    const dispatch = useDispatch();
    const [showCityDetail, setShowCityDetail] = useState(false)
    const [page, setPage] = useState(1)

    const { totalPages, fetching } = useSelector(state => state.apsrtcCity);

    useEffect(() => {
        dispatch(fetchAllApsrtcCities({ page, limit: 25 }))
    }, [page])

    return (
        <div style={{ padding: '30px' }}>
            <div className='d-flex'>
                <h3 style={{ flex: 1, textTransform: 'capitalize' }}>APSRTC Cities</h3>
                <div>
                    <Button className='ms-2' variant='secondary' onClick={() => dispatch(fetchAllApsrtcCities())}>{refreshIcon}&nbsp;Refresh</Button>
                </div>
                <div>
                    <Button className='ms-2' onClick={() => setShowCityDetail(true)}>{addIcon} Add new city</Button>
                </div>
            </div>
            <hr />

            <Pagination style={{ marginRight: '20px' }}>
                <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || fetching} />
                <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || fetching} />
                {page - 2 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                {page - 1 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                <Pagination.Item active>{page}</Pagination.Item>
                {totalPages >= page + 1 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                {totalPages >= page + 2 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || fetching} />
                <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || fetching} />
            </Pagination>

            <CityTable />

            <Pagination style={{ marginRight: '20px' }}>
                <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || fetching} />
                <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || fetching} />
                {page - 2 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                {page - 1 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                <Pagination.Item active>{page}</Pagination.Item>
                {totalPages >= page + 1 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                {totalPages >= page + 2 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || fetching} />
                <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || fetching} />
            </Pagination>

            <CityDetails show={showCityDetail} handleClose={() => setShowCityDetail(false)} />
        </div>
    )
}
