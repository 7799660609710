import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Table, Button, Spinner, ToastContainer, Toast, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { fetchBusinessUserOrders } from '../../../app/features/shipmantrabusiness/order/businessOrderSlice';
import { fetchBusinessUsers, updateBusinessUser } from '../../../app/features/shipmantrabusiness/user/businessUserSlice';
import { addIcon, copyIcon, editIcon, orderIcon, refreshIcon, sortAscIcon, sortDescIcon, sortIcon, walletIcon } from '../../../FontAwesome';
import Orders from '../Orders/Orders';
import RechargeWallet from '../Wallet/RechargeWallet';
import WalletTransactionsList from '../Wallet/WalletTransactionsList';
import AddNewUser from './AddNewUser';

function UserRow({ businessUser, copyText }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { fetching } = useSelector(state => state.businessUser);

    const [showOrders, setShowOrders] = useState(false);
    const [showFundModal, setShowFundModal] = useState(false);
    const [showTransactions, setShowTransactions] = useState(false);
    const [isUserActive, setIsUserActive] = useState(businessUser.isActive);

    const viewOrders = (uid) => {
        setShowOrders(true)
        dispatch(fetchBusinessUserOrders(uid))
    }

    const updateUserActiveState = (e) => {
        setIsUserActive(!isUserActive)
        dispatch(updateBusinessUser({ id: businessUser._id, reqBody: { isActive: e.target.checked } }))
    }

    useEffect(() => {
        setIsUserActive(businessUser.isActive)
    }, [businessUser])

    const createdAtDateString = new Date(businessUser.createdAt);
    const createdAtDate = createdAtDateString.toDateString() + ", " + createdAtDateString.toLocaleTimeString();
    const updatedAtDateString = new Date(businessUser.updatedAt);
    const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();
    return (
        <>
            <tr>
                <td>
                    {businessUser.uid}&nbsp;
                    <span style={{ cursor: 'pointer' }} onClick={() => copyText(businessUser.uid)}>{copyIcon}</span>
                    <hr />
                    <span className='fw-bold'>Active</span>:
                    <Form.Check type="switch" value={isUserActive} checked={isUserActive} onChange={(e => updateUserActiveState(e))} disabled={fetching} />
                </td>
                <td>
                    {businessUser.fullName} <hr />
                    <span style={{ fontWeight: 'bold' }}>Company</span>: {businessUser.companyName} <hr />
                    <span style={{ fontWeight: 'bold' }}>Email</span>: {businessUser.email} <hr />
                    <span style={{ fontWeight: 'bold' }}>Phone</span>: {businessUser.phone}
                </td>
                {/* <td>{businessUser.email}</td>
                                    <td>{businessUser.phone}</td> */}
                {/* <td>{createdAtDate}</td> */}
                <td>{createdAtDate}</td>
                <td>
                    <Button variant="primary" onClick={() => navigate(`/business/user/edit/${businessUser._id}`)}>
                        {editIcon}&nbsp;Edit
                    </Button>
                    <Button type='button' variant="secondary" className='ms-2' onClick={() => viewOrders(businessUser.uid)}>
                        {orderIcon}&nbsp;Orders
                    </Button>
                    <Button type='button' variant="success" className='ms-2' onClick={() => setShowFundModal(true)}>
                        {walletIcon}&nbsp;Add Funds
                    </Button>
                    <br />
                    <Button type='button' variant="info" className='mt-2' onClick={() => setShowTransactions(true)}>
                        See transactions
                    </Button>
                </td>
            </tr>
            {showOrders && <Orders show={showOrders} handleClose={() => setShowOrders(false)} />}
            {showFundModal && <RechargeWallet show={showFundModal} handleClose={() => setShowFundModal(false)} businessUserUID={businessUser.uid} />}
            {showTransactions && <WalletTransactionsList show={showTransactions} handleClose={() => setShowTransactions(false)} businessUserUID={businessUser.uid} />}
        </>
    )
}

export default function Users() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { users, fetching } = useSelector(state => state.businessUser);

    const [searchString, setSearchString] = useState('');

    const [busy, setBusy] = useState(false);

    const [sortType, setSortType] = useState("createdAt-desc")
    const [sortedResults, setSortedResults] = useState(users ? users : [])

    const [isTextCopied, setIsTextCopied] = useState(false);
    const [newUserModal, setOpenNewUserModal] = useState(false);
    // const [showOrders, setShowOrders] = useState(false);
    // const [showFundModal, setShowFundModal] = useState(false)

    const copyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        setIsTextCopied(true)
        setTimeout(() => {
            setIsTextCopied(false)
        }, 3000);
    }


    const handleSort = (type) => {
        const user = sortType.split("-")[1]
        if (user === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (user === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        setSortedResults(users)
    }, [users])

    useEffect(() => {
        const [attr, type] = sortType.split("-")
        let temp = [...sortedResults]
        if (attr === "updatedAt" || attr === "createdAt") {
            if (type === "asc") {
                temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) =>
                    new Date(b[attr]) - new Date(a[attr])
                )
                setSortedResults(temp)
            }
        } else {
            if (type === "asc") {
                temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                setSortedResults(temp)
            }
        }
    }, [sortType])

    useEffect(() => {
        let temp = []
        users.map(res => {
            if (res.phone.includes(searchString)) {
                temp.push(res)
            } else if (res.email.includes(searchString)) {
                temp.push(res)
            } else if (res.fullName.includes(searchString)) {
                temp.push(res)
            } else if (res.username.includes(searchString)) {
                temp.push(res)
            } else if (res.uid.includes(searchString)) {
                temp.push(res)
            }
        })
        setSortedResults(temp)
        setSortType('createdAt-desc')
    }, [searchString])

    useEffect(() => {
        setBusy(true);
        dispatch(fetchBusinessUsers())
            .then(() => setBusy(false))
    }, [])

    // const viewOrders = (uid) => {
    //     setShowOrders(true)
    //     dispatch(fetchBusinessUserOrders(uid))
    // }

    if (fetching && busy) return (
        <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
            <Spinner animation="border" />
        </div>
    )

    return (
        <div style={{ padding: '30px' }}>
            <div className='d-flex justify-content-md-between'>
                <h3>Business Users</h3>
                <div>
                    <Button onClick={() => setOpenNewUserModal(true)}>{addIcon}&nbsp;Add new user</Button>
                    <Button onClick={() => dispatch(fetchBusinessUsers())} className='ms-2' variant='secondary' disabled={fetching}>{refreshIcon}&nbsp;Refresh</Button>
                </div>
            </div>
            <hr />
            <div>
                <input type="text" className='form-control' placeholder='Search user' value={searchString} onChange={e => setSearchString(e.currentTarget.value)} />
            </div>
            {
                isTextCopied && <ToastContainer position='top-center'>
                    <Toast bg='info' delay={3000} autohide style={{ margin: '20px', fontSize: '20px', textAlign: 'center' }}>
                        <Toast.Body>Text Copied</Toast.Body>
                    </Toast>
                </ToastContainer>
            }
            <Table striped bordered hover responsive className='mt-3'>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th className='col-3'>UID</th>
                        {/* <th>Username</th> */}
                        {/* <th className='col-1'>
                            Full name&nbsp;
                            {sortType.split("-")[0] !== "fullName" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("fullName")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "fullName" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("fullName")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "fullName" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("fullName")}>{sortDescIcon}</span>
                            }
                        </th> */}
                        <th className='col-3'>User</th>
                        {/* <th className='col-1'>Email</th> */}
                        {/* <th className='col-1'>Contact</th> */}
                        <th className='col-2'>
                            Created At&nbsp;
                            {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                            }
                        </th>
                        <th className='col-4'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sortedResults && sortedResults?.map((businessUser, index) => {
                            return (
                                <UserRow businessUser={businessUser} copyText={copyText} key={index} />
                            )
                        })
                    }
                </tbody>
            </Table>
            {newUserModal && <AddNewUser show={newUserModal} handleClose={() => setOpenNewUserModal(false)} />}
            {/* {showOrders && <Orders show={showOrders} handleClose={() => setShowOrders(false)} />} */}
            {/* {showFundModal && <BusinessUserFundsModal show={showFundModal} handleClose={() => setShowFundModal(false)} />} */}

        </div>
    )
}
