import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toggleBannerStatusAPI, fetchBannersAPI, deleteBannerAPI } from '../../api/banner'

const initialState = {
    fetching: false,
    toggling: false,
    allBanners: []
}


export const fetchBanners = createAsyncThunk('/banners', async(obj, { rejectWithValue }) => {
    const response = await fetchBannersAPI();
    if (response.success) return response.data
    return rejectWithValue('Opps there seems to be an error')
})

export const toggleBannerStatus = createAsyncThunk('/banner/disable', async({ bannerId, lastUpdatedBy }, { rejectWithValue }) => {
    const response = await toggleBannerStatusAPI({ bannerId, lastUpdatedBy })
    if (response.success) return { response, bannerId }
    return rejectWithValue('Opps there seems to be an error')
})

export const deleteBanner = createAsyncThunk('/banners/delete', async(id, { rejectWithValue }) => {
    const response = await deleteBannerAPI(id);
    if (response.success) return response.data
    return rejectWithValue('Opps there seems to be an error while deleting the banner')
})

export const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {
        updateBanners: (state, action) => {}
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBanners.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchBanners.fulfilled, (state, action) => {
                state.fetching = false
                    // console.log("PAYLOAD");
                    // console.log(action.payload);
                state.allBanners = action.payload
            })
            .addCase(fetchBanners.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
                console.log(action.payload);
            })
            .addCase(toggleBannerStatus.pending, (state, action) => {
                state.toggling = true
            })
            .addCase(toggleBannerStatus.fulfilled, (state, action) => {
                state.toggling = false
                alert("Status changed.")
                console.log(action.payload);
            })
            .addCase(toggleBannerStatus.rejected, (state, action) => {
                state.toggling = false
                    // alert(action.payload)
                console.log(action.payload);
            })
            .addCase(deleteBanner.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(deleteBanner.fulfilled, (state) => {
                state.fetching = false
            })
            .addCase(deleteBanner.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
    }
})

export const { updateBanners } = bannerSlice.actions
    // export default bannerSlice.reducer