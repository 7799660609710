import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Spinner, Alert, Row, Col, Image, Card } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleAgentOrgAPI } from '../../app/api/saas/agents';
import AmazonS3Upload from '../AmazonS3Upload';
import { getAllAgents, updateAgentOrg } from '../../app/features/saas/agent/agentSlice';

function EditAgentOrg({ orgDetails }) {
    const dispatch = useDispatch();

    const { updateAgentOrgFetching, errorMessage } = useSelector(state => state.saasAgent);

    const { handleSubmit, watch, control, getValues, setValue } = useForm({
        defaultValues: {
            orgId: orgDetails?._id,
            orgType: "agent",
            payload: {
                companyDisplayName: orgDetails?.companyDisplayName ? orgDetails?.companyDisplayName : '',
                logo_url: orgDetails?.logo_url ? orgDetails?.logo_url : '',
                enabledShippers: orgDetails?.enabledShippers ? orgDetails?.enabledShippers : [],
                deliveryWindow: orgDetails?.deliveryWindow ? orgDetails.deliveryWindow : undefined,
                pickupWindow: orgDetails?.pickupWindow ? orgDetails.pickupWindow : undefined,
                officeTimings: orgDetails?.officeTimings ? orgDetails.officeTimings : undefined,
                displayShipperBrand: orgDetails?.displayShipperBrand ? orgDetails?.displayShipperBrand : false,
                hubsData: orgDetails?.hubsData ? orgDetails?.hubsData : [],
                lastMileAgents: orgDetails?.lastMileAgents ? orgDetails?.lastMileAgents : [],
                bankDetails: orgDetails?.bankDetails ? orgDetails?.bankDetails : undefined
            }
        }
    })

    useEffect(() => {
        setValue('payload', orgDetails)
        setValue('orgId', orgDetails?._id)
    }, [orgDetails])


    const [uploadNewLogo, setUploadNewLogo] = useState(false);
    const [logoFileName, setLogoFileName] = useState(orgDetails?.logo_url ? orgDetails?.logo_url : '');


    useEffect(() => {
        if (logoFileName) {
            setValue('payload.logo_url', logoFileName)
            setUploadNewLogo(false)
        }
    }, [logoFileName])

    const updateAgentOrgHandler = (data) => {
        console.log(data);

        dispatch(updateAgentOrg(data))
            .then(res => {
                alert(`${orgDetails?.companyDisplayName} updated Successfully`);
                dispatch(getAllAgents());
            })
            .catch(err => {
                alert(`Update failed`)
            })

    }


    return (
        <>
            <Form onSubmit={handleSubmit(updateAgentOrgHandler)}>

                <Card className='mb-3'>
                    <Card.Header className='fw-bold fs-6'>Organization Details</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <Controller
                                    control={control}
                                    name="payload.logo_url"
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group className="mb-3" controlId="Organization Logo">
                                            <Form.Label>
                                                Organization Logo
                                                <Button style={{ display: 'inline-block' }} variant={uploadNewLogo ? 'danger' : 'primary'} className='ms-2' size='sm' onClick={() => setUploadNewLogo(!uploadNewLogo)}>{uploadNewLogo ? 'Cancel' : 'Upload New Logo'}</Button>
                                            </Form.Label>
                                            <div>
                                                {
                                                    uploadNewLogo ?
                                                        <AmazonS3Upload bucket={process.env.REACT_APP_S3_AGENT_LOGO_BUCKET} setFileName={setLogoFileName} />
                                                        : getValues('payload.logo_url') ? <Image src={getValues('payload.logo_url')} rounded height={350} /> : 'No Image Available'
                                                }
                                            </div>
                                        </Form.Group>
                                    )}
                                />
                            </Col>

                            <Col sm={6}>
                                <Row>
                                    <Controller
                                        control={control}
                                        name="payload.companyDisplayName"
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Group className="mb-3" controlId="Organization Name">
                                                <Form.Label>Organization name</Form.Label>
                                                <Form.Control type="text" value={value} onChange={onChange} placeholder="Organization Name" required />
                                            </Form.Group>
                                        )}
                                    />
                                </Row>

                                <Row>
                                    <Form.Label style={{ textDecoration: 'underline' }}>Pickup Window</Form.Label>
                                    <Col sm={6}>
                                        <Controller
                                            control={control}
                                            name="payload.pickupWindow.startTime"
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Group className="mb-3" controlId="pw_start_time">
                                                    <Form.Label>Start Time</Form.Label>
                                                    <Form.Control type="time" value={value} onChange={onChange} placeholder="Start Time" required />
                                                </Form.Group>
                                            )}
                                        />
                                    </Col>

                                    <Col sm={6}>
                                        <Controller
                                            control={control}
                                            name="payload.pickupWindow.endTime"
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Group className="mb-3" controlId="pw_end_time">
                                                    <Form.Label>End Time</Form.Label>
                                                    <Form.Control type="time" value={value} onChange={onChange} placeholder="End Time" required />
                                                </Form.Group>
                                            )}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Form.Label style={{ textDecoration: 'underline' }}>Delivery Window</Form.Label>
                                    <Col sm={6}>
                                        <Controller
                                            control={control}
                                            name="payload.deliveryWindow.startTime"
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Group className="mb-3" controlId="dw_start_time">
                                                    <Form.Label>Start Time</Form.Label>
                                                    <Form.Control type="time" value={value} onChange={onChange} placeholder="Start Time" required />
                                                </Form.Group>
                                            )}
                                        />
                                    </Col>

                                    <Col sm={6}>
                                        <Controller
                                            control={control}
                                            name="payload.deliveryWindow.endTime"
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Group className="mb-3" controlId="dw_end_time">
                                                    <Form.Label>End Time</Form.Label>
                                                    <Form.Control type="time" value={value} onChange={onChange} placeholder="End Time" required />
                                                </Form.Group>
                                            )}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Form.Label style={{ textDecoration: 'underline' }}>Office Timings</Form.Label>
                                    <Col sm={6}>
                                        <Controller
                                            control={control}
                                            name="payload.officeTimings.startTime"
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Group className="mb-3" controlId="ot_start_time">
                                                    <Form.Label>Start Time</Form.Label>
                                                    <Form.Control type="time" value={value} onChange={onChange} placeholder="Start Time" required />
                                                </Form.Group>
                                            )}
                                        />
                                    </Col>

                                    <Col sm={6}>
                                        <Controller
                                            control={control}
                                            name="payload.officeTimings.endTime"
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Group className="mb-3" controlId="ot_end_time">
                                                    <Form.Label>End Time</Form.Label>
                                                    <Form.Control type="time" value={value} onChange={onChange} placeholder="End Time" required />
                                                </Form.Group>
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Card className='mb-3'>
                    <Card.Header className='fw-bold fs-6'>Hub Details</Card.Header>
                    <Card.Body>
                        <div className="d-flex">
                            {
                                orgDetails?.hubsData?.length ? orgDetails?.hubsData?.map((hub, index) => {
                                    return (
                                        <Card body key={index}>
                                            <div>
                                                <p><span className='fw-bold'>Hub City:</span> {hub.city_id}</p>
                                                <p><span className='fw-bold'>POC Name:</span> {hub.contact?.name}</p>
                                                <p><span className='fw-bold'>POC Phone:</span> {hub.contact?.phone}</p>
                                                <p><span className='fw-bold'>Hub Address:</span> {hub.address?.mapData?.address}</p>
                                                <p><span className='fw-bold'>Serviceable Km:</span> {hub.serviceableKM}</p>
                                            </div>
                                        </Card>
                                    )
                                }) : 'No details available'
                            }
                        </div>
                    </Card.Body>
                </Card>

                <Card className='mb-3'>
                    <Card.Header className='fw-bold fs-6'>Enabled Shippers</Card.Header>
                    <Card.Body>
                        <Row>
                            {
                                orgDetails?.enabledShippers?.length ? orgDetails?.enabledShippers?.map((shipper, index) => {
                                    return (
                                        <Col sm={2} key={index}>
                                            <Form.Control type='text' value={shipper.shipperUid} disabled />
                                        </Col>
                                    )
                                }) : 'No details available'
                            }
                        </Row>
                    </Card.Body>
                </Card>

                <Card className='mb-3'>
                    <Card.Header className='fw-bold fs-6'>Bank Details</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Controller
                                    control={control}
                                    name="payload.bankDetails.acNo"
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group className="mb-3" controlId="account_no">
                                            <Form.Label>Account Number</Form.Label>
                                            <Form.Control type='text' value={value} onChange={onChange} />
                                        </Form.Group>
                                    )}
                                />
                            </Col>

                            <Col>
                                <Controller
                                    control={control}
                                    name="payload.bankDetails.acHolderName"
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group className="mb-3" controlId="account_holder_name">
                                            <Form.Label>Account Holder Name</Form.Label>
                                            <Form.Control type='text' value={value} onChange={onChange} />
                                        </Form.Group>
                                    )}
                                />
                            </Col>

                            <Col>
                                <Controller
                                    control={control}
                                    name="payload.bankDetails.bankName"
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group className="mb-3" controlId="bank_name">
                                            <Form.Label>Name of the Bank</Form.Label>
                                            <Form.Control type='text' value={value} onChange={onChange} />
                                        </Form.Group>
                                    )}
                                />
                            </Col>

                            <Col>
                                <Controller
                                    control={control}
                                    name="payload.bankDetails.ifscCode"
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group className="mb-3" controlId="ifscCode">
                                            <Form.Label>IFSC Code</Form.Label>
                                            <Form.Control type='text' value={value} onChange={onChange} />
                                        </Form.Group>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <hr />

                <Button type='submit' variant="primary" disabled={updateAgentOrgFetching}>
                    Submit&nbsp;
                    {updateAgentOrgFetching && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />}
                </Button>


            </Form>
        </>
    )
}

export default function EditAgentOrgModal({ show, handleClose, orgDetails }) {
    const { updateAgentOrgFetching, errorMessage } = useSelector(state => state.saasAgent);

    const [organizationDetails, setOrganizationDetails] = useState(undefined);

    useEffect(async () => {
        async function fetchOrgDetails() {
            await getSingleAgentOrgAPI({ orgId: orgDetails?._id })
                .then(res => {
                    if (res.success) {
                        setOrganizationDetails(res.data)
                    }
                })
        }

        fetchOrgDetails();

    }, [])

    return (
        <>
            <Modal
                size='lg'
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Organization {orgDetails?.companyDisplayName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        errorMessage && <Alert variant='danger'>
                            {errorMessage}
                        </Alert>
                    }
                    <EditAgentOrg orgDetails={organizationDetails} />
                </Modal.Body>
            </Modal >
        </>
    )
}
