import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react'
import { Modal, Form, Button, Spinner } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { rechargeBusinessWallet } from '../../../app/features/shipmantrabusiness/wallet/businessWalletSlice';

export default function RechargeWallet({ show, handleClose, businessUserUID }) {
    const dispatch = useDispatch();

    const { fetching } = useSelector(state => state.businessWallet)
    const { adminUser } = useSelector(state => state.adminUser)

    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            'amount': 0
        }
    })

    const rechargeWallet = (data) => {
        const reqBody = {
            amount: data.amount * 100, //in paise
            user: businessUserUID,
            admin: {
                username: adminUser.username
            }
        }
        dispatch(rechargeBusinessWallet(reqBody))
            .then(unwrapResult)
            .then(() => {
                alert('Recharge successful')
                handleClose()
            })
            .catch(err => {
                console.error(err)
                alert('Some error occured')
            })
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered backdrop="static">
                <Modal.Header closeButton>
                    ADD FUNDS TO THE WALLET
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(rechargeWallet)}>
                        <Controller
                            defaultValue={0}
                            control={control}
                            rules={{ required: { value: true, message: 'This field is required' }, validate: value => parseInt(value) > 0 || 'Please enter a valid amount' }}
                            name={`amount`}
                            render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="fund_amount">
                                    <Form.Label className='text-muted'>Recharge Amount</Form.Label>
                                    <Form.Control ref={ref} type="text" placeholder="Enter fund amount" onChange={onChange} value={value} required />
                                    {errors.amount && <div className='error-text'>{errors.amount?.message}</div>}
                                </Form.Group>
                            )}
                        />
                        <Button type='submit' disabled={fetching}>
                            Add funds&nbsp;
                            {fetching && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
