import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchAllAPSRTCOrders, fetchAllAPSRTCOrdersWithoutPagingAPI, generateAPSRTCapi, syncAPSRTCapi, updateAPSRTCOrderAPI, uploadApsrtcLrmAPI } from '../../api/orders/old_apsrtc';


const initialState = {
    fetching: false,
    fetchingSingle: false,
    orders: [],
    ordersWithoutPaging: [],
    fetchingPendingOrders: false,
    orderDetail: {},
    hypSearchShippers: [],
    searchShipperFetching: false,
    intSearchShippers: [],
    panSearchShippers: [],
    bookShipperFetching: false,
    totalPages: 1
}

export const fetchApsrtcBookingOrders = createAsyncThunk('/apsrtcBooking/userOrders', async (queryParams) => {
    const response = await fetchAllAPSRTCOrders(queryParams);
    return {
        data: response.data.orders,
        page: queryParams.page ? queryParams.page : 1,
        totalPages: response.data.totalPages
    }
})

export const fetchAllAPSRTCOrdersWithoutPaging = createAsyncThunk('/apsrtcBooking/user-orders-without-paging', async (queryParams, { rejectWithValue }) => {
    const response = await fetchAllAPSRTCOrdersWithoutPagingAPI(queryParams);
    if (response.success) return response.data
    return rejectWithValue('No orders found')
})

export const updateApsrtcBookingOrder = createAsyncThunk('/apsrtcBooking/updateOrder', async (updateBody, { rejectWithValue }) => {
    const response = await updateAPSRTCOrderAPI(updateBody);
    if (response.success) {
        return {
            orderDetail: response.data
        }
    }
    rejectWithValue("There was an error while updating the order")
})

export const syncApsrtcBookingOrder = createAsyncThunk('/apsrtcBooking/syncOrder', async (updateBody, { rejectWithValue }) => {
    const { lrn, order_id } = updateBody;
    const response = await syncAPSRTCapi(lrn, order_id);
    if (response.success) {
        return {
            orderDetail: response.data
        }
    }
    rejectWithValue("There was an error while updating the order")
})

export const generateApsrtcBookingOrder = createAsyncThunk('/apsrtcBooking/generateOrder', async (updateBody, { rejectWithValue }) => {
    const { start_station_data, end_station_data, order_id } = updateBody;
    const response = await generateAPSRTCapi(order_id, start_station_data, end_station_data);
    if (response.success) {
        return {
            orderDetail: response.data
        }
    }
    rejectWithValue("There was an error while updating the order")
})

export const uploadApsrtcLRM = createAsyncThunk('/apsrtcBooking/upload-lrm', async (updateBody, { rejectWithValue }) => {
    const response = await uploadApsrtcLrmAPI(updateBody);
    if (response.success) {
        return response
    }
    rejectWithValue("There was an error while updating the order")
})

export const apsrtcBookingSlice = createSlice({
    name: 'apsrtcBookingSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchApsrtcBookingOrders.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchApsrtcBookingOrders.fulfilled, (state, action) => {
                state.fetching = false
                const { data, page, totalPages } = action.payload
                state.orders = data
                state.totalPages = totalPages;
                // if (page <= 1) {
                //     state.orders = data
                // } else {
                //     data.forEach(dataEl => {
                //         state.orders.push(dataEl)
                //     });
                // }
            })
            .addCase(fetchApsrtcBookingOrders.rejected, (state) => {
                state.fetching = false
            })

            .addCase(fetchAllAPSRTCOrdersWithoutPaging.pending, (state) => {
                false
                state.ordersWithoutPaging = []
                state.fetching = true
            })
            .addCase(fetchAllAPSRTCOrdersWithoutPaging.fulfilled, (state, action) => {
                state.fetching = false
                state.ordersWithoutPaging = action.payload
            })
            .addCase(fetchAllAPSRTCOrdersWithoutPaging.rejected, (state) => {
                state.fetching = false
            })

            .addCase(updateApsrtcBookingOrder.pending, (state) => {
                state.fetchingSingle = true
            })
            .addCase(updateApsrtcBookingOrder.fulfilled, (state, action) => {
                state.fetchingSingle = false
                const { orderStatus, sm_orderid, lr_number } = action.payload.orderDetail;
                // state.fetchingSingle = action.payload
                for (let i = 0; i < state.orders.length; i++) {
                    if (state.orders[i].sm_orderid == sm_orderid) {
                        state.orders[i].orderStatus = orderStatus;
                        state.orders[i].lr_number = lr_number;
                        break;
                    }
                }
            })
            .addCase(updateApsrtcBookingOrder.rejected, (state) => {
                state.fetchingSingle = false
            })

            .addCase(syncApsrtcBookingOrder.pending, (state) => {
                state.fetchingSingle = true
            })
            .addCase(syncApsrtcBookingOrder.fulfilled, (state, action) => {
                state.fetchingSingle = false
                const { orderStatus, sm_orderid, lr_number } = action.payload.orderDetail;
                // state.fetchingSingle = action.payload
                for (let i = 0; i < state.orders.length; i++) {
                    if (state.orders[i].sm_orderid == sm_orderid) {
                        state.orders[i].orderStatus = orderStatus;
                        state.orders[i].lr_number = lr_number;
                        break;
                    }
                }
            })
            .addCase(syncApsrtcBookingOrder.rejected, (state) => {
                state.fetchingSingle = false
            })

            .addCase(generateApsrtcBookingOrder.pending, (state) => {
                state.fetchingSingle = true
            })
            .addCase(generateApsrtcBookingOrder.fulfilled, (state, action) => {
                state.fetchingSingle = false
                const { orderStatus, sm_orderid, lr_number } = action.payload.orderDetail;
                // state.fetchingSingle = action.payload
                for (let i = 0; i < state.orders.length; i++) {
                    if (state.orders[i].sm_orderid == sm_orderid) {
                        state.orders[i].orderStatus = orderStatus;
                        state.orders[i].lr_number = lr_number;
                        break;
                    }
                }
            })
            .addCase(generateApsrtcBookingOrder.rejected, (state) => {
                state.fetchingSingle = false
            })

            .addCase(uploadApsrtcLRM.pending, (state) => {
                state.fetchingSingle = true
            })
            .addCase(uploadApsrtcLRM.fulfilled, (state, action) => {
                state.fetchingSingle = false
                const { data } = action.payload;
                // state.fetchingSingle = action.payload
                for (let i = 0; i < state.orders.length; i++) {
                    if (state.orders[i].sm_orderid == data.sm_orderid) {
                        state.orders[i] = data;
                        break;
                    }
                }
            })
            .addCase(uploadApsrtcLRM.rejected, (state) => {
                state.fetchingSingle = false
            })

    }
})