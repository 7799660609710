import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createNewBusinessUserAPI, fetchAllBusinessUsersAPI, fetchBusinessUserDetailsAPI, generateApiKeyForBusinessAPI, updateBusinessUserAPI } from '../../../api/shipmantrabusiness/users';


const initialState = {
    fetching: false,
    createUserFetching: false,
    users: [],
    userDetail: {}
}

export const fetchBusinessUsers = createAsyncThunk('/business/users', async (obj, { rejectWithValue }) => {
    const response = await fetchAllBusinessUsersAPI();
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const fetchBusinessUserDetail = createAsyncThunk('/business/user/userDetail', async (id, { rejectWithValue }) => {
    const response = await fetchBusinessUserDetailsAPI(id);
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const createBusinessUser = createAsyncThunk('/business/users/create', async ({ reqBody }, { rejectWithValue }) => {
    const data = { reqBody }
    const response = await createNewBusinessUserAPI(data);
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const updateBusinessUser = createAsyncThunk('/business/users/update', async ({ id, reqBody }, { rejectWithValue }) => {
    const response = await updateBusinessUserAPI(id, reqBody);
    if (response.success) return response.data
    return rejectWithValue(response.message)
})

export const businessUserSlice = createSlice({
    name: 'businessUsers',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBusinessUsers.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchBusinessUsers.fulfilled, (state, action) => {
                state.fetching = false
                state.users = action.payload
            })
            .addCase(fetchBusinessUsers.rejected, (state) => {
                state.fetching = false
                state.users = []
            })

            .addCase(fetchBusinessUserDetail.pending, (state) => {
                state.fetching = true
                state.userDetail = {}
            })
            .addCase(fetchBusinessUserDetail.fulfilled, (state, action) => {
                state.fetching = false
                state.userDetail = action.payload
            })
            .addCase(fetchBusinessUserDetail.rejected, (state) => {
                state.fetching = false
                state.userDetail = {}
            })

            .addCase(createBusinessUser.pending, (state) => {
                state.createUserFetching = true
            })
            .addCase(createBusinessUser.fulfilled, (state, action) => {
                state.createUserFetching = false
                console.log(action.payload)
                state.users.unshift(action.payload)
            })
            .addCase(createBusinessUser.rejected, (state) => {
                state.createUserFetching = false
            })

            .addCase(updateBusinessUser.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateBusinessUser.fulfilled, (state, action) => {
                state.fetching = false
                state.userDetail = action.payload
                let tempUsers = state.users
                tempUsers?.forEach((user, index) => {
                    if (user.uid === action.payload.uid) {
                        tempUsers[index] = action.payload
                    }
                })
                state.users = tempUsers
            })
            .addCase(updateBusinessUser.rejected, (state) => {
                state.fetching = false
            })

    }
})
