import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addCMSAnnouncementFeedAPI, deleteCMSAnnouncementFeedAPI, editCMSAnnouncementFeedAPI, getCMSAnnouncementFeedAPI, getCMSSingleAnnouncementFeedAPI } from "../../api/contentmanagment"

const initialState = {
    fetching: false,
    announcementfeeds: [],
    detail: {}
}

export const getCMSAnnouncementFeed = createAsyncThunk('/cms/get/announcement-feed', async(obj, { rejectWithValue }) => {
    const res = await getCMSAnnouncementFeedAPI()
    if (res.success) return res.data
    return rejectWithValue("There seems to be an error while fetching the content")
})

export const getCMSSingleAnnouncementFeed = createAsyncThunk('/cms/get/single-announcement-feed', async(id, { rejectWithValue }) => {
    const res = await getCMSSingleAnnouncementFeedAPI(id)
    if (res.success) return res.data
    return rejectWithValue("There seems to be an error while fetching the content")
})

export const addCMSAnnouncementFeed = createAsyncThunk('/cms/create/announcement-feed', async(data, { rejectWithValue }) => {
    const res = await addCMSAnnouncementFeedAPI(data)
    if (res.success) return res
    return rejectWithValue("There seems to be an error while adding the content")
})

export const editCMSAnnouncementFeed = createAsyncThunk('/cms/update/announcement-feed', async(data, { rejectWithValue }) => {
    const res = await editCMSAnnouncementFeedAPI(data)
    if (res.success) return res
    return rejectWithValue("There seems to be an error while updating the content")
})

export const deleteCMSAnnouncementFeed = createAsyncThunk('/cms/delete/announcement-feed', async(data, { rejectWithValue }) => {
    const res = await deleteCMSAnnouncementFeedAPI(data)
    if (res.success) return res
    return rejectWithValue("There seems to be an error while deleting the content")
})

export const contentManagementSlice = createSlice({
    name: 'content-managment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCMSAnnouncementFeed.pending, (state) => {
                state.fetching = true
            })
            .addCase(getCMSAnnouncementFeed.fulfilled, (state, action) => {
                state.fetching = false
                state.announcementfeeds = action.payload
            })
            .addCase(getCMSAnnouncementFeed.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(getCMSSingleAnnouncementFeed.pending, (state) => {
                state.fetching = true
            })
            .addCase(getCMSSingleAnnouncementFeed.fulfilled, (state, action) => {
                state.fetching = false
                state.detail = action.payload
            })
            .addCase(getCMSSingleAnnouncementFeed.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(addCMSAnnouncementFeed.pending, (state) => {
                state.fetching = true
            })
            .addCase(addCMSAnnouncementFeed.fulfilled, (state, action) => {
                state.fetching = false
                alert("Content added successfully")
            })
            .addCase(addCMSAnnouncementFeed.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(editCMSAnnouncementFeed.pending, (state) => {
                state.fetching = true
            })
            .addCase(editCMSAnnouncementFeed.fulfilled, (state, action) => {
                state.fetching = false
                alert("Content updated successfully")
            })
            .addCase(editCMSAnnouncementFeed.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(deleteCMSAnnouncementFeed.pending, (state) => {
                state.fetching = true
            })
            .addCase(deleteCMSAnnouncementFeed.fulfilled, (state, action) => {
                state.fetching = false
                alert("Content deleted successfully")
            })
            .addCase(deleteCMSAnnouncementFeed.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
    }
})