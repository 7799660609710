import React from 'react'
import { Button, Col, Form, Row, Spinner, InputGroup } from 'react-bootstrap'
import AWS from 'aws-sdk'
import { useState } from 'react'
import { uploadIcon } from '../FontAwesome'

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
})

export default function AmazonS3Upload({ bucket, S3Folder, setFileName, allowedFileTypes = [] }) {

    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [fileMimeType, setFileMimeType] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const myBucket = new AWS.S3({
        params: { Bucket: bucket },
        region: 'ap-south-1',
    })

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            alert('No file selected');
            return;
        }
        // Check if the file type is allowed
        if (allowedFileTypes && allowedFileTypes.length > 0 && !allowedFileTypes.includes(file.type)) {
            alert(`Only ${allowedFileTypes.join(', ')} files are allowed`);
            return;
        }
        setSelectedFile(file)
        setFileMimeType(file.type)
    }

    const uploadFile = () => {
        if (selectedFile) {
            setLoading(true);
            const fileName = selectedFile.name.split(".")[0]?.replace(/[\s]/g, "_");
            // const contentType = selectedFile.name.split(".")[1] + (selectedFile.name.split(".")[1] === 'svg' ? '+xml' : '')
            const fileExt = selectedFile.name.split(".")[1]

            const params = {
                ACL: 'public-read',
                Body: selectedFile,
                Bucket: bucket,
                Key: S3Folder ? `${S3Folder}/${fileName}.${fileExt}` : `${fileName}.${fileExt}`,
                ContentType: fileMimeType || 'application/octet-stream'
            };

            myBucket.putObject(params, async (err, data) => {
                if (err) {
                    setLoading(false)
                    alert("Error while uploading. " + err);
                } else {
                    setLoading(false)
                    console.log(params.Key + " uploaded");
                    setFileName(`https://${bucket}.s3.ap-south-1.amazonaws.com/` + params.Key)
                    setDisableFileUpload(true)
                }
            })
        } else {
            alert('Please select a file first')
        }
    }

    return (
        <>
            <InputGroup>
                <Form.Control type="file" onChange={handleFileChange} disabled={disableFileUpload} />

                <Button onClick={uploadFile} disabled={(loading || disableFileUpload)}>
                    {uploadIcon} Upload&nbsp;
                    {
                        loading && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                </Button>

                {/* <Button variant="secondary" id="button-addon1">
                    Button
                </Button>
                <Form.Control
                    aria-label="Example text with button addon"
                    aria-describedby="basic-addon1"
                /> */}
            </InputGroup>
            {/* <Row>
                <Col sm={9}>
                </Col>
                <Col sm={3}>
                    <Button onClick={uploadFile} disabled={(loading || disableFileUpload)}>
                        {uploadIcon} Upload&nbsp;
                        {
                            loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </Button>
                </Col>
            </Row> */}
        </>
    )
}
