/* eslint-disable react/react-in-jsx-scope */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTableColumns,
    faBox, faCheck,
    faCircle,
    faFolderPlus,
    faUser,
    faRightFromBracket,
    faRectangleAd,
    faSort,
    faTrash,
    faCircleMinus,
    faArrowsRotate,
    faCirclePlus,
    faCircleQuestion,
    faCircleExclamation,
    faCircleCheck,
    faCity,
    faBoxOpen,
    faPen,
    faArrowLeft,
    faGlobe,
    faTruck,
    faLocationDot,
    faUsers,
    faTicket,
    faComments,
    faSortAsc,
    faSortDesc,
    faEarthAmericas,
    faTruckFast,
    faPlane,
    faCommentDollar,
    faTruckLoading,
    faBook,
    faHeader,
    faBullhorn,
    faPlusCircle,
    faFlag,
    faBoltLightning,
    faCoins,
    faMotorcycle,
    faTruckPickup,
    faTruckMoving,
    faPlaneDeparture,
    faCubes,
    faFileInvoice,
    faCopy,
    faArrowUpRightFromSquare,
    faUserTie,
    faDownload,
    faUpload,
    faPrint,
    faHourglassHalf,
    faWallet,
    faBriefcase,
    faFilter,
    faPhoneFlip,
    faList,
    faTriangleExclamation,
    faXmark,
    faPause,
    faEye
}
    from '@fortawesome/free-solid-svg-icons'

export const dashboardIcon = <FontAwesomeIcon icon={faTableColumns} />
export const ordersIcon = <FontAwesomeIcon icon={faBox} />
export const pendingOrderIcon = <FontAwesomeIcon icon={faHourglassHalf} />
export const newOrderIcon = <FontAwesomeIcon icon={faFolderPlus} />
export const liveOrderIcon = <FontAwesomeIcon icon={faCircle} />
export const completedOrderIcon = <FontAwesomeIcon icon={faCheck} />
export const cancelledOrderIcon = <FontAwesomeIcon icon={faXmark} />
export const onHoldOrderIcon = <FontAwesomeIcon icon={faPause} />
export const userIcon = <FontAwesomeIcon icon={faUser} />
export const customersIcon = <FontAwesomeIcon icon={faUsers} />
export const logoutIcon = <FontAwesomeIcon icon={faRightFromBracket} />
export const bannerIcon = <FontAwesomeIcon icon={faRectangleAd} />
export const sortIcon = <FontAwesomeIcon icon={faSort} />
export const sortAscIcon = <FontAwesomeIcon icon={faSortAsc} />
export const sortDescIcon = <FontAwesomeIcon icon={faSortDesc} />
export const deleteIcon = <FontAwesomeIcon icon={faTrash} />
export const disableIcon = <FontAwesomeIcon icon={faCircleMinus} />
export const enableIcon = <FontAwesomeIcon icon={faCirclePlus} />
export const refreshIcon = <FontAwesomeIcon icon={faArrowsRotate} />
export const addIcon = <FontAwesomeIcon icon={faPlusCircle} />
export const closeIcon = <FontAwesomeIcon icon={faXmark} />
export const copyIcon = <FontAwesomeIcon icon={faCopy} />
export const openLinkOnNewPageIcon = <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
export const enquiryIcon = <FontAwesomeIcon icon={faCircleQuestion} />
export const enquiredIcon = <FontAwesomeIcon icon={faCircleCheck} style={{ color: 'green' }} />
export const notEnquiredIcon = <FontAwesomeIcon icon={faCircleExclamation} style={{ color: 'red' }} />
export const inProgressEnquiryIcon = <FontAwesomeIcon icon={faCircleExclamation} style={{ color: 'yellow' }} />
export const cityIcon = <FontAwesomeIcon icon={faCity} />
export const countryIcon = <FontAwesomeIcon icon={faEarthAmericas} />
export const orderIcon = <FontAwesomeIcon icon={faBoxOpen} />
export const internationalOrderIcon = <FontAwesomeIcon icon={faPlaneDeparture} />
export const importOrderIcon = <FontAwesomeIcon icon={faGlobe} />
export const rentalOrderIcon = <FontAwesomeIcon icon={faTruck} />
export const hyperlocalOrderIcon = <FontAwesomeIcon icon={faLocationDot} />
export const panIndiaOrderIcon = <FontAwesomeIcon icon={faFlag} />
export const expressDeliveryOrderIcon = <FontAwesomeIcon icon={faBoltLightning} />
export const editIcon = <FontAwesomeIcon icon={faPen} />
export const backButtonIcon = <FontAwesomeIcon icon={faArrowLeft} />
export const couponIcon = <FontAwesomeIcon icon={faTicket} />
export const reviewIcon = <FontAwesomeIcon icon={faComments} />
export const shipperIcon = <FontAwesomeIcon icon={faTruckFast} />
export const internationalShipperIcon = <FontAwesomeIcon icon={faPlane} />
export const hyperlocalShipperIcon = <FontAwesomeIcon icon={faTruckLoading} />
export const panIndiaShipperIcon = <FontAwesomeIcon icon={faTruckFast} />
export const requestPaymentIcon = <FontAwesomeIcon icon={faCommentDollar} />
export const contentManagementIcon = <FontAwesomeIcon icon={faBook} />
export const cmsHeaderIcon = <FontAwesomeIcon icon={faHeader} />
export const megaPhoneIcon = <FontAwesomeIcon icon={faBullhorn} />
export const shipmantraChargesIcon = <FontAwesomeIcon icon={faCoins} />
export const hyperlocal2WheelerIcon = <FontAwesomeIcon icon={faMotorcycle} />
export const hyperlocal3WheelerIcon = <FontAwesomeIcon icon={faTruckPickup} />
export const hyperlocal4WheelerIcon = <FontAwesomeIcon icon={faTruckMoving} />
export const exportIcon = <FontAwesomeIcon icon={faPlaneDeparture} />
export const expressDeliveryIcon = <FontAwesomeIcon icon={faTruckFast} />
export const normalPanIndiaDeliveryIcon = <FontAwesomeIcon icon={faCubes} />
export const invoiceIcon = <FontAwesomeIcon icon={faFileInvoice} />
export const downloadIcon = <FontAwesomeIcon icon={faDownload} />
export const uploadIcon = <FontAwesomeIcon icon={faUpload} />
export const printIcon = <FontAwesomeIcon icon={faPrint} />
export const filterIcon = <FontAwesomeIcon icon={faFilter} />
export const phoneIcon = <FontAwesomeIcon icon={faPhoneFlip} />
export const openInNewTabIcon = <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
export const cautionIcon = <FontAwesomeIcon icon={faTriangleExclamation} />
export const listIcon = <FontAwesomeIcon icon={faList} />
export const viewIcon = <FontAwesomeIcon icon={faEye} />
//Shipmantra Busniness
export const businessUserIcon = <FontAwesomeIcon icon={faUserTie} />
export const walletIcon = <FontAwesomeIcon icon={faWallet} />
export const briefcaseIcon = <FontAwesomeIcon icon={faBriefcase} />

