import React, { useEffect, useState } from 'react'
import { Table, Container, Button, Spinner, Form, Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { fetchNoOptionEnquiries, updateNoOptionEnquiry } from '../../app/features/enquiries/enquirySlice'
import { enquiredIcon, inProgressEnquiryIcon, notEnquiredIcon, refreshIcon } from '../../FontAwesome'
import LoadingOverlay from 'react-loading-overlay'
import { unwrapResult } from '@reduxjs/toolkit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function NoOptionEnquiry() {
    const dispatch = useDispatch()

    const { enquiries, fetching } = useSelector(state => state.enquiry)
    const { adminUser } = useSelector(state => state.adminUser)

    useEffect(() => {
        dispatch(fetchNoOptionEnquiries())
    }, [])

    const [sortedResults, setSortedResults] = useState(enquiries ? enquiries : [])

    useEffect(() => {
        if (enquiries) {
            setSortedResults(enquiries);
        }
    }, [enquiries])

    const [enquiryStatus, setEnquiryStatus] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [activeSelectedState, setActiveSelectedState] = useState('')
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <span
            // href=""
            style={{ cursor: 'pointer' }}
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            &#x25bc;
        </span>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                        )}
                    </ul>
                </div>
            );
        },
    );

    const filterByOrderState = (stateToFilter) => {
        console.log(stateToFilter)
        setActiveSelectedState(stateToFilter)
        if (stateToFilter === 'Remove filter') {
            setSortedResults(enquiries)
        } else {
            let temp = []
            enquiries.map(enquiry => {
                if (enquiry.status === stateToFilter) {
                    temp.push(enquiry)
                }
            })
            setSortedResults(temp)
        }
    }


    const changeEnquiryStatus = (e, enquiryID) => {
        const updateData = {
            status: e.target.value,
            enquiredBy: adminUser.email
        }
        dispatch(updateNoOptionEnquiry({ id: enquiryID, data: updateData }))
            .then(unwrapResult)
            .then(() => {
                alert("Enquiry updated successfully");
                dispatch(fetchNoOptionEnquiries());
            })
    }

    if (fetching) return (
        <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
            <Spinner animation="border" />
        </div>
    )

    return (
        <Container className='p-4'>
            <h3>No Option enquiries</h3>
            <hr />
            <div className='d-flex justify-content-end align-items-center'>
                <Button variant="secondary" onClick={e => { dispatch(fetchNoOptionEnquiries()) }}>{refreshIcon}&nbsp;Refresh</Button>
            </div>
            <Table striped bordered hover className='mt-3'>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th>#</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Mode</th>
                        <th>Source</th>
                        <th>Destination</th>
                        <th>Enquired By</th>
                        <th>
                            Status
                            <Dropdown style={{ display: 'inline-block', zIndex: '10000' }} onSelect={filterByOrderState}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu}>
                                    <Dropdown.Item eventKey="Remove filter">Remove filter</Dropdown.Item>
                                    <Dropdown.Item eventKey="Pending" active={activeSelectedState === "Placed"}>Pending</Dropdown.Item>
                                    <Dropdown.Item eventKey="In Progress" active={activeSelectedState === "In Progress"}>In Progress</Dropdown.Item>
                                    <Dropdown.Item eventKey="Completed" active={activeSelectedState === "Delivered"}>Completed</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sortedResults.map((enquiry, index) => {
                            return (
                                <tr key={index} onClick={handleShow}>
                                    <td>
                                        {
                                            enquiry.status ?
                                                enquiry.status === 'Completed' ? enquiredIcon
                                                    : enquiry.status === 'Pending' ? notEnquiredIcon
                                                        : enquiry.status === 'In Progress' ? inProgressEnquiryIcon
                                                            : null
                                                : null
                                        }
                                    </td>
                                    <td>{enquiry.email}</td>
                                    <td>{enquiry.name}</td>
                                    <td>{enquiry.phone}</td>
                                    <td style={{ textTransform: 'capitalize' }}>{enquiry.mode}</td>
                                    <td>
                                        City: {enquiry?.source?.city}
                                        <br />
                                        Pincode: {enquiry?.source?.pincode}
                                    </td>
                                    <td>
                                        {
                                            enquiry.mode === 'international' ?
                                                <>
                                                    Country: {enquiry?.destination?.country}
                                                    <br />
                                                    Pincode: {enquiry?.destination?.pincode}
                                                </> :
                                                null
                                        }
                                    </td>
                                    <td>{enquiry.enquiredBy}</td>
                                    <td>
                                        <Form.Select defaultValue={enquiry.status} aria-label="Default select example" name='enquiryStatus' onChange={(e) => { changeEnquiryStatus(e, enquiry._id) }}>
                                            <option disabled value="">Change status</option>
                                            <option value="Pending">Pending</option>
                                            <option value="In Progress">In Progess</option>
                                            <option value="Completed">Completed</option>
                                        </Form.Select>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </Container>
    )
}
