import React, { useState } from 'react'
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { requestPaymentAPI } from '../../app/api/payment'

function PaymentPage({ order }) {
    const dispatch = useDispatch()

    // console.log(order)

    const handleSubmit = (e) => {
        setDisable(true)
        const payment_url_div = document.getElementById('payment_url')
        e.preventDefault();
        const reqBody = {
            orderID: orderId,
            amount: parseFloat(cost),
            contact,
            name,
            email
        }
        requestPaymentAPI(reqBody)
            .then(res => {
                setDisable(false)
                console.log(res)
                if (res.success) {
                    const url = res.paymentLink
                    setPaymentUrl(url)
                    if (payment_url_div) {
                        payment_url_div.style.display = 'block'
                    }
                } else {
                    alert("Something went wrong:(")
                }
            })
            .catch(err => {
                setDisable(false)
                console.log(err)
            })
    }

    const [orderId, setOrderId] = useState(order ? order.sm_orderid : "")
    const [cost, setCost] = useState("")
    const [contact, setContact] = useState(order ? order.start?.contact?.phone : "")
    const [name, setName] = useState(order ? order.start?.contact?.name : "")
    const [email, setEmail] = useState(order ? order.start?.contact?.email : "")
    const [disable, setDisable] = useState(false)
    const [paymentUrl, setPaymentUrl] = useState("")

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col sm={12}>
                        <Form.Group className="mb-3" controlId="orderId">
                            <Form.Label>Order Id</Form.Label>
                            <Form.Control type="text" value={orderId} onChange={e => setOrderId(e.currentTarget.value)} placeholder="Enter order id" required />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group className="mb-3" controlId="cost">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control type="text" value={cost} onChange={e => setCost(e.currentTarget.value)} placeholder="Enter Cost" required />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group className="mb-3" controlId="contact">
                            <Form.Label>Contact number</Form.Label>
                            <Form.Control type="text" value={contact} onChange={e => setContact(e.currentTarget.value)} placeholder="Enter contact number" required />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={e => setName(e.currentTarget.value)} placeholder="Enter contact number" required />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" value={email} onChange={e => setEmail(e.currentTarget.value)} placeholder="Enter contact number" required />
                        </Form.Group>
                    </Col>
                </Row>
                <hr />
                <Button type="submit" disabled={disable}>
                    Submit {disable && <Spinner variant='primary' />}
                </Button>
            </Form>
            <div id='payment_url' style={{ display: 'none', marginTop: '20px' }}>
                Url :&nbsp;
                <a href={paymentUrl} target="_blank" style={{ overflowWrap: 'anywhere' }} rel="noreferrer">{paymentUrl}</a>
            </div>
        </>
    )
}

export default function RequestPayment({ order, show, handleClose }) {
    // const dispatch = useDispatch()

    // // console.log(order)

    // const handleSubmit = (e) => {
    //     setDisable(true)
    //     const payment_url_div = document.getElementById('payment_url')
    //     e.preventDefault();
    //     const reqBody = {
    //         orderID: orderId,
    //         amount: parseFloat(cost) * 100,
    //         contact
    //     }
    //     requestPaymentAPI(reqBody)
    //         .then(res => {
    //             setDisable(false)
    //             console.log(res)
    //             if (res.success) {
    //                 const url = res.data.data.instrumentResponse.redirectInfo.url
    //                 setPaymentUrl(url)
    //                 if (payment_url_div) {
    //                     payment_url_div.style.display = 'block'
    //                 }
    //             } else {
    //                 alert("Something went wrong:(")
    //             }
    //         })
    //         .catch(err => {
    //             setDisable(false)
    //             console.log(err)
    //         })
    // }

    return (
        <>
            {
                order ?
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Request Payment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="orderId">
                                            <Form.Label>Order Id</Form.Label>
                                            <Form.Control type="text" value={orderId} onChange={e => setOrderId(e.currentTarget.value)} placeholder="Enter order id" required />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group className="mb-3" controlId="cost">
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control type="text" value={cost} onChange={e => setCost(e.currentTarget.value)} placeholder="Enter Cost" required />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group className="mb-3" controlId="contact">
                                            <Form.Label>Contact number</Form.Label>
                                            <Form.Control type="text" value={contact} onChange={e => setContact(e.currentTarget.value)} placeholder="Enter contact number" required />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr />
                                <Button type="submit" disabled={disable}>
                                    Submit {disable && <Spinner variant='primary' />}
                                </Button>
                            </Form>
                            <div id='payment_url' style={{ display: 'none', marginTop: '20px' }}>
                                Url :&nbsp;
                                <a href={paymentUrl} target="_blank" style={{ overflowWrap: 'anywhere' }}>{paymentUrl}</a>
                            </div> */}
                            <PaymentPage order={order} />
                        </Modal.Body>
                    </Modal>
                    :
                    <div style={{ padding: '30px' }}>
                        <h3>Request payment</h3>
                        <hr />
                        <PaymentPage order={order} />
                    </div>
            }
        </>
    )
}
