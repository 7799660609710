import { checkLogin } from "../../../utils/firebase";

const nodeBaseUrl = process.env.REACT_APP_NODE_BASE_URL

export const fetchAllC2cRequestOrdersForAdminAPI = async () => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${nodeBaseUrl}/get-c2c-request-order/admin`, {
            method: 'GET',
            headers: {
                'admin-authorization': "Bearer " + (authToken ? authToken : '')
            }
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}
