import React, { useState } from 'react'
import { useEffect } from 'react';
import { Container, Form, Row, Col, Button, Spinner, ToggleButton } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { fetchCouponById, updateCoupon } from '../../app/features/coupons/couponSlice';
import Loader from '../Loader';
import { backButtonIcon } from '../../FontAwesome';

export default function EditCoupon() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams();

    const couponParam = params.coupon

    const { fetching, couponDetails } = useSelector(state => state.coupon)

    const [couponDetail, setCouponDetails] = useState({});
    const [coupon, setCoupon] = useState("");
    const [desc, setDescription] = useState("");
    const [expiry, setExpiry] = useState("");
    const [flatRate, setFlatRate] = useState("");
    const [percent, setPercent] = useState("");
    const [serviceType, setServiceType] = useState("");
    const [upperlimit, setUpperlimit] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [isCustomCoupon, setIsCustomCoupon] = useState(false);
    const [displayRate, setDisplayRate] = useState(true)
    const [displayPercent, setDisplayPercent] = useState(true)


    const newExpiryDate = new Date(expiry).toLocaleDateString('en-CA');

    const handleLessThanTen = (i) => {
        return (i < 10 ? '0' : '') + i;
    }

    const getDateTimeLocal = (inputDate) => {
        const date = new Date(inputDate)
        const day = handleLessThanTen(date.getDate())
        const month = handleLessThanTen(date.getMonth() + 1)
        const year = handleLessThanTen(date.getFullYear())
        const hrs = handleLessThanTen(date.getHours())
        const minutes = handleLessThanTen(date.getMinutes())
        const seconds = handleLessThanTen(date.getSeconds())

        const dateString = year + "-" + month + "-" + day + "T" + hrs + ":" + minutes + ":" + seconds
        return dateString
    }

    useEffect(() => {
        dispatch(fetchCouponById(couponParam))
    }, [])

    useEffect(() => {
        setCouponDetails(couponDetails)
        setCoupon(couponDetails.coupon)
        setDescription(couponDetails.desc)
        setExpiry(getDateTimeLocal(couponDetails.expiry))
        // setExpiry('2022-9-5T18:48')
        setFlatRate(couponDetails.flatRate)
        setPercent(couponDetails.percent)
        setServiceType(couponDetails.serviceType)
        setUpperlimit(couponDetails.upperlimit)
        setIsActive(couponDetails.isActive)
        setIsCustomCoupon(couponDetails.isCustomCoupon)
    }, [couponDetails])

    useEffect(() => {
        if (!percent) {
            setDisplayRate(true)
        }
        else {
            setDisplayRate(false)
        }
        if (!flatRate) {
            setDisplayPercent(true)
        }
        else {
            setDisplayPercent(false)
        }
    }, [percent, flatRate])

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            coupon: coupon,
            desc: desc,
            serviceType: serviceType,
            expiry: new Date(expiry).toISOString(),
            isActive: isActive,
            isCustomCoupon: isCustomCoupon,
            flatRate: flatRate,
            percent: percent,
            upperlimit: upperlimit
        }
        console.log("Submitted data:", data);

        //dispatch code.....
        dispatch(updateCoupon(data))
            .then(() => {
                dispatch(fetchCouponById(couponParam))
                alert('Coupon updated')
            });
    }

    if (fetching) return (
        <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
            <Spinner animation="border" />
        </div>
    )

    return (
        <Container className='p-3'>
            <Button variant='primary' onClick={e => { navigate(-1) }}>{backButtonIcon}&nbsp;Back</Button>
            <hr />
            <Form onSubmit={handleSubmit}>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="coupon">
                            <Form.Label>Coupon</Form.Label>
                            <Form.Control type="text" value={coupon} placeholder="Coupon" onChange={(e => setCoupon(e.currentTarget.value))} readOnly />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="username">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" value={desc} placeholder="Description" onChange={(e => setDescription(e.currentTarget.value))} required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Row>
                            <Col xs={12} sm={6}>
                                <Form.Group controlId="active">
                                    <Form.Label>Activate</Form.Label>
                                    <Form.Check type="switch" value={isActive} checked={isActive} placeholder="isActive" onChange={(e => setIsActive(!isActive))} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Form.Group controlId="hidden">
                                    <Form.Label>Is Custom Coupon?</Form.Label>
                                    <Form.Check type="switch" value={isCustomCoupon} checked={isCustomCoupon} placeholder="isCustomCoupon" onChange={(e => setIsCustomCoupon(!isCustomCoupon))} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="validTill">
                            <Form.Label>Valid Till</Form.Label>
                            <Form.Control type="datetime-local" value={expiry} placeholder="Expiring On" onChange={(e => setExpiry(e.currentTarget.value))} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="flatrate">
                            <Form.Label>Flat Rate</Form.Label>
                            <Form.Control type="text" value={flatRate} readOnly={!displayRate} placeholder="Flat Rate" onChange={(e => { setFlatRate(e.currentTarget.value); })} />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="percent">
                            <Form.Label>Percent</Form.Label>
                            <Form.Control type="text" value={percent} readOnly={!displayPercent} placeholder="Percent" onChange={(e => { setPercent(e.currentTarget.value); })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={6}>
                        <Form.Group controlId="upperlimit">
                            <Form.Label>Minimum discount(in Rs.)</Form.Label>
                            <Form.Control type="text" value={upperlimit} placeholder="Upper Limit" onChange={(e => setUpperlimit(e.currentTarget.value))} />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="availedfor">
                            <Form.Label>Availed For</Form.Label>
                            {/* Change this to dropdown */}
                            <Form.Select defaultValue={serviceType} name="serviceType" onChange={e => setServiceType(e.target.value)}>
                                <option value="">Select</option>
                                <option value="Hyperlocal">Hyperlocal</option>
                                <option value="Hyperlocal2W">Hyperlocal - 2 wheeler</option>
                                <option value="Hyperlocal3W">Hyperlocal - 3 wheeler</option>
                                <option value="Hyperlocal4W">Hyperlocal - 4 wheeler</option>
                                <option value="International">International</option>
                                <option value="IntExport">International - export</option>
                                <option value="PanIndia">Pan india</option>
                                <option value="PanIndiaExpress">Pan india - Express</option>
                                <option value="PanIndiaPackers&Movers">Pan india - Packers & movers</option>
                                <option value="PanIndiaColdChain">Pan india - Cold chain</option>
                                <option value="PanIndiaPetTransport">Pan india - Pet transport</option>
                            </Form.Select>
                            {/* <Form.Control type="text" value={serviceType} placeholder="Availed For" onChange={(e => setServiceType(e.currentTarget.value))} /> */}
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant='primary' type="submit">Submit</Button>
            </Form>
        </Container>
    )
}
