import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBulkCallDetails } from '../../app/features/general/generalSlice';
import { Button, Spinner, Table } from 'react-bootstrap';
// import { refreshIcon } from '../../FontAwesome';
import { useRef } from 'react';
import { phoneIcon } from '../../FontAwesome';
import VoiceCall from './VoiceCall';

function CallRow({ callDetails, setNumberToCall, setShowVoiceCallModal }) {
    return (
        <tr className='d-flex'>
            <td className='col-2'>
                {callDetails.From}&nbsp;
                <span style={{ cursor: 'pointer' }} onClick={() => { setNumberToCall(callDetails.From); setShowVoiceCallModal(true) }}>{phoneIcon}</span>
            </td>
            <td className='col-2'>{callDetails.To}</td>
            <td
                className='fw-bold col-2'
                style={{ color: callDetails.Status === 'completed' ? 'green' : (callDetails.Status === 'no-answer' || callDetails.Status === 'busy' || callDetails.Status === 'failed') ? 'red' : 'blue' }}
            >{callDetails.Status}</td>
            <td className='col-3'>
                <span className='fw-bold' style={{ color: 'orange' }}>{callDetails.Duration} s</span> <br />
                <span className='fw-bold'>Start Time:</span> {callDetails.StartTime} <br />
                <span className='fw-bold'>End Time:</span> {callDetails.EndTime}
            </td>
            <td className='col-3' style={{ wordWrap: 'break-word' }}><a href={callDetails.RecordingUrl} target="_blank" rel="noopener noreferrer">{callDetails.RecordingUrl}</a></td>
        </tr>
    )
}

export default function CallList() {
    const dispatch = useDispatch();

    const { fetchingCallDetails, callDetailsList, exotelNextPageUri, hasNextCallDetails } = useSelector(state => state.general)
    const [sortedResults, setSortedResults] = useState([]);
    const [numberToCall, setNumberToCall] = useState('');
    const [showVoiceCallModal, setShowVoiceCallModal] = useState(false);
    const initialLoad = useRef(true);

    const fetchCallDetails = () => {
        const queryParams = {
            exotelNextPageUri
        }
        dispatch(getBulkCallDetails(queryParams));
    }

    useEffect(() => {
        fetchCallDetails();
        if (initialLoad.current) {
            initialLoad.current = false
        }
    }, []);

    useEffect(() => {
        setSortedResults(callDetailsList)
    }, [callDetailsList]);

    return (
        <div style={{ padding: '30px' }}>
            <div className='d-flex'>
                <h3 style={{ flex: 1, textTransform: 'capitalize' }}>Call Details</h3>
                {/* <div>
                    <Button onClick={async () => { dispatch(refreshCallDetails()); fetchCallDetails() }} className='ms-2' variant='secondary'>{refreshIcon}&nbsp;Refresh</Button>
                </div> */}
            </div>
            <hr />
            <Table hover responsive style={{ minHeight: '300px' }}>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr className='d-flex'>
                        <th className='col-2'>From</th>
                        <th className='col-2'>To</th>
                        <th className='col-2'>Status</th>
                        <th className='col-3'>Duration</th>
                        <th className='col-3'>Recording Url</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (fetchingCallDetails && initialLoad.current) ?
                            <tr><td colSpan={8} style={{ width: '100%', textAlign: 'center', padding: '20px' }}>Loading...</td></tr>
                            : sortedResults?.length ? sortedResults.map((callDetails, index) => {
                                return (
                                    <CallRow key={index} callDetails={callDetails} setNumberToCall={setNumberToCall} setShowVoiceCallModal={setShowVoiceCallModal} />
                                )
                            }) : <tr><td colSpan={8} style={{ width: '100%', textAlign: 'center', padding: '20px' }}>No Data to show</td></tr>
                    }
                </tbody>
            </Table>
            <Button className='mt-3' onClick={fetchCallDetails} disabled={fetchingCallDetails || !hasNextCallDetails}>
                {
                    fetchingCallDetails && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                }
                &nbsp;{!hasNextCallDetails ? "No more Data" : fetchingCallDetails ? 'Fetching....' : 'Load More'}
            </Button>
            {showVoiceCallModal && <VoiceCall show={showVoiceCallModal} handleClose={() => setShowVoiceCallModal(false)} numberToCall={numberToCall} orderType='general' />}
        </div >
    )
}
