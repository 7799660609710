import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createCouponAPI, deleteCouponAPI, fetchAllCouponsAPI, fetchCouponByIdAPI, updateCouponAPI } from '../../api/coupons';


const initialState = {
    error: false,
    fetching: false,
    allCoupons: [],
    couponDetails: {}
}

export const fetchAllCoupons = createAsyncThunk('/coupons', async(obj, { rejectWithValue }) => {
    const response = await fetchAllCouponsAPI();
    if (response.success) return response.data
    return rejectWithValue('There was some error while processing the request');
})

export const fetchCouponById = createAsyncThunk('/coupons/fetchCouponById', async(coupon, { rejectWithValue }) => {
    const response = await fetchCouponByIdAPI(coupon);
    if (response.success) return response.data
    return rejectWithValue('There was some error while processing the request');
})

export const updateCoupon = createAsyncThunk('/coupons/update', async(data, { rejectWithValue }) => {
    const response = await updateCouponAPI(data.coupon, data);
    if (response.success) return response
    return rejectWithValue(response.message)
})

export const createCoupon = createAsyncThunk('/coupons/create', async(data, { rejectWithValue }) => {
    const response = await createCouponAPI(data);
    if (response.success) return response
    return rejectWithValue(response.message)
})
export const deleteCoupon = createAsyncThunk('/coupons/delete', async(couponName, { rejectWithValue }) => {
    const response = await deleteCouponAPI(couponName);
    if (response.success) return response
    return rejectWithValue(response.message)
})

export const couponSlice = createSlice({
    name: 'coupons',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCoupons.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchAllCoupons.fulfilled, (state, action) => {
                state.fetching = false
                state.allCoupons = action.payload
            })
            .addCase(fetchAllCoupons.rejected, (state, action) => {
                state.fetching = false
                console.log("Rejected: ", JSON.stringify(action.payload));
            })
            .addCase(fetchCouponById.pending, (state) => {
                state.fetching = true
            })
            .addCase(fetchCouponById.fulfilled, (state, action) => {
                state.fetching = false
                state.couponDetails = action.payload
                console.log("PAYLOAD: ", JSON.stringify(action.payload));
            })
            .addCase(fetchCouponById.rejected, (state, action) => {
                state.fetching = false
                console.log("Rejected: ", JSON.stringify(action.payload));
            })
            .addCase(updateCoupon.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateCoupon.fulfilled, (state, action) => {
                state.fetching = false
            })
            .addCase(updateCoupon.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(createCoupon.pending, (state) => {
                state.fetching = true
            })
            .addCase(createCoupon.fulfilled, (state, action) => {
                state.fetching = false
            })
            .addCase(createCoupon.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
            .addCase(deleteCoupon.pending, (state) => {
                state.fetching = true
            })
            .addCase(deleteCoupon.fulfilled, (state, action) => {
                state.fetching = false
            })
            .addCase(deleteCoupon.rejected, (state, action) => {
                state.fetching = false
                alert(action.payload)
            })
    }
})