import React from 'react'
import { useState } from 'react';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react';
import XLSX from "xlsx";
import { useEffect } from 'react';
import { addIntShipperAPI } from '../../app/api/shippers/internationalshippers';
import { useDispatch, useSelector } from 'react-redux';
import { addIntShipper } from '../../app/features/shipper/shipperSlice';
import { addIcon, deleteIcon } from '../../FontAwesome';
import AmazonS3Upload from '../AmazonS3Upload';


export default function AddNewIntShipper() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [rates, setRates] = useState([])
    const [shipperName, setShipperName] = useState("")
    const [shipperLogo, setShipperLogo] = useState("")
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("")
    const [packing, setPacking] = useState(false)
    const [tracking, setTracking] = useState(false)
    const [pickup, setPickup] = useState(false)
    const [ior, setIOR] = useState(false)
    const [imports, setImports] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [shipmentType, setShipmentType] = useState([""])

    const { fetching } = useSelector(state => state.shipper)


    function uploadFile() {
        var input = document.getElementById('excelFile');
        var files = input.files
        if (files && files[0]) {
            console.log(files[0].type);
            if (files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                handleFile(files[0])
            } else {
                alert("only excel sheets in .XLSX format acceoted");
            }
            console.log('File was imported');
        }
    }

    function handleFile(file) {
        console.log('Inside handle File');
        var reader = new FileReader();
        reader.onload = function (e) {
            console.log('Reader loaded');
            var data = e.target.result;
            var workbook = XLSX.read(data, {
                type: 'binary'
            })

            workbook.SheetNames.forEach(function (sheetName) {
                // Here is your object
                var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                readData(XL_row_object);
            })

        };

        reader.onerror = function (ex) {
            console.log(ex);
        };

        reader.readAsBinaryString(file)
    }

    function readData(data) {
        const rateDiv = document.getElementById('rates')
        rateDiv.style.display = 'block'
        rateDiv.innerText = ''
        console.log('Inside Read Data');
        console.log(data.length);
        var JSONArray = [];
        for (let i = 0; i < data.length; i++) {
            let jsonElement = {};
            let rates = {};
            for (var key in data[i]) {
                let element = data[i]
                if (key === 'Weight Slab') {
                    jsonElement["weight"] = JSON.stringify(element[key])
                }
                else {
                    let country = key;
                    rates[country] = parseInt(element[key])
                }
            }
            jsonElement["cost"] = rates;
            JSONArray.push(jsonElement);
        }
        rateDiv.innerText += "[\n"
        JSONArray.map((e, i) => {
            rateDiv.innerText += JSON.stringify(e, undefined, 7) + ","
        })
        rateDiv.innerText += "\n]"
        setRates(JSONArray)
        console.log(JSONArray)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const reqBody = {
            shipper: shipperName,
            rates,
            logoURL: shipperLogo,
            packaging: packing,
            tracking,
            pickup,
            contact,
            email,
            ior,
            imports,
            shipmentType
        }
        // console.log(reqBody)
        if (!rates && rates.length === 0) {
            alert("Please add rates file")
        } else if (!shipperLogo) {
            alert("Please upload shipper logo")
        }
        else {
            dispatch(addIntShipper(reqBody))
        }
    }

    const handleRemoveShipmentType = (index) => {
        console.log(shipmentType)
        console.log(index)
        let newShipmentTypes = [...shipmentType]
        newShipmentTypes.splice(index, 1)
        console.log(newShipmentTypes)
        setShipmentType(newShipmentTypes)
    }

    const updateShipmentValue = (value, index) => {
        const newShipmentTypes = [...shipmentType]
        newShipmentTypes[index] = value
        setShipmentType(newShipmentTypes)
    }

    const shipmentOptions = [
        { value: 'personal', label: 'Personal' },
        { value: 'business', label: 'Business' }
    ]

    return (
        <div style={{ padding: '30px' }}>
            <h3 style={{ marginBottom: '30px' }}>Add new international Shipper</h3>
            <Button onClick={() => navigate('/shipper/internationalShipper')}>All Shippers</Button>
            <hr />
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3" controlId="shipper">
                            <Form.Label>Shipper</Form.Label>
                            <Form.Control type="text" value={shipperName} onChange={(e) => setShipperName(e.currentTarget.value)} placeholder="Enter shipper name" required />
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3" controlId="logoUrl">
                            <Form.Label>Logo URL</Form.Label>
                            <AmazonS3Upload bucket='shipmantra-assets' S3Folder='3rd-party-logo' setFileName={setShipperLogo} />
                            {/* <Form.Control type="text" value={shipperLogo} onChange={(e) => setShipperLogo(e.currentTarget.value)} placeholder="Enter shipper logo url" /> */}
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} placeholder="Enter email" />
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group className="mb-3" controlId="number">
                            <Form.Label>Contact number</Form.Label>
                            <Form.Control type="text" value={contact} onChange={(e) => setContact(e.currentTarget.value)} placeholder="Enter contact number" />
                        </Form.Group>
                    </Col>

                    <Col xs={6} sm={2}>
                        <Form.Group className="mb-3" controlId="packing">
                            <Form.Label>Packing</Form.Label>
                            <Form.Check
                                type="switch"
                                value={packing} onChange={() => setPacking(!packing)}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={6} sm={2}>
                        <Form.Group className="mb-3" controlId="tracking">
                            <Form.Label>Tracking</Form.Label>
                            <Form.Check
                                type="switch"
                                value={tracking} onChange={() => setTracking(!tracking)}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={6} sm={2}>
                        <Form.Group className="mb-3" controlId="pickup">
                            <Form.Label>Pickup</Form.Label>
                            <Form.Check
                                type="switch"
                                value={pickup} onChange={() => setPickup(!pickup)}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={6} sm={2}>
                        <Form.Group className="mb-3" controlId="ior">
                            <Form.Label>Ior</Form.Label>
                            <Form.Check
                                type="switch"
                                value={ior} onChange={() => setIOR(!ior)}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={6} sm={2}>
                        <Form.Group className="mb-3" controlId="imports">
                            <Form.Label>Imports</Form.Label>
                            <Form.Check
                                type="switch"
                                value={imports} onChange={() => setImports(!imports)}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={6} sm={2}>
                        <Form.Group className="mb-3" controlId="isActive">
                            <Form.Label>isActive</Form.Label>
                            <Form.Check
                                type="switch"
                                value={isActive} onChange={() => setIsActive(!isActive)}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <Row>
                            <Form.Label>Shipment types</Form.Label>
                            {
                                shipmentType && shipmentType?.map((type, index) => {
                                    return (
                                        <Col sm={4} key={index}>
                                            <Form.Group className="mb-3" controlId="imports">
                                                <div className='d-flex'>
                                                    <Form.Select value={type} onChange={(e) => updateShipmentValue(e.target.value, index)}>
                                                        <option value="">Select</option>
                                                        {
                                                            shipmentOptions.map((opt, index) => {
                                                                return (
                                                                    <option key={index} value={opt.value}>{opt.label}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    {shipmentType.length !== 1 && <Button variant='danger' className='ms-1' onClick={() => handleRemoveShipmentType(index)}>{deleteIcon}</Button>}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    )
                                })
                            }
                            {(shipmentType.length < shipmentOptions.length) && <Col xs={12}>
                                <Button variant='success' className='mb-3' onClick={() => setShipmentType([...shipmentType, ""])}>{addIcon}&nbsp;Add</Button>
                            </Col>}
                        </Row>
                    </Col>

                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="excelFile">
                            <Form.Label>Rates</Form.Label>
                            <div className="d-flex flex-column flex-md-row">
                                <Form.Control type="file" className='me-4 align-self-center' required />
                                <Button variant='secondary' onClick={() => uploadFile()}>Click to import</Button>
                            </div>
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <div style={{
                            display: 'none',
                            height: '400px',
                            // background: '#002b36',
                            padding: '20px',
                            background: '#242424',
                            color: '#fff',
                            // color: '#81900f',
                            overflowY: 'auto'
                        }}
                            id="rates">
                        </div>
                    </Col>

                    <Col xs={12} className='text-center'>
                        <Button type='submit' disabled={fetching}>
                            Add shipper&nbsp;
                            {fetching && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
