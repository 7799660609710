import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { Container, Button, Table, Spinner, Overlay, Tooltip } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchAllUsers } from '../../app/features/customers/customerSlice'
import { refreshIcon, sortIcon, editIcon, sortAscIcon, sortDescIcon, addIcon, userIcon, copyIcon } from '../../FontAwesome'
import Loader from '../Loader'
import RegisterCustomer from './RegisterCustomer'

export default function Customers() {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { fetching, allAppUsers } = useSelector(state => state.customer)

    const [searchString, setSearchString] = useState('')
    const [sortType, setSortType] = useState("updatedAt-desc")
    const [sortedResults, setSortedResults] = useState(allAppUsers ? allAppUsers : [])
    const [openedRegisterModal, setOpenRegisterModal] = useState(false)

    const handleSort = (type) => {
        const order = sortType.split("-")[1]
        if (order === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (order === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        const [attr, type] = sortType.split("-")
        let temp = [...allAppUsers]
        if (attr === "updatedAt" || attr === "createdAt") {
            if (type === "asc") {
                temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                console.log(temp)
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) =>
                    new Date(b[attr]) - new Date(a[attr])
                )
                setSortedResults(temp)
            }
        } else {
            if (type === "asc") {
                temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                setSortedResults(temp)
            }
        }
    }, [sortType, fetching, allAppUsers])


    useEffect(() => {
        dispatch(fetchAllUsers())
    }, [])

    useEffect(() => {
        let temp = []
        allAppUsers.map(res => {
            if (res.contact.includes(searchString)) {
                temp.push(res)
            } else if (res.email?.includes(searchString)) {
                temp.push(res)
            } else if (res.username?.includes(searchString)) {
                temp.push(res)
            }
        })
        setSortedResults(temp)
        setSortType('updatedAt-desc')
    }, [searchString])

    const copyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
    }

    if (fetching) return (
        <Loader />
    )

    return (
        <Container className='mt-3'>
            <hr />
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Button variant="primary" onClick={() => setOpenRegisterModal(true)}>
                    <span>{userIcon}</span>&nbsp;
                    Register a user
                </Button>
                <Button variant="secondary" onClick={e => { dispatch(fetchAllUsers()) }}>
                    <span>{refreshIcon}</span>&nbsp;
                    Refresh
                </Button>
            </div>
            <div>
                <input type="text" className='form-control' placeholder='Search user' value={searchString} onChange={e => setSearchString(e.currentTarget.value)} />
            </div>
            <LoadingOverlay
                active={false}
                spinner
                text='Disabling.....'
            >
                <div style={{ height: '100%', overflowY: 'auto' }}>
                    <Table striped bordered hover responsive className='mt-3'>
                        <thead style={{ verticalAlign: 'middle' }}>
                            <tr>
                                <th>#</th>
                                <th>UID</th>
                                <th>
                                    Username&nbsp;
                                    {sortType.split("-")[0] !== "username" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("username")}>{sortIcon}</span>}
                                    {
                                        (sortType.split("-")[0] === "username" && sortType.split("-")[1] === "asc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("username")}>{sortAscIcon}</span>
                                    }
                                    {
                                        (sortType.split("-")[0] === "username" && sortType.split("-")[1] === "desc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("username")}>{sortDescIcon}</span>
                                    }
                                </th>
                                <th>Email</th>
                                <th>Contact</th>
                                <th>Blocked</th>
                                <th>
                                    Created At&nbsp;
                                    {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                                    {
                                        (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                                    }
                                    {
                                        (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                                    }
                                </th>
                                <th>
                                    Updated At&nbsp;
                                    {sortType.split("-")[0] !== "updatedAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortIcon}</span>}
                                    {
                                        (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "asc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortAscIcon}</span>
                                    }
                                    {
                                        (sortType.split("-")[0] === "updatedAt" && sortType.split("-")[1] === "desc") &&
                                        <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortDescIcon}</span>
                                    }
                                    {/* <span style={{ cursor: 'pointer' }} onClick={() => handleSort("updatedAt")}>{sortIcon}</span> */}
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortedResults.map((appUser, index) => {
                                    const createdAtDateString = new Date(appUser.createdAt);
                                    const createdAtDate = createdAtDateString.toDateString() + ", " + createdAtDateString.toLocaleTimeString();
                                    const updatedAtDateString = new Date(appUser.updatedAt);
                                    const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {
                                                    appUser.blocked ?
                                                        <FontAwesomeIcon style={{ color: 'red' }} icon={faCircleExclamation} /> :
                                                        <FontAwesomeIcon style={{ color: 'green' }} icon={faCircleCheck} />
                                                }
                                            </td>
                                            <td>
                                                {appUser.uid}&nbsp;
                                                <span style={{ cursor: 'pointer' }} onClick={() => copyText(appUser.uid)}>{copyIcon}</span>
                                            </td>
                                            <td>{appUser.username}</td>
                                            <td>{appUser.email}</td>
                                            <td>{appUser.contact}</td>
                                            <td>{appUser.blocked.toString()}</td>
                                            <td>{createdAtDate}</td>
                                            <td>{updatedAtDate}</td>
                                            <td>
                                                <Button variant="primary" onClick={() => navigate(`/customer/${appUser.uid}`)}>{editIcon}</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </LoadingOverlay>
            <RegisterCustomer show={openedRegisterModal} handleClose={() => setOpenRegisterModal(false)} />
        </Container>
    )
}
