import { checkLogin } from "../../../utils/firebase";

const ecsBaseUrl = process.env.REACT_APP_ECS_BASE_URL

export const fetchAllAPSRTCOrdersAPI = async (queryParams) => {
    let normalQueryParams = queryParams;
    let queryString = Object.keys(normalQueryParams)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(normalQueryParams[k]))
        .join('&');

    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

        const res = await fetch(`${ecsBaseUrl}/get-all-orders?` + queryString, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': "Bearer " + (authToken ? authToken : ''),
                "stakeholder": "legacy-admin"
            },
            // queryParams: queryParams
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const fetchAllAPSRTCRequestOrdersAPI = async (queryParams) => {
    let normalQueryParams = queryParams;
    let queryString = Object.keys(normalQueryParams)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(normalQueryParams[k]))
        .join('&');

    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })

        const res = await fetch(`${ecsBaseUrl}/get-all-saas-request-orders?` + queryString, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': "Bearer " + (authToken ? authToken : ''),
                "stakeholder": "legacy-admin"
            },
            // queryParams: queryParams
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const updateApsrtcOrderAPI = async (updateBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${ecsBaseUrl}/update-saas-order`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'authorization': "Bearer " + (authToken ? authToken : ''),
                "stakeholder": "legacy-admin"
            },
            body: JSON.stringify(updateBody)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}

export const updateRequestApsrtcOrderAPI = async (updateBody) => {
    try {
        let authToken = '';
        await checkLogin().then((res) => { authToken = res.stsTokenManager.accessToken })
        const res = await fetch(`${ecsBaseUrl}/update-saas-request-order`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'authorization': "Bearer " + (authToken ? authToken : ''),
                "stakeholder": "legacy-admin"
            },
            body: JSON.stringify(updateBody)
        }).then(res => res.json())
        return res
    } catch (error) {
        console.log(error);
    }
}