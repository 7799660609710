import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Toast, ToastContainer, Pagination } from 'react-bootstrap'
import { addIcon, refreshIcon, copyIcon, sortAscIcon, sortDescIcon, sortIcon, phoneIcon, disableIcon, editIcon, deleteIcon } from '../../FontAwesome';
import AdminDetailsModal from './AdminDetails';
import { getAllAdminUserAPI } from '../../app/api/auth';
import { deleteAdmin, getAllAdmins } from '../../app/features/user/adminUserSlice';
import VoiceCall from '../Misc/VoiceCall';

function AdminRow({ adminUser, copyText }) {
    const dispatch = useDispatch();

    const { updateFetching: fetching } = useSelector(state => state.newAdminUser);

    const [showEditAdminModal, setShowEditAdminModal] = useState(false)

    const [numberToCall, setNumberToCall] = useState('')
    const [showVoiceCallModal, setShowVoiceCallModal] = useState(false)

    const createdAtDateString = new Date(adminUser.createdAt);
    const createdAtDate = createdAtDateString.toDateString() + ", " + createdAtDateString.toLocaleTimeString();
    const updatedAtDateString = new Date(adminUser.updatedAt);
    const updatedAtDate = updatedAtDateString.toDateString() + ", " + updatedAtDateString.toLocaleTimeString();


    const deleteAdminHandler = () => {
        if (confirm(`Confirm you want to delete Admin ${adminUser?.username}`)) {
            const deletionPayload = {
                uid: adminUser.uid
            }
            dispatch(deleteAdmin(deletionPayload))
                .then(res => {
                    console.log(res)
                    if (res.error) {
                        alert(res?.payload ? res.payload : 'Internal server error')
                    } else {
                        alert(`Successfully deleted ${adminUser.username}`);
                    }
                })
        }
    }


    return (
        <>
            <tr>
                <td>
                    {adminUser?.uid}&nbsp;
                    <span style={{ cursor: 'pointer' }} onClick={() => copyText(adminUser?.uid)}>{copyIcon}</span>
                    <hr />
                    {/* <span className='fw-bold'>Active</span>: */}
                    {/* <Form.Check type="switch" value={isUserActive} checked={isUserActive} onChange={(e => updateUserActiveState(e))} disabled={fetching} /> */}
                </td>
                <td>
                    {adminUser?.displayName} <hr />
                    <span style={{ fontWeight: 'bold' }}>Email</span>: {adminUser?.email} <hr />
                    <span style={{ fontWeight: 'bold' }}>Phone</span>: {adminUser?.phoneNumber}&nbsp;
                    {adminUser?.phoneNumber && <span style={{ cursor: 'pointer' }} onClick={() => { setNumberToCall(adminUser?.phoneNumber); setShowVoiceCallModal(true) }}>{phoneIcon}</span>}
                </td>
                <td>{createdAtDate}</td>
                <td>
                    <Button variant="primary" onClick={() => setShowEditAdminModal(true)} disabled={fetching}>
                        {editIcon}&nbsp;Edit
                    </Button>
                    <Button type='button' variant="danger" className='ms-2' onClick={deleteAdminHandler} disabled={fetching}>
                        {deleteIcon}&nbsp;Delete
                    </Button>
                    {/* <Button type='button' variant="success" className='ms-2' onClick={() => setShowFundModal(true)}>
                        {walletIcon}&nbsp;Add Funds
                    </Button>
                    <br />
                    <Button type='button' variant="info" className='mt-2' onClick={() => setShowTransactions(true)}>
                        See transactions
                    </Button> */}
                </td>
            </tr>
            <VoiceCall show={showVoiceCallModal} handleClose={() => setShowVoiceCallModal(false)} numberToCall={numberToCall} />
            {showEditAdminModal && <AdminDetailsModal show={showEditAdminModal} handleClose={() => setShowEditAdminModal(false)} isEdit={true} adminDetails={adminUser} />}
            {/* {showOrders && <Orders show={showOrders} handleClose={() => setShowOrders(false)} />}
            {showFundModal && <RechargeWallet show={showFundModal} handleClose={() => setShowFundModal(false)} businessUserUID={adminUser?.uid} />}
            {showTransactions && <WalletTransactionsList show={showTransactions} handleClose={() => setShowTransactions(false)} businessUserUID={adminUser?.uid} />} */}
        </>
    )
}


export default function AdminList() {
    const dispatch = useDispatch();

    const { getAllUserFetching, allAdmins, totalPages } = useSelector(state => state.newAdminUser)

    const [page, setPage] = useState(1)

    const [openNewAdminModal, setOpenNewAdminModal] = useState(false);
    const [sortedResults, setSortedResults] = useState([])
    const [sortType, setSortType] = useState("createdAt-desc")

    const [isTextCopied, setIsTextCopied] = useState(false);

    const copyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        setIsTextCopied(true)
        setTimeout(() => {
            setIsTextCopied(false)
        }, 3000);
    }


    useEffect(() => {
        dispatch(getAllAdmins({ page }))
    }, [page])


    useEffect(() => {
        setSortedResults(allAdmins)
    }, [allAdmins])


    const handleSort = (type) => {
        const user = sortType.split("-")[1]
        if (user === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (user === "asc") {
            setSortType(type + "-" + "desc")
        }
    }


    useEffect(() => {
        const [attr, type] = sortType.split("-")
        let temp = [...sortedResults]
        if (attr === "updatedAt" || attr === "createdAt") {
            if (type === "asc") {
                temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) =>
                    new Date(b[attr]) - new Date(a[attr])
                )
                setSortedResults(temp)
            }
        } else {
            if (type === "asc") {
                temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                setSortedResults(temp)
            }
        }
    }, [sortType])

    return (
        <div style={{ padding: '30px' }}>
            <div className='d-flex justify-content-md-between'>
                <h4>Support Admins</h4>
                <div>
                    <Button onClick={() => setOpenNewAdminModal(true)}>{addIcon}&nbsp;Add new admin</Button>
                    <Button onClick={() => dispatch(getAllAdmins({ page }))} className='ms-2' variant='secondary' disabled={getAllUserFetching}>{refreshIcon}&nbsp;Refresh</Button>
                </div>
            </div>
            <hr />
            {/* <div>
                <input type="text" className='form-control' placeholder='Search user' value={searchString} onChange={e => setSearchString(e.currentTarget.value)} />
            </div> */}
            {
                isTextCopied && <ToastContainer position='top-center'>
                    <Toast bg='info' delay={3000} autohide style={{ margin: '20px', fontSize: '20px', textAlign: 'center' }}>
                        <Toast.Body>Text Copied</Toast.Body>
                    </Toast>
                </ToastContainer>
            }

            <Pagination style={{ marginRight: '20px' }}>
                <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || getAllUserFetching} />
                <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || getAllUserFetching} />
                {page - 2 > 0 && <Pagination.Item disabled={getAllUserFetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                {page - 1 > 0 && <Pagination.Item disabled={getAllUserFetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                <Pagination.Item active>{page}</Pagination.Item>
                {totalPages >= page + 1 && <Pagination.Item disabled={getAllUserFetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                {totalPages >= page + 2 && <Pagination.Item disabled={getAllUserFetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || getAllUserFetching} />
                <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || getAllUserFetching} />
            </Pagination>


            <Table striped bordered hover responsive className='mt-3'>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th className='col-3'>UID</th>
                        <th className='col-3'>Admin Details</th>
                        <th className='col-3'>
                            Created At&nbsp;
                            {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                            }
                        </th>
                        <th className='col-3'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getAllUserFetching ? "Loading ....." :
                            sortedResults && sortedResults?.map((adminUser, index) => {
                                return (
                                    <AdminRow adminUser={adminUser} copyText={copyText} key={index} />
                                )
                            })
                    }
                </tbody>
            </Table>


            <Pagination style={{ marginRight: '20px' }}>
                <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || getAllUserFetching} />
                <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || getAllUserFetching} />
                {page - 2 > 0 && <Pagination.Item disabled={getAllUserFetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                {page - 1 > 0 && <Pagination.Item disabled={getAllUserFetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                <Pagination.Item active>{page}</Pagination.Item>
                {totalPages >= page + 1 && <Pagination.Item disabled={getAllUserFetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                {totalPages >= page + 2 && <Pagination.Item disabled={getAllUserFetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || getAllUserFetching} />
                <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || getAllUserFetching} />
            </Pagination>

            {openNewAdminModal && <AdminDetailsModal show={openNewAdminModal} handleClose={() => setOpenNewAdminModal(false)} />}

        </div>
    )
}
