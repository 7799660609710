import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Modal, Button, Form, Spinner, Row, Col, InputGroup, Alert } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createAgentOrg, getAllAgents } from '../../app/features/saas/agent/agentSlice';

function AddNewAgent({ useFormControl: control }) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <Row>
                <Col sm={6}>
                    <Controller
                        control={control}
                        name="payload.name"
                        render={({ field: { onChange, value } }) => (
                            <Form.Group className="mb-3" controlId="Agent Name">
                                <Form.Label>Agent name</Form.Label>
                                <Form.Control type="text" value={value} onChange={onChange} placeholder="Agent Name" required />
                            </Form.Group>
                        )}
                    />
                </Col>

                <Col sm={6}>
                    <Controller
                        control={control}
                        name="payload.contact"
                        render={({ field: { onChange, value } }) => (
                            <Form.Group className="mb-3" controlId="contact">
                                <Form.Label>Contact</Form.Label>
                                <Form.Control type="text" onChange={onChange} value={value} placeholder="Agent Contact details" required />
                            </Form.Group>
                        )}
                    />
                </Col>

                <Col sm={6}>
                    <Controller
                        control={control}
                        name="payload.email"
                        render={({ field: { onChange, value } }) => (
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" value={value} onChange={onChange} placeholder="Email" required />
                            </Form.Group>
                        )}
                    />
                </Col>

                <Col sm={6}>
                    <Controller
                        control={control}
                        name="payload.password"
                        render={({ field: { onChange, value } }) => (
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <InputGroup>
                                    <Form.Control type={showPassword ? "text" : "password"} value={value} onChange={onChange} placeholder="Password" required />
                                    <InputGroup.Text id="btnGroupAddon" style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}>
                                        {!showPassword && <FontAwesomeIcon icon={faEye} />}
                                        {showPassword && <FontAwesomeIcon icon={faEyeSlash} />}
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        )}
                    />
                </Col>

                <Col sm={6}>
                    <Controller
                        control={control}
                        name="payload.organizationData.companyDisplayName"
                        render={({ field: { onChange, value } }) => (
                            <Form.Group className="mb-3" controlId="companyDisplayName">
                                <Form.Label>Agent Organization Name</Form.Label>
                                <Form.Control type="text" onChange={onChange} value={value} placeholder="Agent Org Name" required />
                            </Form.Group>
                        )}
                    />
                </Col>

            </Row>
        </>
    )
}

export default function AddNewAgentModal({ show, handleClose }) {
    const dispatch = useDispatch();

    const { createAgentFetching, errorMessage } = useSelector(state => state.saasAgent);


    const { handleSubmit, watch, control, getValues } = useForm({
        defaultValues: {
            orgType: "agent",
            payload: {
                name: "",
                contact: "",
                email: "",
                password: "",
                organizationData: {
                    //Required
                    companyDisplayName: "",
                    enabledShippers: [
                        {
                            active: true,
                            shipperUid: "apsrtc"
                        }
                    ]
                    //Other organization data if applicable such as hubs data, pricing, lm agent, discounts, delivery methods, etc
                }
            }
        }
    })

    const createNewAgent = (data) => {
        console.log(data)
        dispatch(createAgentOrg(data))
            .then(res => {
                console.log(res)
                if (!res?.error) {
                    alert('Agent created Successfully')
                    dispatch(getAllAgents());
                    handleClose();
                }
            })
            .catch(err => {
                console.log(err)
                alert('Something went wrong. Contact support')
            })
    }


    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create new Agent</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(createNewAgent)}>
                    <Modal.Body>
                        {
                            errorMessage && <Alert variant='danger'>
                                {errorMessage}
                            </Alert>
                        }
                        <AddNewAgent useFormControl={control} />
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: 'flex-start' }}>
                        <Button type='submit' variant="primary" disabled={createAgentFetching}>
                            Submit&nbsp;
                            {createAgentFetching && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
