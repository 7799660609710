import { PDFDownloadLink, PDFViewer, usePDF } from '@react-pdf/renderer'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Loader from './Loader'

export default function PDFPreview({ pdfTemplate, show, handleClose, fileName = new Date().getTime() }) {

    return (
        <>
            <Modal show={show} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: '70vh' }}>
                        {/* {instance.loading?'loading':'done'} */}
                        {/* eslint-disable-next-line react/no-children-prop  */}
                        {< PDFViewer children={pdfTemplate} width='100%' style={{ height: 'inherit' }} />}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <PDFDownloadLink document={pdfTemplate} fileName={fileName}>
                        {({ blob, url, loading, error }) =>
                            loading ? <Button variant='success' disabled>Generating PDF...</Button> : <Button variant='success'>Download</Button>
                        }
                    </PDFDownloadLink>
                    {/* {!instance.loading && <Button type='sucess'><PDFDownloadLink document={pdfTemplate} fileName={fileName}>Download</PDFDownloadLink></Button>} */}
                    <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
