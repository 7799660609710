import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import SHIPMANTRA_LOGO from '../../assets/images/shipmantra_logo_with_name.png'
import moment from 'moment';
import Inter from '../../assets/fonts/Inter-VariableFont_slnt,wght.ttf'

Font.register({
    family: 'Inter',
    format: "truetype",
    src: Inter
});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Inter'
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    details: {
        fontSize: '12px',
        lineHeight: '1.2px'
    },
    subHeading: {
        fontSize: '20px',
        fontWeight: 'bold'
    },
    tableHead: {
        borderRight: "1px solid #EDEDED",
        padding: "5px 2px",
        backgroundColor: "#744ED5",
        color: "white",
        verticalAlign: "middle",
        fontWeight: "bold",
        flex: 1,
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    table: {
        borderTop: "1px solid #EDEDED",
        borderLeft: "1px solid #EDEDED",
        borderCollapse: "none",
        display: "flex",
        flexDirection: "column",
        marginTop: '40px'
    },
    tableCell: {
        padding: "5px 2px",
        // borderLeft: "1px solid black",
        borderRight: "1px solid #EDEDED",
        // textAlign: "center",
        display: "table-cell",
        verticalAlign: "middle",
        flex: 1,
        fontSize: "12px",
    },
    tableRow: {
        // borderTop: "1px solid black",
        borderBottom: "1px solid #EDEDED",
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        fontSize: "12px",
    },
    gstDetailsContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    gstDetails: {
        padding: '5px',
        border: '1px solid #E7E7E7',
        borderRadius: '5px',
        marginBottom: '5px',
        width: '200px'
        // alignSelf: 'center'
        // width: '100%'
    },
    gstDetailsLabels: {
        fontWeight: 'extrabold',
        padding: '5px',
        marginBottom: '8px',
        alignSelf: 'end'
    }
})

export default function InvoicePDFTemplate({ data }) {

    return (
        <Document>
            <Page style={styles.page}>
                <View style={{ padding: '30px' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Image src={SHIPMANTRA_LOGO} style={{ height: '25px', width: '210px', marginBottom: '10px' }} />
                        <Text style={{ ...styles.subHeading, textTransform: 'uppercase' }}>{data.docType === 'invoice' ? 'Tax invoice' : 'Quotation'}</Text>
                    </View>
                    <View style={styles.headerContainer}>
                        <View style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', maxWidth: '50%' }}>
                            <Text style={{ ...styles.details, lineHeight: '1.2px' }}>{data.companyDetails.companyName}</Text>
                            <Text style={{ ...styles.details, lineHeight: '1.2px' }}>{data.companyDetails.companyGST}</Text>
                            <Text style={{ ...styles.details, lineHeight: '1.2px' }}>{data.companyDetails.companyCIN}</Text>
                            <Text style={{ ...styles.details, lineHeight: '1.2px' }}>{data.companyDetails.companyAddress}</Text>
                            <Text style={{ ...styles.details }}>www.shipmantra.com</Text>
                            <Text style={{ ...styles.details }}><Text style={{ display: 'inline-block' }}>Email:&nbsp;</Text>support@shipmantra.com</Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'column', maxWidth: '50%' }}>
                            {/* <Text style={{ ...styles.subHeading, textTransform: 'capitalize' }}>{data.docType}</Text> */}
                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                <View style={{ marginRight: '20px', fontSize: '12px', fontWeight: 'bold' }}>
                                    <Text style={{ lineHeight: '1.2px' }}>Invoice #</Text>
                                    <Text style={{ lineHeight: '1.2px' }}>Invoice date</Text>
                                    <Text style={{ lineHeight: '1.2px' }}>Due date</Text>
                                    <Text style={{ lineHeight: '1.2px' }}>Expiry date</Text>
                                </View>
                                <View style={{ fontSize: '12px' }}>
                                    <Text style={{ ...styles.details, lineHeight: '1.2px' }}>{data.invoiceNo}</Text>
                                    <Text style={{ ...styles.details, lineHeight: '1.2px' }}>{moment(data.invoiceDate).format("DD-MM-YYYY")}</Text>
                                    <Text style={{ ...styles.details, lineHeight: '1.2px' }}>{moment(data.dueDate).format("DD-MM-YYYY")}</Text>
                                    <Text style={{ ...styles.details, lineHeight: '1.2px' }}>{moment(data.expiryDate).format("DD-MM-YYYY")}</Text>
                                </View>
                            </View>
                        </View>

                    </View>

                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: '40px', justifyContent: 'space-between' }}>
                        <View style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', maxWidth: '50%', marginRight: '20px' }}>
                            <Text style={{ ...styles.details, textTransform: 'capitalize' }}>{data.senderDetails.senderType.replace("-", " ")}</Text>
                            <Text style={styles.details}>{data.senderDetails.senderName}</Text>
                            <Text style={styles.details}>{data.senderDetails.senderContact}</Text>
                            <Text style={{ ...styles.details, overflowWrap: 'word-break' }}>{data.senderDetails.senderAddress}</Text>
                            {
                                data.senderDetails.senderType === 'seller' && <>
                                    <Text style={styles.details}>{data.senderDetails.sellerCompanyName}</Text>
                                    <Text style={styles.details}>{data.senderDetails.sellerCompanyGST}</Text>
                                </>
                            }
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', maxWidth: '50%' }}>
                            <Text style={{ ...styles.details, textTransform: 'capitalize' }}>{data.receiverDetails.receiverType.replace("-", " ")}</Text>
                            <Text style={styles.details}>{data.receiverDetails.receiverName}</Text>
                            <Text style={styles.details}>{data.receiverDetails.receiverContact}</Text>
                            <Text style={{ ...styles.details, overflowWrap: 'word-break' }}>{data.receiverDetails.receiverAddress}</Text>
                            {
                                data.receiverDetails.receiverType === 'buyer' && <>
                                    <Text style={styles.details}>{data.receiverDetails.buyerCompanyName}</Text>
                                    <Text style={styles.details}>{data.receiverDetails.buyerCompanyGST}</Text>
                                </>
                            }
                        </View>
                    </View>

                    {/* Table description */}
                    <View
                        style={styles.table}
                    >
                        <View
                            style={styles.tableRow}
                        >
                            <Text style={styles.tableHead}>Description</Text>
                            <Text style={styles.tableHead}>SAC code</Text>
                            <Text style={styles.tableHead}>Quantity</Text>
                            <Text style={styles.tableHead}>Rate</Text>
                            <Text style={styles.tableHead}>Amount</Text>
                        </View>

                        {/* table body */}
                        {
                            data.invoiceDetails.map((details, index) => {
                                return (
                                    <View key={index}
                                        style={styles.tableRow}
                                    >
                                        <Text style={styles.tableCell}>{details.desc}</Text>
                                        <Text style={styles.tableCell}>{details.sacCode}</Text>
                                        <Text style={styles.tableCell}>{details.qty}</Text>
                                        <Text style={styles.tableCell}>{details.rate}</Text>
                                        <Text style={styles.tableCell}>{details.totalAmount.toFixed(2)}</Text>
                                    </View>
                                )
                            })
                        }
                    </View>

                    {/* Gst details */}
                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', marginLeft: 'auto', fontSize: '14px' }}>
                        <View style={{ marginRight: '20px', fontSize: '12px', fontWeight: 'bold', display: 'flex', flexDirection: 'column' }}>
                            <Text style={{ ...styles.details, ...styles.gstDetailsLabels }}>IGST(%)</Text>
                            <Text style={{ ...styles.details, ...styles.gstDetailsLabels }}>CGST(%)</Text>
                            <Text style={{ ...styles.details, ...styles.gstDetailsLabels }}>SGST(%)</Text>
                            <Text style={{ ...styles.details, ...styles.gstDetailsLabels }}>Platform fee(Rs.)</Text>
                            <Text style={{ ...styles.details, ...styles.gstDetailsLabels }}>Discount(Rs.)</Text>
                            <Text style={{ ...styles.details, ...styles.gstDetailsLabels }}>Total amount(Rs.)</Text>
                        </View>
                        <View style={{ fontSize: '14px', display: 'flex', flexDirection: 'column' }}>
                            <Text style={{ ...styles.details, ...styles.gstDetails }}>{data.IGST}</Text>
                            <Text style={{ ...styles.details, ...styles.gstDetails }}>{data.CGST}</Text>
                            <Text style={{ ...styles.details, ...styles.gstDetails }}>{data.SGST}</Text>
                            <Text style={{ ...styles.details, ...styles.gstDetails }}>{data.platformFee}</Text>
                            <Text style={{ ...styles.details, ...styles.gstDetails }}>{data.discount}</Text>
                            <Text style={{ ...styles.details, ...styles.gstDetails }}>{data.totalAmount}</Text>
                        </View>
                    </View>

                    {/* Additional notes  */}
                    {
                        data.additionalNotes &&
                        <View style={{ marginTop: '40px' }}>
                            <Text style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '8px', color: '#744ED5' }}>Additional notes</Text>
                            <Text style={styles.details}>{data.additionalNotes}</Text>
                        </View>
                    }

                    {/* Terms and conditions */}
                    {
                        data.terms &&
                        <View style={{ marginTop: '40px' }}>
                            <Text style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '8px', color: '#744ED5' }}>Terms and conditions</Text>
                            <Text style={styles.details}>{data.terms}</Text>
                        </View>
                    }
                </View>
            </Page >
        </Document >
    )
}
