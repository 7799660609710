import React from 'react'
import Charges from '../../Charges'

export default function PanIndiaB2BExpressCharge() {
    return (
        <div style={{ padding: '30px' }}>
            <h4>Pan India b2b platform charges</h4>
            <hr />
            <Charges serviceType='panindia_express_b2b' />
        </div>
    )
}
