import React, { useEffect, useState } from 'react'
import { Form, Modal, Row, Col, Button, Card } from 'react-bootstrap'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import MapInput from '../MapInput'
import { useDispatch, useSelector } from 'react-redux'
import { createApsrtcCity, editApsrtcCity } from '../../app/features/apsrtc_booking/apsrtcCitySlice'
import { unwrapResult } from '@reduxjs/toolkit'
// const axios = require('axios');


export default function CityDetails({ show, handleClose, isEdit = false, cityDetails }) {
    const dispatch = useDispatch();

    const { cities, fetching } = useSelector(state => state.apsrtcCity);

    const [selectAllRoutesCheckBox, setSelectAllRoutesCheckbox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedCityRoutes, setSelectedCityRoutes] = useState([]);

    const { handleSubmit, control, setValue, getValues, reset, formState: { errors } } = useForm({
        defaultValues: {
            city_id: isEdit ? cityDetails?.city_id : '',
            // sm_hub: isEdit ? cityDetails?.sm_hub : [
            //     {
            //         contact: {
            //             name: '',
            //             phone: ''
            //         },
            //         address: {
            //             flatno: '',
            //             building: '',
            //             fromGoogle: {
            //                 lat: '',
            //                 lng: '',
            //                 address: '',
            //                 city: '',
            //                 state: '',
            //                 pincode: '',
            //                 country: ''
            //             }
            //         },
            //         serviceableKM: ''
            //     }
            // ],
            station: isEdit ? cityDetails?.station : {
                contact: {
                    name: '',
                    phone: ''
                },
                address: {
                    flatno: '',
                    building: '',
                    fromGoogle: {
                        lat: '',
                        lng: '',
                        address: '',
                        city: '',
                        state: '',
                        pincode: '',
                        country: ''
                    }
                },
                loginCounterID: undefined,
                recieverCounterID: undefined,
                counterName: ''
            },
            // routes: isEdit ? cityDetails?.routes : [],
            active: isEdit ? cityDetails?.active : true,
            bounds: isEdit ? cityDetails?.bounds : {
                sw: {
                    lat: '',
                    lng: ''
                },
                ne: {
                    lat: '',
                    lng: ''
                }
            }
        }
    })

    useEffect(() => {
        if (isEdit && cityDetails?.routes?.length) {
            setSelectedCityRoutes(cityDetails.routes)
        }
    }, [isEdit, cityDetails])


    // const { fields, append, remove } = useFieldArray({
    //     control,
    //     name: 'sm_hub'
    // })

    // const { fields: routes_fields, append: append_routes, remove: remove_routes } = useFieldArray({
    //     control,
    //     name: 'routes'
    // })

    // const addSmHub = () => {
    //     append({
    //         contact: {
    //             name: '',
    //             phone: ''
    //         },
    //         address: {
    //             flatno: '',
    //             building: '',
    //             fromGoogle: {
    //                 lat: '',
    //                 lng: '',
    //                 address: '',
    //                 city: '',
    //                 state: '',
    //                 pincode: '',
    //                 country: ''
    //             }
    //         }
    //     })
    // }

    const handleRouteChange = async (event, destination_city_id) => {
        const isSelected = selectedCityRoutes.some(city => city.city_id === destination_city_id)

        if (!isSelected) {
            const { google } = window;
            const service = new google.maps.DistanceMatrixService();
            let distance = 0;

            await service.getDistanceMatrix(
                {
                    origins: [getValues('city_id')],
                    destinations: [destination_city_id],
                    travelMode: 'DRIVING', // You can change this based on your needs
                    unitSystem: google.maps.UnitSystem.METRIC,
                },
                (response, status) => {
                    if (status === 'OK' && response.rows[0].elements[0].status === 'OK') {
                        distance = Math.ceil(response.rows[0].elements[0].distance.value / 1000);
                        console.log(`Distance from ${getValues('city_id')} to ${destination_city_id}: ${distance}KM`);
                    } else {
                        console.error('Error calculating distance:', status);
                    }
                }
            );

            setSelectedCityRoutes([
                ...selectedCityRoutes,
                {
                    city_id: destination_city_id,
                    distance: distance
                }
            ])

            // append_routes({
            //     city_id: destination_city_id,
            //     distance: distance
            // })
        } else {
            const updatedCities = selectedCityRoutes.filter(city => city.city_id !== destination_city_id);
            setSelectedCityRoutes(updatedCities);
        }
    }

    const handleSelectAllRoutes = async (event) => {
        if (event.target?.checked) {
            setLoading(true)
            let tempCityRoutes = []
            await Promise.all(
                cities?.map(async city => {
                    const { google } = window;
                    const service = new google.maps.DistanceMatrixService();
                    let distance = 0;

                    await service.getDistanceMatrix(
                        {
                            origins: [getValues('city_id')],
                            destinations: [city.city_id],
                            travelMode: 'DRIVING', // You can change this based on your needs
                            unitSystem: google.maps.UnitSystem.METRIC,
                        },
                        (response, status) => {
                            if (status === 'OK' && response.rows[0].elements[0].status === 'OK') {
                                distance = Math.ceil(response.rows[0].elements[0].distance.value / 1000);
                                console.log(`Distance from ${getValues('city_id')} to ${city.city_id}: ${distance}KM`);
                            } else {
                                console.error('Error calculating distance:', status);
                            }
                        }
                    );

                    tempCityRoutes.push({
                        city_id: city.city_id,
                        distance: distance
                    })

                })
            ).finally(() => {
                setLoading(false)
            })

            setSelectedCityRoutes(tempCityRoutes)

        } else {
            setSelectedCityRoutes([])
        }
    }

    const handleCityDetails = (data) => {
        const updatedData = {
            ...data,
            routes: selectedCityRoutes
        }
        console.log(updatedData)
        if (isEdit) {
            dispatch(editApsrtcCity(updatedData))
                .then(unwrapResult)
                .then(() => {
                    alert('City details updated successfully')
                })
                .catch(err => {
                    alert(err ? err : 'Some error occured')
                })
        } else {
            dispatch(createApsrtcCity(updatedData))
                .then(unwrapResult)
                .then(() => {
                    alert('City created successfully')
                    // reset()
                })
                .catch(err => {
                    alert(err ? err : 'Some error occured')
                })
        }
    }


    return (
        <>
            <Modal show={show} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Edit' : 'Add'} City details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(handleCityDetails)} style={{ padding: '20px' }}>
                        <Card body>
                            <Row>
                                {/* City id */}
                                <Col sm={6}>
                                    <Controller
                                        control={control}
                                        name="city_id"
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Group className="mb-3" controlId="city_id">
                                                <Form.Label className='fw-bolder'>City id</Form.Label>
                                                <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="Enter city id" required />
                                                {errors.city_id && <div className='error-text'>{errors.city_id.message}</div>}
                                            </Form.Group>
                                        )}
                                    />
                                </Col>

                                {/* Active */}
                                <Col sm={6}>
                                    <Controller
                                        control={control}
                                        name="active"
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Group className="mb-3" controlId="active">
                                                <Form.Label className='fw-bolder'>Active</Form.Label>
                                                <Form.Check
                                                    type="switch"
                                                    checked={value} onChange={onChange}
                                                />
                                                {errors.active && <div className='error-text'>{errors.active?.message}</div>}
                                            </Form.Group>
                                        )}
                                    />
                                </Col>

                                {/* Southwest */}
                                <Col sm={6}>
                                    <Controller
                                        control={control}
                                        name="bounds.sw.lat"
                                        rules={{ validate: value => Number(value) || 'Enter a valid southwest latitude' }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Group className="mb-3" controlId="bounds.sw.lat">
                                                <Form.Label className='fw-bolder'>Southwest lat</Form.Label>
                                                <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="Sw Lat" required />
                                                {errors.bounds?.sw?.lat && <div className='error-text'>{errors.bounds?.sw?.lat?.message}</div>}
                                            </Form.Group>
                                        )}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Controller
                                        control={control}
                                        name="bounds.sw.lng"
                                        rules={{ validate: value => Number(value) || 'Enter a valid southwest longitude' }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Group className="mb-3" controlId="bounds.sw.lng">
                                                <Form.Label className='fw-bolder'>Southwest lng</Form.Label>
                                                <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="Sw lng" required />
                                                {errors.bounds?.sw?.lng && <div className='error-text'>{errors.bounds?.sw?.lng?.message}</div>}
                                            </Form.Group>
                                        )}
                                    />
                                </Col>

                                {/* Northeast */}
                                <Col sm={6}>
                                    <Controller
                                        control={control}
                                        name="bounds.ne.lat"
                                        rules={{ validate: value => Number(value) || 'Enter a valid northeast latitude' }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Group className="mb-3" controlId="bounds.ne.lat">
                                                <Form.Label className='fw-bolder'>Northeast lat</Form.Label>
                                                <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="NE Lat" required />
                                                {errors.bounds?.ne?.lat && <div className='error-text'>{errors.bounds?.ne?.lat?.message}</div>}
                                            </Form.Group>
                                        )}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Controller
                                        control={control}
                                        name="bounds.ne.lng"
                                        rules={{ validate: value => Number(value) || 'Enter a valid northeast longitude' }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Form.Group className="mb-3" controlId="bounds.ne.lng">
                                                <Form.Label className='fw-bolder'>Northeast lng</Form.Label>
                                                <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="NE lng" required />
                                                {errors.bounds?.ne?.lng && <div className='error-text'>{errors.bounds?.ne?.lng?.message}</div>}
                                            </Form.Group>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Card>

                        {/* Apsrtc Station details */}
                        <Card body style={{ margin: '10px 0' }}>
                            <Row>
                                <Form.Label className='fw-bolder'>Apsrtc Station details</Form.Label>
                                <Col sm={12}>
                                    {/* Apsrtc Station Data details */}
                                    <Form.Label className='fw-bolder' style={{ textDecoration: 'underline' }}>Apsrtc Station data</Form.Label>
                                    <Row>
                                        <Col xs={4}>
                                            <Controller
                                                control={control}
                                                rules={{ required: { value: true, message: 'This is a required field' }, validate: value => Number(value) || 'Enter a valid login counter id' }}
                                                name={`station.loginCounterID`}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <Form.Group className="mb-3" controlId={"station_loginCounterID"}>
                                                        <Form.Label>Login counter ID</Form.Label>
                                                        <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="Enter login counter ID" required />
                                                        {errors.station?.loginCounterID && <div className='error-text'>{errors.station?.loginCounterID?.message}</div>}
                                                    </Form.Group>
                                                )}
                                            />
                                        </Col>
                                        {/* <Col xs={4}>
                                            <Controller
                                                control={control}
                                                name={`station.recieverCounterID`}
                                                // rules={{ required: { value: true, message: 'This is a required field' }, validate: value => Number(value) || 'Enter a valid receiver counter id' }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <Form.Group className="mb-3" controlId={"station_recieverCounterID"}>
                                                        <Form.Label>Receiver counter ID</Form.Label>
                                                        <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="Enter receiver counter ID" />
                                                        {errors.station?.recieverCounterID && <div className='error-text'>{errors.station?.recieverCounterID?.message}</div>}
                                                    </Form.Group>
                                                )}
                                            />
                                        </Col> */}
                                        <Col xs={4}>
                                            <Controller
                                                control={control}
                                                name={`station.counterName`}
                                                rules={{ required: { value: true, message: 'This is a required field' } }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <Form.Group className="mb-3" controlId={"station_counterNameD"}>
                                                        <Form.Label>Counter Name</Form.Label>
                                                        <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="Enter counter name" required />
                                                        {errors.station?.counterName && <div className='error-text'>{errors.station?.counterName?.message}</div>}
                                                    </Form.Group>
                                                )}
                                            />
                                        </Col>
                                    </Row>

                                    {/* Contact details */}
                                    <Form.Label className='fw-bolder' style={{ textDecoration: 'underline' }}>Contact details</Form.Label>
                                    <Row>
                                        <Col xs={6}>
                                            <Controller
                                                control={control}
                                                name={`station.contact.name`}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Group className="mb-3" controlId={"station_contact_name_"}>
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="Enter contact name" />
                                                        {errors.station?.contact?.name && <div className='error-text'>{errors.station?.contact?.name?.message}</div>}
                                                    </Form.Group>
                                                )}
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <Controller
                                                control={control}
                                                name={`station.contact.phone`}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Group className="mb-3" controlId={"station_contact_phone_"}>
                                                        <Form.Label >Phone</Form.Label>
                                                        <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="Enter phone number" />
                                                        {errors.station?.contact?.phone && <div className='error-text'>{errors.station?.contact?.phone?.message}</div>}
                                                    </Form.Group>
                                                )}
                                            />
                                        </Col>
                                    </Row>

                                    {/* Address details */}
                                    <Form.Label className='fw-bolder' style={{ textDecoration: 'underline' }}>Address Details</Form.Label>
                                    <Row>
                                        <Col xs={6}>
                                            <Controller
                                                control={control}
                                                name={`station.address.flatno`}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Group className="mb-3" controlId={"station_address_flatno"}>
                                                        <Form.Label>Flatno</Form.Label>
                                                        <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="Enter flatno" required />
                                                        {errors.station?.address?.flatno && <div className='error-text'>{errors.station?.address?.flatno?.message}</div>}
                                                    </Form.Group>
                                                )}
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <Controller
                                                control={control}
                                                name={`station.address.building`}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Group className="mb-3" controlId={"station_address_building"}>
                                                        <Form.Label>Building</Form.Label>
                                                        <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="Enter building" required />
                                                        {errors.station?.address?.building && <div className='error-text'>{errors.station?.address?.building?.message}</div>}
                                                    </Form.Group>
                                                )}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <Controller
                                                control={control}
                                                name={`station.address.fromGoogle.address`}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Address</Form.Label>
                                                        <MapInput setMapData={(mapValue) => setValue(`station.address.fromGoogle`, mapValue)} value={getValues('station.address.fromGoogle.address')} id={"station_google_address"} restrictedCountryCode='in' />
                                                        {errors.station?.address?.fromGoogle?.address && <div className='error-text'>{errors.station?.address?.fromGoogle?.address?.message}</div>}
                                                    </Form.Group>
                                                )}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <Controller
                                                control={control}
                                                name={`station.address.fromGoogle.city`}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="City" required />
                                                        {errors.station?.address?.fromGoogle?.city && <div className='error-text'>{errors.station?.address?.fromGoogle?.city?.message}</div>}
                                                    </Form.Group>
                                                )}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <Controller
                                                control={control}
                                                name={`station.address.fromGoogle.state`}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>State</Form.Label>
                                                        <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="State" required />
                                                        {errors.station?.address?.fromGoogle?.state && <div className='error-text'>{errors.station?.address?.fromGoogle?.state?.message}</div>}
                                                    </Form.Group>
                                                )}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <Controller
                                                control={control}
                                                name={`station.address.fromGoogle.pincode`}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Pincode</Form.Label>
                                                        <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="Pincode" required />
                                                        {errors.station?.address?.fromGoogle?.pincode && <div className='error-text'>{errors.station?.address?.fromGoogle?.pincode?.message}</div>}
                                                    </Form.Group>
                                                )}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <Controller
                                                control={control}
                                                name={`station.address.fromGoogle.country`}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Country</Form.Label>
                                                        <Form.Control ref={ref} type="text" value={value} onChange={onChange} placeholder="Country" required />
                                                        {errors.station?.address?.fromGoogle?.country && <div className='error-text'>{errors.station?.address?.fromGoogle?.country?.message}</div>}
                                                    </Form.Group>
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>

                        <Card body style={{ margin: '10px 0' }}>
                            <Row>
                                {/* Routes */}
                                <Col sm={12}>
                                    <Form.Label>
                                        <span className='fw-bolder'>Routes</span>
                                        <span>
                                            <input
                                                className='mx-2'
                                                value={selectAllRoutesCheckBox}
                                                style={{ textTransform: 'capitalize' }}
                                                // checked={route.city_id ? true : false}
                                                onChange={(e) => { setSelectAllRoutesCheckbox(!selectAllRoutesCheckBox); handleSelectAllRoutes(e) }}
                                                type='checkbox'
                                                id={`select-all-routes`}
                                                name="select-all-routes"
                                            />
                                            <label htmlFor="select-all-routes">Select All</label>
                                        </span>
                                    </Form.Label>
                                    <Row>
                                        {
                                            // routes_fields.map((route, index) => {
                                            cities?.map((city, index) => {
                                                return (
                                                    <Col sm={2} key={index}>
                                                        <Form.Group className="mb-2" controlId={"routes_city_" + index}>
                                                            <Form.Check
                                                                key={index}
                                                                // value={city.city_id}
                                                                style={{ textTransform: 'capitalize' }}
                                                                checked={selectedCityRoutes.some(selectedCity => selectedCity.city_id === city.city_id)}
                                                                // checked={route.city_id ? true : false}
                                                                onChange={(e) => { handleRouteChange(e, city.city_id) }}
                                                                type='checkbox'
                                                                id={`default-checkbox-${city.city_id}`}
                                                                label={`${city.city_id}`}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row >
                                </Col>
                            </Row>
                        </Card>
                        <Button type='submit' disabled={loading || fetching}>{isEdit ? "Update" : "Create"}</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
