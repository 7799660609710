import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addPanIndiaShipper } from '../../app/features/shipper/shipperSlice'
import AmazonS3Upload from '../AmazonS3Upload'

export default function AddNewPanIndiaShipper() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { handleSubmit, control, setValue, formState: { errors } } = useForm({
        defaultValues: {
            company: '',
            address: '',
            phone: '',
            email: '',
            desc: '',
            logoURL: '',
            services: [{ value: '', label: '' }],
            fuelCharges: 0,
            packaging: false,
            doorToDoorDelivery: false,
            tracking: false,
            isBookingApiAvailable: false
        }
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'services'
    })

    const { fetching } = useSelector(state => state.shipper)

    const resetData = () => {
        setValue('company', '')
        setValue('address', '')
        setValue('phone', '')
        setValue('email', '')
        setValue('desc', '')
        setValue('logoURL', '')
        setValue('packaging', false)
        setValue('tracking', false)
        setValue('doorToDoorDelivery', false)
        setValue('isBookingApiAvailable', false)
        setValue('services', [{ value: '', label: '' }])
    }

    const addNewShipper = (data) => {
        console.log(data)
        if (!data.logoURL) {
            alert("Please upload shipper logo")
        }
        else {
            dispatch(addPanIndiaShipper(data))
                .then(() => resetData())
        }
    }

    return (
        <div style={{ padding: '30px' }}>
            <h3 style={{ marginBottom: '30px' }}>Add new Pan India shipper</h3>
            <Button onClick={() => navigate('/shipper/panIndiaShipper')}>All Shippers</Button>
            <hr />
            <Form onSubmit={handleSubmit(addNewShipper)}>
                <Row>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="company"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter company name" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="address"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter company address" />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="phone"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Contact number</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter contact" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" value={value} onChange={onChange} placeholder="Enter email" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="desc"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="desc">
                                    <Form.Label>Desc</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter desc" />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="logoURL"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="logoURL">
                                    <Form.Label>LogoURL</Form.Label>
                                    <AmazonS3Upload bucket='shipmantra-assets' S3Folder='3rd-party-logo' setFileName={onChange} />
                                    {/* <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter company logo url" required /> */}
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs={12} sm={2}>
                        <Controller
                            control={control}
                            name="packaging"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="packaging">
                                    <Form.Label>Packing</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs={12} sm={2}>
                        <Controller
                            control={control}
                            name="tracking"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="tracking">
                                    <Form.Label>Tracking</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs={12} sm={2}>
                        <Controller
                            control={control}
                            name="doorToDoorDelivery"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="doorToDoorDelivery">
                                    <Form.Label>Door to door delivery</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs={12} sm={2}>
                        <Controller
                            control={control}
                            name="isBookingApiAvailable"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="isBookingApiAvailable">
                                    <Form.Label>Booking Api available?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        checked={value} onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <Form.Label>Services</Form.Label>
                        <Row>
                            {
                                fields.map((service, index) => {
                                    return (
                                        <Col xs={4} key={service.id}>
                                            <Controller
                                                control={control}
                                                defaultValue={service.value}
                                                name={`services.${index}.value`}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Group className="mb-2" controlId="services">
                                                        <Form.Select onChange={(data) => { onChange(data); setValue(`services.${index}.label`, data.target.options[data.target.selectedIndex].innerHTML); }} value={value} required>
                                                            <option value="" disabled>Select</option>
                                                            <option value="surface-delivery">Surface delivery</option>
                                                            <option value="express-delivery">Express delivery</option>
                                                            <option value="critical-logistics">Critical logistics</option>
                                                            <option value="pet-logistics">Pet logistics</option>
                                                            <option value="cold-chain">Cold chain</option>
                                                            <option value="packers-and-movers">Packers and movers</option>
                                                            <option value="container">Cross country containers</option>
                                                            <option value="ftl">Full truck Load(FTL)</option>
                                                            <option value="htl">Half truck Load(HTL)</option>
                                                            <option value="ltl">Low truck Load(LTL)</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                )}
                                            />
                                            {fields.length !== 1 && <Button variant='danger' type='button' className='mb-3' onClick={() => remove(index)}>Remove</Button>}
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <Button variant='success' className='mt-2 mb-3' onClick={() => append({ value: '', label: '' })}>Add</Button>
                    </Col>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="fuelCharges"
                            rules={{ validate: value => value >= 0 || 'Value should be greater than equal to 0' }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="fuelCharges">
                                    <Form.Label>Fuel charges (in %)</Form.Label>
                                    <Form.Control type="number" min={0} value={value} onChange={onChange} placeholder="Enter fuel charges" />
                                    {errors.fuelCharges && <div className='error-text'>{errors.fuelCharges.message}</div>}
                                </Form.Group>
                            )}
                        />
                    </Col>
                </Row>
                <hr />
                <Button type='submit' disabled={fetching}>
                    Add shipper&nbsp;
                    {fetching && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />}
                </Button>
            </Form>
        </div>
    )
}
