import { faEye, faEyeSlash, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, Button, Spinner, Form, InputGroup, Alert } from 'react-bootstrap'
import { useFieldArray, useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { deleteIcon } from '../../FontAwesome';
import { createNewAdmin, updateAdmin } from '../../app/features/user/adminUserSlice';

export default function AdminDetailsModal({ show, handleClose, adminDetails, isEdit = false }) {
    const dispatch = useDispatch();
    const { createUserFetching, updateFetching } = useSelector(state => state.newAdminUser);

    console.log(adminDetails)
    const { handleSubmit, control } = useForm({
        defaultValues: {
            displayName: isEdit ? adminDetails?.displayName : '',
            email: isEdit ? adminDetails?.email : '',
            password: '',
            phoneNumber: isEdit ? adminDetails?.phoneNumber : '',
            isSuperAdmin: isEdit ? adminDetails?.isSuperAdmin : false
            // modules: isEdit ? adminDetails?.modules : ['']
        }
    })

    const modulesList = [
        {
            label: 'Admin',
            value: 'admin'
        },
        {
            label: 'Shipmantra C2C',
            value: 'shipmantra_c2c'
        },
        {
            label: 'Business',
            value: 'business'
        },
        {
            label: 'APSRTC',
            value: 'apsrtc'
        },
        {
            label: 'API Monitoring',
            value: 'api_monitoring'
        },
        {
            label: 'Miscellaneous',
            value: 'misc'
        },
        {
            label: 'Sales & Marketing',
            value: 'sales_and_marketing'
        },
        {
            label: 'Daily Sales Report',
            value: 'daily_sales_report'
        }
    ]

    // const { fields: modulesList, append: addModule, remove: removeModule } = useFieldArray({
    //     control,
    //     name: 'modules'
    // })

    const [adminPermissionWarning, setAdminPermissionWarning] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [selectedModules, setSelectedModules] = useState(isEdit ? adminDetails?.modules ? adminDetails?.modules : [] : [])
    const [selectAllModulesCheckbox, setSelectAllModulesCheckbox] = useState(false);


    function addModule() {
        setSelectedModules([...selectedModules, ''])
    }

    function removeModule(index) {
        let tempModules = [...selectedModules];
        tempModules.splice(index, 1)
        setSelectedModules(tempModules)
    }

    function handleModuleChange(value) {
        const isSelected = selectedModules.some(module => module === value);

        if (!isSelected) {
            let tempModules = [...selectedModules]
            // tempModules[index] = event.currentTarget.value;
            tempModules.push(value)
            setSelectedModules(tempModules)
        } else {
            const updatedModules = selectedModules.filter(module => module !== value);
            setSelectedModules(updatedModules);
        }
    }

    function handleSelectAllModules(event) {
        if (event.target?.checked) {
            // setLoading(true)
            let tempModules = []
            modulesList?.map(async module => {
                tempModules.push(module.value)

            })
            setSelectedModules(tempModules)

        } else {
            setSelectedModules([])
        }
    }

    useEffect(() => {
        if (selectedModules?.includes('admin')) {
            setAdminPermissionWarning(true)
        } else {
            setAdminPermissionWarning(false)
        }
    }, [selectedModules])

    useEffect(() => {
        if (isEdit) {
            setSelectedModules(adminDetails?.modules ? adminDetails?.modules : [])
        }
    }, [adminDetails])



    const handleFormSubmit = (data) => {
        if (isEdit) {
            const editAdminData = {
                ...data,
                modules: selectedModules
            }
            console.log(editAdminData)
            dispatch(updateAdmin(editAdminData))
                .then(res => {
                    console.log(res)
                    if (res.error) {
                        alert(res?.payload ? res.payload : 'Internal server error')
                    } else {
                        alert(`Successfully updated support admin`)
                        handleClose();
                    }
                })
                .catch(err => {
                    alert(err?.payload ? err.payload : 'Internal server error')
                })
        }
        else {
            const newAdminData = {
                ...data,
                modules: selectedModules
            }
            console.log(newAdminData)
            dispatch(createNewAdmin(newAdminData))
                .then(res => {
                    console.log(res)
                    if (res.error) {
                        alert(res?.payload ? res.payload : 'Internal server error')
                    } else {
                        alert(`Successfully created new support admin`);
                        handleClose();
                    }
                })
                .catch(err => {
                    alert(err?.payload ? err.payload : 'Internal server error')
                })
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Edit' : 'Add New'} Support Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        adminPermissionWarning &&
                        <Alert variant={'warning'} dismissible>
                            <FontAwesomeIcon icon={faWarning} />&nbsp;
                            Warning: You have added Admin permission for this Support Admin.
                        </Alert>
                    }
                    <Form onSubmit={handleSubmit(handleFormSubmit)}>
                        <Row>
                            <Col sm={12}>
                                <Controller
                                    control={control}
                                    name={`isSuperAdmin`}
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Check
                                            onChange={onChange}
                                            checked={value}
                                            type='checkbox'
                                            id={`isSuperAdmin`}
                                            label={`Is Super Admin?`}
                                            className='mb-3'
                                        />
                                    )}
                                />
                            </Col>

                            <Col sm={6}>
                                <Controller
                                    control={control}
                                    name="displayName"
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group className="mb-3" controlId="displayName">
                                            <Form.Label>Admin name</Form.Label>
                                            <Form.Control type="text" value={value} onChange={onChange} placeholder="Display Name" required />
                                        </Form.Group>
                                    )}
                                />
                            </Col>

                            <Col sm={6}>
                                <Controller
                                    control={control}
                                    name="phoneNumber"
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group className="mb-3" controlId="phoneNumber">
                                            <Form.Label>Contact</Form.Label>
                                            <Form.Control type="text" onChange={onChange} value={value} placeholder="Contact details" disabled={isEdit} required />
                                        </Form.Group>
                                    )}
                                />
                            </Col>

                            <Col sm={6}>
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group className="mb-3" controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" value={value} onChange={onChange} placeholder="Email" disabled={isEdit} required />
                                        </Form.Group>
                                    )}
                                />
                            </Col>

                            {!isEdit && <Col sm={6}>
                                <Controller
                                    control={control}
                                    name="password"
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group className="mb-3" controlId="password">
                                            <Form.Label>Password</Form.Label>
                                            <InputGroup>
                                                <Form.Control type={showPassword ? "text" : "password"} value={value} onChange={onChange} placeholder="Password" required />
                                                <InputGroup.Text id="btnGroupAddon" style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}>
                                                    {!showPassword && <FontAwesomeIcon icon={faEye} />}
                                                    {showPassword && <FontAwesomeIcon icon={faEyeSlash} />}
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                    )}
                                />
                            </Col>}

                            <Col sm={12}>
                                <Form.Label>
                                    <span>Modules</span>
                                    <span>
                                        <input
                                            className='mx-2'
                                            value={selectAllModulesCheckbox}
                                            style={{ textTransform: 'capitalize' }}
                                            // checked={route.city_id ? true : false}
                                            onChange={(e) => { setSelectAllModulesCheckbox(!selectAllModulesCheckbox); handleSelectAllModules(e) }}
                                            type='checkbox'
                                            id={`select-all-modules`}
                                            name="select-all-modules"
                                        />
                                        <label htmlFor="select-all-modules">Select All</label>
                                    </span>
                                </Form.Label>
                                <Row>
                                    {
                                        modulesList.map((module, index) => {
                                            return (
                                                <Col sm={3} key={index}>
                                                    <Form.Check
                                                        // style={{ textTransform: 'capitalize' }}
                                                        checked={selectedModules.some(selectedModule => selectedModule === module.value)}
                                                        onChange={(e) => { handleModuleChange(module.value) }}
                                                        type='checkbox'
                                                        id={`default-checkbox-${module.value}`}
                                                        label={`${module.label}`}
                                                    // value={module.value}
                                                    />
                                                </Col>
                                            )
                                        })
                                    }
                                    {/* {
                                        modules.map((module, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <Col xs={4}>
                                                        <InputGroup className="mb-3">
                                                            <Form.Select onChange={(e) => { handleModuleChange(index, e) }} value={module} required>
                                                                <option value="" disabled>Select</option>
                                                                <option value="admin">Admin</option>
                                                                <option value="shipmantra_c2c">Shipmantra C2C</option>
                                                                <option value="business">Business</option>
                                                                <option value="apsrtc">APSRTC</option>
                                                                <option value="api_monitoring">API Monitoring</option>
                                                                <option value="misc">Miscellaneous</option>
                                                            </Form.Select>
                                                            {modules.length !== 1 && <Button variant='outline-danger' type='button' onClick={() => removeModule(index)}>{deleteIcon}</Button>}
                                                        </InputGroup>
                                                    </Col>
                                                </React.Fragment>
                                            )
                                        })
                                    } */}
                                </Row>
                                {/* <Button variant='success' className='mt-2 mb-3' onClick={() => addModule()}>Add</Button> */}
                            </Col>
                        </Row>

                        <hr />

                        <Button type='submit' variant="primary" disabled={createUserFetching || updateFetching}>
                            Submit&nbsp;
                            {(createUserFetching || updateFetching) && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}
                        </Button>
                    </Form>
                </Modal.Body >
            </Modal >
        </>
    )
}
