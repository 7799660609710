import { faBoxOpen, faFileInvoiceDollar, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Row, Col, Form, Button, Spinner, Modal, InputGroup, Alert } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { generateApiKeyForBusinessAPI } from '../../../app/api/shipmantrabusiness/users';
import { fetchBusinessUserDetail, updateBusinessUser } from '../../../app/features/shipmantrabusiness/user/businessUserSlice';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { backButtonIcon, downloadIcon } from '../../../FontAwesome';
import { unwrapResult } from '@reduxjs/toolkit';
import Orders from '../Orders/Orders';
import { fetchBusinessUserOrders } from '../../../app/features/shipmantrabusiness/order/businessOrderSlice';
import WalletTransactionsList from '../Wallet/WalletTransactionsList';


function AlertModal({ show, handleClose, setOpenKeyModal }) {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body style={{ padding: 0 }}>
                <Alert show={true} variant="danger" style={{ marginBottom: 0 }}>
                    <Alert.Heading>Please confirm?!</Alert.Heading>
                    <p>
                        Looks like you already have an API key associated with this account. Confirm and deactivate current key?
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => { setOpenKeyModal(true); handleClose() }} variant="success">
                            Confirm
                        </Button>
                        <Button onClick={handleClose} variant="danger" className='ms-2'>
                            Cancel
                        </Button>
                    </div>
                </Alert>

            </Modal.Body>
        </Modal>
    )
}


function GenerateApiKey({ isVisible, handleClose, uid }) {
    const [loading, setLoading] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const [apiToken, setApiToken] = useState('')

    useEffect(() => {
        if (uid) {
            setLoading(true);
            generateApiKeyForBusinessAPI(uid)
                .then(res => {
                    console.log(res)
                    const data = res.response.data.data
                    setLoading(false)
                    setApiKey(data.apiKey)
                    setApiToken(data.apiToken)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }, [uid])

    const downloadToken = () => {
        if (apiKey && apiToken) {
            const userFileName = "Shipmantra Credentials"
            const fileType = 'application/vnd.openxml-formats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.csv'
            const fileData = [
                {
                    "sm-key": apiKey,
                    "sm-token": apiToken
                }
            ]

            const ws = XLSX.utils.json_to_sheet(fileData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, userFileName + fileExtension);
        }
    }

    return (
        <>
            <Modal show={isVisible} onHide={handleClose} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="api-token">API token</InputGroup.Text>
                        <Form.Control
                            placeholder="token"
                            aria-label="token"
                            aria-describedby="api-token"
                            value={apiToken}
                            readOnly
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">API key</InputGroup.Text>
                        <Form.Control
                            placeholder="key"
                            aria-label="key"
                            aria-describedby="basic-addon1"
                            value={apiKey}
                            readOnly
                        />
                    </InputGroup>
                    <Button onClick={downloadToken} disabled={loading}>
                        {downloadIcon}&nbsp;Download&nbsp;
                        {
                            loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default function EditBusinessUser() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userID } = useParams();
    const { userDetail, fetching } = useSelector(state => state.businessUser)

    const { handleSubmit, control, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {
            fullName: userDetail.fullName,
            companyName: userDetail.companyName,
            gstIn: userDetail.gstIn,
            email: userDetail.email,
            phone: userDetail.phone,
            isActive: false,
            value: 0,
            type: "percentage"
        }
    })

    const [openKeyModal, setOpenKeyModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showOrders, setShowOrders] = useState(false);
    const [showTransactions, setShowTransactions] = useState(false)

    useEffect(() => {
        if (userDetail) {
            setValue('email', userDetail.email)
            setValue('fullName', userDetail.fullName)
            setValue('companyName', userDetail.companyName)
            setValue('gstIn', userDetail.gstIn)
            setValue('phone', userDetail.phone)
            setValue('type', userDetail.platformFee?.type ? userDetail?.platformFee?.type : 'percentage')
            setValue('value', userDetail.platformFee?.value ? userDetail?.platformFee?.value : '')
            setValue('isActive', userDetail.platformFee?.isActive ? true : false)
        }
    }, [userDetail])

    useEffect(() => {
        if (errors) {
            console.log(errors, "isActive", getValues('isActive'))
        }
    }, [errors])


    useEffect(() => {
        dispatch(fetchBusinessUserDetail(userID))
    }, [])

    const updateUser = (data) => {
        const body = {
            fullName: data.fullName,
            companyName:data.companyName,
            gstIn:data.gstIn,
            email: data.email,
            phone: data.phone,
            platformFee: {
                type: data.type,
                value: data.value,
                isActive: data.isActive
            }
        }
        dispatch(updateBusinessUser({ id: userID, reqBody: body }))
            .then(unwrapResult)
            .then(() => {
                alert("User updated successfully")
            })
    }

    const generateKey = () => {
        if (userDetail.apiToken) {
            setShowAlert(true)
        } else {
            setOpenKeyModal(true)
        }
    }

    const viewOrders = () => {
        setShowOrders(true)
        dispatch(fetchBusinessUserOrders(userDetail.uid))
    }

    const validatePlatformFee = (value) => {
        if (getValues('isActive') && value <= 0) {
            return false
        }
        return true
    }

    if (fetching) return (
        <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
            <Spinner animation="border" />
        </div>
    )

    return (
        <div style={{ padding: '30px' }}>
            <Button onClick={() => navigate('/business/users')}>{backButtonIcon}&nbsp;Back</Button>
            <div className="d-flex justify-content-md-between mt-4">
                <h4>Edit user: {userDetail.fullName}</h4>
                <div>
                    <Button type='button' variant="success" className='me-2' onClick={() => setShowTransactions(true)}>
                        <FontAwesomeIcon icon={faFileInvoiceDollar} />&nbsp;
                        View transactions
                    </Button>
                    <Button type='button' variant="secondary" className='me-2' onClick={viewOrders}>
                        <FontAwesomeIcon icon={faBoxOpen} />&nbsp;
                        View orders
                    </Button>
                    <Button onClick={generateKey}>
                        <FontAwesomeIcon icon={faKey} />&nbsp;Generate Key
                    </Button>
                </div>
            </div>
            <hr />
            <Form onSubmit={handleSubmit(updateUser)}>
                <Row>
                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="companyName"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="companyName">
                                    <Form.Label>Company name</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Company Name" required />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={6}>
                        <Controller
                            control={control}
                            name="gstIn"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="gstIn">
                                    <Form.Label>GSTIN</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="GSTIN" />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={4}>
                        <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" value={value} onChange={onChange} placeholder="Email" required />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={4}>
                        <Controller
                            control={control}
                            name="fullName"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="fullName">
                                    <Form.Label>POC name</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="POC Name" required />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={4}>
                        <Controller
                            control={control}
                            name="phone"
                            render={({ field: { onChange, value } }) => (
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Contact</Form.Label>
                                    <Form.Control type="text" onChange={onChange} value={value} placeholder="Contact details" required />
                                </Form.Group>
                            )}
                        />
                    </Col>

                    <Col sm={6}>
                        <Row>
                            <label>Platform Fee</label>
                            <Col sm={2}>
                                <Controller
                                    control={control}
                                    name="isActive"
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group className="mb-3" controlId="enable">
                                            <Form.Label>Active</Form.Label>
                                            <Form.Check type="switch" checked={value} onChange={onChange} />
                                        </Form.Group>
                                    )}
                                />
                            </Col>

                            <Col sm={5}>
                                <Controller
                                    control={control}
                                    name="value"
                                    // rules={{ validate: value => !( getValues('isActive') && value <= 0) || 'Value should be  greater than 0'} }
                                    rules={{ validate: value => validatePlatformFee(value) || 'Value should be  greater than 0' }}
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group className="mb-3" controlId="value">
                                            <Form.Label>Value</Form.Label>
                                            <Form.Control type="number" onChange={onChange} value={value} placeholder="Enter value" />
                                            {errors.value && <div className='error-text'>{errors.value.message}</div>}
                                        </Form.Group>
                                    )}
                                />
                            </Col>

                            <Col sm={5}>
                                <Controller
                                    control={control}
                                    name="type"
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group className="mb-3" controlId="type">
                                            <Form.Label>Type</Form.Label>
                                            <Form.Select aria-label="Default select example" value={value} onChange={onChange}>
                                                <option value={""}>Open this select menu</option>
                                                <option value="flat">Flat</option>
                                                <option value="percentage">Percentage</option>
                                            </Form.Select>
                                        </Form.Group>
                                    )}
                                />
                            </Col>

                        </Row>
                    </Col>
                    <Col sm={12}>
                        <Button type='submit' variant="primary" disabled={fetching}>
                            Update&nbsp;
                            {fetching && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {openKeyModal && <GenerateApiKey isVisible={openKeyModal} handleClose={() => setOpenKeyModal(false)} uid={userDetail.uid} />}
            {showAlert && <AlertModal show={showAlert} handleClose={() => setShowAlert(false)} setOpenKeyModal={setOpenKeyModal} />}
            {showOrders && <Orders show={showOrders} handleClose={() => setShowOrders(false)} />}
            {showTransactions && <WalletTransactionsList show={showTransactions} handleClose={() => setShowTransactions(false)} businessUserUID={userDetail.uid} />}
        </div >
    )
}
