import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { backButtonIcon } from '../../FontAwesome'
import { useSelector } from 'react-redux'
import { fetchAllPanindiaShipper } from '../../app/features/shipper/shipperSlice'
import { calculateGoogleAddress } from '../../app/api/general'
import { createExpressDelOrder, fetchSingleExpressDeliveryOrder, updateExpressOrder } from '../../app/features/order/orderSlice'
import Loader from '../Loader'


export default function EditExpressDeliveryOrder() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const { id } = params

  const { fetching, orderDetails } = useSelector(state => state.order)
  const { shippers } = useSelector(state => state.shipper)

  const [isSingleAwb, setIsSingleAwb] = useState(false)


  const { handleSubmit, control, setValue, setError, formState: { errors } } = useForm({
    defaultValues: {
      pkg: [{ l: '', b: '', h: '', weight: '', status: '' }],
      orderIds: [],
      fragile: false,
      shipperID: '',
      user: '',
      weight: 0,
      cost: '',
      tracking_url: '',
      estimatedCompletionDate: '',
      senderName: '',
      senderContact: '',
      senderEmail: '',
      senderAddress: '',
      receiverName: '',
      receiverContact: '',
      receiverEmail: '',
      receiverAddress: '',
      bookedAt: '',
      gst: '',
      state: '',
      monetary_value: '',
      payment_status: 'Initiated',
      shipmentPurpose: 'personal',
      goodsCategory: ''
    }
  })


  const { fields, append, remove } = useFieldArray({
    control,
    name: "pkg",
  });

  useEffect(() => {
    dispatch(fetchSingleExpressDeliveryOrder(id))
    dispatch(fetchAllPanindiaShipper())
  }, [])

  useEffect(() => {
    if (orderDetails) {
      setValue('pkg', orderDetails.pkg)
      setValue('orderIds', orderDetails.placedOrderData?.order_ids)
      setValue('fragile', orderDetails.fragile)
      setValue('shipperID', orderDetails.shipperID)
      setValue('user', orderDetails.user)
      setValue('weight', orderDetails.weight)
      setValue('tracking_url', orderDetails.tracking_url)
      setValue('cost', orderDetails.cost)
      setValue('senderName', orderDetails.start?.contact?.name)
      setValue('senderContact', orderDetails.start?.contact?.phone)
      setValue('senderEmail', orderDetails.start?.contact?.email)
      setValue('senderAddress', orderDetails.start?.startLocation?.address)
      setValue('receiverName', orderDetails.end?.contact?.name)
      setValue('receiverContact', orderDetails.end?.contact?.phone)
      setValue('receiverEmail', orderDetails.end?.contact?.email)
      setValue('receiverAddress', orderDetails.end?.endLocation?.address)
      setValue('bookedAt', orderDetails.bookedAt)
      setValue('gst', orderDetails.gst)
      setValue('monetary_value', orderDetails.monetary_value)
      setValue('payment_status', orderDetails.payment_status)
      setValue('shipmentPurpose', orderDetails.shipmentPurpose)
      setValue('goodsCategory', orderDetails.goodsCategory)
      setValue('state', orderDetails.state)
      setValue('estimatedCompletionDate', orderDetails.estimatedCompletionDate ? new Date(orderDetails.estimatedCompletionDate).toISOString().split("T")[0] : '')
    }
  }, [orderDetails])

  const updateOrder = async (data) => {
    let updatedTrackingUrl = data.tracking_url
    if (!/^https?:\/\//i.test(data.tracking_url)) {
      updatedTrackingUrl = 'https://' + data.tracking_url;
    }
    const reqBody = {
      shipperID: data.shipperID,
      user: data.user,
      weight: data.weight,
      cost: data.cost,
      tracking_url: updatedTrackingUrl,
      pkg: data.pkg,
      bookedAt: data.bookedAt,
      fragile: data.fragile,
      gst: data.gst,
      monetary_value: data.monetary_value,
      order_type: 'pi_express',
      payment_status: data.payment_status,
      shipmentPurpose: data.shipmentPurpose,
      goodsCategory: data.goodsCategory,
      start: {
        contact: {
          name: data.senderName,
          phone: data.senderContact,
          email: data.senderEmail
        },
        startLocation: {
          address: data.senderAddress,
          fromGoogle: orderDetails.start.startLocation.fromGoogle,
          state: orderDetails.start.startLocation.state,
          city: orderDetails.start.startLocation.city,
          pincode: orderDetails.start.startLocation.pincode
        }
      },
      end: {
        contact: {
          name: data.receiverName,
          phone: data.receiverContact,
          email: data.receiverEmail
        },
        endLocation: {
          address: data.receiverAddress,
          fromGoogle: orderDetails.end.endLocation.fromGoogle,
          state: orderDetails.end.endLocation.state,
          city: orderDetails.end.endLocation.city,
          pincode: orderDetails.end.endLocation.pincode
        }
      },
      state: data.state,
      estimatedCompletionDate: data.estimatedCompletionDate,
      "placedOrderData.order_ids": data.orderIds
    }
    console.log(reqBody)
    dispatch(updateExpressOrder({ id, reqBody }))
      .then(() => {
        dispatch(fetchSingleExpressDeliveryOrder(id))
        alert('Order updated successfully')
      })
  }

  if (fetching) {
    <Loader />
  }

  return (
    <div style={{ padding: '30px' }}>
      <Button onClick={() => navigate(-1)}>{backButtonIcon}&nbsp;Back</Button>
      <h4 className='my-3'>Express order-{orderDetails.sm_orderid ? orderDetails.sm_orderid : orderDetails._id}</h4>
      <hr />
      <Form onSubmit={handleSubmit(updateOrder)}>
        <Row>
          <Col sm={6}>
            <Controller
              control={control}
              name="payment_status"
              defaultValue='Initiated'
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="payment_status">
                  <Form.Label>Payment status</Form.Label>
                  <Form.Select onChange={onChange} value={value}>
                    <option value='Initiated'>Initiated</option>
                    <option value='Successful'>Successful</option>
                    <option value='Pending'>Pending</option>
                  </Form.Select>
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="state"
              defaultValue='Initiated'
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="state">
                  <Form.Label>Overall order state</Form.Label>
                  <Form.Select onChange={onChange} value={value}>
                    <option value='Initiated'>Initiated</option>
                    <option value='To be placed'>To be placed</option>
                    <option value='Placed'>Order placed</option>
                    <option value='Manifested'>Order manifested</option>
                    <option value='Out for pickup'>Out for pickup</option>
                    <option value='At source hub'>At source hub</option>
                    <option value='In transit'>In transit</option>
                    <option value='At destination hub'>At destination hub</option>
                    <option value='Out for delivery'>Out for delivery</option>
                    <option value='Delivered'>Delivered</option>
                    <option value='Cancelled'>Order cancelled</option>
                    <option value='Under Hold'>Shipment under hold</option>
                    <option value='Delivery failed'>Delivery failed</option>
                  </Form.Select>
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="tracking_url"
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="tracking_url">
                  <Form.Label>Tracking Url</Form.Label>
                  <Form.Control type="text" placeholder="Enter tracking URL" onChange={onChange} value={value} />
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="estimatedCompletionDate"
              render={({ field: { onChange, value } }) => (
                <Form.Group controlId="estimatedCompletionDate">
                  <Form.Label>Estimated date of arrival</Form.Label>
                  <Form.Control type="date" value={value} onChange={onChange} />
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={12}>
            <Row>
              <Form.Label className='text-decoration-underline'>
                Package details
                <span><Form.Check style={{ display: 'inline-block' }} checked={isSingleAwb} className='ms-3' onChange={() => setIsSingleAwb(!isSingleAwb)}
                  type='checkbox' label='Single AWB for all packages' /></span>
              </Form.Label>
              {
                fields.map((item, index) => {
                  return (
                    <React.Fragment key={item.id}>
                      <Col sm={2}>
                        <Controller
                          defaultValue={item.l}
                          control={control}
                          rules={{ required: { value: true, message: 'This field is required' } }}
                          name={`pkg.${index}.l`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Form.Group className="mb-3" controlId="packageLength">
                              <Form.Label className='text-muted'>Length(cm)</Form.Label>
                              <Form.Control ref={ref} type="text" placeholder="Enter length of the package" onChange={onChange} value={value} required />
                              {/* {(errors.pkg && errors.pkg[index].l) && <div className='error-text'>{errors.pkg[index].l.message}</div>} */}
                            </Form.Group>
                          )}
                        />
                      </Col>
                      <Col sm={2}>
                        <Controller
                          defaultValue={item.b}
                          control={control}
                          rules={{ required: { value: true, message: 'This field is required' } }}
                          name={`pkg.${index}.b`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Form.Group className="mb-3" controlId="packageBreadth">
                              <Form.Label className='text-muted'>Breadth(cm)</Form.Label>
                              <Form.Control ref={ref} type="text" placeholder="Enter breadth of the package" onChange={onChange} value={value} required />
                              {/* {(errors.pkg && errors.pkg[index].b) && <div className='error-text'>{errors.pkg[index].b.message}</div>} */}
                            </Form.Group>
                          )}
                        />
                      </Col>
                      <Col sm={2}>
                        <Controller
                          defaultValue={item.h}
                          control={control}
                          rules={{ required: { value: true, message: 'This field is required' } }}
                          name={`pkg.${index}.h`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Form.Group className="mb-3" controlId="packageHeight">
                              <Form.Label className='text-muted'>Height(cm)</Form.Label>
                              <Form.Control ref={ref} type="text" placeholder="Enter height of the package" onChange={onChange} value={value} required />
                              {/* {(errors.pkg && errors.pkg[index].h) && <div className='error-text'>{errors.pkg[index].h.message}</div>} */}
                            </Form.Group>
                          )}
                        />
                      </Col>
                      <Col sm={2}>
                        <Controller
                          defaultValue={item.weight}
                          control={control}
                          rules={{ required: { value: true, message: 'This field is required' } }}
                          name={`pkg.${index}.weight`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Form.Group className="mb-3" controlId="packageWeight">
                              <Form.Label className='text-muted'>Approx. Weight(kgs)</Form.Label>
                              <Form.Control ref={ref} type="text" placeholder="Enter weight of the package" onChange={onChange} value={value} required />
                              {/* {(errors.pkg && errors.pkg[index].weight) && <div className='error-text'>{errors.pkg[index].weight.message}</div>} */}
                            </Form.Group>
                          )}
                        />
                      </Col>
                      <Col sm={4}>
                        {
                          isSingleAwb ?
                            <Controller
                              control={control}
                              name={`orderIds.${0}`}
                              render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="packageAwb">
                                  <Form.Label className='text-muted'>
                                    AWB
                                  </Form.Label>
                                  <Form.Control ref={ref} type="text" placeholder="Enter awb of the package" onChange={onChange} value={value} readOnly={index > 0 ? true : false} />
                                  {/* {(errors.pkg && errors.pkg[index].weight) && <div className='error-text'>{errors.pkg[index].weight.message}</div>} */}
                                </Form.Group>
                              )}
                            />
                            :
                            <Controller
                              control={control}
                              name={`orderIds.${index}`}
                              render={({ field: { onChange, value, ref } }) => (
                                <Form.Group className="mb-3" controlId="packageAwb">
                                  <Form.Label className='text-muted'>
                                    AWB
                                  </Form.Label>
                                  <Form.Control ref={ref} type="text" placeholder="Enter awb of the package" onChange={onChange} value={value} />
                                  {/* {(errors.pkg && errors.pkg[index].weight) && <div className='error-text'>{errors.pkg[index].weight.message}</div>} */}
                                </Form.Group>
                              )}
                            />
                        }
                      </Col>

                      {/* <Col xs={12}>
                        {fields.length !== 1 && <Button variant='danger' type='button' className='mb-3' onClick={() => remove(index)}>Remove</Button>}
                      </Col> */}
                    </React.Fragment>
                  )
                })
              }
            </Row>
            {/* <Button variant='success' className='mb-3' onClick={() => append({ l: '', b: '', h: '', weight: '' })}>Add</Button> */}
          </Col>
          <Col sm={6}>
            <Form.Label>Select shipper</Form.Label>
            <Controller
              control={control}
              name="shipperID"
              rules={{ required: { value: true, message: 'This field is required' } }}
              render={({ field: { onChange, value } }) => (
                <Form.Select aria-label="Default select example" onChange={onChange} value={value} disabled={true}>
                  <option value="">Select</option>
                  {
                    shippers?.map((shipper, index) => {
                      return (
                        <option key={index} value={shipper.shipperUID}>{shipper.company}</option>
                      )
                    })
                  }
                </Form.Select>
              )}
            />
            {errors.shipperID && <div className='error-text'>{errors.shipperID.message}</div>}
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="user"
              rules={{ required: { value: true, message: 'This field is required' } }}
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="user">
                  <Form.Label>User</Form.Label>
                  <Form.Control type="text" placeholder="Enter user" onChange={onChange} value={value} readOnly />
                  {errors.user && <div className='error-text'>{errors.user.message}</div>}
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="weight"
              rules={{ required: { value: true, message: 'This field is required' }, validate: value => value > 0 || 'Weight should be greater than 0', min: { value: 0, message: 'Weight should be greater than 0kg' } }}
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="weight">
                  <Form.Label>Weight of the shipment</Form.Label>
                  <Form.Control type="number" placeholder="Enter weight" onChange={onChange} value={value} readOnly />
                  {errors.weight && <div className='error-text'>{errors.weight.message}</div>}
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="cost"
              rules={{ required: { value: true, message: 'This field is required' }, validate: (value) => value > 0 || 'Cost cannot be less than 0' }}
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="cost">
                  <Form.Label>Cost&nbsp;(₹)</Form.Label>
                  <Form.Control type="text" onChange={onChange} value={value} readOnly />
                  {errors.cost && <div className='error-text'>{errors.cost.message}</div>}
                </Form.Group>
              )}
            />
          </Col>
        </Row>
        {/* Sender details */}
        <Row>
          <Form.Label className='text-decoration-underline'>Sender details</Form.Label>
          <Col sm={6}>
            <Controller
              control={control}
              name="senderName"
              rules={{ required: { value: true, message: 'This field is required' } }}
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="senderName">
                  <Form.Label>Sender name</Form.Label>
                  <Form.Control type="text" placeholder="Enter name of the sender" onChange={onChange} value={value} readOnly />
                  {errors.senderName && <div className='error-text'>{errors.senderName.message}</div>}
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="senderContact"
              rules={{ required: { value: true, message: 'This field is required' } }}
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="senderContact">
                  <Form.Label>Sender contact</Form.Label>
                  <Form.Control type="text" placeholder="Enter contact of the sender" onChange={onChange} value={value} readOnly />
                  {errors.senderContact && <div className='error-text'>{errors.senderContact.message}</div>}
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="senderEmail"
              rules={{ required: { value: true, message: 'This field is required' } }}
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="senderEmail">
                  <Form.Label>Sender email</Form.Label>
                  <Form.Control type="text" placeholder="Enter email of the sender" onChange={onChange} value={value} readOnly />
                  {errors.senderEmail && <div className='error-text'>{errors.senderEmail.message}</div>}
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="senderAddress"
              rules={{ required: { value: true, message: 'This field is required' } }}
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="senderAddress">
                  <Form.Label>Sender address</Form.Label>
                  <Form.Control type="text" placeholder="Enter address of the sender" onChange={onChange} value={value} readOnly />
                  {errors.senderAddress && <div className='error-text'>{errors.senderAddress.message}</div>}
                </Form.Group>
              )}
            />
          </Col>
        </Row>
        {/* Receiver details */}
        <Row>
          <Form.Label className='text-decoration-underline'>Receiver details</Form.Label>
          <Col sm={6}>
            <Controller
              control={control}
              name="receiverName"
              rules={{ required: { value: true, message: 'This field is required' } }}
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="receiverName">
                  <Form.Label>Receiver name</Form.Label>
                  <Form.Control type="text" placeholder="Enter name of the receiver" onChange={onChange} value={value} readOnly />
                  {errors.receiverName && <div className='error-text'>{errors.receiverName.message}</div>}
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="receiverContact"
              rules={{ required: { value: true, message: 'This field is required' } }}
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="receiverContact">
                  <Form.Label>Receiver contact</Form.Label>
                  <Form.Control type="text" placeholder="Enter contact of the receiver" onChange={onChange} value={value} readOnly />
                  {errors.receiverContact && <div className='error-text'>{errors.receiverContact.message}</div>}
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="receiverEmail"
              rules={{ required: { value: true, message: 'This field is required' } }}
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="receiverEmail">
                  <Form.Label>Receiver email</Form.Label>
                  <Form.Control type="text" placeholder="Enter email of the receiver" onChange={onChange} value={value} readOnly />
                  {errors.receiverEmail && <div className='error-text'>{errors.receiverEmail.message}</div>}
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="receiverAddress"
              rules={{ required: { value: true, message: 'This field is required' } }}
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="receiverAddress">
                  <Form.Label>Receiver address</Form.Label>
                  <Form.Control type="text" placeholder="Enter address of the receiver" onChange={onChange} value={value} readOnly />
                  {errors.receiverAddress && <div className='error-text'>{errors.receiverAddress.message}</div>}
                </Form.Group>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Controller
              control={control}
              rules={{ required: { value: true, message: 'This field is required' } }}
              name="bookedAt"
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="receiverAddress">
                  <Form.Label>Booked at</Form.Label>
                  <Form.Control type="datetime-local" onChange={onChange} value={value} readOnly />
                  {errors.bookedAt && <div className='error-text'>{errors.bookedAt.message}</div>}
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              defaultValue={false}
              name="fragile"
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="fragile">
                  <Form.Label>Fragile</Form.Label>
                  <Form.Select onChange={onChange} value={value} disabled={true}>
                    <option value='true'>True</option>
                    <option value='false'>False</option>
                  </Form.Select>
                  {/* {errors.fragile && <div className='error-text'>{errors.fragile.message}</div>} */}
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="gst"
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="gst">
                  <Form.Label>GST</Form.Label>
                  <Form.Control type="text" placeholder="Enter gst" onChange={onChange} value={value} readOnly />
                </Form.Group>
              )}
            />
            {/* {errors.gst && <div className='error-text'>{errors.gst.message}</div>} */}
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="monetary_value"
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="monetary_value">
                  <Form.Label>Monetary value</Form.Label>
                  <Form.Control type="text" placeholder="Enter monetary value" onChange={onChange} value={value} readOnly />
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="shipmentPurpose"
              defaultValue='personal'
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="shipmentPurpose">
                  <Form.Label>Shipment Purpose</Form.Label>
                  <Form.Select onChange={onChange} value={value} disabled={true}>
                    <option value='personal'>Personal</option>
                    <option value='business'>Business</option>
                  </Form.Select>
                </Form.Group>
              )}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              name="goodsCategory"
              render={({ field: { onChange, value } }) => (
                <Form.Group className="mb-3" controlId="goodsCategory">
                  <Form.Label>Category of goods</Form.Label>
                  <Form.Control type="text" placeholder="Enter category of goods" onChange={onChange} value={value} readOnly />
                </Form.Group>
              )}
            />
          </Col>
        </Row>
        <Button type='submit'>Submit</Button>
      </Form>
    </div>
  )
}
