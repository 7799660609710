import React from 'react'
import Charges from '../Charges'

export default function InternationalExportsCharge() {
    return (
        <div style={{ padding: '30px' }}>
            <h4>Exports platform charges</h4>
            <hr />
            <Charges serviceType='int_export' />
        </div>
    )
}
