/* eslint-disable react/react-in-jsx-scope */
import { Card, Nav, Button, Tabs, Tab, Form, Col, Row, Alert, Dropdown } from "react-bootstrap";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardDetailsAction } from "../../app/features/dashboard/dashboardSlice";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, ScriptableContext, registerables, Filler } from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
import { filterIcon } from "../../FontAwesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler);


function LineGraph({ data, options = {} }) {
    return (
        <>
            <Line data={data} options={{
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        radius: 0
                    },
                    line: {
                        borderColor: '#0d6efd',
                        // fill: true,
                        borderWidth: 1
                    },
                },
                scales: {
                    x: {
                        display: false
                    },
                    y: {
                        display: false
                    }
                }
            }} />
        </>
    )
}

export default function Dashboard() {
    const dispatch = useDispatch();

    const [orderType, setOrderType] = useState('hyperlocal'); //type of order
    const { orderData, fetching } = useSelector(state => state.dashboard);

    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(new Date())
    const [analyticsArrayC2C, setAnalyticsArrayC2C] = useState([])
    const [analyticsArrayB2B, setAnalyticsArrayB2B] = useState([])
    const [analyticsArrayAPSRTC, setAnalyticsArrayAPSRTC] = useState([])

    const [orderCountChartData, setOrderCountChartData] = useState(undefined);
    const [paymentChartData, setPaymentChartData] = useState(undefined);
    const [userChartData, setUserChartData] = useState(undefined);
    const [past30DaysUserChartData, setPast30DaysUserChartData] = useState(undefined);
    const [graphDataStartDate, setGraphDataStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)))
    const [graphDataEndDate, setGraphDataEndDate] = useState(new Date())
    const [timespan, setTimespan] = useState('Past 30 days')

    useEffect(() => {
        switch (timespan) {
            case 'Past 30 days':
                var firstDayPast30 = new Date(new Date().setDate(new Date().getDate() - 30))
                var lastDayPast30 = new Date()
                setGraphDataStartDate(firstDayPast30.toISOString().split('T')[0]);
                setGraphDataEndDate(lastDayPast30.toISOString().split('T')[0]);
                break;

            case 'This Month':
                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                var firstDay = new Date(y, m, 2);
                var lastDay = new Date(y, m + 1, 1);
                setGraphDataStartDate(firstDay.toISOString().split('T')[0]);
                setGraphDataEndDate(date.toISOString().split('T')[0]);
                break;

            case 'Previous Month':
                var dateLM = new Date(), yLM = dateLM.getFullYear(), mLM = dateLM.getMonth();
                var firstDayLM = new Date(yLM, mLM - 1, 2);
                var lastDayLM = new Date(yLM, mLM, 1);
                setGraphDataStartDate(firstDayLM.toISOString().split('T')[0]);
                setGraphDataEndDate(lastDayLM.toISOString().split('T')[0]);
                break;

            case 'Lifetime':
                var startDate = new Date(null)
                var endDate = new Date()
                setGraphDataStartDate(startDate.toISOString().split('T')[0]);
                setGraphDataEndDate(endDate.toISOString().split('T')[0]);
                break;

            default:
                break;
        }
    }, [timespan])


    useEffect(() => {
        dispatch(fetchDashboardDetailsAction({ startDate: graphDataStartDate, endDate: graphDataEndDate, graphDataStartDate, graphDataEndDate }))
    }, [])

    const { hyperlocal, international, panindia, hyperlocalB2B, panindiaB2B, apsrtc, totalShipmantraOrders, totalShipmantraPayments, totalShipmantraUsers, past30DaysShipmantraUsers, eachDayOrderCount, eachDayPayments, eachDayUsers } = orderData;

    useEffect(() => {
        const tempAnalyticsArrayC2C = [
            {
                title: "Hyperlocal",
                data: [
                    {
                        tabName: '2W',
                        tabKey: '2w',
                        orderCount: {
                            total: hyperlocal?.subTypes?.twoWheeler?.placed +
                                hyperlocal?.subTypes?.twoWheeler?.inProgress +
                                hyperlocal?.subTypes?.twoWheeler?.delivered +
                                hyperlocal?.subTypes?.twoWheeler?.cancelled +
                                hyperlocal?.subTypes?.twoWheeler?.underHold,
                            active: hyperlocal?.subTypes?.twoWheeler?.inProgress,
                            completed: hyperlocal?.subTypes?.twoWheeler?.delivered,
                            cancelledOrHold: hyperlocal?.subTypes?.twoWheeler?.cancelled +
                                hyperlocal?.subTypes?.twoWheeler?.underHold
                        }
                    },
                    {
                        tabName: '3W',
                        tabKey: '3w',
                        orderCount: {
                            total: hyperlocal?.subTypes?.threeWheeler?.placed +
                                hyperlocal?.subTypes?.threeWheeler?.inProgress +
                                hyperlocal?.subTypes?.threeWheeler?.delivered +
                                hyperlocal?.subTypes?.threeWheeler?.cancelled +
                                hyperlocal?.subTypes?.threeWheeler?.underHold,
                            active: hyperlocal?.subTypes?.threeWheeler?.inProgress,
                            completed: hyperlocal?.subTypes?.threeWheeler?.delivered,
                            cancelledOrHold: hyperlocal?.subTypes?.threeWheeler?.cancelled +
                                hyperlocal?.subTypes?.threeWheeler?.underHold
                        }
                    },
                    {
                        tabName: '4W',
                        tabKey: '4w',
                        orderCount: {
                            total: hyperlocal?.subTypes?.fourWheeler?.placed +
                                hyperlocal?.subTypes?.fourWheeler?.inProgress +
                                hyperlocal?.subTypes?.fourWheeler?.delivered +
                                hyperlocal?.subTypes?.fourWheeler?.cancelled +
                                hyperlocal?.subTypes?.fourWheeler?.underHold,
                            active: hyperlocal?.subTypes?.fourWheeler?.inProgress,
                            completed: hyperlocal?.subTypes?.fourWheeler?.delivered,
                            cancelledOrHold: hyperlocal?.subTypes?.fourWheeler?.cancelled +
                                hyperlocal?.subTypes?.fourWheeler?.underHold
                        }
                    }
                ]
            },
            {
                title: "Pan India",
                data: [
                    {
                        tabName: 'Express',
                        tabKey: 'express',
                        orderCount: {
                            total: panindia?.subTypes?.express?.placed +
                                panindia?.subTypes?.express?.inProgress +
                                panindia?.subTypes?.express?.delivered +
                                panindia?.subTypes?.express?.cancelled +
                                panindia?.subTypes?.express?.underHold,
                            active: panindia?.subTypes?.express?.inProgress,
                            completed: panindia?.subTypes?.express?.delivered,
                            cancelledOrHold: panindia?.subTypes?.express?.cancelled +
                                panindia?.subTypes?.express?.underHold
                        }
                    }
                ]
            },
            {
                title: "International",
                data: [
                    {
                        tabName: 'Export',
                        tabKey: 'export',
                        orderCount: {
                            total: international?.subTypes?.export?.placed +
                                international?.subTypes?.export?.inProgress +
                                international?.subTypes?.export?.delivered +
                                international?.subTypes?.export?.cancelled +
                                international?.subTypes?.export?.underHold,
                            active: international?.subTypes?.export?.inProgress,
                            completed: international?.subTypes?.export?.delivered,
                            cancelledOrHold: international?.subTypes?.export?.cancelled +
                                international?.subTypes?.export?.underHold
                        }
                    },
                    {
                        tabName: 'Import',
                        tabKey: 'import',
                        orderCount: {
                            total: international?.subTypes?.import?.placed +
                                international?.subTypes?.import?.inProgress +
                                international?.subTypes?.import?.delivered +
                                international?.subTypes?.import?.cancelled +
                                international?.subTypes?.import?.underHold,
                            active: international?.subTypes?.import?.inProgress,
                            completed: international?.subTypes?.import?.delivered,
                            cancelledOrHold: international?.subTypes?.import?.cancelled +
                                international?.subTypes?.import?.underHold
                        }
                    }
                ]
            }
        ]
        setAnalyticsArrayC2C(tempAnalyticsArrayC2C)

        const tempAnalyticsArrayB2B = [
            {
                title: "Hyperlocal",
                data: [
                    {
                        tabName: '2W',
                        tabKey: '2w',
                        orderCount: {
                            total: hyperlocalB2B?.subTypes?.twoWheeler?.placed +
                                hyperlocalB2B?.subTypes?.twoWheeler?.inProgress +
                                hyperlocalB2B?.subTypes?.twoWheeler?.delivered +
                                hyperlocalB2B?.subTypes?.twoWheeler?.cancelled +
                                hyperlocalB2B?.subTypes?.twoWheeler?.underHold,
                            active: hyperlocalB2B?.subTypes?.twoWheeler?.inProgress,
                            completed: hyperlocalB2B?.subTypes?.twoWheeler?.delivered,
                            cancelledOrHold: hyperlocalB2B?.subTypes?.twoWheeler?.cancelled +
                                hyperlocalB2B?.subTypes?.twoWheeler?.underHold
                        }
                    },
                    {
                        tabName: '3W',
                        tabKey: '3w',
                        orderCount: {
                            total: hyperlocalB2B?.subTypes?.threeWheeler?.placed +
                                hyperlocalB2B?.subTypes?.threeWheeler?.inProgress +
                                hyperlocalB2B?.subTypes?.threeWheeler?.delivered +
                                hyperlocalB2B?.subTypes?.threeWheeler?.cancelled +
                                hyperlocalB2B?.subTypes?.threeWheeler?.underHold,
                            active: hyperlocalB2B?.subTypes?.threeWheeler?.inProgress,
                            completed: hyperlocalB2B?.subTypes?.threeWheeler?.delivered,
                            cancelledOrHold: hyperlocalB2B?.subTypes?.threeWheeler?.cancelled +
                                hyperlocalB2B?.subTypes?.threeWheeler?.underHold
                        }
                    },
                    {
                        tabName: '4W',
                        tabKey: '4w',
                        orderCount: {
                            total: hyperlocalB2B?.subTypes?.fourWheeler?.placed +
                                hyperlocalB2B?.subTypes?.fourWheeler?.inProgress +
                                hyperlocalB2B?.subTypes?.fourWheeler?.delivered +
                                hyperlocalB2B?.subTypes?.fourWheeler?.cancelled +
                                hyperlocalB2B?.subTypes?.fourWheeler?.underHold,
                            active: hyperlocalB2B?.subTypes?.fourWheeler?.inProgress,
                            completed: hyperlocalB2B?.subTypes?.fourWheeler?.delivered,
                            cancelledOrHold: hyperlocalB2B?.subTypes?.fourWheeler?.cancelled +
                                hyperlocalB2B?.subTypes?.fourWheeler?.underHold
                        }
                    }
                ]
            },
            {
                title: "Pan India",
                data: [
                    {
                        tabName: 'Express',
                        tabKey: 'express',
                        orderCount: {
                            total: panindiaB2B?.subTypes?.express?.placed +
                                panindiaB2B?.subTypes?.express?.inProgress +
                                panindiaB2B?.subTypes?.express?.delivered +
                                panindiaB2B?.subTypes?.express?.cancelled +
                                panindiaB2B?.subTypes?.express?.underHold,
                            active: panindiaB2B?.subTypes?.express?.inProgress,
                            completed: panindiaB2B?.subTypes?.express?.delivered,
                            cancelledOrHold: panindiaB2B?.subTypes?.express?.cancelled +
                                panindiaB2B?.subTypes?.express?.underHold
                        }
                    }
                ]
            }
        ]
        setAnalyticsArrayB2B(tempAnalyticsArrayB2B)

        const tempAnalyticsArrayAPSRTC = [
            {
                title: "APSRTC",
                data: [
                    {
                        tabName: 'Orders',
                        tabKey: 'Orders',
                        orderCount: {
                            total: apsrtc?.subTypes?.overall_order_status?.placed +
                                apsrtc?.subTypes?.overall_order_status?.inProgress +
                                apsrtc?.subTypes?.overall_order_status?.delivered +
                                apsrtc?.subTypes?.overall_order_status?.cancelled +
                                apsrtc?.subTypes?.overall_order_status?.underHold,
                            active: apsrtc?.subTypes?.overall_order_status?.inProgress,
                            completed: apsrtc?.subTypes?.overall_order_status?.delivered,
                            cancelledOrHold: apsrtc?.subTypes?.overall_order_status?.cancelled +
                                apsrtc?.subTypes?.overall_order_status?.underHold
                        }
                    }
                ]
            }
        ]
        setAnalyticsArrayAPSRTC(tempAnalyticsArrayAPSRTC)
    }, [orderData])

    // var gradient = ctx.createLinearGradient(0, 0, 0, 400);
    // gradient.addColorStop(0, 'rgba(250,174,50,1)');
    // gradient.addColorStop(1, 'rgba(250,174,50,0)');

    useEffect(() => {
        if (eachDayOrderCount) {
            const tempOrderCountChartData = {
                labels: Object.keys(eachDayOrderCount),
                datasets: [
                    {
                        label: 'Order count',
                        // fill: true,
                        data: Object.values(eachDayOrderCount),
                        // backgroundColor: (context) => {
                        //     const ctx = context.chart.ctx;
                        //     const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                        //     gradient.addColorStop(0, "rgba(250,174,50,1)");
                        //     gradient.addColorStop(1, "rgba(250,174,50,0)");
                        //     return gradient;
                        // },
                        // fillColor: gradient
                        // backgroundColor: 'linear-gradient(0deg, rgba(255,255,255,1) 24%, rgba(169,204,255,1) 100%);',
                    }
                ],
            };
            setOrderCountChartData(tempOrderCountChartData)
        }
    }, [eachDayOrderCount])

    useEffect(() => {
        if (eachDayPayments) {
            const tempPaymentChartData = {
                labels: Object.keys(eachDayPayments),
                datasets: [
                    {
                        label: 'Payment count',
                        // fill: true,
                        data: Object.values(eachDayPayments),
                        // fillColor: gradient
                        // backgroundColor: 'linear-gradient(0deg, rgba(255,255,255,1) 24%, rgba(169,204,255,1) 100%);',
                    }
                ],
            };
            setPaymentChartData(tempPaymentChartData)
        }
    }, [eachDayPayments])

    useEffect(() => {
        if (eachDayUsers) {
            const tempUserChartData = {
                labels: Object.keys(eachDayUsers),
                datasets: [
                    {
                        label: 'User count',
                        // fill: true,
                        data: Object.values(eachDayUsers),
                        // fillColor: gradient
                        // backgroundColor: 'linear-gradient(to bottom, rgba(13,110,253,0,8), rgba(0,0,0,0) 100%);',
                    }
                ],
            };
            setUserChartData(tempUserChartData)
        }
    }, [eachDayUsers])

    // useEffect(() => {
    //     if (past30DayUsers) {
    //         const tempUserChartData = {
    //             labels: Object.keys(past30DayUsers),
    //             datasets: [
    //                 {
    //                     label: 'Past 30 days User count',
    //                     fill: true,
    //                     data: Object.values(past30DayUsers),
    //                     fillColor: gradient
    //                     // backgroundColor: 'rgb(153, 77, 0, 0.5)',
    //                 }
    //             ],
    //         };
    //         setPast30DaysUserChartData(tempUserChartData)
    //     }
    // }, [past30DayUsers])

    const CustomToggle = React.forwardRef(({ label, children, active, onClick }, ref) => (
        <div>
            <Button
                // href=""
                // style={{ cursor: 'pointer' }}
                variant={active ? "primary" : 'outline-primary'}
                size='sm'
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}&nbsp;<FontAwesomeIcon icon={faChevronDown} />
            </Button>
        </div>
    ));


    return (
        <div style={{ padding: '30px' }}>
            {/* Shipmantra Data Count */}
            <div body style={{
                // boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
                border: 'none',
                padding: '0px',
                borderRadius: '10px',
                marginTop: '20px',
                marginBottom: '20px'
            }}>
                <div className='d-flex justify-content-end align-items-end mb-3'>
                    <div className='me-2'>
                        <Form.Label>{filterIcon}&nbsp;Select Timespan:</Form.Label>
                        <Form.Select aria-label="Default select example" value={timespan} onChange={e => setTimespan(e.currentTarget.value)}>
                            <option value=''>Select</option>
                            <option value="Past 30 days">Past 30 days</option>
                            <option value="This Month">This Month</option>
                            <option value="Previous Month">Previous Month</option>
                            <option value="Lifetime">Lifetime</option>
                        </Form.Select>
                    </div>

                    <div className='d-flex'>
                        <Form.Group controlId="filter">
                            <Form.Label>Start date:</Form.Label>
                            <Form.Control type="date" value={new Date(graphDataStartDate)?.toISOString().split("T")[0]} onChange={e => setGraphDataStartDate(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='ms-3' controlId="filter">
                            <Form.Label>End date:</Form.Label>
                            <Form.Control type="date" value={new Date(graphDataEndDate)?.toISOString().split("T")[0]} onChange={e => setGraphDataEndDate(e.target.value)} />
                        </Form.Group>
                    </div>
                    <Button style={{width:'120px'}} disabled={fetching} className='ms-3' onClick={() => dispatch(fetchDashboardDetailsAction({ startDate: graphDataStartDate, endDate: graphDataEndDate, graphDataStartDate, graphDataEndDate }))}>{fetching?"Loading...":"Filter"}</Button>
                </div>


                <div style={{ display: 'flex', color: '#605b5b' }}>
                    <Card body style={{
                        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
                        borderRadius: '10px',
                        marginRight: '10px',
                        flex: 1
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>Payment Volume</p>
                            <h3>₹&nbsp;
                                {
                                    totalShipmantraPayments && Intl.NumberFormat('en-US', {
                                        notation: "compact",
                                        maximumFractionDigits: 1
                                    }).format(totalShipmantraPayments)
                                }
                            </h3>
                        </div>
                        <div style={{ height: '80px' }}>
                            {paymentChartData && Object.keys(paymentChartData).length && <LineGraph data={paymentChartData} options={{ scales: { x: { display: false }, y: { display: false } } }} />}
                        </div>
                    </Card>

                    <Card body style={{
                        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
                        borderRadius: '10px',
                        marginRight: '10px',
                        flex: 1
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>Orders Placed</p>
                            <h3>{totalShipmantraOrders}</h3>
                        </div>
                        <div style={{ height: '80px' }}>
                            {orderCountChartData && Object.keys(orderCountChartData).length && <LineGraph data={orderCountChartData} options={{ scales: { x: { display: false }, y: { display: false } } }} />}
                        </div>
                    </Card>

                    {/* <Card body style={{
                    boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
                    borderRadius: '10px',
                    marginRight: '10px',
                    flex: 1
                }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>New sign-ups (Last 30 days)</p>
                        <h3>{past30DaysShipmantraUsers}</h3>
                    </div>
                    {past30DaysUserChartData && Object.keys(past30DaysUserChartData).length && <LineGraph data={past30DaysUserChartData} options={{ scales: { x: { display: false }, y: { display: false } } }} />}
                </Card> */}

                    <Card body style={{
                        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
                        borderRadius: '10px',
                        // marginRight: '10px',
                        flex: 1
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>Users Onboarded</p>
                            <h3>{totalShipmantraUsers}</h3>
                        </div>
                        <div style={{ height: '80px' }}>
                            {userChartData && Object.keys(userChartData).length && <LineGraph data={userChartData} options={{ scales: { x: { display: false }, y: { display: false } } }} />}
                        </div>
                    </Card>
                </div>
            </div>


            <Card body style={{
                boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
                borderRadius: '10px',
                marginTop: '20px',
                marginBottom: '20px'
            }}>
                <Card.Body>
                    <div className='d-flex justify-content-end align-items-end mb-3'>

                        <div style={{ fontSize: '30px', fontWeight: 600, flex: 1 }}>C2C</div>
                        {/* <Form.Group controlId="filter">
                            <Form.Label>Start date</Form.Label>
                            <Form.Control type="date" value={graphDataStartDate} onChange={e => setGraphDataStartDate(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='ms-3' controlId="filter">
                            <Form.Label>End date</Form.Label>
                            <Form.Control type="date" value={graphDataEndDate} onChange={e => setGraphDataEndDate(e.target.value)} />
                        </Form.Group>
                        <Button disabled={fetching} className='ms-3' onClick={() => dispatch(fetchDashboardDetailsAction({ startDate: graphDataStartDate, endDate: graphDataEndDate, graphDataStartDate, graphDataEndDate }))}>{fetching?"Loading...":"Filter"}</Button> */}
                    </div>

                    <Row>
                        {analyticsArrayC2C.map((c2cData, index) => {

                            return (
                                <Col sm={12} lg={4} key={c2cData.title}>
                                    <Card body style={{
                                        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
                                        borderRadius: '10px',
                                        marginTop: '20px',
                                        marginBottom: '20px'
                                    }}>
                                        <Card.Body>
                                            <div style={{ fontSize: '20px', fontWeight: 600 }}>{c2cData.title}</div>
                                            <Tabs
                                                id="controlled-tab-example"
                                                // activeKey={orderType}
                                                // onSelect={(k) => setOrderType(k)}
                                                className="mb-3"
                                            >
                                                {c2cData.data.map((orderData, index) => {
                                                    return (
                                                        <Tab eventKey={orderData.tabKey} key={c2cData.title + "_" + orderData.tabKey} title={orderData.tabName}>
                                                            <div style={{ padding: '10px', fontSize: '12px' }}>
                                                                <Alert variant='primary' style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <Alert.Link href="#" style={{ flex: 1 }}>Total Orders:</Alert.Link>&nbsp;{orderData.orderCount.total}
                                                                </Alert>
                                                                <Alert variant='info' style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <Alert.Link href="#" style={{ flex: 1 }}>Active:</Alert.Link>&nbsp;{orderData.orderCount.active}
                                                                </Alert>
                                                                <Alert variant='success' style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <Alert.Link href="#" style={{ flex: 1 }}>Completed:</Alert.Link>&nbsp;{orderData.orderCount.completed}
                                                                </Alert>
                                                                <Alert variant='danger' style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <Alert.Link href="#" style={{ flex: 1 }}>Cancelled or Under Hold:</Alert.Link>&nbsp;{orderData.orderCount.cancelledOrHold}
                                                                </Alert>
                                                            </div>
                                                        </Tab>
                                                    )
                                                })}
                                            </Tabs>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )

                        })}
                    </Row>

                </Card.Body>
            </Card>

            <Card body style={{
                boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
                borderRadius: '10px',
                marginTop: '20px',
                marginBottom: '20px'
            }}>
                <Card.Body>
                    <div className='d-flex justify-content-end align-items-end mb-3'>

                        <div style={{ fontSize: '30px', fontWeight: 600, flex: 1 }}>B2B</div>
                        {/* <Form.Group controlId="filter">
                            <Form.Label>Start date</Form.Label>
                            <Form.Control type="date" value={graphDataStartDate} onChange={e => setGraphDataStartDate(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='ms-3' controlId="filter">
                            <Form.Label>End date</Form.Label>
                            <Form.Control type="date" value={graphDataEndDate} onChange={e => setGraphDataEndDate(e.target.value)} />
                        </Form.Group>
                        <Button disabled={fetching} className='ms-3' onClick={() => dispatch(fetchDashboardDetailsAction({ startDate: graphDataStartDate, endDate: graphDataEndDate, graphDataStartDate, graphDataEndDate }))}>{fetching?"Loading...":"Filter"}</Button> */}
                    </div>

                    <Row>
                        {analyticsArrayB2B.map((b2bData, index) => {

                            return (
                                <Col sm={12} lg={6} key={b2bData.title}>
                                    <Card body style={{
                                        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
                                        borderRadius: '10px',
                                        marginTop: '20px',
                                        marginBottom: '20px'
                                    }}>
                                        <Card.Body>
                                            <div style={{ fontSize: '20px', fontWeight: 600 }}>{b2bData.title}</div>
                                            <Tabs
                                                id="controlled-tab-example"
                                                // activeKey={orderType}
                                                // onSelect={(k) => setOrderType(k)}
                                                className="mb-3"
                                            >
                                                {b2bData.data.map((orderData, index) => {
                                                    return (
                                                        <Tab eventKey={orderData.tabKey} key={b2bData.title + "_" + orderData.tabKey} title={orderData.tabName}>
                                                            <div style={{ padding: '10px', fontSize: '12px' }}>
                                                                <Alert variant='primary' style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <Alert.Link href="#" style={{ flex: 1 }}>Total Orders:</Alert.Link>&nbsp;{orderData.orderCount.total}
                                                                </Alert>
                                                                <Alert variant='info' style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <Alert.Link href="#" style={{ flex: 1 }}>Active:</Alert.Link>&nbsp;{orderData.orderCount.active}
                                                                </Alert>
                                                                <Alert variant='success' style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <Alert.Link href="#" style={{ flex: 1 }}>Completed:</Alert.Link>&nbsp;{orderData.orderCount.completed}
                                                                </Alert>
                                                                <Alert variant='danger' style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <Alert.Link href="#" style={{ flex: 1 }}>Cancelled or Under Hold:</Alert.Link>&nbsp;{orderData.orderCount.cancelledOrHold}
                                                                </Alert>
                                                            </div>
                                                        </Tab>
                                                    )
                                                })}
                                            </Tabs>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )

                        })}
                    </Row>

                </Card.Body>
            </Card>

            <Card body style={{
                boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
                borderRadius: '10px',
                marginTop: '20px',
                marginBottom: '20px'
            }}>
                <Card.Body>
                    <div className='d-flex justify-content-end align-items-end mb-3'>

                        <div style={{ fontSize: '30px', fontWeight: 600, flex: 1 }}>APSRTC</div>
                        {/* <Form.Group controlId="filter">
                            <Form.Label>Start date</Form.Label>
                            <Form.Control type="date" value={graphDataStartDate} onChange={e => setGraphDataStartDate(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='ms-3' controlId="filter">
                            <Form.Label>End date</Form.Label>
                            <Form.Control type="date" value={graphDataEndDate} onChange={e => setGraphDataEndDate(e.target.value)} />
                        </Form.Group>
                        <Button disabled={fetching} className='ms-3' onClick={() => dispatch(fetchDashboardDetailsAction({ startDate: graphDataStartDate, endDate: graphDataEndDate, graphDataStartDate, graphDataEndDate }))}>{fetching?"Loading...":"Filter"}</Button> */}
                    </div>

                    <Row>
                        {analyticsArrayAPSRTC.map((apsrtcData, index) => {

                            return (
                                <Col sm={12} lg={12} key={apsrtcData.title}>
                                    <Card body style={{
                                        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
                                        borderRadius: '10px',
                                        marginTop: '20px',
                                        marginBottom: '20px'
                                    }}>
                                        <Card.Body>
                                            <div style={{ fontSize: '20px', fontWeight: 600 }}>{apsrtcData.title}</div>
                                            <Tabs
                                                id="controlled-tab-example"
                                                // activeKey={orderType}
                                                // onSelect={(k) => setOrderType(k)}
                                                className="mb-3"
                                            >
                                                {apsrtcData.data.map((orderData, index) => {
                                                    return (
                                                        <Tab eventKey={orderData.tabKey} key={apsrtcData.title + "_" + orderData.tabKey} title={orderData.tabName}>
                                                            <div style={{ padding: '10px', fontSize: '12px' }}>
                                                                <Alert variant='primary' style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <Alert.Link href="#" style={{ flex: 1 }}>Total Orders:</Alert.Link>&nbsp;{orderData.orderCount.total}
                                                                </Alert>
                                                                <Alert variant='info' style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <Alert.Link href="#" style={{ flex: 1 }}>Active:</Alert.Link>&nbsp;{orderData.orderCount.active}
                                                                </Alert>
                                                                <Alert variant='success' style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <Alert.Link href="#" style={{ flex: 1 }}>Completed:</Alert.Link>&nbsp;{orderData.orderCount.completed}
                                                                </Alert>
                                                                <Alert variant='danger' style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <Alert.Link href="#" style={{ flex: 1 }}>Cancelled or Under Hold:</Alert.Link>&nbsp;{orderData.orderCount.cancelledOrHold}
                                                                </Alert>
                                                            </div>
                                                        </Tab>
                                                    )
                                                })}
                                            </Tabs>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )

                        })}
                    </Row>

                </Card.Body>
            </Card>

        </div>
    )
}