import React from 'react'
import { useEffect } from 'react'
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getSingleCountry, updateCountry } from '../../app/features/countries/countrySlice'
import { backButtonIcon } from '../../FontAwesome'
import Loader from '../Loader'
import { useForm, Controller } from "react-hook-form";
const lookup = require('country-code-lookup')


export default function EditCountry() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { countryId } = useParams()

    const countries = lookup.countries

    const { fetching, countryDetail } = useSelector(state => state.country)

    const { reset, control, handleSubmit } = useForm({
        defaultValues: {
            countryString: countryDetail.countryString ? countryDetail.countryString : "",
            countryText: countryDetail.countryText ? countryDetail.countryText : "",
            canImport: countryDetail.canImport ? countryDetail.canImport : false,
            alphaCode: countryDetail.alphaCode ? countryDetail.alphaCode.toUpperCase() : ""
        }
    })

    useEffect(() => {
        dispatch(getSingleCountry(countryId))
    }, [])

    useEffect(() => {
        reset({
            countryString: countryDetail.countryString,
            countryText: countryDetail.countryText,
            canImport: countryDetail.canImport,
            alphaCode: countryDetail.alphaCode?.toUpperCase()
        })
    }, [countryDetail])

    const editCountry = (data) => {
        console.log(data)
        const reqBody = {
            alphaCode: data.alphaCode.toLowerCase(),
            canImport: data.canImport,
            countryString: data.countryString,
            countryText: data.countryText
        }
        dispatch(updateCountry(reqBody))
            .then(() => getSingleCountry(countryId))
    }

    if (fetching) {
        return <Loader />
    }

    return (
        <div style={{ padding: '20px' }}>
            <Button variant='primary' onClick={() => navigate(-1)}>{backButtonIcon}&nbsp;Back</Button>
            <hr />
            <Form onSubmit={handleSubmit(editCountry)}>
                <Row>
                    <Col xs sm={6}>
                        <Controller
                            control={control}
                            name="countryString"
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, }) => (
                                <Form.Group className="mb-3" controlId="countryString">
                                    <Form.Label>Country string</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter country string" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs sm={6}>
                        <Controller
                            control={control}
                            name="countryText"
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, }) => (
                                <Form.Group className="mb-3" controlId="countryText">
                                    <Form.Label>Country text</Form.Label>
                                    <Form.Control type="text" value={value} onChange={onChange} placeholder="Enter country text" required />
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs sm={6}>
                        <Controller
                            control={control}
                            name="alphaCode"
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, }) => (
                                <Form.Group className="mb-3" controlId="alphaCode">
                                    <Form.Label>Select ISO code</Form.Label>
                                    <Form.Select aria-label="Default select example" value={value} onChange={onChange} required>
                                        <option value="" disabled>Open this select menu</option>
                                        {
                                            countries.map((country, index) => {
                                                return (
                                                    <option key={index} value={country.iso2}>{country.country}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            )}
                        />
                    </Col>
                    <Col xs sm={6}>
                        <Controller
                            control={control}
                            name="canImport"
                            render={({ field: { onChange, value }, }) => (
                                <Form.Group className="mb-3" controlId="canImport">
                                    <Form.Label>Can Import ?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="isImport"
                                        checked={value}
                                        onChange={onChange}
                                    />
                                </Form.Group>
                            )}
                        />
                    </Col>
                </Row>
                <hr />
                <div className="w-100 text-center">
                    {
                        fetching ?
                            <Button disabled variant="primary" type="button">
                                <Spinner animation="border" role="status" variant="light" size="sm" />
                            </Button>
                            :
                            <Button variant="primary" type="submit">Submit</Button>
                    }
                </div>
            </Form>
        </div>
    )
}
