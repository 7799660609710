import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Table, Button, Card, Col, Form, Toast, ToastContainer, Row, Badge, Image, Pagination, Modal } from 'react-bootstrap'
import { copyIcon, phoneIcon, sortAscIcon, sortDescIcon, sortIcon, refreshIcon } from '../../FontAwesome';
import VoiceCall from '../Misc/VoiceCall';
import { fetchC2CRequestOrders } from '../../app/features/order/orderSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import No_Image_Placeholder from '../../assets/images/No-Image-Placeholder.png'


function Details({ order }) {

    const createdAtDateObj = new Date(order.createdAt)
    const fromCounter = order.firstMile?.fromCounter
    let totalCost = order.cost?.amountCollectedFromCustomer

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="orderID">
                        <Form.Label>Order id</Form.Label>
                        <Form.Control value={order.sm_orderid ? order.sm_orderid : order._id} type="text" placeholder="Order id" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>From Name</Form.Label>
                        <Form.Control value={order.start?.contact?.name} type="text" placeholder="Sender's name" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>From Contact</Form.Label>
                        <Form.Control value={order.start?.contact?.phone} type="text" placeholder="Sender's contact" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="startAddr">
                        <Form.Label>From address</Form.Label>
                        {fromCounter ?
                            <Form.Control value={"Booked from Counter " + order.midMile?.startStation?.address?.fromGoogle?.city} type="text" placeholder="Address" readOnly />
                            :
                            <Form.Control value={(order.start?.address?.houseNo || "") + " " + (order.start?.address?.buildingName || "") + " " + order.start?.address?.fromGoogle?.address} type="text" placeholder="Address" readOnly />
                        }
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>To Name</Form.Label>
                        <Form.Control value={order.end?.contact?.name} type="text" placeholder="Receiver's name" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="shipper">
                        <Form.Label>To Contact</Form.Label>
                        <Form.Control value={order.end?.contact?.phone} type="text" placeholder="Receiver's contact" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="endAddr">
                        <Form.Label>To address</Form.Label>
                        <Form.Control value={(order.end?.address?.houseNo || "") + " " + (order.end?.address?.buildingName || "") + " " + order.end?.address?.fromGoogle?.address} type="text" placeholder="Address" readOnly />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-3" controlId="bookedAt">
                        <Form.Label>Booked at</Form.Label>
                        <Form.Control value={createdAtDateObj.toDateString() + ", " + createdAtDateObj.getHours() + ":" + createdAtDateObj.getMinutes()} type="text" placeholder="Booked at" readOnly />
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}

function Package({ packageData, packageImgURL }) {
    return (
        <>
            <Row sm={12}>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="manifestUrl">
                        {/* <Form.Label>Package Image</Form.Label> */}
                        <div style={{ maxHeight: '200px' }}>
                            {
                                packageImgURL ?
                                    <Image src={packageImgURL} style={{ maxHeight: '200px', width: '100%' }} fluid rounded alt={packageImgURL ? packageImgURL : 'package_image'} />
                                    : <Image src={No_Image_Placeholder} style={{ maxHeight: '200px', width: '100%' }} thumbnail rounded alt='no_package' />
                            }
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            {
                packageData?.map((packageEl, i) => {
                    return (
                        <>
                            <Row sm={12}>
                                <Col sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                                    Package #{i + 1}
                                </Col>
                                {/* <Col>
                                    <Row sm={6}> */}
                                <Col sm={3}>
                                    <Form.Group className="mb-3" controlId="weight">
                                        <Form.Label>LxBxH(in cm)</Form.Label>
                                        <Form.Control value={packageEl.l + " x " + packageEl.b + " x " + packageEl.h} type="text" placeholder="lxbxh" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group className="mb-3" controlId="weight">
                                        <Form.Label>Vol. Weight</Form.Label>
                                        <Form.Control value={(packageEl.volumetricWeight ? packageEl.volumetricWeight : (parseFloat(packageEl.l) * parseFloat(packageEl.b) * parseFloat(packageEl.h)) / 2500) + "KG"} type="text" placeholder="weight" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group className="mb-3" controlId="weight">
                                        <Form.Label>Weight</Form.Label>
                                        <Form.Control value={packageEl.weight + "KG"} type="text" placeholder="weight" readOnly />
                                    </Form.Group>
                                </Col>
                                {/* </Row>
                                </Col> */}
                            </Row>
                        </>
                    )
                })
            }
        </>
    )
}

function OrderDetail({ show, handleClose, order }) {
    const dispatch = useDispatch();

    const { fetching } = useSelector(state => state.order);

    const {  package: packageData } = order

    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg' centered style={{ backdropFilter: 'blur(20px)' }}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>
                        <span>Order details</span>
                    </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    {
                        fetching ?
                            <div className='d-flex container justify-content-center align-items-center flex-grow-1'>
                                <Spinner animation="border" />
                            </div>
                            :
                            <>
                                <Card className='mb-3'>
                                    <Card.Header className='fw-bold fs-5'>Order Details</Card.Header>
                                    <Card.Body>
                                        <Details order={order} />
                                    </Card.Body>
                                </Card>
                                <Card className='mb-3'>
                                    <Card.Header className='fw-bold fs-5'>Package {order?.numberOfPackages ? `(${order?.numberOfPackages} packages mentioned by user)` : null}</Card.Header>
                                    <Card.Body style={{ maxHeight: '400px', overflow: 'scroll' }}>
                                        <Package packageData={packageData} packageImgURL={order.packageImgURL} />
                                    </Card.Body>
                                </Card>
                            </>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

function OrderRow({ order, setIsTextCopied, setNumberToCall, setShowVoiceCallModal, setShowInvoicePreviewModal }) {
    const dispatch = useDispatch();

    const [busy, setBusy] = useState(false);

    const [showCalculateEstimateModal, setShowCalculateEstimateModal] = useState(false);
    const [showConvertOrderModal, setShowConvertOrderModal] = useState(false);
    const [showOrderDetail, setShowOrderDetail] = useState(false)

    const copyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        setIsTextCopied(true)
        setTimeout(() => {
            setIsTextCopied(false)
        }, 3000);
    }


    const createdAtDateObj = new Date(order.createdAt)
    const fromCounter = order.firstMile?.fromCounter

    let totalPackageWeight = order?.package?.reduce((prevValue, pkg) => prevValue + (pkg.weight || 0), 0)
    let totalVolWeight = order?.package?.reduce((prevValue, pkg) => prevValue + ((pkg.l * pkg.b * pkg.h) / 5000 || 0), 0)


    const orderid = order.sm_orderid?.split("-")
    return (
        <>
            <tr>
                <td>
                    <div>
                        {createdAtDateObj.toDateString() + ", " + createdAtDateObj.toLocaleTimeString()}
                    </div>
                    {/* <hr />
                    {orderid?.[0] + '-'}<OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="stn-tooltip" style={{ width: 'fit-content' }}>{order.firstMile?.city}&nbsp;to&nbsp;{order.lastMile?.city}</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <span ref={ref} {...triggerHandler} style={{ cursor: 'pointer', fontWeight: 600 }}>{orderid?.[1] + '-' + orderid?.[2]}</span>
                        )}
                    </OverlayTrigger>{'-' + orderid?.[3]}
                    <span style={{ cursor: 'pointer' }} onClick={() => copyText(order.sm_orderid)}>&nbsp;{copyIcon}</span>
                    <hr /> */}
                    {/* <div className='fw-bold'>
                        Payment: <span style={{ textTransform: 'capitalize', color: order?.paymentStatus?.toLowerCase() === 'pending' || order?.paymentStatus?.toLowerCase() === 'failed' ? 'red' : 'green' }}>{order?.paymentStatus}</span>
                    </div> */}
                </td>
                <td>
                    <div>
                        <b>From:</b>&nbsp;{order.start.contact.name}<br />
                        <b>Phone: </b>&nbsp;{order.start.contact.phone}<span style={{ cursor: 'pointer', float: 'right' }} onClick={() => { setNumberToCall(order.start?.contact?.phone); setShowVoiceCallModal(true) }}>&nbsp;{phoneIcon}</span><br />
                        <b>Address:</b>&nbsp;{order?.start?.address?.fromGoogle?.address}
                        {
                            fromCounter &&
                            <Badge bg="primary">
                                Booked From Counter
                            </Badge>
                        }
                    </div>
                    {/* <hr /> */}
                    {/* <div>
                        <p className='fw-bold mb-1'>Available Agents:</p>
                        {
                            order?.stakeholders?.firstMileAgents?.length ? order?.stakeholders?.firstMileAgents?.map((agent, index) => {
                                return (
                                    <Card body key={index}>
                                        <b>Agent:</b>&nbsp;{agent.agentId?.companyDisplayName}<br />
                                        <b>Estimated Distance:</b>&nbsp;{agent.distance} KM<br />
                                        <b>Estimated Cost:</b>&nbsp;{agent.cost} ₹<br />
                                    </Card>
                                )
                            })
                                : 'No Agents Available'
                        }
                    </div> */}
                </td>
                <td>
                    <div>
                        <b>To:</b>&nbsp;{order.end.contact.name}<br />
                        <b>Phone: </b>&nbsp;{order.end.contact.phone}<span style={{ cursor: 'pointer', float: 'right' }} onClick={() => { setNumberToCall(order.end?.contact?.phone); setShowVoiceCallModal(true) }}>&nbsp;{phoneIcon}</span><br />
                        <b>Address:</b>&nbsp;{order?.end?.address?.fromGoogle?.address}
                    </div>
                    {/* <hr />
                    <p className='fw-bold mb-1'>Available Agents:</p>
                    {
                        order?.stakeholders?.lastMileAgents?.length ? order?.stakeholders?.lastMileAgents?.map((agent, index) => {
                            return (
                                <Card body key={index}>
                                    <b>Agent:</b>&nbsp;{agent.agentId?.companyDisplayName}<br />
                                    <b>Estimated Distance:</b>&nbsp;{agent.distance} KM<br />
                                    <b>Estimated Cost:</b>&nbsp;{agent.cost} ₹<br />
                                </Card>
                            )
                        })
                            : 'No Agents Available'
                    } */}
                </td>
                <td>
                    <div>
                        <b>Weight:</b>&nbsp;{totalPackageWeight} KG<br />
                        <b>Vol. Weight:</b>&nbsp;{totalVolWeight} KG<br />
                    </div>

                    {/* <hr /> */}

                    {/* <div>
                        <b>Estimated Midmile Cost:</b>&nbsp;{order?.midMile?.cost} ₹<br />
                    </div> */}
                </td>
                <td>
                    <Button
                        onClick={() => { setShowOrderDetail(true) }}
                        style={{ width: '100%', marginBottom: '5px' }}
                        variant='success'>Details
                    </Button> <br />
                    {/* <Button
                        onClick={() => { setShowCalculateEstimateModal(true) }}
                        style={{ width: '100%', marginBottom: '5px' }}
                        variant='dark'>Calculate
                    </Button> <br />
                    <Button
                        onClick={() => { setShowConvertOrderModal(true) }}
                        style={{ width: '100%', marginBottom: '5px' }}
                        variant='primary'>Convert
                    </Button> <br /> */}
                </td>
            </tr >
            {showOrderDetail && <OrderDetail show={showOrderDetail} handleClose={() => { setShowOrderDetail(false); }} order={order} />}
            {/* {showCalculateEstimateModal && <CalculateEstimateModal show={showCalculateEstimateModal} handleClose={() => setShowCalculateEstimateModal(false)} order={order} />} */}
            {/* {showConvertOrderModal && <ConvertOrderModal show={showConvertOrderModal} handleClose={() => setShowConvertOrderModal(false)} order={order} />} */}
        </>
    )
}

function OrderTable({ superuser, onScroll, setSortedResults, sortType, sortedResults, setSortType, firstMileStatus, lastMileStatus, midMileStatus }) {
    const dispatch = useDispatch();
    const { orders, fetching } = useSelector(state => state.apsrtcBooking)

    const [isTextCopied, setIsTextCopied] = useState(false);
    const [orderData, setOrderData] = useState(undefined)

    const [filterOrderStatus, setFilterOrderStatus] = useState('');
    const [startCityFilter, setStartCityFilter] = useState('');
    const [endCityFilter, setEndCityFilter] = useState('');
    const [priorityPickupFilter, setPriorityPickupFilter] = useState('');

    const [numberToCall, setNumberToCall] = useState('');
    const [showVoiceCallModal, setShowVoiceCallModal] = useState(false);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [showPackageImgModal, setShowPackageImgModal] = useState(false);
    const [showInvoicePreviewModal, setShowInvoicePreviewModal] = useState(false);

    const handleSort = (type) => {
        const sortingOrder = sortType.split("-")[1]
        if (sortingOrder === 'desc') {
            setSortType(type + "-" + "asc")
        } else if (sortingOrder === "asc") {
            setSortType(type + "-" + "desc")
        }
    }

    useEffect(() => {
        setSortedResults(orders)
        setSortType('createdAt-desc')
    }, [orders, filterOrderStatus, startCityFilter, endCityFilter])

    return (
        <>
            {
                isTextCopied && <ToastContainer position='top-center' style={{ zIndex: '10000' }}>
                    <Toast bg='info' delay={3000} autohide style={{ margin: '20px', fontSize: '20px', textAlign: 'center' }}>
                        <Toast.Body>Item Copied</Toast.Body>
                    </Toast>
                </ToastContainer>
            }
            <Table onScroll={onScroll} bordered hover responsive className='mt-3' style={{ minHeight: '100vh', overflowY: 'scroll' }}>
                <thead style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <th className='col-2'>
                            Order Details&nbsp;
                            {sortType.split("-")[0] !== "createdAt" && <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortIcon}</span>}
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "asc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortAscIcon}</span>
                            }
                            {
                                (sortType.split("-")[0] === "createdAt" && sortType.split("-")[1] === "desc") &&
                                <span style={{ cursor: 'pointer' }} onClick={() => handleSort("createdAt")}>{sortDescIcon}</span>
                            }
                        </th>
                        <th className='col-4'>First Mile</th>
                        <th className='col-4'>Last Mile</th>
                        <th className='col-2'>
                            Weight
                        </th>
                        <th className='col-1'>Actions</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        fetching ?
                            <tr><td colSpan={8} style={{ width: '100%', textAlign: 'center', padding: '20px' }}>Loading...</td></tr>
                            :
                            sortedResults.length > 0 ?
                                sortedResults?.map((order, index) => {
                                    return (
                                        <OrderRow
                                            key={"apsrtc_order_" + index} setIsTextCopied={setIsTextCopied} order={order} index={index}
                                            setNumberToCall={setNumberToCall} setShowFileUploadModal={setShowFileUploadModal} setShowInvoicePreviewModal={setShowInvoicePreviewModal}
                                            setShowPackageImgModal={setShowPackageImgModal} setShowVoiceCallModal={setShowVoiceCallModal}
                                        />
                                    )
                                }) :
                                <tr><td colSpan={8} style={{ width: '100%', textAlign: 'center', padding: '20px' }}>No Orders to show</td></tr>
                    }
                </tbody>
            </Table>
            {/* {showOrderDetail && orderData && <OrderDetail show={showOrderDetail} handleClose={() => { setShowOrderDetail(false); setOrderData(undefined) }} order={orderData} />} */}
            {showVoiceCallModal && <VoiceCall show={showVoiceCallModal} handleClose={() => setShowVoiceCallModal(false)} numberToCall={numberToCall} orderType='apsrtc' orderId={orderData?.sm_orderid} />}
        </>
    )
}

export default function C2CRequestOrders() {
    // const { fetching } = useSelector(state => state.apsrtc);
    const { fetching, requestOrders: orders, totalPages } = useSelector(state => state.order)

    const { adminUser } = useSelector(state => state.adminUser)
    const dispatch = useDispatch();
    const firstLoad = useRef(true)
    const searchInputFirstLoad = useRef(false);


    const [searchString, setSearchString] = useState('')
    const [page, setPage] = useState(1)
    const [allOrdersFound, setAllOrdersFound] = useState(false)
    const [exportBusy, setExportBusy] = useState(false)

    const [sortType, setSortType] = useState("createdAt-desc")
    const [pendingOrderType, setPendingOrderType] = useState('firstMile')

    const [sortedResults, setSortedResults] = useState(orders ? orders : [])
    const [firstMileStatus, setFirstMileStatus] = useState([]);
    const [lastMileStatus, setLastMileStatus] = useState([]);
    const [midMileStatus, setMidMileStatus] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterOrderStatusArr, setFilterOrderStatusArr] = useState([]);
    const [filterOrderStatus, setFilterOrderStatus] = useState('');
    const [startCityFilter, setStartCityFilter] = useState('');
    const [endCityFilter, setEndCityFilter] = useState('');
    const [orderFlowType, setOrderFlowType] = useState('')


    const nextPage = (e) => {
        setPage(page + 1)
    }

    const resetFilters = () => {
        setFilterOrderStatusArr([]);
        setStartCityFilter('');
        setEndCityFilter('');
        setStartDate(null);
        setEndDate(null);
    }

    const manageOrderStatusFilterArr = (status) => {
        if (status == 'remove') {
            setFilterOrderStatusArr([]);
            return;
        }

        let tempArr = filterOrderStatusArr;
        if (tempArr.includes(status)) {
            tempArr = tempArr.filter(e => e !== status)
        } else {
            tempArr.push(status)
        }

        setFilterOrderStatusArr(tempArr);

    }

    const getFilterQueryParams = () => {
        let queryParams = {};
        queryParams.page = page;
        queryParams.limit = 20;
        queryParams.shipperId = 'apsrtc'
        if (startCityFilter) {
            queryParams["startCity"] = startCityFilter;
        }
        if (endCityFilter) {
            queryParams["endCity"] = endCityFilter;
        }
        if (filterOrderStatus) {
            queryParams["orderStatus"] = filterOrderStatus;
        }
        if (startDate) {
            queryParams["startDate"] = startDate;
        }
        if (endDate) {
            queryParams["endDate"] = endDate;
        }
        if (searchString) {
            queryParams.searchString = searchString
        }
        if (orderFlowType) {
            queryParams["orderType"] = orderFlowType;
        }
        return queryParams
    }

    const fetchAllRequestOrders = () => {
        let queryParams = getFilterQueryParams();
        dispatch(fetchC2CRequestOrders(queryParams))
            .then(() => { })
            .catch(() => {
                setAllOrdersFound(true);
            })
    }


    useEffect(() => {
        setSortedResults(orders)
    }, [orders])

    useEffect(() => {
        setPage(1)
        fetchAllRequestOrders();
    }, [startCityFilter, endCityFilter, filterOrderStatus, filterOrderStatusArr, startDate, endDate, orderFlowType])

    useEffect(() => {
        if (!firstLoad.current) {
            fetchAllRequestOrders();
        } else {
            firstLoad.current = false
        }
    }, [page])


    useEffect(() => {
        const [attr, type] = sortType.split("-")
        let temp = [...sortedResults]
        if (attr === "updatedAt" || attr === "createdAt") {
            if (type === "asc") {
                temp.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))
                console.log(temp)
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) =>
                    new Date(b[attr]) - new Date(a[attr])
                )
                setSortedResults(temp)
            }
        } else {
            if (type === "asc") {
                temp.sort((a, b) => a[attr].toLowerCase().localeCompare(b[attr].toLowerCase()))
                setSortedResults(temp)
            }
            else if (type === "desc") {
                temp.sort((a, b) => b[attr].toLowerCase().localeCompare(a[attr].toLowerCase()))
                setSortedResults(temp)
            }
        }
    }, [sortType])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let queryParams = getFilterQueryParams();
            if (searchInputFirstLoad.current) {
                fetchAllRequestOrders();
            } else {
                searchInputFirstLoad.current = true
            }
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchString])


    return (
        <div style={{ padding: '30px' }}>
            <div className='d-flex'>
                <h3 style={{ flex: 1, textTransform: 'capitalize' }}>Request Orders</h3>
                <div>
                    <Button onClick={() => { fetchAllRequestOrders() }} className='ms-2' variant='secondary'>{refreshIcon}&nbsp;Refresh</Button>
                </div>
                {/* <div>
                    <Button disabled={exportBusy} onClick={() => fetchAllApsrtcOrdersForExport()} className='ms-2' variant='success'><FontAwesomeIcon icon={faFileExcel} />&nbsp;{exportBusy ? "Exporting..." : "Export Sheet"}</Button>
                </div> */}
            </div>
            <hr />
            {/* <Row>
                <Col lg={2}>
                    <Dropdown style={{ display: 'inline-block', zIndex: '1000', width: '100%' }} onSelect={(orderFlow) => setOrderFlowType(orderFlow)}>
                        <Dropdown.Toggle
                            as={CustomToggle} id="order-flow-dropdown" label="Filter by Order Flow"
                            active={orderFlowType ? true : false}
                        >
                            {orderFlowType ? orderFlowType === 'inflow' ? 'First Mile' : 'LastMile' : "Apply filter"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomMenu}>
                            <Dropdown.Item eventKey="">Remove filter</Dropdown.Item>
                            <Dropdown.Item eventKey="inflow" active={orderFlowType === "inflow"}>First Mile</Dropdown.Item>
                            <Dropdown.Item eventKey="outflow" active={orderFlowType === "outflow"}>Last Mile</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row >
            <hr /> */}
            <div style={{ display: 'flex' }}>
                <Pagination style={{ marginRight: '20px' }}>
                    <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || fetching} />
                    <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || fetching} />
                    {page - 2 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                    {page - 1 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                    <Pagination.Item active>{page}</Pagination.Item>
                    {totalPages >= page + 1 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                    {totalPages >= page + 2 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                    <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || fetching} />
                    <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || fetching} />
                </Pagination>

                <div style={{ height: '38px', flex: 1 }}>
                    <input style={{ height: '100%' }} type="text" className='form-control' placeholder='Search order ID, phone number or LR number' value={searchString} onChange={e => setSearchString(e.currentTarget.value)} />
                </div>
                <div style={{ marginLeft: '10px', marginTop: '-22px' }}>
                    <div style={{ fontWeight: 800, fontSize: '10px', paddingTop: '8px' }}><FontAwesomeIcon icon={faFilter} />&nbsp;Filter by Date:</div>
                    <div style={{ display: 'flex' }}>
                        <Form.Group controlId="filter">
                            <Form.Control style={{ cursor: 'pointer', borderColor: startDate ? '#0d6efd' : undefined, color: startDate ? '#0d6efd' : undefined, borderRadius: '0.2rem' }} type="date" value={startDate} disabled={fetching} onChange={e => setStartDate(e.target.value)} />
                        </Form.Group>
                        <div>&nbsp;To</div>
                        <Form.Group className='ms-3' controlId="filter">
                            <Form.Control style={{ cursor: 'pointer', borderColor: endDate ? '#0d6efd' : undefined, color: endDate ? '#0d6efd' : undefined, borderRadius: '0.2rem' }} type="date" value={endDate} disabled={fetching} onChange={e => setEndDate(e.target.value)} />
                        </Form.Group>
                    </div>
                </div>
            </div >
            {sortedResults && <OrderTable superuser={adminUser.displayName == 'super'} sortType={sortType} setSortType={setSortType} setSortedResults={setSortedResults} sortedResults={sortedResults} firstMileStatus={firstMileStatus} lastMileStatus={lastMileStatus} midMileStatus={midMileStatus} pendingOrderType={pendingOrderType} />
            }

            <Pagination>
                <Pagination.First onClick={() => setPage(1)} disabled={(page === 1) || fetching} />
                <Pagination.Prev onClick={() => setPage(page - 1)} disabled={(page - 1 <= 0) || fetching} />
                {page - 2 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item>}
                {page - 1 > 0 && <Pagination.Item disabled={fetching} onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                <Pagination.Item active>{page}</Pagination.Item>
                {totalPages >= page + 1 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}
                {totalPages >= page + 2 && <Pagination.Item disabled={fetching} onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item>}
                <Pagination.Next onClick={() => setPage(page + 1)} disabled={(totalPages < page + 1) || fetching} />
                <Pagination.Last onClick={() => setPage(totalPages)} disabled={(totalPages <= page) || fetching} />
            </Pagination>
        </div>
    )
}

